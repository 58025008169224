import { useEffect } from "react";

const useReadOnlyInputs = (readOnly) => {
  useEffect(() => {
    const inputs = document.querySelectorAll("input, select, textarea");
    if (readOnly) {
      inputs?.forEach((input) => {
        input.readOnly = true;
        input.ariaReadOnly = true;
        input.disabled = true;
        input.ariaDisabled = true;
      });
    } else {
      inputs?.forEach((input) => {
        input.readOnly = false;
        input.ariaReadOnly = false;
        input.disabled = false;
        input.ariaDisabled = false;
      });
    }
  }, [readOnly]);
};

export default useReadOnlyInputs;
