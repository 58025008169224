import MenuItems from "generalComponents/DataTable/MenuItems";
import { HiDotsVertical } from "react-icons/hi";

export const validTableKeys = {
  vendor: {
    title: "Name",
    addBadge: false,
    isImage: false,
    addCopyPreview: false,
    showOnMobile: true,
  },
  title: {
    title: "Account Name",
    addBadge: false,
    isSortable: false,
    isImage: false,
    addCopyPreview: false,
    showOnMobile: false,
  },
  link: {
    title: "Account number",
    addBadge: false,
    isSortable: false,
    isImage: false,
    addCopyPreview: false,
  },
  church: {
    title: "Church",
    addBadge: false,
    isSortable: false,
    isImage: false,
    addCopyPreview: false,
    sortByString: false,
    showOnMobile: true,
  },
  menu: {
    title: "",
    addBadge: false,
    isSortable: false,
    isImage: false,
    addCopyPreview: false,
    showOnMobile: true,
  },
};

export function modifyData(data, setSelectedLink) {
  if (!data.length) return [];
  const newArray = data?.map((item) => ({
    ...item,
    church: item?.campus?.name,
    menu: (
      <MenuItems
        itemExtraClass={""}
        items={[
          {
            label: "Edit",
            onClick: () => {
              setSelectedLink(item);
              document.getElementById("add_paymentLink").showModal();
            },
            disabled: false,
          },
          {
            label: "Delete",
            onClick: () => {
              setSelectedLink(item);
              document.getElementById("admin_delete").showModal();
            },
            disabled: false,
          },
        ]}
      >
        <HiDotsVertical className="h-5 w-5" />
      </MenuItems>
    ),
  }));

  return newArray;
}
