import HeaderFooter from "generalComponents/HeaderFooter";
import TextGradient from "generalComponents/TextGradient";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { CircleArrowLeft, CircleArrowRight } from "../components/Icons";
// import { homeAboutUs } from "assets/images";
import SermonHScroll from "../components/SermonHScroll";
import CategoriesNav from "generalComponents/CategoriesNav";
import TabComponent from "generalComponents/TabComponent";
// import CategoryRow from "generalComponents/CategoryRow";
import AudioItem from "generalComponents/AudioItem";
import VideoItem from "generalComponents/VideoItem";
import useGetCategories from "../controllers/get_categories_controller";
import useGetSermons from "../controllers/get_sermons_contoller";
// import { useSearchParams } from "react-router-dom";
import useQueryParams from "constants/useQueryParams";
import CustomPagination from "generalComponents/CustomPagination";
import EmptyState from "generalComponents/EmptyState";
import SermonDetails from "generalComponents/SermonDetails";

const tabs = ["Video", "Audio"];

const debounce = (func, wait) => {
  let timeout;

  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

const Sermons = () => {
  const { params, setParam, deleteParam } = useQueryParams({
    page: 1,
    pageSize: 28,
  });
  const [selectedVideo, setSelectedVideo] = useState();
  const [selectedAudio, setSelectedAudio] = useState();
  const [tabIdx, setTabIdx] = useState(0);
  const tilesRef = useRef();

  const onSelectVideo = (data) => {
    setSelectedVideo(data);
  };
  const onCloseVideoDetails = () => {
    setSelectedVideo(undefined);
  };
  const onSelectAudio = (data) => {
    setSelectedAudio(data);
  };
  const onCloseAudioDetails = () => {
    setSelectedAudio(undefined);
  };

  const tabUpdate = (type) => {
    setParam("content_type", type?.toLowerCase());
  };

  const tileScrollLeft = () => {
    return (tilesRef.current.scrollLeft = -500);
  };
  const tileScrollRight = () => {
    return (tilesRef.current.scrollLeft = +500);
  };

  const { data: sermonList, isLoading: sermonLoading } = useGetSermons({
    group: "sermon",
    ...params,
  });

  const { data: categories } = useGetCategories({
    group: "sermon",
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce(
      (value) =>
        value === "" ? deleteParam("title") : setParam("title", value),
      1000
    ),
    []
  );

  const tabContent = [
    sermonList?.data?.items?.length > 0 ? (
      <div className="flex flex-wrap gap-5 mx-auto">
        {sermonList?.data?.items?.map((message, mIndex) => (
          <VideoItem
            key={mIndex}
            message={message}
            onShowDetails={onSelectVideo}
          />
        ))}
      </div>
    ) : (
      <EmptyState
        heading="Whoops!"
        message="Looks like this category has no video sermons at the moment. But don't worry, there's a treasure trove of inspiring content waiting for you in other categories. Go ahead, explore and be uplifted!"
        scriptureText={
          "Let the message of Christ dwell among you richly as you teach and admonish one another with all wisdom through psalms, hymns, and songs from the Spirit, singing to God with gratitude in your hearts."
        }
        scripture={"Colossians 3:16 (NIV)"}
      />
    ),
    sermonList?.data?.items?.length > 0 ? (
      <div className="flex flex-wrap gap-5 mx-auto">
        {sermonList?.data?.items?.map((message, mIndex) => (
          <AudioItem
            key={mIndex}
            message={message}
            onShowDetails={onSelectAudio}
          />
        ))}
      </div>
    ) : (
      <EmptyState
        heading="Ohh no!"
        message="It seems this category has no audio sermons at the moment. Tune into other categories and discover the powerful messages awaiting you!"
        scriptureText={
          "Therefore everyone who hears these words of mine and puts them into practice is like a wise man who built his house on the rock."
        }
        scripture={"Matthew 7:24 (NIV)"}
      />
    ),
  ];

  const tiles = sermonList?.data?.items
    ?.filter((item, index) => index < 3)
    ?.map((sermon) => ({
      data: sermon,
      image: sermon?.cover_image?.url,
      title: sermon?.title,
      author: sermon?.facilitator,
    }));

  useEffect(() => {
    if (!params?.content_type) {
      setParam("content_type", "video");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (params?.content_type === "video") {
      setTabIdx(0);
    } else if (params?.content_type === "audio") {
      setTabIdx(1);
    }
  }, [params]);

  useEffect(() => {
    if (selectedVideo !== undefined) {
      window["video-details"]?.showModal();
    } else {
      window["video-details"]?.close();
    }
  }, [selectedVideo]);
  useEffect(() => {
    if (selectedAudio !== undefined) {
      window["audio-details"]?.showModal();
    } else {
      window["audio-details"]?.close();
    }
  }, [selectedAudio]);
  return (
    <HeaderFooter solid>
      <TextGradient>Sermons</TextGradient>
      <div className="pt-[300px] pb-20">
        <div className="flex justify-between items-center mb-3 max-w-section mx-auto px-5">
          <h1 className="font-semibold text-[52px] lg:text-[60px] lg:leading-[70px] text-purpleOverlay">
            Sermons
          </h1>

          <div className="flex justify-end items-center gap-2 [&>*]:cursor-pointer">
            <CircleArrowLeft onClick={tileScrollLeft} />
            <CircleArrowRight onClick={tileScrollRight} />
          </div>
        </div>

        <SermonHScroll ref={tilesRef} sermons={tiles} />

        <div className="max-w-section mx-auto mt-10 px-5">
          <div className="flex flex-col md:flex-row gap-5 md:items-center justify-between mb-6">
            <p className="font-medium text-[30px] text-purpleOverlay">
              Categories
            </p>
            <div className="relative">
              <input
                type="search"
                onChange={(e) => handleSearch(e.target?.value)}
                placeholder="Search"
                className="h-[50px] min-w-[300px] bg-[#F2F2F2] text-purpleOverlay placeholder:text-gray-400 outline-none border-none p-3 pl-14 rounded-[50px] accent-purpleBg"
              />
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="absolute inset-0 left-3 my-auto"
              >
                <path
                  d="M11.9791 21.8757C17.4445 21.8757 21.875 17.4451 21.875 11.9798C21.875 6.51449 17.4445 2.08398 11.9791 2.08398C6.51382 2.08398 2.08331 6.51449 2.08331 11.9798C2.08331 17.4451 6.51382 21.8757 11.9791 21.8757Z"
                  stroke="#8311B3"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M22.9166 22.9173L20.8333 20.834"
                  stroke="#8311B3"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>

          <CategoriesNav categoryList={categories?.data?.categories ?? []} />

          <TabComponent
            tabButtons={tabs}
            tabContent={tabContent}
            isLoading={sermonLoading}
            tabIdx={tabIdx}
            tabUpdate={tabUpdate}
          />

          {sermonList?.data?.pagination?.total > 0 && (
            <div className="flex justify-end mt-10 min-w-full">
              <CustomPagination
                count={parseInt(sermonList?.data?.pagination?.total)}
                page={parseInt(params?.page)}
                pageSize={parseInt(params?.pageSize) ?? 20}
                setPage={(newPage) => setParam("page", newPage)}
                isLoading={sermonLoading}
              />
            </div>
          )}
        </div>
      </div>

      {selectedVideo && (
        <SermonDetails
          onClose={onCloseVideoDetails}
          message={selectedVideo}
          id={"video-details"}
          type="video"
          title="Video Details"
        />
      )}

      {selectedAudio && (
        <SermonDetails
          onClose={onCloseAudioDetails}
          message={selectedAudio}
          id={"audio-details"}
          type="audio"
          title="Audio Details"
        />
      )}
    </HeaderFooter>
  );
};

export default Sermons;
