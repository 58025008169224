import CustomButton from "generalComponents/Button";
import InputWithFullBoarder from "generalComponents/InputWithFullBoarder";
import ModalManagement from "generalComponents/ModalManagement";
import React, { useEffect, useRef, useState } from "react";

import useFileUpload from "modules/fileupload/fileUploadController";

import BigUploadFileComponent from "generalComponents/BigUploadFileComponent";
import { deleteSTuf } from "assets/icons";
import { CreateCampusManager } from "../controllers/createCampusController";
import { EditCampusManager } from "../controllers/editCampusController";

const AddEditCampus = ({ details }) => {
  const [media, setMedia] = useState([]);
  const [uploadingMedia, setUploadingMedia] = useState(false);
  const { editCampus, isLoading: updatingCampus } = EditCampusManager(
    details?.id
  );
  const {
    progress,
    error: fileUploadError,
    handleFileUpload,
    isLoading: fileLoading,
    data: uploadUrl,
  } = useFileUpload();
  const { createCampus, isLoading } = CreateCampusManager();

  const initialData = {
    name: "",
    images: [],
    contacts: [
      {
        name: "",
        phone: "",
      },
    ],
    location: {
      address: "",
      city: "",
      state: "",
      country: "",
      long: 0.0,
      lat: 0.0,
    },
  };

  const [formData, setFormData] = useState(initialData);

  const handleAddContact = () => {
    setFormData((prevState) => ({
      ...prevState,
      contacts: [...prevState.contacts, { name: "", phone: "" }],
    }));
  };
  const handleRemoveContact = (index) => {
    setFormData((prevState) => {
      const updatedContacts = [...prevState.contacts];
      updatedContacts.splice(index, 1);
      return { ...prevState, contacts: updatedContacts };
    });
  };
  const handleChange = (e, index, field) => {
    const value = e.target.value;
    setFormData((prevState) => {
      const updatedContacts = [...prevState.contacts];
      updatedContacts[index][field] = value;
      return { ...prevState, contacts: updatedContacts };
    });
  };
  const inputRef = useRef(null);

  useEffect(() => {
    if (details) {
      //update the formData with the value of selected
      setFormData({ ...formData, ...details });
    } else {
      setFormData(initialData);
    }
  }, [details]);

  const handleSubmit = async () => {
    const updatedFormData = { ...formData };

    if (media.length > 0) {
      const fileUrls = [];
      setUploadingMedia(true);
      for (let i = 0; i < media.length; i++) {
        const url = await handleFileUpload(media[i]);
        fileUrls.push(url);
      }
      updatedFormData.images = fileUrls;
      setUploadingMedia(false);
    }
    // console.log(updatedFormData);
    if (details) {
      await editCampus(updatedFormData);
    } else {
      await createCampus(updatedFormData);
    }
    document.getElementById("add_campus").close();
  };

  return (
    <ModalManagement id={"add_campus"}>
      <div className="rounded-[10px] max-w-full   bg-whiteColor ">
        <p className="text-18px mb-4 font-bold">
          {details ? "Edit Campus" : "Create Campus"}
        </p>
        <BigUploadFileComponent
          buttonText={"Upload Cover"}
          inputRef={inputRef}
          isLoading={uploadingMedia}
          image={
            media.length > 0
              ? URL.createObjectURL(media[0])
              : details && formData?.images[0]
          }
          progress={progress}
          accept={"image/*"}
          buttonClick={() => setMedia([])}
          onChange={(e) => {
            const file = e.target.files[0];
            if (file) {
              setMedia([file]);
            } else {
              setMedia([]);
            }
          }}
        />
        <InputWithFullBoarder
          label={"Name"}
          className={"mb-2"}
          value={formData.name}
          placeholder={"Enter name"}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
        />
        <InputWithFullBoarder
          label={"Address"}
          className={"mb-2"}
          value={formData.location.address}
          placeholder={"Enter location"}
          onChange={(e) =>
            setFormData((prevState) => ({
              ...prevState,
              location: { ...prevState.location, address: e.target.value },
            }))
          }
        />
        <InputWithFullBoarder
          label={"City"}
          className={"mb-2"}
          value={formData.location.city}
          placeholder={"Enter city"}
          onChange={(e) =>
            setFormData((prevState) => ({
              ...prevState,
              location: { ...prevState.location, city: e.target.value },
            }))
          }
        />
        <InputWithFullBoarder
          label={"State"}
          className={"mb-2"}
          value={formData.location.state}
          placeholder={"Enter state"}
          onChange={(e) =>
            setFormData((prevState) => ({
              ...prevState,
              location: { ...prevState.location, state: e.target.value },
            }))
          }
        />
        <InputWithFullBoarder
          label={"Country"}
          className={"mb-2"}
          value={formData.location.country}
          placeholder={"Enter country"}
          onChange={(e) =>
            setFormData((prevState) => ({
              ...prevState,
              location: { ...prevState.location, country: e.target.value },
            }))
          }
        />
        <InputWithFullBoarder
          label={"Long"}
          className={"mb-2"}
          type={"number"}
          value={formData.location.long}
          onChange={(e) =>
            setFormData((prevState) => ({
              ...prevState,
              location: {
                ...prevState.location,
                long: parseFloat(e.target.value),
              },
            }))
          }
        />
        <InputWithFullBoarder
          label={"Lat"}
          className={"mb-2"}
          type={"number"}
          value={formData.location.lat}
          onChange={(e) =>
            setFormData((prevState) => ({
              ...prevState,
              location: {
                ...prevState.location,
                lat: parseFloat(e.target.value),
              },
            }))
          }
        />

        {/* for the contacts */}
        <div className="flex items-center justify-between">
          <p className="mb-5 mt-7 font-medium text-blackColor">
            Campus Contacts
          </p>
          <p
            onClick={handleAddContact}
            className="text-[12px] cursor-pointer font-medium text-[#FF8A00] "
          >
            +Add Contact
          </p>
        </div>
        <div className="w-full">
          {formData.contacts.map((contact, index) => (
            <div key={index} className="flex items-center space-x-2">
              <InputWithFullBoarder
                label={"Name"}
                className={"w-[90%]"}
                type={"text"}
                value={contact.name}
                onChange={(e) => handleChange(e, index, "name")}
              />
              <InputWithFullBoarder
                label={"Phone"}
                type={"number"}
                className={"w-[90%]"}
                value={contact.phone}
                onChange={(e) => handleChange(e, index, "phone")}
              />
              <img
                src={deleteSTuf}
                className="cursor-pointer "
                alt=""
                onClick={() => handleRemoveContact(index)}
              />
            </div>
          ))}
        </div>

        <CustomButton
          className={"mt-20"}
          buttonText={details ? "Edit Campus" : "Create Campus"}
          loader={uploadingMedia ? `${progress}% done` : "Loading..."}
          isLoading={uploadingMedia || updatingCampus || isLoading}
          onClick={handleSubmit}
        />
      </div>
    </ModalManagement>
  );
};

export default AddEditCampus;
