import usePostManager from "constants/controller_templates/post_controller_template";

export const CreateChildrenContentManager = () => {
  const { postCaller, isLoading, isSuccess, error, data } = usePostManager(
    `/children/create`,
    ["allChildrenContent"],
    true
  );
  const createContent = async (details) => {
    try {
      await postCaller(details);
    } catch (error) {
      console.error("error:", error);
    }
  };
  return {
    createContent,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
