import HeaderFooter from "generalComponents/HeaderFooter";
import React from "react";
import Banner from "./Banner";
import { cozaLogoWhite } from "assets/icons";
import { useGetCampuses } from "modules/pageChurches/controllers/get_controller";
import GiveOptionsModal from "./GiveOptionsModal";
import GoBackComponent from "generalComponents/GoBackComponent";
import { eChurchLinks } from "../giveLinks";

const ChurchGivings = () => {
  const { data: churches } = useGetCampuses();
  //   const hQ = churches?.data?.filter((church) =>
  //     church?.name?.includes("Guzape")
  //   )[0];
  return (
    <HeaderFooter>
      <section className="grid lg:grid-cols-2 items-center py-10 px-5 gap-12 max-w-section mx-auto pt-48 lg:h-[100svh] overflow-hidden">
        <Banner />
        {/* <GiveOptions /> */}
        <div className="h-full overflow-scroll  flex flex-col gap-5 pb-10 lg:pb-5 px-5">
          <GoBackComponent path={"/coza-give"} />
          {churches?.data?.map((item, index) => (
            <div
              key={index}
              role="button"
              onClick={() => {
                if (item?.paymentChannels[0]?.link?.includes("http")) {
                  window.open(item?.paymentChannels[0]?.link, "_blank");
                } else {
                  window[`church-give-${index}`]?.showModal();
                }
              }}
              className="flex items-center gap-5 p-5 rounded-[20px] shadow-lg border border-purpleOverlay/40"
            >
              <div className="grid place-content-center bg-purpleOverlay p-2 rounded-[10px] size-fit">
                <img src={cozaLogoWhite} alt="" className="!size-10" />
              </div>
              <div className="max-w-[80%]">
                <p className="text-purpleOverlay font-bold">{item?.name}</p>
                <p className="text-xs text-purpleOverlay">
                  {item?.location?.address}
                </p>
              </div>

              <GiveOptionsModal
                // onClose={() => setData(undefined)}
                id={`church-give-${index}`}
                data={item}
                links={
                  item?.name?.toLowerCase()?.includes("e-church")
                    ? {
                        USD: eChurchLinks?.dollar,
                        GBP: eChurchLinks?.pounds,
                      }
                    : undefined
                }
              />
            </div>
          ))}
        </div>
      </section>
    </HeaderFooter>
  );
};

export default ChurchGivings;
