import { cozaLogo } from "assets/icons";
import React from "react";
import { Link } from "react-router-dom";

const NewFooter = () => {
  const year = new Date().getFullYear();
  return (
    <div className="p-6 bg-transparent w-[100svw] relative z-10">
      <div className="bg-purpleOverlay flex flex-col py-14 rounded-[20px] px-10 w-full">
        <div className="flex flex-col items-center">
          <p className="mb-2 font-semibold text-xs">BE BORN AGAIN</p>
          <p className="mb-3 font-bold text-7">SAY THESE WORDS</p>
          <p className="max-w-[858px] text-[13px] text-center">
            Today I confess Jesus, as my Lord and Saviour. I believe He came to
            the earth, I believe He died, and I believe that on the 3rd day, He
            was raised from the dead. Everything Jesus did, by His death, burial
            and resurrection, I receive into my human spirit. I receive the gift
            of eternal life. I am Born Again. Thank You Jesus. Hallelujah!
          </p>
          <div className="mt-8 font-semibold flex flex-col gap-5">
            <p className="text-lg">We’ll be happy to hear from you</p>
            <Link
              to="/support"
              className="rounded-lg py-4 px-6 bg-[#EB5017] text-center"
            >
              Shoot us a DM
            </Link>
          </div>
        </div>
        <div className="flex justify-between items-center pt-8  border-t border-t-white mt-16">
          <img src={cozaLogo} alt="" />
          <span className="text-sm text-[#667185]">
            © {year} COZA. All rights reserved
          </span>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default NewFooter;
