import AxiosWithToken from "constants/api_management/MyHttpHelperWithToken";
import { useQuery } from "react-query";
import { toast } from "react-toastify";

const useGetTicketMessagesManager = ({ ticketId }) => {
  return useQuery({
    queryKey: ["tickets_messages"],
    queryFn: async () => {
      try {
        const response = await AxiosWithToken.get(
          `/support/tickets/${ticketId}/messages`
        );
        return response.data;
      } catch (error) {
        toast.error(error.response?.data?.message);
        throw new Error("Sorry: " + error.response?.data?.message);
      }
    },
    enabled: Boolean(ticketId),
    // refetchOnWindowFocus: false,
  });
};

export default useGetTicketMessagesManager;
