import React from "react";

const PlayButton = (props) => {
  return (
    <svg
      role="button"
      width="56"
      height="62"
      viewBox="0 0 56 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity="0.7"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 9.77249C0 2.22576 8.20024 -2.46434 14.705 1.36201L50.6633 22.514C57.0773 26.2869 57.0773 35.5622 50.6633 39.3349L14.705 60.487C8.20024 64.3133 0 59.6231 0 52.0765V9.77249Z"
        fill="white"
      />
    </svg>
  );
};

export default PlayButton;
