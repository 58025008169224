import { twMerge } from "tailwind-merge";

const ModalManagement = ({
  children,
  id,
  hasClose,
  title,
  titleClass,
  onClose,
  className,
  modalClass,
}) => {
  return (
    <dialog
      id={id}
      className={twMerge(
        "modal modal-middle mx-auto outline-none z-[99]",
        modalClass
      )}
    >
      {/* <div className="modal-backdrop bg-black/30 backdrop-blur-0"></div> */}
      <div
        className={twMerge(
          "modal-box p-5 bg-white shadow-xl text-black pb-12",
          className
        )}
      >
        <div
          className={twMerge(
            "flex justify-between items-center text-lg",
            title && "mb-5",
            titleClass
          )}
        >
          <h2 className="text-[#1D1D1D] text-lg font-semibold">{title}</h2>
          <form method={hasClose ? "" : "dialog"}>
            <button
              onClick={onClose}
              className="rounded-full p-3 duration-300 hover:bg-[#FCF5FF] outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </button>
          </form>
        </div>

        {children}
      </div>

      {/* <div className="modal-action">
        {!hasClose && (
          <form
            method="dialog"
            className="modal-box w-11/12 max-w-5xl bg-whiteColor"
          >
            if there is a button in form, it will close the modal
            <button className="btn bg-brandPurple hover:bg-brandOrange text-whiteColor">
              Cancel
            </button>
          </form>
        )}
      </div> */}
    </dialog>
  );
};

export default ModalManagement;
