import React, { useEffect, useState } from "react";
// import BaseDashboardNavigation from "../../../../generalComponents/BaseDashboardNavigation";
import useGetAppointmentsManager from "../controllers/getAppointmentsController";
import InputWithFullBoarder from "../../../../generalComponents/InputWithFullBoarder";
import SearchComponent from "generalComponents/SearchComponent";
import useDebounce from "utils/UseDebounce";
import AvatarWithTitle from "generalComponents/AvatarWithTitle";
import { formatDate } from "utils/formatDate";
import TablesComponent from "generalComponents/TablesComponent";
import PaginationRounded from "generalComponents/Pagination";
import StatusButton from "generalComponents/StatusButton";
import TabsManagement from "generalComponents/TabsManagement";
import ViewAppointment from "../components/ViewAppointment";
import AdminWrapper from "modules/admin/AdminWrapper";
import DataTable from "generalComponents/DataTable";
import { modifyData, validTableKeys } from "../utils";
import Loader from "generalComponents/Loader";

const AllAdminAppointmentsPage = () => {
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(`&first_name=${searchValue}`, 1000);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selected, setSelected] = useState(null);
  const [tableValues, setTableValues] = useState({
    pageIndex: 0,
    perPage: 20,
  });
  function setter(name, value) {
    setTableValues((val) => ({
      ...val,
      [name]: value,
    }));
  }

  useEffect(() => {
    if (selected) {
      document.getElementById("view_appointment").showModal();
    }
  }, [selected]);

  const tableHeads = ["Name", "Email", "Phone", "Date", "Status", "Action"];
  const getFormattedValue = (el, index) => {
    return [
      <AvatarWithTitle
        image={el.user?.profile_picture}
        title={`${el?.user?.first_name} ${el?.user?.last_name}`}
      />,
      el?.user?.email,
      el?.user?.phone,
      formatDate(el?.createdAt),
      <StatusButton
        status={el?.status === "requested" ? "pending" : el?.status}
      />,
    ];
  };

  const statuses = ["All", "accepted", "declined", "assigned"];

  const { isLoading, data } = useGetAppointmentsManager({
    page: currentPage,
    searchQuery: searchValue ? debouncedSearchValue : "",
    enabled: true,
    status: currentIndex === 0 ? "" : `&status=${statuses[currentIndex]}`,
  });

  function onRowClick(rowData) {
    setSelected(rowData);
    if (rowData?.id === selected?.id) {
      document.getElementById("view_appointment").showModal();
    }
  }

  return (
    <AdminWrapper>
      <div className="flex flex-col h-[85vh] pr-10">
        <div className="flex justify-between flex-col gap-5 md:flex-row w-full mb-10">
          <div className=" flex flex-col items-start">
            <p className="text-[24px] font-semibold">Appointments</p>
            <p className="text-14px">30 Appointments in total</p>
          </div>
        </div>
        <div className="flex items-center justify-between flex-col gap-5 md:flex-row">
          <div className="max-w-[291px]">
            <SearchComponent
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
          <InputWithFullBoarder type={"date"} />
        </div>

        <div className="w-full my-3">
          <TabsManagement
            options={statuses}
            currentView={currentIndex}
            setCurrentView={setCurrentIndex}
          />
        </div>

        {/* user tables */}
        <div className="md:h-[67vh] relative md:overflow-y-auto scrollbar-hide">
          {isLoading ? (
            <Loader isSmall />
          ) : (
            <DataTable
              tableData={modifyData(data?.data?.appointments)}
              totalItemCount={data?.pagination?.total}
              validTableKeys={validTableKeys}
              onRowClick={onRowClick}
              noOfItemsPerPage={tableValues?.perPage}
              onCheckClick={() => null}
              onItemsPerPageChange={(newPage) => setter("perPage", newPage)}
              pageIndex={tableValues?.pageIndex}
              onPageIndexChange={(idx) => setter("pageIndex", idx)}
              showFooter
            />
          )}
          {/* <TablesComponent
            data={data?.data?.appointments}
            headers={tableHeads}
            isLoading={isLoading}
            getFormattedValue={getFormattedValue}
            buttonFunction={(val) => {
              console.log(val);
              setSelected(val);
              if (val?.id === selected?.id) {
                document.getElementById("view_appointment").showModal();
              }
            }}
          /> */}
        </div>

        {/* {
          <div className="mb-10 flex items-center justify-center">
            <PaginationRounded
              count={data?.pagination?.pageTotal}
              defaultPage={data?.pagination?.currentPage}
              onChange={(page) => {
                setCurrentPage(page);
              }}
            />
          </div>
        } */}
      </div>
      {selected && <ViewAppointment details={selected} />}
    </AdminWrapper>
  );
};

export default AllAdminAppointmentsPage;
