import { RoundedButton } from "generalComponents/Button";
import DatePickerInput from "generalComponents/DatePickerInput";
import InputWithFullBoarder from "generalComponents/InputWithFullBoarder";
import RadioGroupInput from "generalComponents/RadioGroupInput";
import { useEffect, useState } from "react";
import FileInput from "generalComponents/FileInput";
import useFileUpload from "modules/fileupload/fileUploadController";
import usePostFamilyProcess from "../controllers/post_controller";
import Loader, { Spinner } from "generalComponents/Loader";
import guarantors from "./guarantors.json";
import SelectInput from "generalComponents/SelectInput";
import { useUserStore } from "store/userStore";
import useQueryParams from "constants/useQueryParams";
import { useGetSingleFamilyProcessSubmission } from "../controllers/get_controller";
import { StatusBadge } from "generalComponents/Badges";
import { capitalizeFirstLetter } from "constants/textFormatters";
import useReadOnlyInputs from "../useReadOnlyInputs";

const guarantorList = guarantors?.map((item) => ({
  label: item,
  value: item,
}));

const partnershipOptions = [
  {
    label: "Partner",
    value: "active",
  },
  {
    label: "Not a Partner",
    value: "inactive",
  },
];

const true_or_false = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

const PreMaritalClassModal = ({ id }) => {
  const { params } = useQueryParams();
  const { data: singleForm, isLoading: singleFormLoading } =
    useGetSingleFamilyProcessSubmission({
      formId: params?.form,
      type: "marital-class",
      enabled: !!params?.form,
    });

  const user = useUserStore((state) => state.user);
  const initialValues = {
    name:
      user?.first_name && user?.last_name
        ? `${user?.first_name} ${user?.last_name}`
        : "",
    partner_email: "",
    phone: user?.phone ?? "",
    address: user?.location?.address ?? "",
    member_duration: "",
    born_again_date: "",
    courtship_duration: "",
    wedding_date: "",
    wedding_location: "",
    guarantor: "",
    partnership_status: "active",
    partnership_id: "",
    allow_social_share: true,
  };
  const [formData, setFormData] = useState(initialValues);
  const [files, setFiles] = useState({
    signature: undefined,
    passport: undefined,
  });
  const [filesSrc, setFilesSrc] = useState({
    signature: undefined,
    passport: undefined,
  });

  const handleFileChange = (e, name) => {
    if (e.target.files[0]) {
      setFiles((val) => ({
        ...val,
        [name]: e.target.files[0],
      }));
      setFilesSrc((val) => ({
        ...val,
        [name]: URL.createObjectURL(e.target.files[0]),
      }));
    }
  };
  const handleChange = (event, name) => {
    const value = event.target?.value;
    setFormData((data) => ({
      ...data,
      [name]: value,
    }));
  };

  const handleBornAgainDate = (date) => {
    setFormData((data) => ({
      ...data,
      born_again_date: date,
    }));
  };
  const handleWeddingDate = (date) => {
    setFormData((data) => ({
      ...data,
      wedding_date: date,
    }));
  };

  const {
    // error: fileUploadError,
    handleFileUpload: uploadFile,
    isLoading: isUploadingFlie,
    // data: uploadUrl,
  } = useFileUpload();

  const { postCaller, isLoading } = usePostFamilyProcess({
    endpoint: "marital-class",
    mutationKey: ["family_process"],
    onSuccess: () => window[id]?.close(),
  });

  function handleFileUpload() {
    return new Promise(async (resolve, reject) => {
      try {
        const uploadPromises = Object.values(files)?.map(async (file) => {
          const fileUrl = await uploadFile(file);
          return fileUrl;
        });

        const uploadedFiles = await Promise.all(uploadPromises);
        resolve(uploadedFiles);
        return uploadedFiles;
      } catch (error) {
        reject(error);
      }
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    // console.log({ formData });
    try {
      const [signature, passport] = await handleFileUpload();

      await postCaller({
        ...formData,
        member_duration: Number(formData.member_duration),
        courtship_duration: Number(formData.courtship_duration),
        allow_social_share:
          formData?.allow_social_share === "true" ? true : false,
        signature,
        passport,
      });

      setFormData(initialValues);
      setFiles({});
      setFilesSrc({});
    } catch (error) {
      console.log({ error });
    }
  }

  useEffect(() => {
    if (singleForm) {
      setFormData({
        name: singleForm?.data?.name,
        partner_email: singleForm?.data?.partner_email,
        phone: singleForm?.data?.phone,
        address: singleForm?.data?.address,
        member_duration: singleForm?.data?.member_duration,
        born_again_date: singleForm?.data?.born_again_date,
        courtship_duration: singleForm?.data?.courtship_duration,
        wedding_date: singleForm?.data?.wedding_date,
        wedding_location: singleForm?.data?.wedding_location,
        guarantor: singleForm?.data?.guarantor,
        partnership_status: singleForm?.data?.partnership_status,
        partnership_id: singleForm?.data?.partnership_id,
        allow_social_share: singleForm?.data?.allow_social_share,
      });
      setFilesSrc({
        signature: singleForm?.data?.signature,
        passport: singleForm?.data?.passport,
      });
    }
  }, [singleForm]);

  useReadOnlyInputs(params?.form !== undefined);

  if (singleFormLoading) return <Loader />;
  return (
    <form onSubmit={handleSubmit} className="w-full">
      {params?.form && (
        <div className="mb-5">
          <StatusBadge
            label={capitalizeFirstLetter(singleForm?.data?.status)}
            status={singleForm?.data?.status}
          />
        </div>
      )}
      <InputWithFullBoarder
        label={"Name"}
        type="text"
        id="name"
        value={formData.name}
        onChange={(e) => handleChange(e, "name")}
        placeholder={"Enter full name"}
        className={"bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-xs"}
        otherProps={{
          required: true,
        }}
      />
      <InputWithFullBoarder
        label={"Phone Number"}
        type="tel"
        id="phone"
        value={formData.phone}
        onChange={(e) => handleChange(e, "phone")}
        placeholder={"Enter phone number"}
        className={"bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-xs"}
        otherProps={{
          required: true,
        }}
      />
      <InputWithFullBoarder
        label={"Partner's Email"}
        type="email"
        id="partner_email"
        value={formData.partner_email}
        onChange={(e) => handleChange(e, "partner_email")}
        placeholder={"Enter your partner's enmail address"}
        className={"bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-xs"}
        otherProps={{
          required: true,
        }}
      />
      <InputWithFullBoarder
        label={"Address"}
        type="text"
        id="address"
        value={formData.address}
        onChange={(e) => handleChange(e, "address")}
        placeholder={"Enter your address"}
        className={"bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-xs"}
        otherProps={{
          required: true,
        }}
      />
      <InputWithFullBoarder
        label={"How long have you been in COZA?"}
        type="number"
        id="member_duration"
        value={formData.member_duration}
        onChange={(e) => handleChange(e, "member_duration")}
        placeholder={"Enter number of years"}
        className={"bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-xs"}
        otherProps={{
          required: true,
        }}
      />

      <DatePickerInput
        label="When did you get born again?"
        name="born_again_date"
        value={formData.born_again_date}
        onChange={handleBornAgainDate}
        placeholder={"Select born again date"}
        disabledPeriod={[
          {
            after: new Date(),
          },
        ]}
      />

      <InputWithFullBoarder
        label={"How long have you courted?"}
        type="number"
        id="courtship_duration"
        value={formData.courtship_duration}
        onChange={(e) => handleChange(e, "courtship_duration")}
        placeholder={"Enter number of years"}
        className={"bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-xs"}
        otherProps={{
          required: true,
        }}
      />

      <DatePickerInput
        label="Proposed Wedding Date"
        name="wedding_date"
        value={formData.wedding_date}
        onChange={handleWeddingDate}
        placeholder={"Select proposed wedding date"}
        disabledPeriod={[
          {
            before: new Date(),
          },
        ]}
      />

      <InputWithFullBoarder
        label={"Wedding Location"}
        type="text"
        id="wedding_location"
        value={formData.wedding_location}
        onChange={(e) => handleChange(e, "wedding_location")}
        placeholder={"Enter wedding location"}
        className={"bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-xs"}
        otherProps={{
          required: true,
        }}
      />

      <SelectInput
        label={"Guarantor"}
        type="text"
        id="guarantor"
        value={formData.guarantor}
        onChange={(e) => handleChange(e, "guarantor")}
        placeholder={"Select guarantor"}
        className={"bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-xs"}
        otherProps={{
          required: true,
        }}
        options={guarantorList}
      />

      <RadioGroupInput
        label={"Partnership Status"}
        name="partnership_status"
        value={formData.partnership_status}
        onChange={(e) => handleChange(e, "partnership_status")}
        options={partnershipOptions}
      />

      <InputWithFullBoarder
        label={"Partnership ID"}
        type="text"
        id="partnership_id"
        value={formData.partnership_id}
        onChange={(e) => handleChange(e, "partnership_id")}
        placeholder={"Enter partnership ID"}
        className={"bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-xs"}
        otherProps={{
          required: formData?.partnership_status === "active",
        }}
      />

      <SelectInput
        label={"Allow to Share on Social Media"}
        name="allow_social_share"
        value={formData.allow_social_share}
        onChange={(e) => handleChange(e, "allow_social_share")}
        placeholder={"Select option"}
        options={true_or_false}
        className={"bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-xs"}
        otherProps={{
          required: true,
        }}
      />

      <div className="mb-4">
        <label className={`text-sm font-medium mb-2`}>Signature</label>
        {/* <p className="text-textGrey font-medium text-xs">
            Add one photo each for both parties.
          </p> */}
        <div className="flex gap-6 items-start mt-3">
          <FileInput
            // label={"Father"}
            onChange={(e) => handleFileChange(e, "signature")}
            required
            src={filesSrc?.signature}
            value={files?.signature}
          />
        </div>
      </div>

      <div className="mb-4">
        <label className={`text-sm font-medium mb-2`}>Passport</label>
        {/* <p className="text-textGrey font-medium text-xs">
            Add one photo each for both parties.
          </p> */}
        <div className="flex gap-6 items-start mt-3">
          <FileInput
            // label={"Father"}
            onChange={(e) => handleFileChange(e, "passport")}
            required
            src={filesSrc?.passport}
            value={files?.passport}
          />
        </div>
      </div>

      {!params?.form && (
        <RoundedButton
          className={
            "rounded-[40px] text-base font-medium w-full py-5 mt-10 grid place-content-center"
          }
        >
          {isLoading || isUploadingFlie ? (
            <Spinner />
          ) : params?.form ? (
            "Update"
          ) : (
            "Submit"
          )}
        </RoundedButton>
      )}
    </form>
  );
};

export default PreMaritalClassModal;
