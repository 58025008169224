import { churchesContent } from "assets/images";
import { RoundedButton } from "generalComponents/Button";

const LocationIcon = () => (
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.42945 14.6883L5.39006 14.6503L5.38938 14.6497C5.33359 14.5954 5.2769 14.5401 5.21946 14.4839C4.23367 13.5177 3.00447 12.2385 2.126 10.7318C1.2442 9.21946 0.691249 7.43502 1.12893 5.48427C2.65883 -1.30153 12.598 -1.29358 14.1199 5.49148C14.5704 7.49913 13.9717 9.32828 13.044 10.867C12.1199 12.3999 10.8406 13.6921 9.85199 14.65C9.13066 15.3524 8.43368 15.8389 7.64572 15.8479C6.85416 15.857 6.15433 15.383 5.43253 14.6913L5.42945 14.6883Z"
      fill="#EFC8FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.28833 6.32806C5.28833 5.03796 6.33418 3.99213 7.62427 3.99213C8.91435 3.99213 9.9602 5.03796 9.9602 6.32806C9.9602 7.61815 8.91435 8.664 7.62427 8.664C6.33418 8.664 5.28833 7.61815 5.28833 6.32806Z"
      fill="#2F0640"
    />
  </svg>
);

export const ChurchLabel = ({ label, image }) => (
  <div className="flex justify-between items-end w-full h-[200px]">
    <span className="text-5xl md:text-6xl lg:text-[70px]">{label}</span>
    <img src={image} alt="" className="relative -z-10 fade-l-28" />
  </div>
);

export const SingleChurch = ({ title, text, url, image }) => (
  <div className="flex flex-col md:flex-row justify-between md:items-center gap-3 mb-5 max-w-section mx-auto px-5">
    <div className="flex flex-col md:flex-row md:items-center gap-6">
      <img
        src={image ?? churchesContent}
        alt=""
        className="w-[250px] object-contain rounded-[10px]"
      />
      <div>
        <p className="font-semibold text-2xl mb-3">{title}</p>
        <p className="flex gap-[6px] items-start">
          <LocationIcon />{" "}
          <span className="text-gray-500 text-sm md:text-base max-w-[330px] md:max-w-[400px]">
            {text}
          </span>{" "}
        </p>
      </div>
    </div>
    {!title?.toLowerCase()?.includes("e-church") && (
      <RoundedButton url={url} external className="px-5 py-3 text-xs">
        See on map
      </RoundedButton>
    )}
  </div>
);

const dubaiChurches = [
  {
    title: "Dubai Church ",
    text: "The Commonwealth of Zion Assembly (COZA), Dubai, United Arab Emirates",
  },
];
const birminghamChurches = [
  {
    title: "Birmingham Church ",
    text: "The Commonwealth of Zion Assembly (COZA), Birmingham, United Kingdom",
  },
];
const manchesterChurches = [
  {
    title: "Manchester Church ",
    text: "The Commonwealth of Zion Assembly (COZA), Manchester, United Kingdom",
  },
];
const ilorinChurches = [
  {
    title: "Ilorin Church ",
    text: "The Commonwealth of Zion Assembly (COZA), Ilorin, Kwara, Nigeria",
  },
];
const phChurches = [
  {
    title: "Port Harcourt Church ",
    text: "The Commonwealth of Zion Assembly (COZA), Port Harcourt, Nigeria",
  },
];
const lagosChurches = [
  {
    title: "Lagos Church ",
    text: "The Commonwealth of Zion Assembly (COZA), Sheba Center, Mobolaji Bank-Anthony, Ikeja, Lagos, Nigeria",
  },
];

const abujaChurches = [
  {
    title: "Guzape Church (HQ)",
    text: "The Commonwealth of Zion Assembly (COZA) 4C58+Q7R, Gwarinpa Estate, Gwarinpa 900108, Federal Capital Territory, Nigeria",
  },
  {
    title: "Guzape Church (HQ)",
    text: "The Commonwealth of Zion Assembly (COZA) 4C58+Q7R, Gwarinpa Estate, Gwarinpa 900108, Federal Capital Territory, Nigeria",
  },
  {
    title: "Guzape Church (HQ)",
    text: "The Commonwealth of Zion Assembly (COZA) 4C58+Q7R, Gwarinpa Estate, Gwarinpa 900108, Federal Capital Territory, Nigeria",
  },
  {
    title: "Guzape Church (HQ)",
    text: "The Commonwealth of Zion Assembly (COZA) 4C58+Q7R, Gwarinpa Estate, Gwarinpa 900108, Federal Capital Territory, Nigeria",
  },
  {
    title: "Guzape Church (HQ)",
    text: "The Commonwealth of Zion Assembly (COZA) 4C58+Q7R, Gwarinpa Estate, Gwarinpa 900108, Federal Capital Territory, Nigeria",
  },
  {
    title: "Guzape Church (HQ)",
    text: "The Commonwealth of Zion Assembly (COZA) 4C58+Q7R, Gwarinpa Estate, Gwarinpa 900108, Federal Capital Territory, Nigeria",
  },
  {
    title: "Guzape Church (HQ)",
    text: "The Commonwealth of Zion Assembly (COZA) 4C58+Q7R, Gwarinpa Estate, Gwarinpa 900108, Federal Capital Territory, Nigeria",
  },
  {
    title: "Guzape Church (HQ)",
    text: "The Commonwealth of Zion Assembly (COZA) 4C58+Q7R, Gwarinpa Estate, Gwarinpa 900108, Federal Capital Territory, Nigeria",
  },
  {
    title: "Guzape Church (HQ)",
    text: "The Commonwealth of Zion Assembly (COZA) 4C58+Q7R, Gwarinpa Estate, Gwarinpa 900108, Federal Capital Territory, Nigeria",
  },
];

export const churchLocationList = {
  LAGOS: lagosChurches,
  ABUJA: abujaChurches,
  PH: phChurches,
  ILORIN: ilorinChurches,
  MANCHESTER: manchesterChurches,
  BIRMINGHAM: birminghamChurches,
  DUBAI: dubaiChurches,
};
