import React from "react";
import { twMerge } from "tailwind-merge";

const ContainerWithVerticalText = ({ text, children, className }) => (
  <div className={twMerge("flex lg:max-w-[720px]", className)}>
    <p
      className={twMerge(
        "text-gray-300 font-medium text-3xl mr-5",
        "h-fit text-nowrap hidden lg:block",
        "vertical-text rotate-180 text-center"
      )}
    >
      {text}
    </p>

    <div className=" lg:ml-auto lg:pl-16 lg:border-l-[6px] border-l-orangeBg">
      {children}
    </div>
  </div>
);

export default ContainerWithVerticalText;
