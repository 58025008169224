import { homeAboutUs } from "assets/images";
import React from "react";
import OrangeBar from "./OrangeBar";
import TextStroke from "generalComponents/TextStroke";

const Hero = () => {
  return (
    <div>
      <TextStroke className="overflow-hidden max-w-[100svh]">
        About Us
      </TextStroke>
      <div className="px-5 md:px-0 max-w-section mx-auto -mt-32">
        <h1 className="mb-16 mt-5 font-medium text-6xl md:text-7xl text-purpleBg md:pl-10">
          About Us
        </h1>
        <div className="flex flex-wrap flex-col-reverse justify-around items-center lg:grid grid-cols-2 gap-10">
          <div className="md:max-w-[620px] md:pl-10">
            <OrangeBar className="w-[200px] md:w-[255px] mb-5" />
            <h2 className="text-black font-semibold text-3xl md:text-4xl lg:text-5xl mb-5">
              The Commonwealth Of Zion Assembly
            </h2>
            <p className="text-sm text-gray-500">
              The Commonwealth of Zion Assembly, popularly known as COZA, is a
              global and dynamic, Bible-believing church dedicated to raising a
              generation of empowered believers that is taking over in every
              sphere of life. Founded on the vision of spreading the love and
              teachings of Jesus Christ, COZA has grown into a global ministry
              that impacts lives through powerful worship, transformative
              messages, and a commitment to community service. <br /> <br />
              We are a vibrant, faith-filled community committed to raising a
              generation of global leaders who will positively influence every
              sphere of life. We are excited to share our journey and vision
              with you and invite you to be a part of this transformative
              experience.
            </p>
          </div>
          <div className="flex justify-end">
            <img
              src={homeAboutUs}
              alt=""
              className="object-cover min-h-[400px] "
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
