import React, { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

const placeholder = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";

const AvatarWithFallback = ({ src, alt, fallback, className }) => {
    const [imageSrc, setImageSrc] = useState(placeholder);

    useEffect(() => {
        if (!src) return;

        // Set placeholder first
        setImageSrc(placeholder);

        // Set the new image URI after a short delay
        const timeout = setTimeout(() => {
            setImageSrc(src);
        }, 50); // Delay ensures the browser processes the placeholder

        return () => clearTimeout(timeout); // Clean up timeout
    }, [src]); // Runs when `newImageUri` changes

    return (
        <div className={twMerge("size-10 shadow-md rounded-full overflow-hidden grid place-content-center mx-auto", className)}>
            <img
                alt={alt}
                src={imageSrc}
                className="object-contain w-full"
            />
        </div>
    );
};

export default AvatarWithFallback;
