import GlobalVariables from "constants/GlobalVariables";
import React from "react";

const AvatarWithTitle = ({ image, title }) => {
  return (
    <div className="flex items-center gap-2 mr-0 md:mr-8">
      <div class=" w-8 h-8 rounded-full overflow-hidden ">
        <img
          className="object-contain rounded-full w-full"
          src={!image ? GlobalVariables.defaultProfilePicture : image}
          alt=""
        />
      </div>
      <span>{title}</span>
    </div>
  );
};

export default AvatarWithTitle;
