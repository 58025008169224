import React from "react";

const ModalManagement = ({
  children,
  id,
  type,
  title,
  className,
  background,
}) => {
  return (
    <dialog
      id={id}
      className={`modal ${type === "large" ? "max-w-none w-4/5 mx-auto" : "mx-auto w-full"
        } `}
    >
      <div
        className={`modal-box scrollbar-hide ${className} ${background ?? "bg-white"
          } p-[26px] ${type === "large" ? "max-w-none w-full" : "max-w-max  mx-auto"
          }`}
      >
        <div className="mt-0 flex flex-col md:flex-row justify-between w-full scrollbar-hide">
          <h3 className=" text-[18px] font-semibold text-black">{title}</h3>
          <button
            onClick={() => {
              document.getElementById(id).close();
            }}
            className="w-[24px] bg-white h-[24px] flex justify-center items-center"
          >
            <svg
              width="33"
              height="33"
              viewBox="0 0 33 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.15381 8.15234L24.4614 24.46"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
              />
              <path
                d="M8.15381 24.4609L24.4614 8.15332"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
          </button>
        </div>
        {children}
      </div>
      <form method="dialog" className="modal-backdrop">
        <button>close</button>
      </form>
    </dialog>
  );
};

export default ModalManagement;
