import React, { useEffect } from "react";
import Nav from "../components/Nav";
import useQueryParams from "constants/useQueryParams";
import HeaderFooter from "generalComponents/HeaderFooter";
import EditProfile from "../components/EditProfile";
import ChangePassword from "../components/ChangePassword";

export const TABS = {
  EDIT: "1",
  PASSWORD: "2",
};

const view = {
  1: <EditProfile />,
  2: <ChangePassword />,
};

const UserProfile = () => {
  const { params, setParam } = useQueryParams();

  useEffect(() => {
    if (!params?.tab) {
      setParam("tab", TABS.EDIT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <HeaderFooter solid>
      <div className="pt-[150px] lg:pt-[250px] pb-20 flex flex-col lg:flex-row gap-6 divide-x divide-textLightGrey max-w-section mx-auto bg-white sticky">
        <Nav />

        <div className="w-full">{view[params?.tab]}</div>
      </div>
    </HeaderFooter>
  );
};

export default UserProfile;
