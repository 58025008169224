import { useQuery } from "react-query";
import { AxiosFaceRecognitionApp } from "../../../../constants/api_management/MyHttpHelperWithToken";

const useGetAttendanceDetails = (props) => {
    const { page = 1, limit = 5000, campusId, serviceId, search, serviceAttendanceId } = props;
    return useQuery(["serviceAttendance", props], async () => {
        try {
            const [response] = [
                await AxiosFaceRecognitionApp.get(
                    `/attendance/service/attendees`,
                    {
                        params: {
                            page,
                            limit,
                            campusId,
                            serviceId,
                            search,
                            serviceAttendanceId
                        },
                    }
                ),
            ];

            return response.data;
        } catch (error) {
            throw new Error(`Sorry: ${error.response.data.message}`);
        }
    });
};

export default useGetAttendanceDetails;