import AxiosWithToken from "constants/api_management/MyHttpHelperWithToken";
import { useQuery } from "react-query";

const useGetEvents = (params = {}) => {
  return useQuery({
    queryKey: ["event", params],
    queryFn: async () => {
      try {
        const [response] = [
          await AxiosWithToken.get("/event/list", { params }),
        ];
        //console.log(`i am checking this ${response.status}`);
        return response.data;
      } catch (error) {
        //console.log(error.response.data);
        throw new Error(`Sorry: ${error.response.data.message}`);
      }
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
  //   return useQuery(["event"], async () => {
  //     try {
  //       const [response] = [await AxiosWithToken.get(url)];
  //       //console.log(`i am checking this ${response.status}`);
  //       return response.data;
  //     } catch (error) {
  //       //console.log(error.response.data);
  //       throw new Error(`Sorry: ${error.response.data.message}`);
  //     }
  //   });
};

export default useGetEvents;
