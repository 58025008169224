import useDeleteManager from "constants/controller_templates/delete_controller_template";

export const DeleteEventManager = (eventId) => {
  const isMultiKey = false;
  const { deleteCaller, isLoading, isSuccess, error, data } = useDeleteManager(
    `/event/${eventId}`,
    ["events"],
    isMultiKey
  );
  const deleteEvent = async (details) => {
    try {
      console.log(details, "This is the first");
      await deleteCaller(details);
    } catch (error) {
      // Handle error if necessary
      console.error("delete error:", error);
    }
  };
  return {
    deleteEvent,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
