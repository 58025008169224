import { formatDistance } from "date-fns";
import React, { useCallback, useRef } from "react";
import useGetPraiseReports from "../controllers/get_praise_reports_contoller";
import PraiseReportItem from "./PraiseReportItem";
import Loader from "generalComponents/Loader";
const praiseReportMapper = (data) => {
  if (!data || data?.length < 1) return [];
  const newData = data.map((item) => ({
    praiseReportData: item,
    avatar: item?.user?.profile_picture,
    fullname: item?.name,
    title: item?.title,
    likes: item?.like_count,
    image: item?.imageUrl === "" ? item?.user?.profile_picture : item?.imageUrl,
    // image: item?.category?.cover_image?.url,
    time: formatDistance(new Date(item?.createdAt), new Date(), {
      addSuffix: true,
    }),
    // time: "3hrs ago",
    description: item?.description,
  }));

  return newData;
};

const GeneralPraiseReports = ({ visible }) => {
  const observer = useRef();
  const { data, isLoading, fetchNextPage, hasNextPage, isFetching } =
    useGetPraiseReports({
      pageSize: 20,
    });
  const lastElementRef = useCallback(
    (node) => {
      if (isLoading) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage && !isFetching) {
          fetchNextPage();
        }
      });

      if (node) observer.current.observe(node);
    },
    [fetchNextPage, hasNextPage, isFetching, isLoading]
  );

  if (isLoading) return <Loader ball />;

  return (
    <div className="h-full overflow-scroll">
      {data?.pages?.map((page, idx) =>
        praiseReportMapper(page?.data?.testimonies).map((item, index) => (
          <PraiseReportItem
            key={index}
            ref={lastElementRef}
            visible={visible}
            {...item}
          />
        ))
      )}

      {isFetching ? <Loader isSmall /> : null}
    </div>
  );
};

export default GeneralPraiseReports;
