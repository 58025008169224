import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useGetSingleGallery } from "../controllers/get_controller";
import { Link, useParams } from "react-router-dom";
import HeaderFooter from "generalComponents/HeaderFooter";
import { format, formatDistance } from "date-fns";
import Loader from "generalComponents/Loader";
import ModalManagement from "generalComponents/CustomModal";
import CustomPagination from "generalComponents/CustomPagination";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import useDisclosure from "constants/useDisclosure";
import {
  useDeleteGalleryComment,
  useGetGalleryComments,
  usePostGalleryComment,
} from "../controllers/comments_controller";
import { useUserStore } from "store/userStore";
import {
  useGetGalleryLikesById,
  useLikeGallery,
} from "../controllers/likes_controller";
import { twMerge } from "tailwind-merge";
import { VscSend } from "react-icons/vsc";
import { avatar } from "assets/images";
import EllipsisIcon from "modules/pagePraiseReports/components/EllipsisIcon";
import CustomModalHeadless from "generalComponents/CustomModalHeadless";
import LikeButton from "modules/pagePraiseReports/components/LikeButton";
import { abbreviateNumber } from "constants/textFormatters";
import ShareButton from "modules/pagePraiseReports/components/ShareButton";

const appBaseUrl = process.env.REACT_APP_APP_BASE_URL;

const SingleGallery = () => {
  const user = useUserStore((state) => state.user);
  const params = useParams();
  const [page, setPage] = useState(1);
  const pageSize = 50;
  const [selecetedImage, setSelectedImage] = useState();
  const [selecetedIndex, setSelectedIndex] = useState(0);
  const [comment, setComment] = useState("");
  const [commentId, setCommentId] = useState();
  const [copied, setCopied] = useState(false);

  const onCloseVideoDetails = () => {
    setSelectedImage(undefined);
  };
  const { data, isLoading } = useGetSingleGallery(params?.id, {
    page,
    pageSize,
  });

  const onSelectImage = (index) => {
    setSelectedIndex(index);
    setSelectedImage(data?.data?.[index]?.image?.url);
  };

  const {
    isOpen: isOpenD,
    onOpen: onOpenD,
    onClose: onCloseD,
  } = useDisclosure();

  const isTouchDevice = useMemo(() => {
    return () => {
      return (
        "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
      );
    };
  }, []);

  const { data: comments } = useGetGalleryComments(params?.id);

  const { postComment: post } = usePostGalleryComment(params?.id);
  const postComment = async (e) => {
    e?.preventDefault();
    const body = {
      id: params?.id,
      comment,
      name: `${user?.first_name} ${user?.last_name}`,
    };
    try {
      const data = await post(body);
      if (data) {
        setComment("");
      }
    } catch (error) {
      // console.log(error?.response?.data?.message, "error");
    }
  };

  const { deleteComment } = useDeleteGalleryComment();

  const { data: galleryLikes } = useGetGalleryLikesById({
    id: params?.id,
    user: user?.id,
  });
  const { data: allGalleryLikes } = useGetGalleryLikesById({
    id: params?.id,
    key: "allGalleryLikes",
  });

  const userLikedGallery = useMemo(() => {
    if (!galleryLikes?.data) return false;
    const userLiked = galleryLikes?.data?.filter(
      (item) => item?.user?.id === user?.id
    );
    if (userLiked?.length > 0) return true;
  }, [galleryLikes?.data, user?.id]);

  const { likeGallery } = useLikeGallery({
    id: params?.id,
    like: "like",
  });
  const { likeGallery: unlikeGallery } = useLikeGallery({
    id: params?.id,
    like: "unlike",
  });

  const onCopy = (e, data) => {
    e.preventDefault();
    e.stopPropagation();
    navigator.clipboard.writeText(data);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 5000);
  };

  const galleryLink = `${appBaseUrl}/gallery/${params?.id}`;

  const handleCopy = (e) => {
    onCopy(e, galleryLink);
  };

  useEffect(() => {
    if (selecetedImage !== undefined) {
      window["image-viewer"]?.showModal();
    } else {
      window["image-viewer"]?.close();
    }
  }, [selecetedImage]);

  useEffect(() => {
    if (commentId) {
      onOpenD();
    } else {
      onCloseD();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentId]);

  if (isLoading) return <Loader />;
  return (
    <HeaderFooter>
      <div className="pt-[184px] pb-20 max-w-section mx-auto px-5">
        <Link
          to="/gallery"
          className="text-sm text-[#667185] flex items-center gap-3 mb-5"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.5"
              y="0.5"
              width="23"
              height="23"
              rx="3.5"
              fill="white"
            />
            <rect
              x="0.5"
              y="0.5"
              width="23"
              height="23"
              rx="3.5"
              stroke="#E4E7EC"
            />
            <path
              d="M7.14645 12.3536C6.95118 12.1583 6.95118 11.8417 7.14645 11.6464L9.14645 9.64645C9.34171 9.45118 9.65829 9.45118 9.85355 9.64645C10.0488 9.84171 10.0488 10.1583 9.85355 10.3536L8.70711 11.5L16.5 11.5C16.7761 11.5 17 11.7239 17 12C17 12.2761 16.7761 12.5 16.5 12.5L8.70711 12.5L9.85355 13.6464C10.0488 13.8417 10.0488 14.1583 9.85355 14.3536C9.65829 14.5488 9.34171 14.5488 9.14645 14.3536L7.14645 12.3536Z"
              fill="black"
            />
          </svg>

          <span>Go back</span>
        </Link>

        <div className="flex flex-col gap-5 lg:flex-row lg:px-5 mb-20 lg:mt-10 lg:overflow-clip">
          <div className="lg:w-[70%]">
            <div className="mb-3">
              <h1 className="font-semibold text-[52px] lg:text-[60px] lg:leading-[70px] text-purpleOverlay">
                {data?.data?.title}
              </h1>
              <p className="text-textGrey">
                {data?.data?.createdAt
                  ? format(data?.data?.createdAt, "PPP")
                  : ""}
              </p>
            </div>
            <div className="grid grid-flow-row-dense grid-cols-2 sm:grid-cols-3 lg:grid-cols-3 gap-2.5">
              {data?.data?.map((item, index) => (
                <Fragment key={index}>
                  <img
                    onClick={() => onSelectImage(index)}
                    src={item?.image?.url}
                    alt=""
                    className="max-h-[200px] object-cover w-full cursor-pointer"
                    loading="lazy"
                    decoding="async"
                    color="lightgrey"
                  />
                </Fragment>
              ))}
            </div>
            <div className="flex justify-end mt-10 min-w-full">
              <CustomPagination
                count={data?.pagination?.total}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
              />
            </div>
          </div>

          <div className="h-full overflow-y-scroll lg:w-[30%] flex-1 px-5 lg:px-0">
            <div className=" pb-2">
              <div className="flex items-center justify-between gap-4 py-2">
                <button
                  onClick={userLikedGallery ? unlikeGallery : likeGallery}
                  className="flex items-center gap-1 rounded-full bg-neutral-100 px-4 py-1.5"
                >
                  <LikeButton
                    liked={userLikedGallery}
                    className="duration-500 hover:scale-[0.9] hover:opacity-70"
                  />
                  <span className="text-sm text-purpleOverlay">
                    {allGalleryLikes?.pagination?.total > 0
                      ? abbreviateNumber(allGalleryLikes?.pagination?.total) +
                        (allGalleryLikes?.pagination?.total > 1
                          ? " Likes"
                          : " Like")
                      : "Like"}
                  </span>
                </button>
                <button
                  onClick={handleCopy}
                  className="flex items-center gap-1 text-purpleOverlay text-sm rounded-full bg-neutral-100 px-4 py-1.5"
                >
                  <ShareButton className="duration-500 hover:scale-[0.9] hover:opacity-70" />
                  {copied ? "Copied" : "Share"}
                </button>
              </div>
            </div>
            <div className="w-full my-3">
              <form
                onSubmit={postComment}
                className="py-3 flex items-center gap-4"
              >
                <img
                  src={user?.profile_picture ?? avatar}
                  alt=""
                  className={twMerge(
                    "w-9 h-9 rounded-full",
                    !user?.profile_picture && "filter grayscale"
                  )}
                />
                <textarea
                  name="comment"
                  placeholder="Add a comment"
                  value={comment}
                  onChange={(e) => setComment(e.target?.value)}
                  type="text"
                  className="h-auto max-h-10 p-2 w-[80%] @md:w-[240px] outline-none rounded duration-300 border-b border-b-gray-200 bg-white focus:border-b-gray-400 text-purpleOverlay text-sm resize-none"
                  maxLength={1000}
                />
                <button
                  type="submit"
                  className="outline-none text-textDark font-medium text-2xl"
                >
                  <VscSend />
                </button>
              </form>
            </div>

            <div>
              {comments?.map((comment, index) => (
                <div
                  key={index}
                  className="group flex items-start w-full gap-2.5 mb-4 pb-2.5"
                >
                  <img
                    src={comment?.user?.profile_picture ?? avatar}
                    alt=""
                    className="w-9 h-9 rounded-full"
                  />
                  <div>
                    <p className="text-sm text-purpleOverlay font-medium whitespace-pre-line">
                      {comment?.name?.includes(undefined)
                        ? `${comment?.user?.first_name} ${comment?.user?.last_name}`
                        : comment?.name}
                    </p>
                    <p className="text-sm text-gray-500 whitespace-pre-line">
                      {comment?.comment}
                    </p>
                    <span className="text-[10px] text-[#BDBDBD] flex items-center gap-2">
                      {formatDistance(
                        new Date(comment?.createdAt),
                        new Date(),
                        {
                          addSuffix: true,
                        }
                      )}

                      {user?.id === comment?.user?.id && (
                        <EllipsisIcon
                          onClick={() => setCommentId(comment?.id)}
                          className={twMerge(
                            "cursor-pointer invisible duration-500 hover:scale-[0.9] hover:opacity-70 group-hover:visible",
                            isTouchDevice() ? "visible" : ""
                          )}
                        />
                      )}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <ModalManagement
        id={"image-viewer"}
        onClose={onCloseVideoDetails}
        className={"w-full max-w-[100svh] max-h-[100svh] relative"}
      >
        <img
          src={selecetedImage}
          alt=""
          className="h-full object-contain mx-auto select-none"
        />

        <button
          onClick={() => onSelectImage(selecetedIndex - 1)}
          disabled={selecetedIndex < 1}
          className="absolute z-[2] left-10 top-0 bottom-0 my-auto h-fit p-3 rounded-full bg-black/60 text-white disabled:opacity-40 disabled:cursor-not-allowed"
        >
          <ChevronLeftIcon className="size-6" />
        </button>
        <button
          onClick={() => onSelectImage(selecetedIndex + 1)}
          disabled={data?.data?.length - selecetedIndex < 2}
          className="absolute z-[2] right-10 top-0 bottom-0 my-auto h-fit p-3 rounded-full bg-black/60 text-white disabled:opacity-40 disabled:cursor-not-allowed"
        >
          <ChevronRightIcon className="size-6" />
        </button>
      </ModalManagement>

      {isOpenD && (
        <CustomModalHeadless
          {...{
            isOpen: isOpenD,
            onClose: onCloseD,
            showCloseButton: false,
            dialogClass: "p-0",
          }}
        >
          <div className="w-full flex flex-col divide-y divide-lightGrey">
            <button
              onClick={async () => {
                try {
                  await deleteComment(commentId);
                  setCommentId(undefined);
                } catch (error) {
                  console.log(error);
                }
              }}
              className="text-red-500 text-sm font-medium w-full py-3 hover:bg-textLightGrey"
            >
              Delete
            </button>
            <button
              onClick={() => setCommentId(undefined)}
              className="text-textDark text-sm font-medium w-full py-3 hover:bg-textLightGrey"
            >
              Cancel
            </button>
          </div>
        </CustomModalHeadless>
      )}
    </HeaderFooter>
  );
};

export default SingleGallery;
