import { useQuery } from "react-query";
import { AxiosWorkforceApp } from "../../../../constants/api_management/MyHttpHelperWithToken";

const useGetServiceListManager = ({ page = 1, limit = 20 }) => {
  return useQuery(["serviceList"], async () => {
    try {
      const response = await AxiosWorkforceApp.get(`/service/getServices`, { params: { page, limit } });
      return response.data;

    } catch (error) {
      throw new Error(`Sorry: ${error.response.data}`);
    }
  },
    {
      select: (response) => response.data // Selector
    }
  );
};

export default useGetServiceListManager;
