import { twMerge } from "tailwind-merge";

const reactIf = (component, conditionState = false, fallBack = null) => {
  if (conditionState) return component;
  return fallBack;
};

const badgeStylesMap = {
  pending: "text-white bg-orangeBg",
  draft: "text-white/80 bg-gray-400",
  success: "text-white bg-[#4CB050]",
  error: "text-white bg-[#fc483f]",
};

const successFlags = [
  true,
  "1",
  "successful",
  "success",
  "closed",
  "active",
  "refunded",
  "on",
];

const errorFlags = [
  false,
  "0",
  "error",
  "failed",
  "rejected",
  "inactive",
  "off",
];

const draftFlags = ["expired"];

const pendingFlags = ["pending"];

export function StatusBadge({ status, label, extraClass = "" }) {
  let statusI = badgeStylesMap[status] ? status : undefined;
  if (!status) {
    if (successFlags.includes(status)) statusI = "success";
    if (errorFlags.includes(status)) statusI = "error";
    if (draftFlags.includes(status)) statusI = "draft";
    if (pendingFlags.includes(status)) statusI = "pending";
  }
  if (!statusI) return null;

  return (
    <div
      className={twMerge(
        `font-medium text-xs rounded-[13px] inline`,
        "py-1 px-3",
        badgeStylesMap[status],
        extraClass
      )}
    >
      {label}
    </div>
  );
}

export function StatusDot({
  status,
  label,
  dotInnerExtraClass = "",
  dotOuterExtraClass = "",
  labelExtraClass = "",
  hideLabel = false,
  description,
}) {
  const border = {
    pending: "bg-[#dba21b]/50",
    draft: "bg-[#7693af50]/50",
    success: "bg-[#12b36c]/50",
    error: "bg-[#fc483f]/50",
  };

  let statusI = badgeStylesMap[status] ? status : undefined;
  if (!statusI) {
    if (successFlags.includes(status)) statusI = "success";
    if (errorFlags.includes(status)) statusI = "error";
    if (draftFlags.includes(status)) statusI = "draft";
    if (pendingFlags.includes(status)) statusI = "pending";
  }

  if (!statusI) return null;

  const MainContent = () => {
    return (
      <div className={twMerge(`flex items-center gap-0.5 w-fit`)}>
        <div
          className={twMerge(
            "h-4 w-4 grid place-content-center rounded-full",
            statusI && border[statusI],
            dotOuterExtraClass
          )}
        >
          <div
            className={twMerge(
              "w-2.5 h-2.5 rounded-full",
              statusI && badgeStylesMap[statusI],
              dotInnerExtraClass
            )}
          />
        </div>
        {(!hideLabel ?? false) && (
          <span className={twMerge("text-sm", labelExtraClass)}>{label}</span>
        )}
      </div>
    );
  };

  if (!Boolean(!!description)) return <MainContent />;

  return (
    <div className="flex flex-col items-end">
      <MainContent />
      {reactIf(
        <div className="text-xs font-normal text-gray-400">{description}</div>,
        Boolean(!!description)
      )}
    </div>
  );
}
