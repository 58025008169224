import { RoundedButton } from "generalComponents/Button";
import InputWithFullBoarder from "generalComponents/InputWithFullBoarder";
import React, { useState } from "react";
import { useCreateAppointment } from "../controllers/post_controller";
import { Spinner } from "generalComponents/Loader";

const CreateAppointment = () => {
  const initialValues = {
    title: "",
    details: "",
    requested_person: "Pastor Biodun Fatoyinbo",
  };
  const [formData, setFormData] = useState(initialValues);

  const handleChange = (event, name) => {
    const value = event.target?.value;
    setFormData((data) => ({
      ...data,
      [name]: value,
    }));
  };

  const { postCaller, isLoading } = useCreateAppointment();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await postCaller(formData);
      setFormData(initialValues);
      window["ap-modal"]?.close();
    } catch (error) {
      console.log({ error });
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <InputWithFullBoarder
        label={"Title"}
        type="text"
        id="title"
        value={formData.title}
        onChange={(e) => handleChange(e, "title")}
        placeholder={"Enter title"}
        className={"bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-xs"}
        otherProps={{
          required: true,
        }}
      />
      <InputWithFullBoarder
        isTextArea
        label={"Description"}
        type="text"
        id="details"
        value={formData.details}
        onChange={(e) => handleChange(e, "details")}
        placeholder={"Enter description"}
        className={"bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-xs"}
        otherProps={{
          required: true,
        }}
      />

      <RoundedButton
        className={
          "rounded-[40px] text-base font-medium w-full py-5 mt-10 grid place-content-center"
        }
      >
        {isLoading ? <Spinner /> : "Submit"}
      </RoundedButton>
    </form>
  );
};

export default CreateAppointment;
