import ModalManagement from "generalComponents/ModalManagement";
import React, { useEffect, useRef, useState } from "react";

import useFileUpload from "modules/fileupload/fileUploadController";
import InputWithFullBoarder from "generalComponents/InputWithFullBoarder";

import CustomButton from "generalComponents/Button";

import { CreateCategoryManager } from "../controllers/createCategoryController";
import { EditCategoryManager } from "../controllers/editCategoryController";
import BigUploadFileComponent from "generalComponents/BigUploadFileComponent";
import CustomDropdown from "generalComponents/CustomDropdown";

const AddEditCategory = ({ details, groupList }) => {
  const { createCategory, isLoading } = CreateCategoryManager();
  const { editCategory, isLoading: editing } = EditCategoryManager(details?.id);
  const initialData = {
    name: "",
    group: "",
    cover_image: {
      url: "",
      thumbnail: "",
    },
  };
  const [formData, setFormData] = useState(initialData);
  useEffect(() => {
    if (details) {
      setFormData({ ...formData, ...details });
    } else {
      setFormData(initialData);
    }
  }, [details]);

  const coverImageRef = useRef();
  const [coverFile, setCoverFile] = useState(null);
  const {
    progress,
    handleFileUpload,
    isLoading: fileLoading,
  } = useFileUpload();

  return (
    <ModalManagement id={"add_category"} title={"Category"}>
      <div className="w-full flex flex-col md:w-[500px]">
        <div className="flex items-center justify-between mb-4 ">
          <p className="text-18px font-bold">
            {details ? "Edit Category" : "Create Category"}
          </p>
        </div>

        <BigUploadFileComponent
          buttonText={"Upload Cover"}
          inputRef={coverImageRef}
          isLoading={fileLoading}
          image={
            coverFile
              ? URL.createObjectURL(coverFile)
              : details && formData?.cover_image?.thumbnail
          }
          progress={progress}
          accept={"image/*"}
          buttonClick={() => setCoverFile(null)}
          onChange={(e) => {
            const file = e.target.files[0];
            if (file) {
              setCoverFile(file);
            } else {
              setCoverFile(null);
            }
          }}
        />
        <div className="mb-4 w-full flex flex-col">
          <label className="text-sm font-medium mb-2" htmlFor={"content_type"}>
            {"Category"}
          </label>
          <CustomDropdown
            placeholder={"Select Category"}
            isFirstStyle={false}
            options={groupList.map((el) => el?.name)}
            value={formData.group}
            onChange={(e) => {
              const groupValue = groupList.find(
                (group) => group?.name === e.target.value
              ).value;
              setFormData({
                ...formData,
                group: groupValue,
              });
            }}
          />
        </div>

        <InputWithFullBoarder
          label={"Name"}
          className={"mb-4"}
          placeholder={"Enter title"}
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
        />

        <CustomButton
          buttonText={details ? "Edit Category" : "Create Category"}
          isLoading={editing || isLoading || fileLoading}
          loader={fileLoading ? `Uploading ${progress}%` : "Submitting..."}
          onClick={async () => {
            const updatedFormData = { ...formData };
            if (coverFile) {
              const coverUrl = await handleFileUpload(coverFile);
              updatedFormData.cover_image = {
                url: coverUrl,
                thumbnail: coverUrl,
              };
            }

            console.log(updatedFormData);
            if (details) {
              await editCategory(updatedFormData);
            } else {
              await createCategory(updatedFormData);
            }
            setFormData(initialData);
            document.getElementById("add_category").close();
          }}
        />
      </div>
    </ModalManagement>
  );
};

export default AddEditCategory;
