import useDeleteManager from "constants/controller_templates/delete_controller_template";

export const DeleteChildrenContentManager = ({ contentId }) => {
  const { deleteCaller, isLoading, isSuccess, error, data } = useDeleteManager(
    `/children/${contentId}`,
    ["allChildrenContent"]
  );
  const deleteContent = async () => {
    try {
      await deleteCaller();
    } catch (error) {
      console.error("error:", error);
    }
  };
  return {
    deleteContent,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
