import React from "react";
import { twMerge } from "tailwind-merge";
import { useAudioContext } from "../view";
import AudioDiskWrapper from "generalComponents/AudioDiskWrapper";
import SermonDisc from "generalComponents/SermonDisc";
import AudioProgress from "./AudioProgress";
import AudioControls from "./AudioControls";

const AudioBottomDialog = () => {
  const { currentAudio, setCurrentAudio, minimize, setMinimize } =
    useAudioContext();
  return (
    <div
      className={twMerge(
        "fixed bottom-0 left-0 w-[100svw] rounded-t-[15px] bg-white z-50 duration-300",
        !currentAudio && "-bottom-[100%]",
        !minimize && "-bottom-[100%]"
      )}
    >
      <div className="w-[25%] h-full absolute left-0 top-0 bg-gradient-to-r from-purpleBg/20 to-white rounded-t-[15px]"></div>
      <div className="w-[50%] h-full absolute left-[25%] top-0 bg-gradient-to-r via-orangeBg/20 from-white to-white rounded-t-[15px]"></div>
      <div className="w-[25%] h-full absolute left-[75%] top-0 bg-gradient-to-r to-purpleBg/20 from-white rounded-t-[15px]"></div>
      <div className="w-full h-full rounded-t-[15px] p-5 relative">
        <div className="flex justify-end divide-[#BDBDBD] divide-x gap-3">
          <button onClick={() => setMinimize(false)}>
            <span className="sr-only">Maximize</span>
            <svg
              width="16"
              height="14"
              viewBox="0 0 16 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 13L1 13"
                stroke="#8311B3"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
              <rect
                x="1"
                y="1"
                width="14"
                height="10"
                stroke="#8311B3"
                strokeWidth="1.5"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          <button onClick={() => setCurrentAudio(undefined)}>
            <span className="sr-only">Close</span>
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.79688 6.79297L20.3866 20.3826"
                stroke="#8311B3"
                strokeWidth="1.66667"
                strokeLinecap="round"
              />
              <path
                d="M6.79688 20.3867L20.3866 6.79704"
                stroke="#8311B3"
                strokeWidth="1.66667"
                strokeLinecap="round"
              />
            </svg>
          </button>
        </div>

        <div className="max-w-[640px] lg:max-w-none mx-auto lg:flex items-center lg:mx-10 lg:gap-10">
          <AudioControls className="hidden lg:flex" />
          <div className=" flex items-center gap-3">
            <AudioDiskWrapper>
              <SermonDisc
                image={currentAudio?.cover_image?.url}
                smaller
                className={"border-purpleBg"}
              />
            </AudioDiskWrapper>

            <div className="lg:max-w-[300px]">
              <p className="text-xl text-purpleOverlay font-semibold">
                {currentAudio?.title}{" "}
                <a href={currentAudio?.content} download={currentAudio?.title}>
                  <svg
                    width="34"
                    height="34"
                    viewBox="0 0 34 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="inline lg:hidden"
                  >
                    <rect
                      x="1.28698"
                      y="0.330435"
                      width="32.3826"
                      height="32.3826"
                      rx="16.1913"
                      fill="#FFF3E5"
                    />
                    <rect
                      x="1.28698"
                      y="0.330435"
                      width="32.3826"
                      height="32.3826"
                      rx="16.1913"
                      stroke="#FF8A00"
                      strokeWidth="0.66087"
                    />
                    <path
                      d="M17.3284 10.8105V18.5369M17.3284 18.5369L20.6396 15.2256M17.3284 18.5369L14.0171 15.2256"
                      stroke="#FF8A00"
                      strokeWidth="1.04699"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M21.7436 21.8477H17.3286H12.9136"
                      stroke="#FF8A00"
                      strokeWidth="1.04699"
                      strokeLinecap="round"
                    />
                  </svg>
                </a>
              </p>
              <p className="text-xs text-orangeBg">
                {currentAudio?.facilitator}
              </p>
            </div>
          </div>

          <div className="max-w-[640px] lg:w-[50%] lg:flex flex-col justify-center">
            <AudioProgress />
            <AudioControls className="flex lg:hidden mt-5" />
          </div>

          <div className="hidden lg:block">
            <a href={currentAudio?.content} download={currentAudio?.title}>
              <svg
                width="34"
                height="34"
                viewBox="0 0 34 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="1.28698"
                  y="0.330435"
                  width="32.3826"
                  height="32.3826"
                  rx="16.1913"
                  fill="#FFF3E5"
                />
                <rect
                  x="1.28698"
                  y="0.330435"
                  width="32.3826"
                  height="32.3826"
                  rx="16.1913"
                  stroke="#FF8A00"
                  strokeWidth="0.66087"
                />
                <path
                  d="M17.3284 10.8105V18.5369M17.3284 18.5369L20.6396 15.2256M17.3284 18.5369L14.0171 15.2256"
                  stroke="#FF8A00"
                  strokeWidth="1.04699"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21.7436 21.8477H17.3286H12.9136"
                  stroke="#FF8A00"
                  strokeWidth="1.04699"
                  strokeLinecap="round"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AudioBottomDialog;
