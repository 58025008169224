import useUpdateManager from "constants/controller_templates/put_controller_template";

export const EditEventManager = (eventId) => {
  const isMultiKey = false;
  const { updateCaller, isLoading, isSuccess, error, data } = useUpdateManager(
    `/event/${eventId}`,
    ["events"],
    isMultiKey
  );
  const editEvent = async (details) => {
    try {
      await updateCaller(details);
    } catch (error) {
      // Handle error if necessary
      console.error("fund wallet error:", error);
    }
  };
  return {
    editEvent,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
