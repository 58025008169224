import useQueryParams from "constants/useQueryParams";
import React from "react";
import { twMerge } from "tailwind-merge";

const CategoriesNav = ({ categoryList }) => {
  const { deleteParam, params, setParam } = useQueryParams();
  return (
    <div className="max-w-section mx-auto overflow-x-scroll no-scrollbar flex gap-[5px]">
      <button
        onClick={() => deleteParam("category")}
        className={twMerge(
          "h-[37px] rounded-[50px] text-gray-400 border border-gray-400 font-medium px-5 text-nowrap",
          !params?.category && "bg-orangeBg text-white border-none"
        )}
      >
        All
      </button>
      {categoryList.map((category, index) => (
        <button
          key={index}
          onClick={() => setParam("category", category?.id)}
          className={twMerge(
            "h-[37px] rounded-[50px] text-gray-400 border border-gray-400 font-medium px-5 text-nowrap",
            params?.category === category?.id &&
              "bg-orangeBg text-white border-none"
          )}
        >
          {category.name}
        </button>
      ))}
    </div>
  );
};

export default CategoriesNav;
