import { useQuery } from "react-query";

import AxiosWithToken from "../../../../constants/api_management/MyHttpHelperWithToken";

const useGetPraiseReportListManager = (props) => {
  const { searchQuery = "", page = "1", pageSize = "30", category } = props;
  return useQuery(["praiseReports", props], async () => {
    try {
      const [response] = [
        await AxiosWithToken.get(
          `/testimony/list?page=${page}&pageSize=${pageSize}${searchQuery}`,
          {
            params: {
              category,
            },
          }
        ),
      ];
      console.log(`i am checking this ${response.data}`);
      return response.data;
    } catch (error) {
      console.log(error.response.data);
      throw new Error(`Sorry: ${error.response.data.message}`);
    }
  });
};

export default useGetPraiseReportListManager;
