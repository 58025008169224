import React, { useEffect, useRef, useState } from "react";

import BaseDashboardNavigation from "../../../../generalComponents/BaseDashboardNavigation";

import useTicketListManager from "../controller/ticketListController";
import useUpdateStatusTicketManager from "../controller/updateStatusTicket";
import Loader from "../../../../generalComponents/Loader";
import ErrorManager from "../../../../generalComponents/ErrorManager";
import CustomButton from "../../../../generalComponents/Button";

import { formatDistanceToNow } from "date-fns";

import useFileUpload from "../../../fileupload/fileUploadController";
import useGetSingleTicketController from "../controller/getSingleTicketController";
import EmptyState from "generalComponents/EmptyState";
import TicketTile from "generalComponents/TicketTile";
import PaginationRounded from "generalComponents/Pagination";
import useDebounce from "utils/UseDebounce";
import { useNavigate } from "react-router-dom";
import AdminWrapper from "modules/admin/AdminWrapper";

const TicketsPage = () => {
  const [currentView, setCurrentView] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();
  const debouncedSearchValue = useDebounce(`&title=${searchValue}`, 1000);
  const [currentPage, setCurrentPage] = useState(1);

  const {
    isError: ticketHasError,
    data: tickets,
    error: ticketFetchError,
    isLoading: fetchingtickets,
    isSuccess: ticketsFetched,
  } = useTicketListManager({
    page: currentPage,
    searchQuery: searchValue ? debouncedSearchValue : "",
    enabled: true,
  });

  if (fetchingtickets) {
    return <Loader />;
  }

  return (
    <AdminWrapper
      title={"Tickets"}
      value={searchValue}
      onChange={(e) => setSearchValue(e.target.value)}
    >
      <div className="flex flex-col w-full mx-auto relative gap-3 pr-10">
        <div className="flex items-center w-full relative justify-between">
          <div className="flex flex-col items-start">
            <p className="text-40px font-semibold">Support</p>
            <p className="text-12px text-textGrey2">
              For assistance, please submit a support ticket.
            </p>
          </div>
          {/* <CustomButton
            buttonText={`Create  New Ticket`}
            onClick={() => {
              document.getElementById("new_ticket").showModal();
            }}
          /> */}
        </div>

        <div className="flex items-center w-full justify-start relative gap-6 mt-5 mb-2">
          {["Open Tickets", "Closed Tickets"].map((el, i) => (
            <p
              key={i}
              role="button"
              onClick={() => setCurrentView(i)}
              className={`text-15px pb-1.5 ${
                currentView === i
                  ? "font-medium text-brandBlack border border-transparent border-b-2 border-b-brandOrange"
                  : "text-textGrey2"
              }`}
            >
              {el}
            </p>
          ))}
          <div className="divider inset-0 absolute pt-3 w-full"></div>
        </div>

        {tickets?.tickets.length <= 0 ? (
          <div className="flex flex-col items-center w-full relative gap-3">
            <EmptyState />
          </div>
        ) : (
          <div className="flex flex-col items-center w-full relative gap-3 h-[67vh] overflow-y-scroll scrollbar-hide">
            {tickets?.tickets.map((el, i) => (
              <TicketTile
                key={i}
                details={el}
                onClick={() => {
                  navigate(`/admin/tickets/${el.id}`);
                }}
              />
            ))}
          </div>
        )}
        {tickets.pagination?.total > 0 && (
          <div className="flex justify-end mt-5 min-w-full">
            <PaginationRounded
              count={tickets?.pagination?.pageTotal}
              defaultPage={tickets?.pagination?.currentPage}
              onChange={(page) => {
                setCurrentPage(page);
              }}
            />
          </div>
        )}
      </div>
    </AdminWrapper>
  );
};

export default TicketsPage;
