import React, { useEffect, useState } from "react";
import { AiOutlineDollar } from "react-icons/ai";

import Loader from "./Loader";
import { IoMdRemoveCircleOutline } from "react-icons/io";
import ModalManagement from "./ModalManagement";

import CustomButton from "./Button";
import ErrorManager from "./ErrorManager";
import useGetPaymentLinksManager from "../modules/admin/paymentLinks/controller/getPaymentLinksController";

import EditPaymentLinkComponent from "../modules/admin/paymentLinks/components/AddEditPaymentLinkComponent";

import useDeleteCellManager from "../modules/admin/cell/controllers/deleteCellController";
import useGetAllCellsManager from "../modules/admin/cell/controllers/getAllCellsController";
import EditCellComponent from "../modules/admin/cell/components/EditCellComponent";
import { useNavigate } from "react-router-dom";
import { CiSquareRemove } from "react-icons/ci/index.esm";

const CellTable = ({ cells }) => {
  const { isError, data, error, isLoading } = useGetAllCellsManager();
  const { deleteCaller, isLoading: deleteIsLoading } = useDeleteCellManager();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [cellDetails, setCellDetails] = useState(null);
  const handleDetailsClick = (index) => {
    setCurrentIndex(index);
    window.my_modal_6.showModal();
  };
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading) {
      setCellDetails({
        cellId: data?.data?.cells[currentIndex]?.id,
        name: data?.data?.cells[currentIndex]?.name,
        meeting_date: data?.data?.cells[currentIndex]?.meeting_date,
        meeting_time: data?.data?.cells[currentIndex]?.meeting_time,
        meeting_day: data?.data?.cells[currentIndex]?.meeting_day,
        meeting_link: data?.data?.cells[currentIndex]?.meeting_link,
      });
    }
  }, [currentIndex, isLoading]);
  const handleSubmission = async () => {
    // handle submission logic
    const requiredId = data.data.cells[currentIndex]._id
      ? data.data.cells[currentIndex]._id
      : "";
    await deleteCaller(requiredId);
  };

  if (isLoading || deleteIsLoading) {
    return <Loader />;
  }

  if (isError) {
    return (
      <div>
        <ErrorManager errorMessage={error.message} />
      </div>
    );
  }

  const style =
    "py-3.5 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-widest";
  return (
    <table class="relative w-full lg:divide-gray-200 lg:divide-y scrollbar-hide">
      <thead class="hidden lg:table-header-group">
        <tr>
          <th class={style}>Cell Name</th>

          <th class={style}>Leader</th>

          <th class={style}>Meeting Schedule</th>
          <th class={style}>Action</th>
        </tr>
      </thead>

      {/* this modal for removing plans */}
      <ModalManagement
        id="my_modal_5"
        hasClose={true}
        children={
          <div className="bg-whiteColor p-14">
            <div
              onClick={() => window.my_modal_5.close()}
              className="text-[40px] mb-10 w-full justify-between items-center flex"
            >
              <p className="text-18px font-bold">{`Delete ${data?.data?.cells[currentIndex]?.name}`}</p>
              <CiSquareRemove />
            </div>
            {data.data.cells[currentIndex] ? (
              <p className=" mb-10 font-normal text-blackColor text-center">
                {`Are you sure you want remove ${data?.data?.cells[currentIndex]?.name} from the list?`}
              </p>
            ) : (
              <p className="text-blackColor">Cells data not available.</p>
            )}

            <CustomButton
              textColor={"whiteColor"}
              buttonText={"Yes, I am sure."}
              buttonColor={"brandPurple"}
              className={"w-full"}
              isLoading={isLoading}
              onClick={handleSubmission}
            />
          </div>
        }
      />

      {/* this modal for editing plans */}
      <ModalManagement
        id="my_modal_6"
        hasClose={true}
        children={
          cellDetails && (
            <EditCellComponent
              onClick={() => window.my_modal_6.close()}
              cellId={cellDetails.cellId}
              name={cellDetails?.name}
              meeting_date={cellDetails.meeting_date}
              meeting_time={cellDetails.meeting_time}
              meeting_day={cellDetails.meeting_day}
              meeting_link={cellDetails.meeting_link}
            />
          )
        }
      />

      <tbody>
        {data.data.cells.map((item, index) => {
          return (
            <tr
              key={index}
              className={`${
                index % 2 === 0 ? "bg-gray-50" : "bg-white"
              } w-full`}
            >
              <td class="px-4 py-4 text-sm font-bold text-gray-900 align-top lg:align-middle whitespace-nowrap">
                <div class="flex items-center mr-0 md:mr-8">
                  <div
                    className={`h-3 w-3 bg-brandOrange rounded-full mr-2`}
                  ></div>
                  {item.name}
                </div>
                <div class="mt-1 space-y-2 font-medium pl-11 lg:hidden">
                  <div class="flex items-center">
                    <AiOutlineDollar size={18} className="mr-2 text-black/40" />
                    {item.cell_head && item.cell_head.first_name
                      ? `${item.cell_head.first_name}  ${item.cell_head.last_name}`
                      : `No Leader Selected`}
                  </div>

                  <div class="flex items-center">
                    <svg
                      class="w-4 h-4 mr-2 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                      />
                    </svg>
                    {`${item.meeting_day}/${item.meeting_time}`}
                  </div>

                  <div class="flex items-center pt-3 space-x-4">
                    <button
                      onClick={() => {
                        navigate(`/admin/cell-details/${item.id}`);
                      }}
                      class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 transition-all duration-200 bg-gray-100 border border-gray-300 rounded-md shadow-sm hover:bg-offCoffee focus:outline-none hover:text-white hover:border-offCoffee focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Details
                    </button>
                    <button
                      onClick={() => {
                        handleDetailsClick(index);
                      }}
                      class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 transition-all duration-200 bg-gray-100 border border-gray-300 rounded-md shadow-sm hover:bg-offCoffee focus:outline-none hover:text-white hover:border-offCoffee focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Edit
                    </button>
                    {
                      <button
                        onClick={() => {
                          setCurrentIndex(index);
                          window.my_modal_5.showModal();
                        }}
                      >
                        <IoMdRemoveCircleOutline color="blackColor" size={20} />
                      </button>
                    }
                  </div>
                </div>
              </td>

              <td class="hidden px-4 py-4 text-sm font-medium text-gray-900 lg:table-cell whitespace-nowrap">
                <div class="flex items-center">
                  <AiOutlineDollar size={18} className="mr-2 text-black/40" />
                  {item.cell_head && item.cell_head.first_name
                    ? `${item.cell_head.first_name}  ${item.cell_head.last_name}`
                    : `No Leader Selected`}
                </div>
              </td>

              <td class="hidden px-4 py-4 text-sm font-medium text-gray-900 xl:table-cell whitespace-nowrap">
                <div class="flex items-center">
                  <svg
                    class="w-4 h-4 mr-2 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                    />
                  </svg>
                  {`${item.meeting_day}/${item.meeting_time}`}
                </div>
              </td>

              <td class="hidden px-4 py-4 lg:table-cell whitespace-nowrap">
                <div class="flex items-center space-x-4">
                  <button
                    onClick={() => {
                      navigate(`/admin/cell-details/${item.id}`);
                    }}
                    class="inline-flex items-center px-3 py-2 text-sm font-medium text-gray-700 transition-all duration-200 bg-gray-100 border border-gray-300 rounded-md shadow-sm hover:bg-offCoffee focus:outline-none hover:text-white hover:border-offCoffee focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Details
                  </button>
                  <button
                    onClick={() => {
                      handleDetailsClick(index);
                    }}
                    class="inline-flex items-center px-3 py-2 text-sm font-medium text-gray-700 transition-all duration-200 bg-gray-100 border border-gray-300 rounded-md shadow-sm hover:bg-offCoffee focus:outline-none hover:text-white hover:border-offCoffee focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Edit
                  </button>
                  {
                    <button
                      onClick={() => {
                        setCurrentIndex(index);
                        window.my_modal_5.showModal();
                      }}
                    >
                      <IoMdRemoveCircleOutline color="blackColor" size={20} />
                    </button>
                  }
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default CellTable;
