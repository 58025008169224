import React, { useEffect } from "react";
import Header from "./Header";
// import Footer from "./Footer";
import NewFooter from "./NewFooter";
import { useLocation, useNavigate } from "react-router-dom";
import useLoggedInUser from "constants/loggedInUser";

const HeaderFooter = ({
  clickHome,
  clickFeatures,
  clickSolutions,

  clickDownload,
  children,

  headerClass,
  solid,
}) => {
  const { pathname } = useLocation();
  const { loggedInUser } = useLoggedInUser();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (!loggedInUser && pathname !== `/terms-and-condition`) {
      localStorage.setItem("pathname", pathname);
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInUser]);
  return (
    <div className="relative">
      <Header
        clickHome={clickHome}
        clickFeatures={clickFeatures}
        clickSolutions={clickSolutions}
        clickDownload={clickDownload}
        headerClass={headerClass}
        solid={solid}
      />
      {children}
      <NewFooter />
    </div>
  );
};

export default HeaderFooter;
