import React from "react";
import { twMerge } from "tailwind-merge";

const AudioDiskWrapper = ({ children, large, className }) => {
  return (
    <div
      className={twMerge(
        "relative grid place-content-center items-center size-[72px] rounded-full",
        large && "size-[280px]",
        className
      )}
    >
      <div
        className={twMerge(
          "semi-circle absolute inset-0 size-full m-auto border-[1.8px] rounded-full border-purpleBg",
          large && "border-[7px]"
        )}
      ></div>
      {children}
    </div>
  );
};

export default AudioDiskWrapper;
