import { cozaLogoWhite } from "assets/icons";
import React from "react";

const NotificationTiles = ({ details }) => {
  return (
    <div className="bg-purpleOverlay text-white/90 rounded-[15px] shadow-md flex justify-start w-full items-start gap-3 py-3 px-3 mb-2">
      <img src={cozaLogoWhite} alt="" className="w-10 h-10 object-contain" />
      <div>
        <p className="text-sm font-semibold mb-0.5">{details.title}</p>
        <p className="text-xs leading-tight">{details.message}</p>
      </div>
    </div>
  );
};

export default NotificationTiles;
