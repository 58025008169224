import { twMerge } from "tailwind-merge";

const TextGradient = (props) => {
  const { children, className, ...rest } = props;
  return (
    <p
      {...rest}
      className={twMerge(
        "text-[400px] leading-[400px] font-bold text-gradient-gray text-nowrap",
        "fixed top-[80px] -left-10 -z-10 overflow-hidden",
        className
      )}
    >
      {children}
    </p>
  );
};

export default TextGradient;
