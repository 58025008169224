import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { useNavigate, useParams } from "react-router-dom";
import GlobalVariables from "../constants/GlobalVariables";
import useRemoveCellMemberManager from "../modules/admin/cell/controllers/removeCellMemberController";
import useMakeUserHeadManager from "../modules/admin/cell/controllers/makeUserHeadController";
import { IoMdRemoveCircleOutline } from "react-icons/io";
import ModalManagement from "./ModalManagement";
import CustomButton from "./Button";

const CellMembersTable = ({ users, className }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "MMMM d, yyyy");
  };
  const [currentIndex, setCurrentIndex] = useState(0);
  const { cellId } = useParams();

  const { updateCaller: deleteMember, isLoading: deletingMember } =
    useRemoveCellMemberManager(cellId);

  const { updateCaller: makeUserHead, isLoading: assigingHead } =
    useMakeUserHeadManager(cellId);
  const navigate = useNavigate();
  return (
    <div>
      <table
        className={`min-w-full lg:divide-gray-200 lg:divide-y mt-0  overflow-scroll`}
      >
        <thead class="hidden lg:table-header-group sticky top-0 z-10 bg-white">
          <tr>
            <th class="py-3.5 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-widest">
              Members
            </th>

            <th class="py-3.5 px-4 text-left text-xs uppercase tracking-widest font-medium text-gray-500">
              Email Address
            </th>

            <th class="py-3.5 px-4 text-left text-xs uppercase tracking-widest font-medium text-gray-500">
              Phone Number
            </th>

            <th class="py-3.5 px-4 text-left hidden xl:table-cell text-xs uppercase tracking-widest font-medium text-gray-500">
              Join Date
            </th>

            <th class="py-3.5 px-4 text-left text-xs uppercase tracking-widest font-medium text-gray-500">
              Occupation
            </th>

            <th class="py-3.5 px-4 text-left text-xs uppercase tracking-widest font-medium text-gray-500">
              Action
            </th>
          </tr>
        </thead>
        {/* this modal for removing plans */}
        <ModalManagement
          id="my_modal_5"
          children={
            <div className="bg-whiteColor p-14">
              {users[currentIndex] ? (
                <p className=" mb-10 font-normal text-blackColor text-center">
                  {`Are you sure you want remove ${users[currentIndex].user.first_name} from this cell?`}
                </p>
              ) : (
                <p className="text-blackColor">
                  User deleted. Please close to continue.
                </p>
              )}

              <CustomButton
                textColor={"whiteColor"}
                buttonText={"Yes, I am sure."}
                buttonColor={"brandPurple"}
                className={"w-full"}
                isLoading={deletingMember}
                onClick={async () => {
                  const details = {
                    userId: users[currentIndex].user.id,
                  };
                  await deleteMember(details);
                }}
              />
            </div>
          }
        />

        <tbody className="overflow-y-auto h-[40vh]  scrollbar-hide relative">
          {users.map((item, index) => {
            const formattedDate = formatDate(item.createdAt);
            const userDetails = item.user;
            return (
              <tr className={`${index % 2 === 0 ? "bg-gray-50" : "bg-white"}`}>
                <td class="px-4 py-4 text-sm font-bold text-gray-900 align-top lg:align-middle whitespace-nowrap">
                  <div class="flex items-center mr-0 md:mr-8">
                    <img
                      class="flex-shrink-0 object-cover w-8 h-8 mr-3 rounded-full"
                      src={
                        !userDetails.profile_picture
                          ? GlobalVariables.defaultProfilePicture
                          : userDetails.profile_picture
                      }
                      alt=""
                    />
                    {`${userDetails.first_name} ${userDetails.last_name}`}
                  </div>
                  <div class="mt-1 space-y-2 font-medium pl-11 lg:hidden">
                    <div class="flex items-center">
                      <svg
                        class="w-4 h-4 mr-2 text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                        />
                      </svg>
                      {userDetails.email}
                    </div>

                    <div class="flex items-center">
                      <svg
                        class="w-4 h-4 mr-2 text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                        />
                      </svg>
                      {userDetails.phone ?? "No Phone"}
                    </div>

                    <div class="flex items-center">
                      <svg
                        class="w-4 h-4 mr-2 text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                        />
                      </svg>

                      {formattedDate}
                    </div>

                    <div class="flex items-center pt-3 space-x-4">
                      <button
                        onClick={() => {
                          const details = {
                            userId: userDetails.id,
                          };
                          makeUserHead(details);
                        }}
                        class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 transition-all duration-200 bg-gray-100 border border-gray-300 rounded-md shadow-sm hover:bg-offCoffee focus:outline-none hover:text-white hover:border-offCoffee focus:ring-2 focus:ring-offset-2 focus:ring-brandOrange"
                      >
                        Make Head
                      </button>
                      <button
                        onClick={() =>
                          navigate(`/admin/user-details/${userDetails.id}`)
                        }
                        class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 transition-all duration-200 bg-gray-100 border border-gray-300 rounded-md shadow-sm hover:bg-offCoffee focus:outline-none hover:text-white hover:border-offCoffee focus:ring-2 focus:ring-offset-2 focus:ring-brandOrange"
                      >
                        Details
                      </button>
                      <button
                        onClick={() => {
                          setCurrentIndex(index);
                          window.my_modal_5.showModal();
                        }}
                      >
                        <IoMdRemoveCircleOutline color="blackColor" size={20} />
                      </button>
                    </div>
                  </div>
                </td>

                <td class="hidden px-4 py-4 text-sm font-medium text-gray-900 lg:table-cell whitespace-nowrap">
                  <div class="flex items-center">
                    <svg
                      class="w-4 h-4 mr-2 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                      />
                    </svg>
                    {userDetails.email}
                  </div>
                </td>

                <td class="hidden px-4 py-4 text-sm font-medium text-gray-900 lg:table-cell whitespace-nowrap">
                  <div class="flex items-center">
                    <svg
                      class="w-4 h-4 mr-2 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                      />
                    </svg>
                    {userDetails.phone ?? "No phone"}
                  </div>
                </td>

                <td class="hidden px-4 py-4 text-sm font-medium text-gray-900 xl:table-cell whitespace-nowrap">
                  <div class="flex items-center">
                    <svg
                      class="w-4 h-4 mr-2 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                      />
                    </svg>
                    {formattedDate}
                  </div>
                </td>

                <td class="px-4 py-4 text-sm font-medium text-right text-gray-900 align-top lg:align-middle lg:text-left whitespace-nowrap">
                  {userDetails.occupation ?? "No Occupation"}
                </td>

                <td class="hidden px-4 py-4 lg:table-cell whitespace-nowrap">
                  <div class="flex items-center space-x-4">
                    <button
                      onClick={() => {
                        const details = {
                          userId: userDetails.id,
                        };
                        makeUserHead(details);
                      }}
                      class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 transition-all duration-200 bg-gray-100 border border-gray-300 rounded-md shadow-sm hover:bg-offCoffee focus:outline-none hover:text-white hover:border-offCoffee focus:ring-2 focus:ring-offset-2 focus:ring-brandOrange"
                    >
                      Make Head
                    </button>
                    <button
                      onClick={() =>
                        navigate(`/admin/user-details/${userDetails.id}`)
                      }
                      class="inline-flex items-center px-3 py-2 text-sm font-medium text-gray-700 transition-all duration-200 bg-gray-100 border border-gray-300 rounded-md shadow-sm hover:bg-offCoffee focus:outline-none hover:text-white hover:border-offCoffee focus:ring-2 focus:ring-offset-2 focus:ring-brandOrange"
                    >
                      Details
                    </button>
                    <button
                      onClick={() => {
                        setCurrentIndex(index);
                        window.my_modal_5.showModal();
                      }}
                    >
                      <IoMdRemoveCircleOutline color="blackColor" size={20} />
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CellMembersTable;
