import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { ArrowDownIcon } from '@heroicons/react/outline';

/**
 * 
 * @param {Array<any>} items 
 * @param {String} className 
 * @returns 
 */

const AccordionBase = ({ items, className }) => {
    return (
        <div className={className} >
            {items.map(({ id, title, content, expandIcon }) => (
                <Accordion key={id}>
                    <AccordionSummary
                        expandIcon={<ArrowDownIcon />}
                        aria-controls={`panel-${id}-content`}
                        id={`panel-${id}-header`}
                    >
                        <Typography>{title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>{content}</Typography>
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    );
};

export default AccordionBase;
