import React, { useEffect, useRef, useState } from "react";
import { format } from "date-fns";
import BaseDashboardNavigation from "../../../../generalComponents/BaseDashboardNavigation";

import PaginationRounded from "../../../../generalComponents/Pagination";

import useGetCategories from "modules/pageSermons/controllers/get_categories_controller";
import useDebounce from "utils/UseDebounce";
import VideoItem from "generalComponents/VideoItem";

import AudioItem from "generalComponents/AudioItem";
import CustomButton from "generalComponents/Button";
import SearchComponent from "generalComponents/SearchComponent";
import InputWithFullBoarder from "generalComponents/InputWithFullBoarder";
import CustomDropdown from "generalComponents/CustomDropdown";
import TabsManagement from "generalComponents/TabsManagement";
import Loader from "generalComponents/Loader";

import AdminDeleteConfirmationModal from "generalComponents/AdminDeleteConfirmationModal";

import AddEditLibrary from "../components/AddEditLibrary";
import ViewLibrary from "../components/ViewLibrary";
import { DeleteLibraryManager } from "../controllers/deleteLibraryContentController";
import useGetLibraryContentsManager from "../controllers/getLibraryContentsController";
import BookItem from "generalComponents/BookItem";
import AdminWrapper from "modules/admin/AdminWrapper";

const AdminLibraryPage = () => {
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(`&title=${searchValue}`, 1000);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [selected, setSelected] = useState(null);
  const { deleteContent, isLoading: deleting } = DeleteLibraryManager(
    selected?.id
  );

  const { data: categories } = useGetCategories({
    group: "library",
  });

  const { data, isLoading } = useGetLibraryContentsManager({
    page: currentPage,
    searchQuery: searchValue,
    type: currentIndex === 0 ? "ebook" : "audio",
  });

  const type = ["ebook", "audio"];

  useEffect(() => {
    if (selected) {
      document.getElementById("view_library").showModal();
    }
  }, [selected]);

  return (
    <AdminWrapper
      title={"Sermons"}
      value={searchValue}
      onChange={(e) => {
        setSearchValue(e.target.value);
      }}
    >
      <div className="w-full flex flex-col pr-10">
        <div className="flex justify-between flex-col gap-5 md:flex-row w-full mb-10">
          <div className=" flex flex-col items-start">
            <p className="text-[24px] font-semibold">Library</p>
            <p className="text-14px">Showing data over the last 30 days</p>
          </div>
          <div className="mb-3">
            <CustomButton
              buttonText={`+Upload Book`}
              onClick={() => {
                setSelected(null);
                document.getElementById("add_library").showModal();
              }}
              radius={`[5px]`}
              className={`max-w-max`}
            />
          </div>
        </div>
        <div className="flex items-center justify-between flex-col gap-5 md:flex-row">
          <div className="max-w-[501px] w-full flex items-center flex-col gap-5 md:flex-row">
            <SearchComponent
              value={searchValue}
              marginRight={"mr-3"}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <CustomDropdown
              placeholder={"Category"}
              options={categories?.data?.categories.map((el) => el?.name)}
            />
          </div>
          <InputWithFullBoarder type={"date"} />
        </div>
        <div className="w-full my-3">
          <TabsManagement
            options={type}
            currentView={currentIndex}
            setCurrentView={setCurrentIndex}
          />
        </div>
        <div className="md:h-[67vh] relative md:overflow-y-auto scrollbar-hide">
          {isLoading ? (
            <Loader />
          ) : (
            <div className="flex flex-wrap gap-5 mx-auto">
              {data?.data?.items?.map((message, mIndex) =>
                currentIndex === 0 ? (
                  <BookItem
                    key={mIndex}
                    book={message}
                    isAdmin={true}
                    showPrice={message?.subscription_type === "paid"}
                    onClick={() => {
                      setSelected(message);
                      if (selected?.id === message?.id) {
                        document.getElementById("view_library").showModal();
                      }
                    }}
                  />
                ) : (
                  <AudioItem
                    key={mIndex}
                    message={message}
                    isAdmin={true}
                    showPrice={message?.subscription_type === "paid"}
                    onClick={() => {
                      setSelected(message);
                      if (selected?.id === message?.id) {
                        document.getElementById("view_library").showModal();
                      }
                    }}
                  />
                )
              )}
            </div>
          )}
        </div>

        {data?.data?.pagination?.total > 0 && (
          <div className="flex justify-end mt-10 min-w-full">
            <PaginationRounded
              count={data?.pagination?.pageTotal}
              defaultPage={data?.pagination?.currentPage}
              onChange={(page) => {
                setCurrentPage(page);
              }}
            />
          </div>
        )}
      </div>
      {selected && <ViewLibrary details={selected} />}
      <AddEditLibrary
        details={selected}
        categories={categories?.data?.categories}
      />
      <AdminDeleteConfirmationModal
        title={"Delete Book"}
        body={`Do you really want to delete ${selected?.title} from the list of Books?`}
        buttonText={`Yes, Delete`}
        onClick={async () => {
          await deleteContent();
          document.getElementById("admin_delete").close();
          document.getElementById("view_library").close();
        }}
        isLoading={deleting}
      />
    </AdminWrapper>
  );
};

export default AdminLibraryPage;
