import ArrowRight from "assets/icons/ArrowRight";
// import { homeLatestSermon } from "assets/images";
import { RoundedButton } from "generalComponents/Button";
import ContainerWithVerticalText from "generalComponents/ContainerWithVerticalText";
import TextStroke from "generalComponents/TextStroke";
import useGetSermons from "modules/pageSermons/controllers/get_sermons_contoller";
import React from "react";

const LatestSermon = () => {
  const { data } = useGetSermons({
    group: "sermon",
    page: 1,
    pageSize: 2,
  });
  return (
    <div className="py-10">
      <TextStroke>Latest Sermon</TextStroke>
      <div className="flex flex-wrap justify-around items-center lg:grid grid-cols-2 gap-10 max-w-section mx-auto -mt-32">
        <div className="flex md:justify-start md:px-0 px-5">
          <img
            src={data?.data?.items?.[0]?.cover_image?.url}
            alt=""
            className="object-cover min-h-[400px] "
          />
        </div>
        <ContainerWithVerticalText
          text={"Our Latest Sermon"}
          className="px-5 md:mr-auto"
        >
          <h2 className="font-semibold text-3xl lg:text-5xl text-black mb-6">
            {data?.data?.items[0]?.title}
          </h2>
          <p className="text-sm text-gray-500">
            {data?.data?.items[0]?.description}
          </p>
          <RoundedButton
            url="/sermon"
            className="group px-5 py-3 flex items-center gap-3 mt-5"
          >
            <span>Learn more</span>
            <ArrowRight className={"group-hover:animate-bounce-forward"} />
          </RoundedButton>
        </ContainerWithVerticalText>
      </div>
    </div>
  );
};

export default LatestSermon;
