// children/list
import AxiosWithToken from "constants/api_management/MyHttpHelperWithToken";
import { useQuery } from "react-query";

export const useGetChildrenResources = (params = {}) => {
  return useQuery(
    ["children_resources", params],
    async () => {
      try {
        const [response] = [
          await AxiosWithToken.get(`/children/list`, { params }),
        ];
        //console.log(`this is the current subscription status ${response}`);
        return response.data;
      } catch (error) {
        //console.log(error.response.data);
        throw new Error(`Sorry: ${error.response.data.message}`);
      }
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const useGetChildrenCategories = (params = {}) => {
  return useQuery(
    ["children_categories", params],
    async () => {
      try {
        const [response] = [
          await AxiosWithToken.get(`/category/list`, { params }),
        ];
        //console.log(`this is the current subscription status ${response}`);
        return response.data;
      } catch (error) {
        //console.log(error.response.data);
        throw new Error(`Sorry: ${error.response.data.message}`);
      }
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};
