import ModalManagement from "generalComponents/ModalManagement";
import React from "react";

import DisplayFile from "generalComponents/DisplayFile";
import CustomButton from "generalComponents/Button";

import SermonDisc from "generalComponents/SermonDisc";
import { EditEventManager } from "../controller/editEventController";
import { clock, eventCalendar } from "assets/icons";
import { formatDate } from "utils/formatDate";
import { convertTo12HourFormat } from "utils/timeToAmPm";

const ViewEvents = ({ details }) => {
  const { editEvent, isLoading: editing } = EditEventManager(details?.id);
  return (
    <ModalManagement id={"view_event"} title={"Content Details"}>
      <div className="w-full md:w-[500px] flex flex-col gap-5 mt-5">
        <DisplayFile
          fileUrl={
            details?.video_url ? details?.video_url : details?.cover_image?.url
          }
        />
        <div className="w-full flex flex-col">
          <p className="text-12px font-light text-textGrey">
            {" "}
            Category:{" "}
            <span className="text-brandPurple font-medium">
              {details?.category?.name}
            </span>
          </p>
          <p className="text-20px text-purpleOverlay font-semibold">
            {details?.name}
          </p>
          <div className="flex flex-col my-2 gap-1">
            <div className="flex items-center gap-2">
              <img src={eventCalendar} alt="" />

              <p className="text-12px text-textGrey">
                {formatDate(details?.date)}
              </p>
            </div>
            <div className="flex items-center gap-2">
              <img src={clock} alt="" />
              <p className="text-12px text-textGrey">
                {convertTo12HourFormat(details?.time)}
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full">
          <p className="text-[15px] text-purpleOverlay">About</p>
          <p className="text-12px text-textGrey">{details?.description}</p>
        </div>

        <div className="flex flex-col w-full">
          <CustomButton
            buttonText={
              !details?.featured ? "Make Featured" : "Remove Featured"
            }
            isLoading={editing}
            onClick={async () => {
              const info = {
                featured: !details?.featured,
              };
              await editEvent(info);
              document.getElementById("view_event").close();
            }}
            buttonColor={details?.approved ? "bg-brandRed" : "bg-success"}
            radius={"rounded-[40px]"}
          />
          <div className="flex items-center gap-5 w-full my-2">
            <CustomButton
              buttonText={"Edit"}
              buttonColor={"bg-purpleOverlay"}
              className={"w-full"}
              radius={"rounded-[40px]"}
              onClick={() => {
                document.getElementById("view_event").close();
                document.getElementById("add_event").showModal();
              }}
            />
            <CustomButton
              buttonText={"Delete"}
              buttonColor={"bg-brandRed"}
              onClick={() => {
                document.getElementById("admin_delete").showModal();
              }}
              className={"w-full"}
              radius={"rounded-[40px]"}
            />
          </div>
        </div>
      </div>
    </ModalManagement>
  );
};

export default ViewEvents;
