import AxiosWithToken from "constants/api_management/MyHttpHelperWithToken";
import { useQuery } from "react-query";

export const useGetFamilyProcessSubmissions = (params = {}) => {
  const getSubmissions = async () => {
    try {
      const [response] = [
        await AxiosWithToken.get("/family-process", { params }),
      ];
      //console.log(`i am checking this ${response.status}`);
      return response.data;
    } catch (error) {
      //console.log(error.response.data);
      throw new Error(`Sorry: ${error.response.data.message}`);
    }
  };

  return useQuery({
    queryKey: ["family_process", params],
    queryFn: getSubmissions,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};

export const useGetSingleFamilyProcessSubmission = ({
  type,
  formId,
  enabled,
}) => {
  const getSubmissions = async () => {
    try {
      const [response] = [
        await AxiosWithToken.get(`/family-process/${type}/${formId}`),
      ];
      //console.log(`i am checking this ${response.status}`);
      return response.data;
    } catch (error) {
      //console.log(error.response.data);
      throw new Error(`Sorry: ${error.response.data.message}`);
    }
  };

  return useQuery({
    queryKey: ["family_process", formId],
    queryFn: getSubmissions,
    enabled,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};
