import {
  authBg,
  // authentication,
  // logo,
  // topPattern,
} from "../../../assets/images";
import { RoundedButton } from "../../../generalComponents/Button";
import { Link } from "react-router-dom";
import { cozaLogo } from "assets/icons";
import {
  AppStoreButton,
  GooglePlayButton,
} from "modules/homePage/component/DownloadButtons";
import { twMerge } from "tailwind-merge";
import { useState } from "react";

const AuthenticationBase = ({
  title,
  subtitle,
  inputFields,
  buttonText,
  onClick,
  afterElements,
  isLoading,
  className,
}) => {
  const [imgLoading, setImgLoading] = useState(true);
  return (
    <div
      className={twMerge(
        "@container h-[100svh] relative text-blackColor overflow-auto",
        className
      )}
    >
      <div className="flex @lg:grid grid-cols-2 items-center justify-around h-full w-full">
        <div className="w-full h-full max-h-[100svh] hidden flex-col items-center justify-center  @lg:flex relative py-5 bg-gradient-to-br to-purpleOverlay via-purpleOverlay from-purpleBg bg-purpleOverlay p-5">
          <div className="relative max-h-[95%] min-w-[90%]">
            <div
              className={twMerge(
                "rounded-[30px] shadow-2xl duration-500 hover:scale-105 max-h-full h-[80svh] w-[95%] bg-purpleOverlay/30 animate-pulse",
                !imgLoading && "hidden"
              )}
            ></div>
            <img
              onLoad={() => setImgLoading(false)}
              className={twMerge(
                "rounded-[30px] shadow-2xl duration-500 hover:scale-105 max-h-full w-auto object-contain",
                imgLoading && "hidden"
              )}
              src={authBg}
              alt="authentication pic"
            />
            <div className="px-14 py-10 absolute inset-0 top-auto bottom-14 mx-auto backdrop-blur-xl rounded-[20px] flex flex-col items-center justify-center gap-7 bg-[#2F064066] w-fit h-fit">
              <p className="text-center max-w-[445px] text-white text-lg leading-[19.8px]">
                But the multitudes, perceiving it, followed him. He welcomed
                them, and spoke to them of the Kingdom of God, and he cured
                those who needed healing.
              </p>

              <p className="text-orangeBg font-medium text-xl">Luke 9:11</p>
            </div>
          </div>
        </div>

        <div className="px-5 @lg:p-5 w-full flex flex-col pt-20 mx-auto md:max-w-[454px] @lg:max-w-[640px]">
          <Link to={"/"}>
            <img
              className="object-contain w-[100px] h-[100px] mb-2 mx-auto"
              src={cozaLogo}
              alt="COZA logo"
            />
          </Link>
          <div className="bg-white flex flex-col justify-center mt-10">
            <div className="mb-8">
              <p className="text-30px leading-8 font-semibold mb-1">{title}</p>
              <p className="text-sm leading-5 text-gray-500 mt-2">{subtitle}</p>
            </div>
            <form onSubmit={onClick}>
              {inputFields}
              <RoundedButton
                onClick={onClick}
                className="mt-[27px] mb-[10px] w-full py-4 text-base font-semibold"
                isLoading={isLoading}
              >
                {buttonText}
              </RoundedButton>
            </form>
          </div>
          <div className="text-gray-500 text-sm">{afterElements}</div>

          <div className="flex flex-col md:flex-row md:items-center gap-2 mt-5 mx-auto">
            <AppStoreButton fill="#2F0640" />
            <GooglePlayButton fill="#2F0640" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthenticationBase;
