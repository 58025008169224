export function convertTo12HourFormat(time) {
  // Split the time into hours and minutes
  const [hours, minutes] = time.split(":").map(Number);

  // Determine AM/PM suffix
  const period = hours >= 12 ? "pm" : "am";

  // Convert hours to 12-hour format
  const formattedHours = hours % 12 || 12;

  // Format the time string
  return `${formattedHours}:${minutes.toString().padStart(2, "0")} ${period}`;
}
