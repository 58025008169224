import { RoundedButton } from "generalComponents/Button";
import InputWithFullBoarder from "generalComponents/InputWithFullBoarder";
import SelectInput from "generalComponents/SelectInput";
import React, { useState } from "react";
import { twMerge } from "tailwind-merge";

const subjectOptions = [
  {
    label: "Enquiry",
    value: "Enquiry",
  },
  {
    label: "New Convert",
    value: "New Convert",
  },
];

const ContactForm = ({
  handleSubmit,
  handleChange,
  formData,
  isLoading,
  className,
}) => {
  const [messageLength, setMessageLength] = useState(0);

  const handleLength = (e) => {
    const value = e.target?.value;
    setMessageLength(value?.length);
  };
  return (
    <form
      onSubmit={handleSubmit}
      className={twMerge(
        "bg-purpleOverlay py-10 rounded-[20px] px-5 md:px-12 w-full max-w-full",
        className
      )}
    >
      <SelectInput
        label={"Subject"}
        labelClass="text-white"
        name="subject"
        value={formData?.title}
        onChange={(e) => handleChange(e, "title")}
        placeholder={"Select subject"}
        options={subjectOptions}
        className={
          "bg-[#F2F2F2] placeholder:text-[#8E8E8E] outline-none border-none"
        }
        otherProps={{
          required: true,
        }}
      />
      <InputWithFullBoarder
        id={"fullname"}
        label={"Full Name"}
        type={"text"}
        value={formData?.fullname}
        onChange={(e) => handleChange(e, "fullname")}
        labelClass={"text-white"}
        className={"border-none bg-white rounded-[5px] min-h-[50px]"}
      />
      <InputWithFullBoarder
        id={"email"}
        label={"Email"}
        type={"email"}
        value={formData?.email}
        onChange={(e) => handleChange(e, "email")}
        labelClass={"text-white"}
        className={"border-none bg-white rounded-[5px] min-h-[50px]"}
      />
      <InputWithFullBoarder
        id={"phone"}
        label={"Phone Number"}
        type={"tel"}
        value={formData?.phone}
        onChange={(e) => handleChange(e, "phone")}
        labelClass={"text-white"}
        className={"border-none bg-white rounded-[5px] min-h-[50px]"}
      />

      {/* <textarea
                  name="Support Request Detail"
                  id="supportRequest"
                  cols="30"
                  rows="10"
                  value={supportRequest}
                  onChange={handleSupportRequestChange}
                ></textarea> */}

      <InputWithFullBoarder
        id={"description"}
        label={`Message (${messageLength}/200)`}
        type={"textarea"}
        value={formData?.description}
        rows={4}
        isTextArea={true}
        onChange={(e) => {
          handleChange(e, "description");
          handleLength(e);
        }}
        className={"h-[160px] border-none bg-white rounded-[5px]"}
        labelClass={"text-white"}
        otherProps={{
          maxLength: 200,
        }}
      />

      <RoundedButton
        type={"submit"}
        isLoading={isLoading}
        className="py-3 w-full"
      >
        Submit
      </RoundedButton>
    </form>
  );
};

export default ContactForm;
