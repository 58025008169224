import AxiosWithToken from "constants/api_management/MyHttpHelperWithToken";
import { useQuery } from "react-query";

export const useGetGallery = (params = {}) => {
  return useQuery(
    ["galleryList", params],
    async () => {
      try {
        const [response] = [
          await AxiosWithToken.get(`/gallery/list`, { params }),
        ];
        //console.log(`this is the current subscription status ${response}`);
        return response.data;
      } catch (error) {
        //console.log(error.response.data);
        throw new Error(`Sorry: ${error.response.data.message}`);
      }
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const useGetSingleGallery = (id, params = {}) => {
  return useQuery(
    ["singleGallery", id, params],
    async () => {
      try {
        const [response] = [
          await AxiosWithToken.get(`/gallery/${id}/images`, { params }),
        ];
        //console.log(`this is the current subscription status ${response}`);
        return response.data;
      } catch (error) {
        //console.log(error.response.data);
        throw new Error(`Sorry: ${error.response.data.message}`);
      }
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};
