import StoreItemDetails from "modules/pageStore/components/StoreItemDetails";
import React from "react";
import SermonDetails from "./SermonDetails";

const BookItem = ({ book, showPrice = false, isAdmin, onClick }) => {
  return (
    <>
      <div onClick={onClick} className="flex flex-col cursor-pointer">
        <img
          src={book?.cover_image?.url}
          alt=""
          className="w-[165px] h-[258px] rounded-[12px] object-cover"
        />

        <p className="max-w-[150px] line-clamp-2 text-sm font-semibold text-purpleOverlay">
          {book.title}
        </p>
        <p className="max-w-[150px] text-xs text-orangeBg">{book.author}</p>
        {showPrice ? (
          <p className="text-xl font-semibold text-purpleBg text-right w-full mt-3">
            <span className="text-orangeBg">₦</span>
            {book.price ? book.price?.toLocaleString() : 0}
          </p>
        ) : null}
      </div>

      {showPrice ? (
        <StoreItemDetails
          message={book}
          id={book?.id}
          type="book"
          title="Book Details"
        />
      ) : (
        <SermonDetails
          message={book}
          id={book?.id}
          type="book"
          title="Book Details"
        />
      )}
    </>
  );
};

export default BookItem;
