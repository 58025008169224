import {
  useGetSermonById,
  useGetSermonLikesById,
} from "modules/pageSermons/controllers/get_sermons_contoller";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Loader from "./Loader";
import useWindowInactivity from "@toluade/use-window-inactivity";
// import { cozaLogo } from "assets/icons";
import HeaderFooter from "./HeaderFooter";
import SermonEngagementButton from "./SermonEngagementButton";
import { abbreviateNumber } from "constants/textFormatters";
import { useLikeSermon } from "modules/pageSermons/controllers/put_controller";
import { useUserStore } from "store/userStore";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { IoIosShareAlt } from "react-icons/io";
import { twMerge } from "tailwind-merge";
import EllipsisIcon from "modules/pagePraiseReports/components/EllipsisIcon";
import {
  useDeleteSermonComment,
  useGetSermonComments,
  usePostSermonComment,
} from "modules/pageSermons/controllers/comments_contoller";
import useDisclosure from "constants/useDisclosure";
import CustomModalHeadless from "./CustomModalHeadless";
import { formatDistance } from "date-fns";
import { avatar } from "assets/images";
import { VscSend } from "react-icons/vsc";

const appBaseUrl = process.env.REACT_APP_APP_BASE_URL;

const VideoPlayer = ({ id }) => {
  const user = useUserStore((state) => state.user);
  const [copied, setCopied] = useState(false);
  const [fullDescription, setFullDescription] = useState(false);
  const [comment, setComment] = useState("");
  const [commentId, setCommentId] = useState();
  const location = useLocation();
  const historyExists =
    location.key !== "default" && location.state !== "login";
  const videoRef = useRef();
  const params = useParams();
  const { data, isLoading, isFetched } = useGetSermonById(params?.id);
  //   console.log({ data });
  const inactive = useWindowInactivity(5);

  // const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenD,
    onOpen: onOpenD,
    onClose: onCloseD,
  } = useDisclosure();

  const isTouchDevice = useMemo(() => {
    return () => {
      return (
        "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
      );
    };
  }, []);

  const { data: comments } = useGetSermonComments(params?.id);

  const { postComment: post } = usePostSermonComment(params?.id);
  const postComment = async (e) => {
    e?.preventDefault();
    const body = {
      id: params?.id,
      comment,
      name: `${user?.first_name} ${user?.last_name}`,
    };
    try {
      const data = await post(body);
      if (data) {
        setComment("");
      }
    } catch (error) {
      // console.log(error?.response?.data?.message, "error");
    }
  };

  const { deleteComment } = useDeleteSermonComment();

  const { data: sermonLikes } = useGetSermonLikesById({
    id: params?.id,
    user: user?.id,
  });

  const userLikedSermon = useMemo(() => {
    if (!sermonLikes?.data) return false;
    const userLiked = sermonLikes?.data?.filter(
      (item) => item?.user?.id === user?.id
    );
    if (userLiked?.length > 0) return true;
  }, [sermonLikes?.data, user?.id]);

  const { likeSermon } = useLikeSermon({
    id: params?.id,
    like: "like",
  });
  const { likeSermon: unlikeSermon } = useLikeSermon({
    id: params?.id,
    like: "unlike",
  });

  const onCopy = (e, data) => {
    e.preventDefault();
    e.stopPropagation();
    navigator.clipboard.writeText(data);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 5000);
  };

  const audioLink = `${appBaseUrl}/sermon/audio/${params?.id}`;
  const videoLink = `${appBaseUrl}/sermon/video/${params?.id}`;

  const handleCopy = (e) => {
    if (data?.data?.content_type === "video") {
      onCopy(e, videoLink);
    } else if (data?.data?.content_type === "audio") {
      onCopy(e, audioLink);
    }
  };

  useEffect(() => {
    if (isFetched && !inactive) {
      videoRef.current?.play();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (commentId) {
      onOpenD();
    } else {
      onCloseD();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentId]);

  return (
    <HeaderFooter solid>
      <div className="relative pt-[98px]">
        {/* <div className="fixed left-0 top-0 w-full h-[50%] bg-gradient-to-br from-purpleBg/30 to-white fade-b-20"></div>
        <div className="fixed left-0 top-[50%] w-full h-[50%] bg-gradient-to-br to-orangeBg/30 from-[#FFFFFF] fade-t-20"></div> */}
        {isLoading ? (
          <Loader ball />
        ) : (
          <div className="relative z-[2]">
            <div className="relative w-full">
              <Link
                to={historyExists ? -1 : "/sermon"}
                className="text-xs text-[#2F0640] flex items-center gap-3 p-5 w-fit"
              >
                <svg
                  width="9"
                  height="16"
                  viewBox="0 0 9 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="rotate-180"
                >
                  <path
                    d="M1 1L6.81206 6.81206C7.46814 7.46814 7.46814 8.53186 6.81206 9.18794L1 15"
                    stroke="#2F0640"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
                <span>{historyExists ? "Go back" : "Go to Sermons"}</span>
              </Link>

              <p className="text-lg font-medium text-purpleOverlay absolute inset-0 m-auto w-fit h-fit">
                Sermon Details
              </p>
            </div>

            <div className="flex flex-col gap-5 mb-20 lg:mt-10 px-2 lg:px-5">
              <div className="h-fit">
                <video
                  ref={videoRef}
                  controls
                  className="h-auto lg:rounded-lg"
                  poster={data?.data?.cover_image?.url}
                >
                  <source src={data?.data?.content} type="video/mp4" />
                </video>
              </div>

              <div className="relative px-5 lg:px-0 py-5 flex-1 overflow-y-scroll">
                <p className="text-xl font-semibold text-purpleOverlay">
                  {data?.data?.title}
                </p>

                <p className="text-orangeBg">{data?.data?.facilitator}</p>
                <div className="mt-4 mb-8 flex items-center justify-between gap-3">
                  <SermonEngagementButton
                    onClick={userLikedSermon ? unlikeSermon : likeSermon}
                    className="flex items-center gap-3"
                  >
                    {userLikedSermon ? <FaHeart /> : <FaRegHeart />}
                    <span>
                      {data?.data?.likes > 0
                        ? abbreviateNumber(data?.data?.likes)
                        : "Like"}
                    </span>
                  </SermonEngagementButton>
                  <SermonEngagementButton
                    onClick={handleCopy}
                    className="flex items-center gap-1"
                  >
                    <IoIosShareAlt />
                    <span>{copied ? "Copied" : "Share"}</span>
                  </SermonEngagementButton>
                  {/* <SermonEngagementButton className="flex items-center gap-1">
            <HiDownload />
            <span>Download</span>
          </SermonEngagementButton> */}
                </div>
                <div className="bg-neutral-100 p-3 py-5 mt-5 rounded-md">
                  <p className="font-semibold text-purpleOverlay text-lg ">
                    Description
                  </p>
                  <p
                    className={`text-sm text-purpleOverlay whitespace-pre-line ${
                      fullDescription ? "" : "line-clamp-3"
                    }`}
                  >
                    {data?.data?.description}{" "}
                  </p>
                  <button
                    onClick={() => setFullDescription((val) => !val)}
                    className="border-none outline-none text-purpleOverlay text-sm mt-2 inline-block"
                  >
                    {fullDescription ? "Show less" : "more"}
                  </button>
                </div>

                <div className="w-full my-3">
                  <form
                    onSubmit={postComment}
                    className="py-3 flex items-center gap-4"
                  >
                    <img
                      src={user?.profile_picture ?? avatar}
                      alt=""
                      className={twMerge(
                        "w-9 h-9 rounded-full",
                        !user?.profile_picture && "filter grayscale"
                      )}
                    />
                    <textarea
                      name="comment"
                      placeholder="Add a comment"
                      value={comment}
                      onChange={(e) => setComment(e.target?.value)}
                      type="text"
                      className="h-auto max-h-10 p-2 w-[80%] @md:w-[240px] outline-none rounded duration-300 bg-white border-b border-b-gray-200 focus:border-b-gray-400 text-purpleOverlay text-sm resize-none"
                      maxLength={1000}
                    />
                    <button
                      type="submit"
                      className="outline-none text-textDark font-medium text-2xl"
                    >
                      <VscSend />
                    </button>
                  </form>
                </div>

                <div>
                  {comments?.map((comment, index) => (
                    <div
                      key={index}
                      className="group flex items-start w-full gap-2.5 mb-4 pb-2.5"
                    >
                      <img
                        src={comment?.user?.profile_picture ?? avatar}
                        alt=""
                        className="w-9 h-9 rounded-full"
                      />
                      <div>
                        <p className="text-sm text-purpleOverlay font-medium whitespace-pre-line">
                          {comment?.name?.includes(undefined)
                            ? `${comment?.user?.first_name} ${comment?.user?.last_name}`
                            : comment?.name}
                        </p>
                        <p className="text-sm text-gray-500 whitespace-pre-line">
                          {comment?.comment}
                        </p>
                        <span className="text-[10px] text-[#BDBDBD] flex items-center gap-2">
                          {formatDistance(
                            new Date(comment?.createdAt),
                            new Date(),
                            {
                              addSuffix: true,
                            }
                          )}

                          {user?.id === comment?.user?.id && (
                            <EllipsisIcon
                              onClick={() => setCommentId(comment?.id)}
                              className={twMerge(
                                "cursor-pointer invisible duration-500 hover:scale-[0.9] hover:opacity-70 group-hover:visible",
                                isTouchDevice() ? "visible" : ""
                              )}
                            />
                          )}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {isOpenD && (
        <CustomModalHeadless
          {...{
            isOpen: isOpenD,
            onClose: onCloseD,
            showCloseButton: false,
            dialogClass: "p-0",
          }}
        >
          <div className="w-full flex flex-col divide-y divide-lightGrey">
            <button
              onClick={async () => {
                try {
                  await deleteComment(commentId);
                  setCommentId(undefined);
                } catch (error) {
                  console.log(error);
                }
              }}
              className="text-red-500 text-sm font-medium w-full py-3 hover:bg-textLightGrey"
            >
              Delete
            </button>
            <button
              onClick={() => setCommentId(undefined)}
              className="text-textDark text-sm font-medium w-full py-3 hover:bg-textLightGrey"
            >
              Cancel
            </button>
          </div>
        </CustomModalHeadless>
      )}
    </HeaderFooter>
  );
};

export default VideoPlayer;
