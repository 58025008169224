import React from "react";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

const SectionCard = ({
  url,
  name,
  imageUrl,
  yellow,
  blue,
  pink,
  className,
}) => {
  return (
    <Link
      to={url}
      className={twMerge(
        "group relative rounded-[15px] px-5 py-3 flex flex-col justify-end w-full min-w-[355px] h-[160px]",
        yellow && "bg-[#FFF3DF]",
        blue && "bg-[#D8F8FF]",
        pink && "bg-[#FFE8F4]",
        className
      )}
    >
      <span className="mb-2 font-semibold text-[30px] text-purpleOverlay">
        {name}
      </span>
      <svg
        width="54"
        height="22"
        viewBox="0 0 54 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="group-hover:animate-bounce-forward"
      >
        <path
          d="M41 1.12207C41.6058 4.37921 46.7552 9.80779 51.2988 10.3506C57.2813 11.0654 45.8465 4.37921 42.2116 20.1221"
          stroke="#FF8A00"
          strokeWidth="2"
          strokewinecap="round"
        />
        <path
          d="M42 10.1221L1 10.1221"
          stroke="#FF8A00"
          strokeWidth="2"
          strokewinecap="round"
        />
      </svg>
      <img src={imageUrl} alt="" className="absolute bottom-0 right-1" />
    </Link>
  );
};

export default SectionCard;
