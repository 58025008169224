import React, { useState } from "react";
import { twMerge } from "tailwind-merge";
import Loader from "./Loader";

const TabComponent = ({
  tabButtons,
  tabContent,
  tabButtonContainer,
  tabContainer,
  isLoading,
  tabIdx,
  tabUpdate,
}) => {
  const [tabIndex, setTabIndex] = useState(tabIdx ?? 0);
  return (
    <div className={twMerge("max-w-section max-auto mt-3", tabContainer)}>
      <div
        className={twMerge(
          "w-full border-b border-b-gray-300 mb-7 sticky top-[88px] lg:top-[92px] bg-white/50 backdrop-blur-xl z-20 flex items-center overflow-scroll no-scrollbar",
          tabButtonContainer
        )}
      >
        {tabButtons.map((tab, index) => (
          <button
            key={index}
            className={twMerge(
              "outline-none px-3 py-1 text-gray-400",
              tabUpdate
                ? tabIdx === index &&
                    "border-b-4 border-b-orangeBg text-purpleOverlay font-semibold"
                : tabIndex === index &&
                    "border-b-4 border-b-orangeBg text-purpleOverlay font-semibold"
            )}
            onClick={() => (tabUpdate ? tabUpdate(tab) : setTabIndex(index))}
          >
            {tab}
          </button>
        ))}
      </div>

      {isLoading ? (
        <Loader isSmall />
      ) : (
        <>{tabContent?.[tabIdx ? tabIdx : tabIndex] ?? null}</>
      )}
    </div>
  );
};

export default TabComponent;
