import { toast } from "react-toastify";

const showToast = (message, type, customOptions = {}) => {
  let bgColor;
  let color;
  let autoClose;
  if (type?.toLowerCase() === "success") {
    bgColor = "#FFFFFF";
    color = "#2F0640";
    autoClose = 5000;
  } else if (type?.toLowerCase() === "error") {
    bgColor = "#FFFFFF";
    color = "#FF4746";
    autoClose = 15000;
  } else if (type?.toLowerCase() === "warn") {
    bgColor = "#FFFFFF";
    color = "#E2A91B";
    autoClose = 5000;
  }
  const options = {
    position: "top-right",
    autoClose: autoClose,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    style: {
      backgroundColor: bgColor,
      color: color,
      zIndex: 1000,
      fontSize: "14px",
    },
    ...customOptions,
  };
  if (toast[type]) {
    toast[type](message ?? "", options);
  } else {
    toast.success(message ?? "", options);
  }
};

export default showToast;
