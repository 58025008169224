import React from "react";
import { twMerge } from "tailwind-merge";

const SelectInput = ({
  name,
  value,
  onChange,
  label,
  labelClass,
  placeholder,
  className,
  options,
  containerClass,
}) => {
  return (
    <div className={twMerge("flex flex-col mb-4", containerClass)}>
      {label && (
        <label
          className={twMerge("text-sm font-medium mb-2", labelClass)}
          htmlFor={name}
        >
          {label}
        </label>
      )}
      <select
        name={name}
        className={twMerge(
          "bg-white rounded-md border border-[#D0D5DD] p-2 py-3 outline-none focus:outline-none flex items-center justify-between focus:border-purpleBg duration-200",
          className
        )}
        onChange={onChange}
        value={value}
      >
        <option value="" selected disabled>
          {placeholder}
        </option>
        {options?.map(({ label, value }, index) => (
          <option key={index} value={value}>
            {label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectInput;
