import React from "react";

const ShareButton = (props) => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="button"
      {...props}
    >
      <g clipPath="url(#clip0_839_17891)">
        <path
          d="M9.98715 16.7784L15.3192 11.4464M10.5349 17.6285L12.2921 21.1541C13.2491 23.0742 13.7275 24.0342 14.3439 24.3049C14.879 24.5401 15.4936 24.5099 16.0032 24.2234C16.59 23.8935 16.972 22.8913 17.7361 20.8866L22.642 8.01529C23.3891 6.05489 23.7628 5.07473 23.55 4.44249C23.3651 3.89318 22.9348 3.46148 22.3862 3.2749C21.7546 3.06008 20.7732 3.43054 18.8105 4.17143L5.89218 9.04777C3.88366 9.80591 2.87932 10.1851 2.54763 10.771C2.25954 11.2798 2.22756 11.8945 2.46126 12.4306C2.73031 13.0478 3.68988 13.5291 5.60886 14.4917L9.20589 16.296C9.53484 16.461 9.69941 16.5435 9.84188 16.6537C9.96831 16.7513 10.0816 16.865 10.179 16.9917C10.2888 17.1344 10.3709 17.2991 10.5349 17.6285Z"
          stroke="#2F0640"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_839_17891">
          <rect width="25.52" height="25.52" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ShareButton;
