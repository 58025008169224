import avatar from "./profilePlaceholder.svg";
import camera from "./camera.svg";
import React, { useState } from "react";
import { useUserStore } from "store/userStore";
import useFileUpload from "modules/fileupload/fileUploadController";
import InputWithFullBoarder from "generalComponents/InputWithFullBoarder";
import DatePickerInput from "generalComponents/DatePickerInput";
import RadioGroupInput from "generalComponents/RadioGroupInput";
import SelectInput from "generalComponents/SelectInput";
import { RoundedButton } from "generalComponents/Button";
import { Spinner } from "generalComponents/Loader";
import { useGetCampuses } from "modules/pageChurches/controllers/get_controller";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { useUpdateProfile } from "../controller/put_controller";

function removeEmptyValuesFromObject(data) {
  const objKeys = Object.keys(data);
  const newData = {};

  objKeys?.forEach((item) => {
    if (data[item] !== "") {
      newData[item] = data[item];
    }
  });

  return newData;
}

const genderOptions = [
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
];
const maritalOptions = [
  {
    label: "Single",
    value: "single",
  },
  {
    label: "Married",
    value: "married",
  },
  {
    label: "Widowed",
    value: "widowed",
  },
];

const EditProfile = () => {
  const user = useUserStore((state) => state.user);
  const [formValues, setFormValues] = useState({
    first_name: user?.first_name ?? "",
    last_name: user?.last_name ?? "",
    email: user?.email ?? "",
    // phone: user?.phone ?? "",
    address: user?.location?.address ?? "",
    city: user?.location?.city ?? "",
    country: user?.location?.country ?? "",
    occupation: user?.occupation ?? "",
    marital_status: user?.marital_status?.toLowerCase() ?? "",
    campus: user?.campus?.id ?? "",
    online: user?.online ?? "",
    bornAgain: user?.bornAgain ?? "",
    dob: user?.dob ?? "",
    gender: user?.gender?.toLowerCase() ?? "",
  });
  const [value, setValue] = useState(
    user?.phone?.includes("+") ? user?.phone : "+234" + user?.phone ?? ""
  );
  const [file, setFile] = useState();
  const [preview, setPreview] = useState();

  const handleFileChange = (e, name) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
      setPreview(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((val) => ({ ...val, [name]: value }));
  };
  const handleDate = (date) => {
    setFormValues((data) => ({
      ...data,
      dob: date,
    }));
  };

  const { data: churches } = useGetCampuses();

  const {
    // error: fileUploadError,
    handleFileUpload: uploadFile,
    isLoading: isUploadingFlie,
    // data: uploadUrl,
  } = useFileUpload();

  function handleFileUpload() {
    return new Promise(async (resolve, reject) => {
      try {
        const fileUrl = await uploadFile(file);
        resolve(fileUrl);
        return fileUrl;
      } catch (error) {
        reject(error);
      }
    });
  }

  const { putCaller, isLoading } = useUpdateProfile();

  // const isLoading = false;
  const handleSubmit = async (e) => {
    e.preventDefault();
    let profile_picture;
    if (file) {
      profile_picture = await handleFileUpload();
    }

    const body = {
      ...formValues,
      online: formValues?.campus !== "" ? false : true,
      profile_picture,
      phone: value,
    };

    await putCaller(removeEmptyValuesFromObject(body));
  };
  return (
    <div className="flex flex-col items-center lg:flex-row lg:items-start px-5">
      <div className="mx-auto w-full max-w-[720px]">
        <h2 className="font-semibold text-3xl lg:text-6xl text-purpleOverlay border-b border-lightGrey pb-2 mb-10">
          Edit Profile
        </h2>
        <div className="w-full flex flex-col lg:flex-row items-center lg:items-start mr-auto">
          <div
            role="button"
            className="size-[140px] rounded-full grid place-content-center bg-[#F2F2F2] relative mb-7 shadow-md"
          >
            <div className="w-full overflow-hidden rounded-full">
              <img
                src={preview ? preview : user?.profile_picture ?? avatar}
                alt=""
                className="object-contain rounded-full"
              />
            </div>

            <button className="absolute bg-purpleOverlay rounded-full p-3 grid place-content-center bottom-0 right-0">
              <img src={camera} alt="" />
            </button>
            <input
              type="file"
              accept="image/png, image/jpeg, image/jpg"
              onChange={handleFileChange}
              className="absolute w-full h-full opacity-0 z-[2] cursor-pointer"
              required={user?.profile_picture ? false : true}
            />
          </div>
          <form
            onSubmit={handleSubmit}
            className="max-w-[560px] w-full lg:pl-7"
          >
            <div className="grid grid-cols-2 gap-2.5 w-full">
              <InputWithFullBoarder
                label={"First Name"}
                type="text"
                id="first_name"
                name="first_name"
                value={formValues?.first_name}
                onChange={handleChange}
                placeholder={"Enter first name"}
                labelClass={"font-medium text-sm text-textDark"}
                className={
                  "bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-sm px-4 py-2 h-[50px] text-purpleOverlay"
                }
                otherProps={{
                  required: true,
                  autoComplete: "given-name",
                }}
              />
              <InputWithFullBoarder
                label={"Last Name"}
                type="text"
                id="last_name"
                name="last_name"
                value={formValues?.last_name}
                onChange={handleChange}
                placeholder={"Enter last name"}
                labelClass={"font-medium text-sm text-textDark"}
                className={
                  "bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-sm px-4 py-2 h-[50px] text-purpleOverlay"
                }
                otherProps={{
                  // required: true,
                  autoComplete: "family-name",
                }}
              />
            </div>
            <InputWithFullBoarder
              label={"Email"}
              type="email"
              id="email"
              name="email"
              value={formValues?.email}
              onChange={handleChange}
              placeholder={"Enter email address"}
              labelClass={"font-medium text-sm text-textDark"}
              className={
                "bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-sm px-4 py-2 h-[50px] w-full text-purpleOverlay"
              }
              otherProps={{
                readOnly: true,
                inputMode: "email",
                autoComplete: "email",
              }}
            />

            <div className="flex flex-col mb-4">
              <label
                className={`text-sm font-medium mb-2 text-textDark`}
                htmlFor={""}
              >
                Phone Number
              </label>
              <PhoneInput
                defaultCountry="ng"
                placeholder="Enter phone number"
                value={value}
                onChange={setValue}
                className="w-full flex [&>input]:flex-1 h-[50px] [&>*]:!h-[50px] [&>input]:px-4 [&>input]:py-2 bg-[#F2F2F2]"
              />
            </div>

            <SelectInput
              label={"Church"}
              name="campus"
              value={formValues?.campus}
              onChange={handleChange}
              placeholder={"Select church"}
              options={
                churches?.data
                  ? churches?.data?.map((item) => ({
                      label: item?.name,
                      value: item?.id,
                    }))
                  : []
              }
              labelClass={"font-medium text-sm text-textDark"}
              className={
                "bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-sm px-4 py-2 h-[50px] w-full text-purpleOverlay"
              }
              otherProps={{
                required: true,
              }}
            />

            <InputWithFullBoarder
              label={"Address"}
              type="text"
              id="address"
              name="address"
              value={formValues?.address}
              onChange={handleChange}
              placeholder={"Enter your address"}
              labelClass={"font-medium text-sm text-textDark"}
              className={
                "bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-sm px-4 py-2 h-[50px] w-full text-purpleOverlay"
              }
              otherProps={{
                required: true,
                autoComplete: "address",
              }}
            />
            {/* COUNTRY AND STATE/CITY */}

            <div className="grid grid-cols-2 gap-2.5 w-full">
              <InputWithFullBoarder
                label={"City"}
                type="text"
                id="city"
                name="city"
                value={formValues?.city}
                onChange={handleChange}
                placeholder={"Enter your city"}
                labelClass={"font-medium text-sm text-textDark"}
                className={
                  "bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-sm px-4 py-2 h-[50px] w-full text-purpleOverlay"
                }
                otherProps={{
                  required: true,
                  autoComplete: "city",
                }}
              />
              <InputWithFullBoarder
                label={"Country"}
                type="text"
                id="country"
                name="country"
                value={formValues?.country}
                onChange={handleChange}
                placeholder={"Enter your country"}
                labelClass={"font-medium text-sm text-textDark"}
                className={
                  "bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-sm px-4 py-2 h-[50px] w-full text-purpleOverlay"
                }
                otherProps={{
                  required: true,
                  autoComplete: "country",
                }}
              />
            </div>

            <DatePickerInput
              label="Date of Birth"
              name="marriage_date"
              value={formValues?.dob}
              onChange={handleDate}
              placeholder={"Select date of birth"}
              className={"text-purpleOverlay text-sm"}
              labelClass={"font-medium text-sm text-textDark"}
              disabledPeriod={(date) =>
                new Date(date).getTime() > new Date().getTime()
              }
            />
            <RadioGroupInput
              label={"Gender"}
              name="gender"
              value={formValues?.gender}
              onChange={handleChange}
              options={genderOptions}
              labelClass={"font-medium text-sm text-textDark"}
              radioLabelClass={"text-sm text-purpleOverlay"}
            />
            <SelectInput
              label={"Marital Status"}
              name="marital_status"
              value={formValues?.marital_status}
              onChange={handleChange}
              placeholder={"Select marital status"}
              options={maritalOptions}
              labelClass={"font-medium text-sm text-textDark"}
              className={
                "bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-sm px-4 py-2 h-[50px] w-full text-purpleOverlay"
              }
              otherProps={{
                required: true,
              }}
            />
            <InputWithFullBoarder
              label={"Occupation"}
              type="text"
              id="occupation"
              name="occupation"
              value={formValues?.occupation}
              onChange={handleChange}
              placeholder={"Enter occupation"}
              labelClass={"font-medium text-sm text-textDark"}
              className={
                "bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-sm px-4 py-2 h-[50px] w-full text-purpleOverlay"
              }
              otherProps={{
                required: true,
                autoComplete: "address",
              }}
            />
            <RoundedButton
              type="submit"
              className={
                "py-4 w-full font-medium mt-12 grid place-content-center"
              }
              // disabled={
              //   !confirm ||
              //   formValues?.old_password === "" ||
              //   formValues?.new_password === ""
              // }
            >
              {isLoading || isUploadingFlie ? <Spinner /> : "Update Profile"}
            </RoundedButton>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
