import { unparse } from 'papaparse';
/**
 * 
 * @param {Array<string>} fields 
 * @param {any} myData 
 * @returns {File}
 */

export const convertJSONToCSV = (fields, data) => {
    try {
        const csv = unparse({ fields, data });
        return csv
    } catch (err) {
        console.error({ message: err });
    }
}

/**
 * 
 * @param {Array<string>} headers 
 * @param {any} items 
 * @param {string} fileTitle 
 */

const exportCSVFile = (headers, items, fileTitle) => {
    var csv = convertJSONToCSV(headers, items);
    var exportedFilename = fileTitle + '.csv' || 'export.csv';

    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, exportedFilename);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
};

export default exportCSVFile