import { RoundedButton } from "generalComponents/Button";
import ModalManagement from "generalComponents/CustomModal";
import CartItem from "./CartItem";
import useDeleteCart from "../controllers/delete_controller";
import { usePutCheckout } from "../controllers/put_controller";
import { Spinner } from "generalComponents/Loader";
import { useLocation } from "react-router-dom";

// const appBaseUrl = process.env.REACT_APP_APP_BASE_URL;

const CartModal = ({ id, data }) => {
  // const cart = useCartStore((state) => state.cart);
  // const cartsumTotal = useCartSumTotal();
  const location = useLocation();
  const { deleteCaller } = useDeleteCart();
  const { putCaller, isLoading } = usePutCheckout();

  async function deleteCartItem(id) {
    await deleteCaller(id);
  }

  async function checkoutCart() {
    const lastRoute = `${location?.pathname}${
      location?.search ? location?.search : ""
    }`;
    localStorage.setItem("lastStoreRoute", lastRoute);
    const payload = data?.items?.map((item) => item?.id);

    try {
      const response = await putCaller(payload);
      if (response) {
        console.log({ response });
        window.open(response?.data?.checkoutUrl, "_blank");
        // console.log("first");
        window[id]?.close();
      }
    } catch (error) {
      // console.log(error);
    }

    // console.log({ response });
  }

  return (
    <ModalManagement
      id={id}
      title={"Cart"}
      className={
        "relative p-0 min-h-[600px] max-h-[90svh] overflow-hidden no-scrollbar"
      }
      titleClass={"p-7 mb-0"}
    >
      <div className="relative">
        <div className="pb-[500px] sm:pb-[200px] relative grid gap-3 px-7 max-h-[800px] overflow-scroll no-scrollbar">
          {data?.items?.map((item, index) => (
            <CartItem
              key={index}
              {...item?.item}
              cartId={item?.id}
              cartItem={item}
              deleteCartItem={deleteCartItem}
            />
          ))}
        </div>

        <div className="bg-white shadow-[0_-10px_15px_-3px_rgb(0_0_0/0.1)] w-full fixed bottom-0 p-5">
          <div className="flex justify-between items-center">
            <p className="text-purpleOverlay font-semibold">Total</p>
            <p className="text-3xl font-semibold text-purpleBg">
              <span className="text-orangeBg">₦</span>
              {data?.total_amount?.toLocaleString()}
            </p>
          </div>
          <RoundedButton
            onClick={checkoutCart}
            className="py-5 mt-3 w-full text-base flex justify-center items-center gap-2.5 rounded-[40px] font-semibold"
          >
            {isLoading ? <Spinner /> : "Checkout"}
          </RoundedButton>
        </div>
      </div>
    </ModalManagement>
  );
};

export default CartModal;
