import { Popover, Transition } from "@headlessui/react";
import { BellIcon } from "assets/icons/adminIcons";
import React, { Fragment } from "react";
import useGetNotifications from "./controllers/getNotificationsController";
import { format, isToday, isYesterday } from "date-fns";
import ErrorManager from "generalComponents/ErrorManager";
// import Loader from "generalComponents/Loader";
import NotificationTiles from "generalComponents/NotificationTiles";

const NotificationPopover = ({ dark }) => {
  const { data, isLoading, isError, error, isSuccess } = useGetNotifications();
  let groupedNotifications;
  const groupNotificationsByDate = (notifications) => {
    const groupedNotifications = [];

    notifications.forEach((notification) => {
      const createdAt = new Date(notification.createdAt);

      if (isToday(createdAt)) {
        const todayGroup = groupedNotifications.find(
          (group) => group.date === "Today"
        );
        if (todayGroup) {
          todayGroup.notifications.push(notification);
        } else {
          groupedNotifications.push({
            date: "Today",
            notifications: [notification],
          });
        }
      } else if (isYesterday(createdAt)) {
        const yesterdayGroup = groupedNotifications.find(
          (group) => group.date === "Yesterday"
        );
        if (yesterdayGroup) {
          yesterdayGroup.notifications.push(notification);
        } else {
          groupedNotifications.push({
            date: "Yesterday",
            notifications: [notification],
          });
        }
      } else {
        const formattedDate = format(createdAt, "MMM d");
        const existingGroup = groupedNotifications.find(
          (group) => group.date === formattedDate
        );
        if (existingGroup) {
          existingGroup.notifications.push(notification);
        } else {
          groupedNotifications.push({
            date: formattedDate,
            notifications: [notification],
          });
        }
      }
    });

    return groupedNotifications;
  };

  if (isLoading) {
    return <div></div>;
  }
  if (isSuccess) {
    groupedNotifications = groupNotificationsByDate(data.data);
  }

  if (isError) {
    return (
      <div className="m-auto">
        <ErrorManager errorMessage={error.message} />
      </div>
    );
  }
  return (
    <div className="relative">
      <Popover className="relative">
        {/* {({ open }) => (
            <> */}
        <Popover.Button
          className={`
        
                    group inline-flex items-center rounded-md bg-orange-700 px-3 py-2 text-base font-medium hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
        >
          <BellIcon dark={dark} role="button" />
        </Popover.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel className="absolute -right-[180%] sm:right-0 z-50 mt-3 max-w-[100svw]  px-4 sm:px-0 lg:max-w-3xl bg-white rounded-lg shadow-lg">
            {/* <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5"> */}
            <div className="bg-gray-50 p-4 rounded-lg shadow-lg w-[420px] max-w-full scroll-smooth">
              <p className="text-lg font-semibold text-purpleOverlay mb-2">
                Notifications
              </p>
              <div className="md:max-h-[50dvh]  overflow-y-auto scrollbar-hide">
                {groupedNotifications.map((group) => (
                  <div key={group.date}>
                    <p className="font-medium mt-8 mb-5 text-purpleOverlay">
                      {group.date}
                    </p>
                    <div className="flex flex-col gap-2 px-1">
                      {group.notifications.map((notification, index) => {
                        return (
                          <NotificationTiles
                            key={index}
                            details={notification}
                          />
                        );
                      })}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* </div> */}
          </Popover.Panel>
        </Transition>
        {/* </>
          )} */}
      </Popover>
    </div>
  );
};

export default NotificationPopover;
