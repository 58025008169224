"use client";
import React, { useEffect, useState } from "react";
import { SendBroadcastManager } from "../controller/sendBroadcastController";
import ModalManagement from "generalComponents/ModalManagement";
import InputWithFullBoarder from "generalComponents/InputWithFullBoarder";
import CustomButton from "generalComponents/Button";

const AddEditNotificationModal = ({ details }) => {
  const { sendBroadcast, isLoading, isSuccess } = SendBroadcastManager();
  const initialData = {
    title: "",
    body: "",
    isDraft: false,
  };
  const [formData, setFormData] = useState(initialData);

  useEffect(() => {
    if (details) {
      setFormData({
        title: details?.title,
        body: details?.body,
        isDraft: details?.isDraft,
      });
    } else {
      setFormData(initialData);
    }
  }, [details]);
  useEffect(() => {
    if (isSuccess) {
      document.getElementById(`add_notification`).close();
    }
  }, [isSuccess]);
  return (
    <ModalManagement id={`add_notification`} title={"Send Notification"}>
      <p className="w-full text-13px">
        Send a notification to all or a user type
      </p>
      <div className="md:w-[500px] flex flex-col items-center gap-3 justify-center relative pt-10">
        <div className="w-full">
          <InputWithFullBoarder
            label={`Title`}
            placeholder={`Enter broadcast title`}
            value={formData.title}
            onChange={(e) =>
              setFormData({ ...formData, title: e.target.value })
            }
          />
          <InputWithFullBoarder
            label={`Message`}
            isTextArea={true}
            placeholder={`Enter message`}
            className={`h-[200px]`}
            value={formData.body}
            onChange={(e) => setFormData({ ...formData, body: e.target.value })}
          />
          <div className="flex items-center gap-3">
            <CustomButton
              buttonText={`Cancel`}
              onClick={() => {
                document.getElementById(`add_notification`).close();
              }}
              buttonColor={"transparent"}
              textColor={`text-brandBlack`}
              className={`w-full mx-auto mt-10 border border-lightGrey`}
            />
            <CustomButton
              buttonText={`Send`}
              className={`w-full mx-auto mt-10`}
              isLoading={isLoading}
              onClick={async () => {
                await sendBroadcast(formData);
              }}
            />
          </div>
        </div>
      </div>
    </ModalManagement>
  );
};

export default AddEditNotificationModal;
