import React from "react";
import Hero from "../components/Hero";
import ChurchList from "../components/ChurchList";
import HeaderFooter from "generalComponents/HeaderFooter";

const Churches = () => {
  return (
    <HeaderFooter solid={false}>
      <Hero />
      <ChurchList />
    </HeaderFooter>
  );
};

export default Churches;
