import { useQuery } from "react-query";
import AxiosWithToken from "../../../../constants/api_management/MyHttpHelperWithToken";

const useAllUsersManager = ({
  page,
  searchQuery = "",
  enabled = true,
  pageSize = "20",
}) => {
  return useQuery(
    ["allUsers", page, searchQuery, pageSize],
    async () => {
      try {
        const [response] = [
          await AxiosWithToken.get(
            `/user/list?page=${page}&pageSize=${pageSize}${searchQuery}`
          ),
        ];

        return response.data.data;
      } catch (error) {
        throw new Error(`Sorry: ${error.response.data.message}`);
      }
    },
    { enabled: enabled }
  );
};

export default useAllUsersManager;
