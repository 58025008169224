import { useQuery } from "react-query";

import AxiosWithToken from "../../../../constants/api_management/MyHttpHelperWithToken";

const useGetAppointmentsManager = ({
  page,
  searchQuery = "",
  enabled = true,
  pageSize = "20",
  status = "",
}) => {
  return useQuery(["appointments", searchQuery, status], async () => {
    try {
      const [response] = [
        await AxiosWithToken.get(
          `/appointment/list?page=${page}&pageSize=${pageSize}${status}${searchQuery}`
        ),
      ];
      console.log(`i am checking this ${response.data}`);
      return response.data;
    } catch (error) {
      console.log(error.response.data);
      throw new Error(`${error.response.data.message}`);
    }
  });
};

export default useGetAppointmentsManager;
