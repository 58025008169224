import Lottie from "lottie-react";
import React, { useEffect, useState } from "react";
import Confetti from "react-confetti";
import { useWindowSize } from "react-use";
import purpleCheckAnimation from "assets/animations/purpleCheckAnimation.json";
import { useLocation, useNavigate } from "react-router-dom";
import { RoundedButton } from "generalComponents/Button";
import { useGetWalletConfrimation } from "../controllers/get_contoller";

const PaymentSuccesfulPage = () => {
  const [position, setPosition] = useState({
    x: 0,
    y: 0,
    w: 500,
    h: 240,
  });
  const navigate = useNavigate();
  const { width } = useWindowSize();

  const lastRoute = localStorage.getItem("lastStoreRoute");
  const location = useLocation();
  console.log({ location });

  //   useEffect(() => {
  //     if (!state) {
  //       goto("/");
  //     }
  //   }, [goto, state]);

  const { data } = useGetWalletConfrimation({
    enabled: !!location?.search,
    query: location?.search,
  });
  console.log({ data });

  useEffect(() => {
    const wrapper = document.getElementById("confettiWrapper");
    if (wrapper) {
      setPosition({
        x: 0,
        y: 0,
        w: wrapper.getBoundingClientRect()?.width,
        h: wrapper.getBoundingClientRect()?.height,
      });
    }
  }, []);

  //   useEffect(() => {
  //     const squadWidget = document.getElementById("squad--widget-div");

  //     squadWidget!.style.transition = "0.5s";
  //     squadWidget!.style.opacity = "0";
  //     setTimeout(() => {
  //       squadWidget!.style.display = "none";
  //     }, 500);
  //   }, []);
  return (
    <section className="w-full px-5 max-w-[768px] mx-auto lg:px-10 lg:flex lg:flex-col lg:justify-center lg:items-center lg:h-svh">
      <div className="w-fit lg:shadow-md lg:p-5">
        <div
          id={"confettiWrapper"}
          className="relative w-full min-h-[240px] flex justify-center items-center overflow-hidden"
        >
          {
            <Confetti
              numberOfPieces={150}
              width={width}
              height={240}
              confettiSource={{
                ...position,
              }}
            />
          }
          <Lottie
            animationData={purpleCheckAnimation}
            // resizeMode="cover"
            style={{
              width: "200px",
            }}
            autoPlay
            loop={false}
          />
        </div>
        <div className="py-20 px-5 text-purpleOverlay">
          <h2 className="h2-header text-center lg:text-xl mb-2">
            Payment Successful
          </h2>
          <p className="text-center font-light text-sm lg:text-base ">
            Thank you for purchasing items from our E-Store. <br />
            Items purchased from the E-Store are available in your E-Library.
            {/* <span className="font-medium">{state?.numberOfShares} units</span>{" "}
            of <span className="font-medium">GTCO Offer</span> and your shares
            information has been sent to your email address{" "}
            <span className="font-medium">{state?.email}</span> */}
          </p>
        </div>
        <div className="w-full mt-10 lg:mt-0 grid grid-cols-2 gap-5">
          <RoundedButton
            onClick={() => navigate(lastRoute)}
            className=" w-full grid place-content-center py-4 lg:py-5 lg:font-semibold rounded-md bg-white border border-purpleBg text-purpleBg font-medium disabled:cursor-not-allowed disabled:opacity-60"
          >
            Go back to E-Store
          </RoundedButton>
          <RoundedButton
            onClick={() => navigate("/library")}
            className=" w-full grid place-content-center py-4 lg:py-5 lg:font-semibold rounded-md bg-purpleBg text-white font-medium disabled:cursor-not-allowed disabled:opacity-60"
          >
            Go to E-Library
          </RoundedButton>
        </div>
      </div>
    </section>
  );
};

export default PaymentSuccesfulPage;
