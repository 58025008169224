import React, { useEffect, useState } from "react";
import BaseDashboardNavigation from "../../../../generalComponents/BaseDashboardNavigation";
import AllProfilesTable from "../../../../generalComponents/AllProfilesTable";
import { useNavigate, useParams } from "react-router-dom";
import useGetSingleUserManager from "../controllers/getSingleUserController";
import Loader from "../../../../generalComponents/Loader";
import ErrorManager from "../../../../generalComponents/ErrorManager";
import GlobalVariables from "../../../../constants/GlobalVariables";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Pagination } from "@mui/material";
import UserPraiseReports from "../components/UserPraiseReports";
import Permission from "../components/Permission";
import UserSubmittedForms from "../components/UserSubmittedForms";
import UsersCellDetail from "../components/UsersCellDetail";
import GoBackButton from "generalComponents/GoBackButton";
import { HiLocationMarker } from "react-icons/hi";
import CustomButton from "generalComponents/Button";
import TabsManagement from "generalComponents/TabsManagement";
import AdminWrapper from "modules/admin/AdminWrapper";

const UserDetailsPage = (specificTab) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const { userId } = useParams();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchTerm) {
        setSearchQuery(`&first_name=${searchTerm}`);
        // refetch();
      } else {
        setSearchQuery(""); // Clear the search query
        // refetch();
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [searchTerm]);
  const { data, isLoading, isError, error } = useGetSingleUserManager(userId);

  const [currentIndex, setCurrentIndex] = useState(specificTab ?? 0);

  const menuList = [
    {
      name: "Praise Reports",
      component: <UserPraiseReports userFilter={userId} />,
    },
    {
      name: "Family Processes",
      component: <UserSubmittedForms userId={userId} />,
    },
    {
      name: "Cell Group",
      component: <UsersCellDetail cellId={data?.data?.user?.cell_id} />,
    },
    {
      name: "Permissions",
      component: (
        <Permission
          userId={userId}
          permissions={data?.data?.user?.permissions}
        />
      ),
    },
    { name: "Sermon Purchases", component: <p>Sermon Purchases</p> },
  ];

  useEffect(() => {
    // Get the tab index from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const tabIndex = urlParams.get("tab");
    const parsedIndex = parseInt(tabIndex);

    if (
      !isNaN(parsedIndex) &&
      parsedIndex >= 0 &&
      parsedIndex < menuList.length
    ) {
      setCurrentIndex(parsedIndex);
    } else {
      setCurrentIndex(0);
    }
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  const details = [
    { title: "Phone No.", value: data?.data?.user?.phone },
    { title: "Gender", value: data?.data?.user?.gender },
    { title: "Date of Birth", value: data?.data?.user?.dob },
    { title: "Martial Status", value: data?.data?.user?.marital_status },
    { title: "Church", value: data?.data?.user?.campus?.name },
    { title: "City", value: data?.data?.user?.location?.city },
    { title: "Country", value: data?.data?.user?.location?.country },
    { title: "Occupation", value: data?.data?.user?.occupation },
  ];

  return (
    <AdminWrapper
      title={"User Details"}
      value={searchTerm}
      onChange={(e) => {
        setSearchTerm(e.target.value);
        console.log(searchTerm);
      }}
    >
      <div className="h-[90vh] md:overflow-y-scroll relative">
        <div className=" md:overflow-y-scroll mr-7  scrollbar-hide">
          <div className="flex flex-col md:flex-row gap-3 items-center justify-between">
            <GoBackButton />
            <div className="flex gap-4 w-full max-w-max">
              <CustomButton
                buttonText={`Suspend User`}
                buttonColor={`bg-whiteColor`}
                className={"border border-lightGrey"}
                textColor={"text-black"}
              />
              <CustomButton buttonText={`Delete User`} />
            </div>
          </div>
          {/* profile picture*/}
          <div className="w-full flex flex-col md:flex-row items-center justify-between  px-2 md:px-8 mt-16 mx-auto">
            <div className=" flex flex-col md:flex-row items-start w-full gap-6">
              <div className="h-[95px] md:h-[150px] relative  w-[95px] md:w-[150px]">
                <img
                  className=" w-full h-full rounded-full object-cover"
                  src={
                    data.data.user.profile_picture
                      ? data.data.user.profile_picture
                      : GlobalVariables.defaultProfilePicture
                  }
                  alt=""
                />
              </div>
              <div className="w-full flex flex-col gap-7">
                <div className="flex flex-col items-start gap-2 md:gap-0">
                  <p className="text-[30px] font-bold ">
                    {`${data.data.user.first_name} ${data.data.user.last_name}`}
                  </p>
                  <p className="text-[15px] font-light ">
                    {data.data.user.email}
                  </p>
                  <div className="gap-1.5 md:gap-1 flex items-start md:items-center w-full relative">
                    <HiLocationMarker size={15} />
                    <p className="text-[13px] font-light text-[#8311B3] flex items-center max-w-[90%]">
                      {data.data.user.location ? (
                        <span className=" ">{`${data.data.user.location.address}, ${data.data.user.location.city}, ${data.data.user.location.state}, ${data.data.user.location.country}`}</span>
                      ) : (
                        <span className="">No location</span>
                      )}
                    </p>
                  </div>
                </div>
                <div className="bg-[#FCF6FF] w-full md:h-[148px] p-5 gap-5 grid grid-cols-2 md:grid-cols-4">
                  {details?.map((el, i) => (
                    <div key={i} className="flex flex-col">
                      <p className="text-[13px] text-[#828282]">{el?.title}</p>
                      <p className="text-[15px] text-blackColor">{el?.value}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="md:flex md:flex-col md:space-x-[20px] gap-20 md:mt-5 md:gap-5 relative md:h-[80vh] w-full md:w-[98%]">
          <TabsManagement
            currentView={currentIndex}
            setCurrentView={setCurrentIndex}
            options={menuList.map((el, i) => el.name)}
          />

          <div className="md:h-[80vh] relative">
            {menuList[currentIndex]?.component}
          </div>
        </div>
      </div>
    </AdminWrapper>
  );
};

export default UserDetailsPage;
