import { churchesHeroBg } from "assets/images";
import React from "react";

const Hero = () => {
  return (
    <div className="relative">
      <img
        src={churchesHeroBg}
        alt=""
        className="w-full h-full absolute -z-20"
      />
      <div className="w-full h-full absolute -z-10 bg-gradient-to-t from-purpleOverlay to-transparent"></div>
      <h1 className="font-bold text-6xl sm:text-8xl md:text-[120px] lg:text-[240px] leading-[240px] pt-[200px] text-center">
        Churches
      </h1>
    </div>
  );
};

export default Hero;
