import React, { useState } from "react";
import useGetSingleFormManager from "../controllers/getSingleFormController";
import Loader from "../../../../generalComponents/Loader";
import ErrorManager from "../../../../generalComponents/ErrorManager";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import FormDetailTile from "../../../../generalComponents/FormDetailTile";
import separateWords from "../wordSeparator";
import { formatDate } from "utils/formatDate";

const WeddingThanksgivingForm = ({ details }) => {
  const formData = [
    {
      name: "Form Status",
      info: details?.status,
    },
    {
      name: "Name",
      info: `${details?.user?.first_name} ${details?.user?.last_name}`,
    },

    {
      name: "Phone No.",
      info: details?.user?.phone,
    },
    {
      name: "Address",
      info: `${details?.user?.location?.address}, ${details?.user?.location?.city}, ${details?.user?.location?.state}, ${details?.user?.location?.country}`,
    },
    {
      name: "Married Since",
      info: details?.marriage_since,
    },
    {
      name: "Marriage Date",
      info: details?.marriage_date,
    },
    {
      name: "Marriage Location",
      info: details?.marriage_location,
    },
    {
      name: "Membership Duration",
      info: details?.member_duration,
    },
    {
      name: "Is Relationship  Registered?",
      info: details?.is_relationship_registered ? "Yes" : "No",
    },
    {
      name: "Is Premarital Class Completed?",
      info: details?.premarital_class_completed ? "Yes" : "No",
    },
    {
      name: "Husband's Signature",
      info: details?.husband_signature,
    },
    {
      name: "Wife's Signature",
      info: details?.wife_signature,
    },
    {
      name: "Passport",
      info: details?.passport,
    },
    {
      name: "Guarantor",
      info: details?.guarantor,
    },
    {
      name: "Applicant's Partnership Status",
      info: details?.partnership_status,
    },
    {
      name: "Applicant's Partnership Id",
      info: details?.partnership_id,
    },
    {
      name: "Allow Social Media Share",
      info: details?.allow_social_share ? "Yes" : "No",
    },
    {
      name: "Submitted On",
      info: formatDate(details?.createdAt),
    },
    {
      name: "Updated On",
      info: formatDate(details?.updatedAt),
    },
  ];

  return (
    <div>
      <div class="flow-root mt-16 ">
        <dl class="-my-3 divide-y divide-gray-100 text-sm mb-20">
          {formData.map((details, index) => (
            <FormDetailTile details={details} index={index} />
          ))}
        </dl>
      </div>
    </div>
  );
};

export default WeddingThanksgivingForm;
