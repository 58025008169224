import { Switch } from "@headlessui/react";
import React from "react";
import { twMerge } from "tailwind-merge";

const SwitchInput = ({
  checked,
  setChecked,
  label,
  className,
  labelClass,
  switchClass,
}) => {
  return (
    <div className={twMerge("flex flex-col gap-2 items-start mb-4", className)}>
      <label htmlFor="" className={twMerge("text-sm font-medium", labelClass)}>
        {label}
      </label>
      <Switch
        checked={checked}
        onChange={setChecked}
        className={twMerge(
          "bg-orangeBg relative inline-flex h-6 w-11 items-center rounded-full",
          checked ? "bg-purpleBg" : "bg-gray-400",
          switchClass
        )}
      >
        <span className="sr-only">{label}</span>
        <span
          className={`${
            checked ? "translate-x-6" : "translate-x-1"
          } inline-block h-4 w-4 transform rounded-full bg-white transition`}
        />
      </Switch>
    </div>
  );
};

export default SwitchInput;
