import { homeIosMockUp } from "assets/icons";
import React from "react";
import { AppStoreButton, GooglePlayButton } from "./DownloadButtons";
import { twMerge } from "tailwind-merge";

const DownloadApps = () => {
  return (
    <div className="px-5">
      <div
        className={twMerge(
          "max-w-section lg:mx-auto grid lg:grid-cols-2 items-end relative my-20 h-[640px] md:h-[491px] px-5 bg-gradient-to-r from-purpleOverlay via-purpleOverlay to-[#7A10A6] rounded-[40px]",
          "before:content-[''] before:top-0 before:left-0 before:absolute before:w-[28%] before:h-[18%] before:bg-white before:z-10 before:rounded-br-[40px]"
        )}
      >
        <div className="pl-5 pb-4 lg:pl-20 lg:pb-16 relative z-20">
          <p className="font-semibold text-4xl md:text-[50px] md:leading-[50px]">
            Download <br />
            the COZA Global App
          </p>
          <p className="text-sm md:max-w-[400px] my-5">
            On the run? Have a long, boring commute? Missed service this week?
            All our content is available on the app. Come watch or listen on
            demand.
          </p>
          <div className="flex flex-col md:flex-row md:items-center gap-2">
            <AppStoreButton />
            <GooglePlayButton />
          </div>
        </div>
        <div className="flex justify-center w-full h-full items-start relative">
          <img
            src={homeIosMockUp}
            alt=""
            className="absolute bottom-0 right-0 lg:right-[25%] h-[150px] sm:h-[250px] md:h-[350px] lg:h-auto z-20 hover:transform-[rotateY(45deg home-phone"
          />
        </div>
        <div className="absolute h-16 w-20 bg-purpleOverlay top-0 left-[calc(28%+0.5px)] md:left-[calc(28%+4px)] z-10 rounded-tl-[40px] -ml-[0.5px] md:-ml-[4px]  shadow-[-15px_-15px_0_0_white]"></div>
        <div className="absolute h-20 w-16 sm:w-20 bg-purpleOverlay top-[18%] left-0 z-10 rounded-tl-[40px]  shadow-[-15px_-15px_0_0_white]"></div>
      </div>
    </div>
  );
};

export default DownloadApps;

// height: 4rem;
// left: calc(28% + 4px);
