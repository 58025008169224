import GlobalVariables from "constants/GlobalVariables";

export const validTableKeys = {
  campusName: {
    title: "Name",
    addBadge: false,

    isImage: false,
    addCopyPreview: false,
    showOnMobile: true,
  },
  location: {
    title: "Location",
    addBadge: false,
    isSortable: false,
    isImage: false,
    addCopyPreview: false,
    showOnMobile: false,
  },
  city: {
    title: "City",
    addBadge: false,
    isSortable: false,
    isImage: false,
    addCopyPreview: false,
  },
  state: {
    title: "State",
    addBadge: false,
    isSortable: false,
    isImage: false,
    addCopyPreview: false,
    sortByString: false,
  },
  contact_count: {
    title: "Contacts",
    addBadge: false,
    isSortable: false,
    isImage: false,
    addCopyPreview: false,
  },
};
export function modifyData(data) {
  if (!data.length) return [];
  const newArray = data?.map((item) => ({
    ...item,
    location: item?.location?.address,
    city: item?.location?.city,
    state: item?.location?.state,
    contact_count: item?.contacts?.length,
    campusName: (
      <span className="flex items-center gap-3">
        <img
          className="object-cover w-[50px] h-[50px] rounded-[10px]"
          src={
            !item?.images?.length > 0
              ? GlobalVariables.defaultProfilePicture
              : item?.images[0]
          }
          alt=""
        />
        <span>{item?.name}</span>
      </span>
    ),
  }));

  return newArray;
}
