import useWindowDimensions from "./useWindowDimensions";

const useMobile = () => {
  const { width } = useWindowDimensions();
  const breakpoint = 1024;
  const isMobile = width ? width < breakpoint : true;

  return isMobile;
};

export default useMobile;
