import useDeleteManager from "constants/controller_templates/delete_controller_template";

export const DeleteSermonManager = (sermonId) => {
  const isMultiKey = false;
  const { deleteCaller, isLoading, isSuccess, error, data } = useDeleteManager(
    `/sermon/${sermonId}`,
    ["allSermons"],
    isMultiKey
  );
  const deleteSermon = async () => {
    try {
      await deleteCaller();
    } catch (error) {
      // Handle error if necessary
      console.error("delete error:", error);
    }
  };
  return {
    deleteSermon,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
