import { RoundedButton } from "generalComponents/Button";
import DatePickerInput from "generalComponents/DatePickerInput";
import InputWithFullBoarder from "generalComponents/InputWithFullBoarder";
import RadioGroupInput from "generalComponents/RadioGroupInput";
import SelectInput from "generalComponents/SelectInput";
import { useEffect, useState } from "react";
import usePostFamilyProcess from "../controllers/post_controller";
import useFileUpload from "modules/fileupload/fileUploadController";
import FileInput from "generalComponents/FileInput";
import Loader, { Spinner } from "generalComponents/Loader";
import guarantors from "./guarantors.json";
import useQueryParams from "constants/useQueryParams";
import { useGetSingleFamilyProcessSubmission } from "../controllers/get_controller";
import { StatusBadge } from "generalComponents/Badges";
import { capitalizeFirstLetter } from "constants/textFormatters";
import useReadOnlyInputs from "../useReadOnlyInputs";

const guarantorList = guarantors?.map((item) => ({
  label: item,
  value: item,
}));

const partnershipOptions = [
  {
    label: "Partner",
    value: "active",
  },
  {
    label: "Not a Partner",
    value: "inactive",
  },
];

const genderOptions = [
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
];

const ChildDedicationModal = ({ id }) => {
  const { params } = useQueryParams();
  const { data: singleForm, isLoading: singleFormLoading } =
    useGetSingleFamilyProcessSubmission({
      formId: params?.form,
      type: "child-dedication",
      enabled: !!params?.form,
    });

  const initialValues = {
    name: "",
    child_gender: "",
    position_of_child: "",
    phone: "",
    address: "",
    marriage_date: "",
    marriage_location: "",
    member_duration: "",
    child_name: "",
    child_dob: "",
    guarantor: "",
    partnership_status: "active",
    partnership_id: "",
  };
  const [formData, setFormData] = useState(initialValues);

  const [files, setFiles] = useState({
    birth_certificate: undefined,
    marriage_certificate: undefined,
    father_signature: undefined,
    father_passport: undefined,
    mother_signature: undefined,
    mother_passport: undefined,
  });
  const [filesSrc, setFilesSrc] = useState({
    birth_certificate: "",
    marriage_certificate: "",
    father_signature: "",
    father_passport: "",
    mother_signature: "",
    mother_passport: "",
  });

  const handleFileChange = (e, name) => {
    if (e.target.files[0]) {
      setFiles((val) => ({
        ...val,
        [name]: e.target.files[0],
      }));
      setFilesSrc((val) => ({
        ...val,
        [name]: URL.createObjectURL(e.target.files[0]),
      }));
    }
  };

  const handleChange = (event, name) => {
    const value = event.target?.value;
    setFormData((data) => ({
      ...data,
      [name]: value,
    }));
  };

  const handleMarriageDate = (date) => {
    setFormData((data) => ({
      ...data,
      marriage_date: date,
    }));
  };

  const handleChildDob = (date) => {
    setFormData((data) => ({
      ...data,
      child_dob: date,
    }));
  };

  const {
    // error: fileUploadError,
    handleFileUpload: uploadFile,
    isLoading: isUploadingFlie,
    // data: uploadUrl,
  } = useFileUpload();

  const { postCaller, isLoading } = usePostFamilyProcess({
    endpoint: "child-dedication",
    mutationKey: ["family_process"],
    onSuccess: () => window[id]?.close(),
  });

  function handleFileUpload() {
    return new Promise(async (resolve, reject) => {
      try {
        const uploadPromises = Object.values(files)?.map(async (file) => {
          const fileUrl = await uploadFile(file);
          return fileUrl;
        });

        const uploadedFiles = await Promise.all(uploadPromises);
        resolve(uploadedFiles);
        return uploadedFiles;
      } catch (error) {
        reject(error);
      }
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    // console.log({ formData });
    try {
      const [
        birth_certificate,
        marriage_certificate,
        father_signature,
        father_passport,
        mother_signature,
        mother_passport,
      ] = await handleFileUpload();

      await postCaller({
        ...formData,
        member_duration: Number(formData.member_duration),
        position_of_child: Number(formData.position_of_child),
        birth_certificate,
        marriage_certificate,
        father_signature,
        father_passport,
        mother_signature,
        mother_passport,
      });
    } catch (error) {}
  }

  useEffect(() => {
    if (singleForm) {
      setFormData({
        name: singleForm?.data?.name,
        child_gender: singleForm?.data?.child_gender,
        position_of_child: singleForm?.data?.position_of_child,
        phone: singleForm?.data?.phone,
        address: singleForm?.data?.address,
        marriage_date: singleForm?.data?.marriage_date,
        marriage_location: singleForm?.data?.marriage_location,
        member_duration: singleForm?.data?.member_duration,
        child_name: singleForm?.data?.child_name,
        child_dob: singleForm?.data?.child_dob,
        guarantor: singleForm?.data?.guarantor,
        partnership_status: singleForm?.data?.partnership_status,
        partnership_id: singleForm?.data?.partnership_id,
      });
      setFilesSrc({
        birth_certificate: singleForm?.data?.birth_certificate,
        marriage_certificate: singleForm?.data?.marriage_certificate,
        father_signature: singleForm?.data?.father_signature,
        father_passport: singleForm?.data?.father_passport,
        mother_signature: singleForm?.data?.mother_signature,
        mother_passport: singleForm?.data?.mother_passport,
      });
    }
  }, [singleForm]);

  useReadOnlyInputs(params?.form !== undefined);

  if (singleFormLoading) return <Loader />;
  return (
    <form onSubmit={handleSubmit}>
      {params?.form && (
        <div className="mb-5">
          <StatusBadge
            label={capitalizeFirstLetter(singleForm?.data?.status)}
            status={singleForm?.data?.status}
          />
        </div>
      )}
      <InputWithFullBoarder
        label={"Name"}
        type="text"
        id="name"
        value={formData.name}
        onChange={(e) => handleChange(e, "name")}
        placeholder={"Enter full name"}
        className={"bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-xs"}
        otherProps={{
          required: true,
        }}
      />
      <InputWithFullBoarder
        label={"Phone Number"}
        type="tel"
        id="phone"
        value={formData.phone}
        onChange={(e) => handleChange(e, "phone")}
        placeholder={"Enter phone number"}
        className={"bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-xs"}
        otherProps={{
          required: true,
        }}
      />

      <InputWithFullBoarder
        label={"Address"}
        type="text"
        id="address"
        value={formData.address}
        onChange={(e) => handleChange(e, "address")}
        placeholder={"Enter your address"}
        className={"bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-xs"}
        otherProps={{
          required: true,
        }}
      />

      <DatePickerInput
        label="Marriage Date"
        name="marriage_date"
        value={formData.marriage_date}
        onChange={handleMarriageDate}
        placeholder={"Select marriage date"}
        disabledPeriod={[
          {
            after: new Date(),
          },
        ]}
      />

      <InputWithFullBoarder
        label={"Marriage Location"}
        type="text"
        id="marriage_location"
        value={formData.marriage_location}
        onChange={(e) => handleChange(e, "marriage_location")}
        placeholder={"Enter marriage location"}
        className={"bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-xs"}
        otherProps={{
          required: true,
        }}
      />

      <InputWithFullBoarder
        label={"How long have you been in COZA? (In years)"}
        type="number"
        id="member_duration"
        value={formData.member_duration}
        onChange={(e) => handleChange(e, "member_duration")}
        placeholder={"Enter number of years"}
        className={"bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-xs"}
        otherProps={{
          required: true,
        }}
      />

      <InputWithFullBoarder
        label={"Name of Child"}
        type="text"
        id="child_name"
        value={formData.child_name}
        onChange={(e) => handleChange(e, "child_name")}
        placeholder={"Enter name of child"}
        className={"bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-xs"}
        otherProps={{
          required: true,
        }}
      />

      <DatePickerInput
        label="Child's Date of Birth"
        name="child_dob"
        value={formData.child_dob}
        onChange={handleChildDob}
        placeholder={"Select child's date of birth"}
        disabledPeriod={[
          {
            after: new Date(),
          },
        ]}
      />

      <div className="grid grid-cols-2 gap-4">
        <SelectInput
          label={"Child's Gender"}
          name="child_gender"
          value={formData.child_gender}
          onChange={(e) => handleChange(e, "child_gender")}
          placeholder={"Select gender"}
          options={genderOptions}
          className={"bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-xs"}
          otherProps={{
            required: true,
          }}
        />
        <InputWithFullBoarder
          label={"Child's Position"}
          type="number"
          id="position_of_child"
          value={formData.position_of_child}
          onChange={(e) => handleChange(e, "position_of_child")}
          placeholder={"Enter position (e.g 3)"}
          className={"bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-xs"}
          otherProps={{
            required: true,
          }}
        />
      </div>

      <SelectInput
        label={"Guarantor"}
        type="text"
        id="guarantor"
        value={formData.guarantor}
        onChange={(e) => handleChange(e, "guarantor")}
        placeholder={"Select guarantor"}
        className={"bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-xs"}
        otherProps={{
          required: true,
        }}
        options={guarantorList}
      />

      <RadioGroupInput
        label={"Partnership Status"}
        name="partnership_status"
        value={formData.partnership_status}
        onChange={(e) => handleChange(e, "partnership_status")}
        options={partnershipOptions}
      />

      <InputWithFullBoarder
        label={"Partnership ID"}
        type="text"
        id="partnership_id"
        value={formData.partnership_id}
        onChange={(e) => handleChange(e, "partnership_id")}
        placeholder={"Enter partnership ID"}
        className={"bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-xs"}
        otherProps={{
          required: formData?.partnership_status === "active",
        }}
      />

      <div className="mb-4">
        <label className={`text-sm font-medium mb-2`}>Certificates</label>
        <p className="text-textGrey font-medium text-xs">
          Add one photo for each certificate.
        </p>
        <div className="flex gap-6 items-start mt-3">
          <FileInput
            label={"Child's Birth Certificate"}
            onChange={(e) => handleFileChange(e, "birth_certificate")}
            required
            src={filesSrc?.birth_certificate}
            value={files?.birth_certificate}
          />
          <FileInput
            label={"Marriage Certificate"}
            onChange={(e) => handleFileChange(e, "marriage_certificate")}
            required
            src={filesSrc?.marriage_certificate}
            value={files?.marriage_certificate}
          />
        </div>
      </div>

      <div className="mb-4">
        <label className={`text-sm font-medium mb-2`}>Signature</label>
        <p className="text-textGrey font-medium text-xs">
          Add one photo each for both parties.
        </p>
        <div className="flex gap-6 items-start mt-3">
          <FileInput
            label={"Father"}
            onChange={(e) => handleFileChange(e, "father_signature")}
            required
            src={filesSrc?.father_signature}
            value={files?.father_signature}
          />
          <FileInput
            label={"Mother"}
            onChange={(e) => handleFileChange(e, "mother_signature")}
            required
            src={filesSrc?.mother_signature}
            value={files?.mother_signature}
          />
        </div>
      </div>

      <div className="mb-4">
        <label className={`text-sm font-medium mb-2`}>Passport</label>
        <p className="text-textGrey font-medium text-xs">
          Upload passport photograph.
        </p>
        <div className="flex gap-6 items-start mt-3">
          <FileInput
            label={"Father"}
            onChange={(e) => handleFileChange(e, "father_passport")}
            required
            src={filesSrc?.father_passport}
            value={files?.father_passport}
          />
          <FileInput
            label={"Mother"}
            onChange={(e) => handleFileChange(e, "mother_passport")}
            required
            src={filesSrc?.mother_passport}
            value={files?.mother_passport}
          />
        </div>
      </div>

      {!params?.form && (
        <RoundedButton
          className={
            "rounded-[40px] text-base font-medium w-full py-5 mt-10 grid place-content-center"
          }
        >
          {isLoading || isUploadingFlie ? (
            <Spinner />
          ) : params?.form ? (
            "Update"
          ) : (
            "Submit"
          )}
        </RoundedButton>
      )}
    </form>
  );
};

export default ChildDedicationModal;
