import AxiosWithToken from "constants/api_management/MyHttpHelperWithToken";
import { useMutation, useQuery, useQueryClient } from "react-query";

export const useGetGalleryLikesById = ({
  id,
  user,
  key = "gallery_likes_by_id",
}) => {
  return useQuery(
    ["gallery_likes_by_id", id],
    async () => {
      try {
        const [response] = [
          await AxiosWithToken.get(`/gallery/${id}/likes`, {
            params: {
              user,
            },
          }),
        ];
        //console.log(`this is the current subscription status ${response}`);
        return response.data;
      } catch (error) {
        //console.log(error.response.data);
        throw new Error(`Sorry: ${error.response.data.message}`);
      }
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const useLikeGallery = ({ id, like }) => {
  const queryClient = useQueryClient();

  const putLike = async () => {
    try {
      const response = await AxiosWithToken.put(`/gallery/${id}/${like}`);
      return response?.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  const mutation = useMutation({
    mutationKey: ["gallery"],
    mutationFn: putLike,
    onSuccess: (data) => {
      //   showToast(data.message, "success");
      queryClient.invalidateQueries(["gallery"]);
      queryClient.invalidateQueries(["gallery_by_id"]);
      queryClient.invalidateQueries(["gallery_likes_by_id"]);
      //   onSuccess();
    },
    onError: (error) => {
      //   showToast(error?.message, "error");
    },
  });

  const likeGallery = async () => {
    try {
      await mutation.mutateAsync();
    } catch (error) {
      console.log({ error });
    }
  };

  return {
    likeGallery,
    ...mutation,
  };
};
