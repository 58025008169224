import { twMerge } from "tailwind-merge";

const TextStroke = (props) => {
  const { children, className, ...rest } = props;
  return (
    <p
      {...rest}
      className={twMerge(
        "text-[300px] leading-[300px] font-bold text-white text-stroke-purple relative -z-20",
        className
      )}
    >
      {children}
    </p>
  );
};

export default TextStroke;
