import EditIcon from "assets/icons/EditIcon";
import EmptyState from "generalComponents/EmptyState";
import React, { useCallback, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useGetNotes } from "../controllers/notes_controller";
import NotePreview from "../components/NotePreview";
import Loader from "generalComponents/Loader";
import { twMerge } from "tailwind-merge";
import CreateButton from "modules/pagePraiseReports/components/CreateButton";

const Notes = () => {
  const [pageBottom, setPageBottom] = useState(false);
  const observer = useRef();
  const navigate = useNavigate();
  const { data, isLoading } = useGetNotes();

  const lastElementRef = useCallback((node) => {
    // if (isLoading) return;

    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setPageBottom(true);
      } else {
        setPageBottom(false);
      }
    });

    if (node) observer.current.observe(node);
  }, []);

  if (isLoading) return <Loader isSmall />;
  return (
    <div className="relative">
      <button
        onClick={() => navigate("/library/notes/new")}
        className={twMerge(
          "-right-4 lg:right-4 -top-24  z-20 max-lg:scale-75",
          pageBottom ? "absolute" : "absolute"
        )}
      >
        <CreateButton />
      </button>
      {!data || data?.data?.data?.length < 1 ? (
        <EmptyState
          icon={EditIcon}
          heading={"Hmm..."}
          messageClass={""}
          message={"It looks like you haven't created any notes yet."}
          scriptureText={
            "Then the Lord replied: Write down the revelation and make it plain on tablets so that a herald may run with it."
          }
          scripture={"Habakkuk 2:2 (NIV)"}
          button={
            <Link
              to={"/library/notes/new"}
              className="px-4 py-2 bg-purpleOverlay rounded-full text-sm"
            >
              Create Note
            </Link>
          }
        />
      ) : (
        <div className="flex flex-col gap-4 mt-8">
          {data
            ? data?.data?.data?.map((note, index) => (
                <NotePreview key={index} note={note} />
              ))
            : null}
          <div ref={lastElementRef}></div>
        </div>
      )}
    </div>
  );
};

export default Notes;
