import { homeLatestSermon } from "assets/images";
import ContainerWithVerticalText from "generalComponents/ContainerWithVerticalText";
import React from "react";

const GlobalSP = () => {
  return (
    <div className="flex flex-wrap flex-col-reverse justify-around items-center md:grid grid-cols-2 gap-10 py-10 max-w-section mx-auto">
      <ContainerWithVerticalText
        text={"Global Senior Pastor"}
        className="px-5 md:ml-auto"
      >
        <h2 className="font-semibold text-3xl lg:text-5xl text-black mb-6 ">
          <span className="text-xl text-purpleBg font-normal tracking-[0.5em]">
            PASTOR
          </span>{" "}
          <br />
          BIODUN <br className="hidden md:block" />
          FATOYINBO
        </h2>
        <p className="text-sm text-gray-500">
          Pastor Biodun Fatoyinbo, fondly called PB is renowned for his powerful
          preaching and unwavering dedication to the gospel. Under his guidance,
          COZA has grown into a thriving global ministry, touching lives across
          continents and inspiring countless individuals to pursue their
          God-given purpose with passion, spirituality and excellence. <br />
          He is a visionary leader, dynamic preacher, and the Global Senior
          Pastor (GSP) of COZA. Known for his passionate, practical and
          inspiring messages, Pastor Biodun has significantly impacted countless
          lives both within Nigeria and around the world.
          <br />
          <br />
          Under Pastor Biodun's dynamic leadership, COZA has grown from a small
          congregation to a thriving global ministry with multiple Churches and
          an extensive online presence. His teachings are characterised by deep
          biblical insights, practical wisdom, and a profound understanding of
          contemporary issues, making them relatable and impactful for a diverse
          audience. <br />
          {/* Pastor Biodun is also known for his commitment to spirituality and
          excellence, which is reflected in every aspect of COZA's operations,
          from worship services to community outreach initiatives. His
          leadership style emphasises integrity, service, and a relentless
          pursuit of spiritual and personal growth. <br />
          His books Redesign, Thrive and Move Up From Average have significantly
          impacted this generation positively. */}
        </p>
        {/* <RoundedButton className="px-5 py-3 flex items-center gap-2 mt-5">
          <span>Know more</span>
          <ArrowRight />
        </RoundedButton> */}
      </ContainerWithVerticalText>

      <div className="flex md:justify-end px-5 md:px-0">
        <img
          src={homeLatestSermon}
          alt=""
          className="object-cover min-h-[400px] "
        />
      </div>
    </div>
  );
};

export default GlobalSP;
