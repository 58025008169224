import React from "react";
import SermonDisc from "./SermonDisc";
// import SermonDetails from "generalComponents/SermonDetails";
// import StoreItemDetails from "modules/pageStore/components/StoreItemDetails";

const AudioItem = ({ message, showPrice, onShowDetails, isAdmin, onClick }) => {
  return (
    <>
      <div
        className="flex flex-col cursor-pointer"
        onClick={isAdmin ? onClick : () => onShowDetails(message)}
      >
        <SermonDisc
          image={message?.cover_image?.url}
          className={"mb-2 border-purpleBg"}
          small={true}
        />
        <p className="max-w-[150px] line-clamp-2 text-sm font-semibold text-purpleOverlay">
          {message.title}
        </p>
        <p className="max-w-[150px] text-xs text-orangeBg">
          {message.facilitator}
        </p>
        {showPrice ? (
          <p className="text-xl font-semibold text-purpleBg text-right w-full mt-3">
            <span className="text-orangeBg">₦</span>
            {message.price ? message.price?.toLocaleString() : 0}
          </p>
        ) : null}
      </div>

      {/* {showPrice ? (
        <StoreItemDetails
          message={message}
          id={message?.id}
          type="audio"
          title="Audio Details"
        />
      ) : (
        <SermonDetails
          message={message}
          id={message?.id}
          type="audio"
          title="Audio Details"
        />
      )} */}
    </>
  );
};

export default AudioItem;
