import { useQuery } from "react-query";

import AxiosWithToken from "../../../../constants/api_management/MyHttpHelperWithToken";

const useGetFormsManager = ({
  page,
  searchQuery = "",
  enabled = true,
  slug,
  status = "",
  pageSize = "20",
  params,
}) => {
  return useQuery(
    ["forms", page, searchQuery, slug, status, params],
    async () => {
      try {
        console.log(status);
        const [response] = [
          await AxiosWithToken.get(
            `/family-process/${slug}/list?page=${page}&pageSize=${pageSize}${status}${searchQuery}`,
            {
              params,
            }
          ),
        ];
        console.log(`i am checking this ${response.data}`);
        return response.data;
      } catch (error) {
        console.log(error.response.data);
        throw new Error(`Sorry: ${error.response.data.message}`);
      }
    },
    { enabled: enabled }
  );
};

export default useGetFormsManager;
