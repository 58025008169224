// import { homeEvent } from "assets/images";
import PlayVideo from "generalComponents/PlayVideo";
import React from "react";

const EventItem = ({ image, title, description, readMore, event }) => {
  return (
    <>
      <div className=" flex flex-col gap-2 lg:w-[351px]">
        <div className="relative w-fit rounded-[20px] overflow-hidden">
          <img
            src={image}
            alt=""
            className="rounded-[20px] lg:w-[351px] h-[410px] object-cover"
          />
          {event?.video_url !== "" ? (
            <>
              <svg
                role="button"
                onClick={() => window[`event_${event?.id}`]?.showModal()}
                width="56"
                height="62"
                viewBox="0 0 56 62"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="absolute inset-0 m-auto z-[3]"
              >
                <path
                  opacity="0.7"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0 9.77249C0 2.22576 8.20024 -2.46434 14.705 1.36201L50.6633 22.514C57.0773 26.2869 57.0773 35.5622 50.6633 39.3349L14.705 60.487C8.20024 64.3133 0 59.6231 0 52.0765V9.77249Z"
                  fill="white"
                />
              </svg>

              <div className="w-full h-full absolute z-[2] inset-0 bg-black/40"></div>
            </>
          ) : null}
        </div>
        <p className="text-purpleOverlay font-semibold">{title}</p>
        <p className="text-gray-400 text-xs line-clamp-2">{description}</p>
        <span
          role="button"
          onClick={() => {
            readMore();
            window.eventModal.showModal();
          }}
          className="text-purpleBg text-xs"
        >
          Read more
        </span>
      </div>

      {event?.video_url !== "" && (
        <PlayVideo id={`event_${event.id}`} src={event?.video_url} />
      )}

      {/* <SingleEventModal
        id="eventModal"
        eventId={eventId}
        onClose={() => window.eventModal.close()}
      /> */}
    </>
  );
};

export default EventItem;
