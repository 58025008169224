import React from "react";

const CreateButton = () => {
  return (
    <svg
      width="115"
      height="115"
      viewBox="0 0 115 115"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_839_17897)">
        <circle
          cx="52.5"
          cy="47.5"
          r="32.5"
          fill="url(#paint0_linear_839_17897)"
        />
        <g clipPath="url(#clip0_839_17897)">
          <path
            d="M43.0954 57.6701L45.6455 55.1192C49.5559 56.0013 53.0278 55.5695 55.9623 53.8002C61.321 50.5717 62.9019 44.1255 62.9661 43.8535C63.0734 43.4014 62.8825 42.9291 62.4887 42.6832C62.2902 42.5581 62.062 42.5049 61.8373 42.5201C63.572 39.5476 63.7849 36.5826 63.7942 36.4162C63.8297 35.8594 63.4376 35.3684 62.8892 35.2763C56.9222 34.2861 52.1144 35.9506 48.5673 40.2261C48.4668 40.0714 48.324 39.9447 48.155 39.8577C47.784 39.6693 47.3379 39.709 47.0067 39.9616C41.9716 43.7909 42.738 50.2303 44.0223 53.6718L41.561 56.134C41.1377 56.5573 41.1368 57.2434 41.561 57.6676C41.9843 58.0943 42.6712 58.0943 43.0954 57.6701ZM46.7625 43.2518C46.7693 43.2747 46.776 43.2992 46.7836 43.322C46.9374 43.7977 47.4579 44.145 47.9547 44.0943C48.5242 44.0368 48.7777 43.6498 49.1309 43.1141C50.2454 41.4208 53.5415 36.4111 61.4832 37.2747C61.19 38.8032 60.3409 41.7681 57.9801 43.883C57.6218 44.2033 57.5162 44.7212 57.7224 45.1547C57.9268 45.5907 58.3941 45.8382 58.8681 45.7639C59.0447 45.7377 59.5179 45.6583 60.0865 45.5155C59.2416 47.4648 57.6176 50.2751 54.8276 51.9506C52.3848 53.4158 49.4494 53.7723 46.0958 53.0102C45.5348 51.496 44.1279 46.7863 46.7625 43.2518Z"
            fill="white"
          />
          <path
            d="M63.1645 58.4648H40.8358C40.1353 58.4648 39.5684 59.0327 39.5684 59.7323C39.5684 60.4319 40.1353 60.9997 40.8358 60.9997H63.1645C63.8649 60.9997 64.4319 60.4319 64.4319 59.7323C64.4319 59.0327 63.8649 58.4648 63.1645 58.4648Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_839_17897"
          x="0"
          y="0"
          width="115"
          height="115"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="5" dy="10" />
          <feGaussianBlur stdDeviation="12.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.184314 0 0 0 0 0.0235294 0 0 0 0 0.25098 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_839_17897"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_839_17897"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_839_17897"
          x1="52.5"
          y1="15"
          x2="52.5"
          y2="80"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7A10A6" />
          <stop offset="1" stopColor="#2F0640" />
        </linearGradient>
        <clipPath id="clip0_839_17897">
          <rect
            width="26"
            height="26"
            fill="white"
            transform="translate(39 35)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CreateButton;
