import AvatarWithTitle from "generalComponents/AvatarWithTitle";
import StatusButton from "generalComponents/StatusButton";
import { formatDate } from "utils/formatDate";

export const validTableKeys = {
  name: {
    title: "Name",
    addBadge: false,
    isImage: false,
    addCopyPreview: false,
    showOnMobile: true,
  },
  email: {
    title: "Email",
    addBadge: false,
    isSortable: false,
    isImage: false,
    addCopyPreview: false,
    showOnMobile: false,
  },
  phone: {
    title: "Phone",
    addBadge: false,
    isSortable: false,
    isImage: false,
    // addCopyPreview: true,
  },
  date: {
    title: "Date",
    addBadge: false,
    isSortable: false,
    isImage: false,
    addCopyPreview: false,
    showOnMobile: true,
  },
  status_badge: {
    title: "Status",
    addBadge: false,
    isSortable: false,
    isImage: false,
    addCopyPreview: false,
    sortByString: false,
    showOnMobile: true,
  },
};
export function modifyData(data) {
  if (!data.length) return [];
  const newArray = data?.map((item) => ({
    ...item,
    phone: item?.user?.phone,
    email: item?.user?.email,
    status_badge: (
      <StatusButton
        status={item?.status === "requested" ? "pending" : item?.status}
      />
    ),
    date: formatDate(item?.createdAt),
    name: (
      <AvatarWithTitle
        image={item?.user?.profile_picture}
        title={`${item?.user?.first_name} ${item?.user?.last_name}`}
      />
    ),
  }));

  return newArray;
}
