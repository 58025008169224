import axios from "axios";
import { getPresignedUrl } from "./getPresignedUrls";

// Upload a single file
const uploadFile = async (fileData, presignedUrl, onProgress) => {
    const { file, key } = fileData;

    try {
        const response = await axios.put(presignedUrl, file, {
            headers: {
                'Content-Type': file.type || 'application/octet-stream',
            },
            onUploadProgress: (event) => {
                if (event.total) {
                    const progress = Math.round((event.loaded / event.total) * 100);
                    onProgress(key, progress);
                }
            },
        });

        if (response.status === 200) {
            onProgress(key, 100)
        }

        return file?.name; // Return the file name after successful upload
    } catch (error) {
        console.error(`Error uploading file ${key}:`, error);
        throw error;
    }
};

/**
 * Upload files with pooling
 * @param {Object} params - Parameters for batch file uploads
 * @param {Object[]} params.files - Array of file objects to upload
 * @param {string} params.bucketName - S3 bucket name
 * @param {number} params.batchSize - Maximum concurrent uploads
 * @param {number} params.expirySeconds - Expiry time for presigned URLs
 * @param {Function} params.onProgressUpdate - Callback for individual file upload progress
 * @param {Function} params.onUploadUpdate - Callback for upload state (start/complete)
 * @param {Object} params.service - Service details
 * @param {Object} params.campus - Campus details
 * @returns {Promise<string[]>} - Array of successfully uploaded file names
 */

export const uploadFilesWithPooling = async ({
    files,
    bucketName,
    batchSize,
    expirySeconds,
    onProgressUpdate,
    onUploadUpdate,
    service,
    campus,
}) => {
    const activeUploads = new Set();
    const results = [];

    const createObjectKey = (fileName) =>
        `coza-app-face-recognition/${service?.name?.replaceAll(' ', '_') ?? 'service'}_${service?.serviceTime}/_${campus?.name?.replaceAll(' ', '_') ?? 'COZA'}_${fileName.replaceAll(' ', '_')}`;

    const addToPool = async (index) => {
        if (index >= files.length) return;

        const fileData = files[index];
        const objectKey = createObjectKey(fileData.file.name);

        try {
            const presignedUrl = await getPresignedUrl({
                bucketName,
                objectKey,
                expirySeconds,
            });

            const uploadPromise = uploadFile(fileData, presignedUrl, onProgressUpdate)
                .then((fileName) => {
                    results.push(fileName);
                })
                .catch((error) => {
                    console.error(`Failed to upload file at index ${index}:`, error);
                })
                .finally(() => {
                    activeUploads.delete(uploadPromise);
                    addToPool(index + batchSize);
                });

            activeUploads.add(uploadPromise);
        } catch (error) {
            console.error(`Failed to fetch presigned URL for file at index ${index}:`, error);
            addToPool(index + batchSize);
        }
    };

    try {
        // Start uploading with batch size
        for (let i = 0; i < Math.min(batchSize, files.length); i++) {
            addToPool(i);
        }


        // Wait for all uploads to be settled (resolved or rejected)
        await Promise.allSettled(activeUploads);

        // Call onUploadUpdate only once all uploads are completed
        onUploadUpdate(true);

        // Return the results of successful uploads
        return results;
    } catch (error) {
        console.error('An error occurred during the upload process:', error);
        throw error;
    } finally {
        // Ensure onUploadUpdate is called when all uploads are complete
        onUploadUpdate(false);
    }
};
