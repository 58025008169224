import React from "react";
import HeaderFooter from "../../../generalComponents/HeaderFooter";
import { topPattern } from "../../../assets/images";

const PrivacyPolicyPage = () => {
  // Get today's date
  const currentDate = new Date().toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const SubSections = ({ title, body, index, number, hasHeader = false }) => (
    <p>
      {
        <strong>
          {number}.{index}. {hasHeader ? `${title}:` : ""}
        </strong>
      }
      {body}
    </p>
  );
  const dataDefinitions = [
    {
      title: "Cookie",
      body: "Small amount of data generated by a website and saved by your web browser. It is used to identify your browser, provide analytics, remember information about you such as your language preference or login information.",
    },
    {
      title: "Company",
      body: 'When this policy mentions "Company," "we," "us," or "our," it refers to COZA Global that is responsible for your information under this Privacy Policy.',
    },
    {
      title: "Country",
      body: "Where COZA Global or the owners/founders of COZA Global are based.",
    },
    {
      title: "Customer",
      body: "Refers to the company, organisation or person that signs up to use the COZA Global App Service to manage the relationships with your consumers or service users.",
    },
    {
      title: "Device",
      body: "Any internet connected device such as a phone, tablet, computer or any other device that can be used to visit COZA Global App and use the services.",
    },
    {
      title: "IP address",
      body: "Every device connected to the Internet is assigned a number known as an Internet protocol (IP) address. These numbers are usually assigned in geographic blocks. An IP address can often be used to identify the location from which a device is connecting to the Internet.",
    },
    {
      title: "Personnel",
      body: "Refers to those individuals who are employed by COZA Global or are under contract to perform a service on behalf of one of the parties.",
    },
    {
      title: "Personal Data",
      body: "Any information that directly, indirectly, or in connection with other information – including a personal identification number – allows for the identification or identifiability of a natural person.",
    },
    {
      title: "Service",
      body: "Refers to the service provided by COZA Global App as described in the relative terms (if available) and on this platform.",
    },
    {
      title: "Third-party service",
      body: "Refers to advertisers, contest sponsors, promotional and marketing partners, and others who provide our content or whose products or services we think may interest you.",
    },
    {
      title: "Website",
      body: "COZA Global's site, which can be accessed via this URL: https://coza.org.ng/",
    },
    {
      title: "You",
      body: "A person or entity that is registered with COZA Global App to use the Services.",
    },
  ];

  const userDataUsage = [
    {
      title: "",
      body: "Name / Username",
    },
    {
      title: "",
      body: "Phone Numbers",
    },
    {
      title: "",
      body: "Email Addresses",
    },
    {
      title: "",
      body: "Mailing Addresses",
    },
    {
      title: "",
      body: "Billing Addresses",
    },
    {
      title: "",
      body: "Debit/credit card numbers",
    },
    {
      title: "",
      body: "Age",
    },
  ];

  const dataUsagePurposes = [
    {
      title: "",
      body: "To personalize your experience (your information helps us to better respond to your individual needs)",
    },
    {
      title: "",
      body: "To improve our service (we continually strive to improve our service offerings based on the information and feedback we receive from you)",
    },
    {
      title: "",
      body: "To improve customer service (your information helps us to more effectively respond to your customer service requests and support needs)",
    },
    {
      title: "",
      body: "To process transactions",
    },
    {
      title: "",
      body: "To administer a contest, promotion, survey, or other site feature",
    },
    {
      title: "",
      body: "To send periodic emails",
    },
  ];

  const informationShareWithThirdParty = [
    {
      title: "",
      body: "We may share the information that we collect, both personal and non-personal, with third parties such as advertisers, contest sponsors, promotional and marketing partners, and others who provide our content or whose products or services we think may interest you. We may also share it with our current and future affiliated companies and business partners, and if we are involved in a merger, asset sale or other business reorganisation, we may also share or transfer your personal and non-personal information to our successors-in-interest. ",
    },
    {
      title: "",
      body: "We may engage trusted third-party service providers to perform functions and provide services to us, such as hosting and maintaining our servers and our service, database storage and management, e-mail management, storage marketing, credit card processing, customer service and fulfilling orders for products and services you may purchase through our platform. We will likely share your personal information, and possibly some non-personal information, with these third parties to enable them to perform these services for us and for you. ",
    },
    {
      title: "",
      body: "We may share portions of our log file data, including IP addresses, for analytics purposes with third parties such as web analytics partners, application developers, and ad networks. If your IP address is shared, it may be used to estimate general location and other technographics such as connection speed, whether you have visited the service in a shared location, and type of device used to visit the service. They may aggregate information about our advertising and what you see on the service and then provide auditing, research and reporting for us and our advertisers. ",
    },
    {
      title: "",
      body: "We may also disclose personal and non-personal information about you to government or law enforcement officials or private parties as we, in our sole discretion, believe necessary or appropriate in order to respond to claims, legal process (including subpoenas), to protect our rights and interests or those of a third party, the safety of the public or any person, to prevent or stop any illegal, unethical, or legally actionable activity, or to otherwise comply with applicable court orders, laws, rules and regulations. ",
    },
  ];

  const thirdPartyServices = [
    {
      title: "",
      body: "We may display, include or make available third-party content (including data, information, applications and other products services) or provide links to third-party websites or services (Third-Party Services). ",
    },
    {
      title: "",
      body: "You acknowledge and agree that we shall not be responsible for any Third-Party Services, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality or any other aspect thereof. We do not assume and shall not have any liability or responsibility to you or any other person or entity for any Third-Party Services. ",
    },
    {
      title: "",
      body: "Third-Party Services and links thereto are provided solely as a convenience to you and you access and use them entirely at your own risk and subject to such third parties' terms and conditions.  ",
    },
  ];

  const personalData = [
    {
      title: "",
      body: "Personal data collected must be processed in a fair, legal, and transparent way and should only be used in a way that a person would reasonably expect. ",
    },
    {
      title: "",
      body: "Personal data should only be collected to fulfil a specific purpose and it should only be used for that purpose. Organisations must specify why they need the personal data when they collect it. ",
    },
    {
      title: "",
      body: "Personal data should be held no longer than necessary to fulfil its purpose. ",
    },
    {
      title: "",
      body: "People covered by the GDPR and the NDPR have the right to access their own personal data. They can also request a copy of their data, and that their data be updated, deleted, restricted, or moved to another organisation. ",
    },
  ];

  return (
    <HeaderFooter
      children={
        <div>
          <div className="container mx-auto p-4 text-blackColor my-[100px] text-[13px] md:text-[16px] max-w-[1240px] ">
            <h1 className="text-2xl font-bold mb-4">Privacy Policy</h1>
            <h2 className="text-lg font-semibold mb-2">
              Updated on: 01-19-2024
            </h2>
            <h2 className="text-lg font-semibold mb-2">1. Introduction</h2>
            <p className="mb-4">
              1.1. COZA Global ("we," "our," or "us") is committed to protecting
              your privacy. This Privacy Policy explains how your personal
              information is collected, used, and disclosed by www.coza.org.ng
            </p>
            <p className="mb-4">
              1.2. This Privacy Policy applies to our website,{" "}
              <a href="https://coza.org.ng">https://coza.org.ng</a>, and it's
              associated subdomains (collectively, our "Service") alongside our
              application, COZA Global. By accessing or using our Service, you
              signify that you have read, understood, and agree to our
              collection, storage, use, and disclosure of your personal
              information as described in this Privacy Policy and our Terms of
              Service.
            </p>
            {/* definitions */}
            <div className="pb-10 space-y-4">
              <h2 className="text-lg font-semibold mb-2">
                2. Definitions and key terms
              </h2>
              For this Privacy Policy:
              {dataDefinitions.map((el, i) => (
                <SubSections
                  hasHeader={true}
                  body={el.body}
                  title={el.title}
                  index={i + 1}
                  number={"2"}
                />
              ))}
            </div>
            {/* information Collected */}
            <div className="pb-10 space-y-4">
              <h2 className="text-lg font-semibold mb-2">
                3. What Information Do We Collect?
              </h2>
              We collect information from you when you visit our service,
              register, place an order, subscribe to our newsletter, respond to
              a survey or fill out a form.
              {userDataUsage.map((el, i) => (
                <SubSections
                  hasHeader={false}
                  body={el.body}
                  index={i + 1}
                  number={"3"}
                />
              ))}
              We also collect information from mobile devices for a better user
              experience, although these features are completely optional.
            </div>
            {/* how information is used */}
            <div className="pb-10 space-y-4">
              <h2 className="text-lg font-semibold mb-2">
                4. How Do We Use The Information We Collect?
              </h2>
              Any of the information we collect from you may be used in one of
              the following ways:
              {dataUsagePurposes.map((el, i) => (
                <SubSections
                  hasHeader={false}
                  body={el.body}
                  index={i + 1}
                  number={"4"}
                />
              ))}
            </div>
            {/* for third parties */}
            <div className="pb-10 space-y-4">
              <h2 className="text-lg font-semibold mb-2">
                5. When do we use customer information from third parties?
              </h2>
              <strong>5.1.</strong> We receive some information from the third
              parties when you contact us. For example, when you submit your
              email address to us to show interest in becoming our customer, we
              receive information from a third party that provides automated
              fraud detection services to us. We also occasionally collect
              information that is made publicly available on social media
              websites. You can control how much of your information social
              media websites make public by visiting these websites and changing
              your privacy settings.
            </div>
            {/* sharing information with third party*/}
            <div className="pb-10 space-y-4">
              <h2 className="text-lg font-semibold mb-2">
                6. Do we share the information we collect with third parties?
              </h2>
              {informationShareWithThirdParty.map((el, i) => (
                <SubSections body={el.body} index={i + 1} number={"6"} />
              ))}
            </div>
            {/* section 7 */}
            <div className="pb-10 space-y-4">
              <h2 className="text-lg font-semibold mb-2">
                7. Where and when is information collected from customers and
                end users?
              </h2>
              <strong>7.1.</strong> We will collect personal information that
              you submit to us. We may also receive personal information about
              you from third parties as described above.
            </div>
            {/* section 8 */}
            <div className="pb-10 space-y-4">
              <h2 className="text-lg font-semibold mb-2">
                8. How Do We Use Your Email Address?
              </h2>
              <strong>8.1.</strong> By submitting your email address on to us,
              you agree to receive emails from us. You can cancel your
              participation in any of these email lists at any time by clicking
              on the opt-out link or other unsubscribe option that is included
              in the respective email. We only send emails to people who have
              authorised us to contact them, either directly, or through a third
              party. We do not send unsolicited commercial emails, because we
              hate spam as much as you do. By submitting your email address, you
              also agree to allow us to use your email address for customer
              audience targeting on sites like Facebook, where we display custom
              advertising to specific people who have opted-in to receive
              communications from us. Email addresses submitted only through the
              order processing page will be used for the sole purpose of sending
              you information and updates pertaining to your order. If, however,
              you have provided the same email to us through another method, we
              may use it for any of the purposes stated in this Policy. Note: If
              at any time you would like to unsubscribe from receiving future
              emails, we include detailed unsubscribe instructions at the bottom
              of each email.
            </div>
            {/* section 9 */}
            <div className="pb-10 space-y-4">
              <h2 className="text-lg font-semibold mb-2">
                9. Could my information be transferred to other countries?
              </h2>
              <strong>9.1.</strong> Information collected via our website,
              through direct interactions with you, or from use of our help
              services may be transferred from time to time to our offices or
              personnel, or to third parties, located throughout the world, and
              may be viewed and hosted anywhere in the world, including
              countries that may not have laws of general applicability
              regulating the use and transfer of such data. To the fullest
              extent allowed by applicable law, by using any of the above, you
              voluntarily consent to the trans-border transfer and hosting of
              such information.
            </div>
            {/* section 10 */}
            <div className="pb-10 space-y-4">
              <h2 className="text-lg font-semibold mb-2">
                10. Is the information collected through our service secure?
              </h2>
              <strong>10.1.</strong> We take precautions to protect the security
              of your information. We have physical, electronic, and managerial
              procedures to help safeguard, prevent unauthorised access,
              maintain data security, and correctly use your information.
              However, neither people nor security systems are foolproof,
              including encryption systems. In addition, people can commit
              intentional crimes, make mistakes or fail to follow policies.
              Therefore, while we use reasonable efforts to protect your
              personal information, we cannot guarantee its absolute security.
              If applicable law imposes any non-disclaimable duty to protect
              your personal information, you agree that intentional misconduct
              will be the standards used to measure our compliance with that
              duty.
            </div>
            {/* section 11 */}
            <div className="pb-10 space-y-4">
              <h2 className="text-lg font-semibold mb-2">
                11. Can I update or correct my information?
              </h2>
              <strong>11.1.</strong> The rights you have to request updates or
              corrections to the information we collect depend on your
              relationship with us. Personnel may update or correct their
              information as detailed in our internal company employment
              policies. Customers have the right to request the restriction of
              certain uses and disclosures of personally identifiable
              information as follows. You can contact us in order to (1) update
              or correct your personally identifiable information, (2) change
              your preferences with respect to communications and other
              information you receive from us, or (3) delete the personally
              identifiable information maintained about you on our systems
              (subject to the following paragraph), by cancelling your account.
              Such updates, corrections, changes and deletions will have no
              effect on other information that we maintain, or information that
              we have provided to third parties in accordance with this Privacy
              Policy prior to such update, correction, change or deletion. To
              protect your privacy and security, we may take reasonable steps
              (such as requesting a unique password) to verify your identity
              before granting you profile access or making corrections. You are
              responsible for maintaining the secrecy of your unique password
              and account information at all times. You should be aware that it
              is not technologically possible to remove each and every record of
              the information you have provided to us from our system. The need
              to back up our systems to protect information from inadvertent
              loss means that a copy of your information may exist in a
              non-erasable form that will be difficult or impossible for us to
              locate. Promptly after receiving your request, all personal
              information stored in databases we actively use, and other readily
              searchable media will be updated, corrected, changed or deleted,
              as appropriate, as soon as and to the extent reasonably and
              technically practicable. If you are an end user and wish to
              update, delete, or receive any information we have about you, you
              may do so by contacting the organisation of which you are a
              customer. duty.
            </div>
            {/* section 12 */}
            <div className="pb-10 space-y-4">
              <h2 className="text-lg font-semibold mb-2">
                12. Sale of Business
              </h2>
              <strong>12.1.</strong> We reserve the right to transfer
              information to a third party in the event of a sale, merger or
              other transfer of all or substantially all of the assets of us or
              any of its Corporate Affiliates (as defined herein), or that
              portion of us or any of its Corporate Affiliates to which the
              Service relates, or in the event that we discontinue our business
              or file a petition or have filed against us a petition in
              bankruptcy, reorganisation or similar proceeding, provided that
              the third party agrees to adhere to the terms of this Privacy
              Policy.
            </div>
            {/* section 13 */}
            <div className="pb-10 space-y-4">
              <h2 className="text-lg font-semibold mb-2">13. Affiliates</h2>
              <strong>13.1.</strong> We may disclose information (including
              personal information) about you to our Corporate Affiliates. For
              purposes of this Privacy Policy "Corporate Affiliate means any
              person or entity which directly or indirectly controlled, is
              controlled by or is under common control with us, whether by
              ownership or otherwise. Any information relating to you that we
              provide to our Corporate Affiliates will be treated by those
              Corporate Affiliates in accordance with the terms of this Privacy
              Policy.
            </div>
            {/* section 14 */}
            <div className="pb-10 space-y-4">
              <h2 className="text-lg font-semibold mb-2">
                14. How Long Do We Keep Your Information?{" "}
              </h2>
              <strong>14.1.</strong> We keep your information only so long as we
              need it to provide service to you and fulfil the purposes
              described in this policy. This is also the case for anyone that we
              share your information with and who carries out services on our
              behalf. When we no longer need to use your information and there
              is no need for us to keep it to comply with our legal or
              regulatory obligations, we'll either remove it from our systems or
              depersonalise it so that we can't identify you.
            </div>
            {/* section 15 */}
            <div className="pb-10 space-y-4">
              <h2 className="text-lg font-semibold mb-2">
                15. How Do We Protect Your Information?
              </h2>
              <strong>15.1.</strong>We implement a variety of security measures
              to maintain the safety of your personal information when you place
              an order or enter, submit, or access your personal information. We
              offer the use of a secure server. All supplied sensitive/credit
              information is transmitted via Secure Socket Layer (SSL)
              technology and then encrypted into our Payment gateway providers’
              database only to be accessible by those authorised with special
              access rights to such systems, and are required to keep the
              information confidential. After a transaction, your private
              information (credit cards, social security numbers, financials,
              etc.) is never kept on file. We cannot, however, ensure or warrant
              the absolute security of any information you transmit to us or
              guarantee that your information on the Service may not be
              accessed, disclosed, altered, or destroyed by a breach of any of
              our physical, technical, or managerial safeguards.
            </div>
            {/* section 16 */}
            <div className="pb-10 space-y-4">
              <h2 className="text-lg font-semibold mb-2">16. Governing Law</h2>
              <strong>16.1.</strong>The laws of United Kingdom, excluding its
              conflicts of law rules, shall govern this Agreement and your use
              of our service. Your use of our service may also be subject to
              other local, state, national, or international laws like the
              Nigerian Data Protection Regulation, 2019.
            </div>
            {/* section 17 */}
            <div className="pb-10 space-y-4">
              <h2 className="text-lg font-semibold mb-2">17. Your Consent </h2>
              <strong>17.1.</strong>By using our service, registering an
              account, or making a purchase, you consent to this Privacy Policy.
            </div>
            {/* section 18 */}
            <div className="pb-10 space-y-4">
              <h2 className="text-lg font-semibold mb-2">
                18. Links to Other Websites{" "}
              </h2>
              <strong>18.1.</strong>This Privacy Policy applies only to the
              Services. The Services may contain links to other websites not
              operated or controlled by COZA Global. We are not responsible for
              the content, accuracy or opinions expressed in such websites, and
              such websites are not investigated, monitored or checked for
              accuracy or completeness by us. Please remember that when you use
              a link to go from the Services to another website, our Privacy
              Policy is no longer in effect. Your browsing and interaction on
              any other website, including those that have a link on our
              platform, is subject to that website's own rules and policies.
              Such third parties may use their own cookies or other methods to
              collect information about you.
            </div>
            {/* section 19 */}
            <div className="pb-10 space-y-4">
              <h2 className="text-lg font-semibold mb-2">19. Cookies </h2>
              <strong>19.1.</strong>We use "Cookies" to identify the areas of
              our website that you have visited. A Cookie is a small piece of
              data stored on your computer or mobile device by your web browser.
              We use cookies to personalise the Content that you see on our
              website. Most web browsers can be set to disable the use of
              cookies. However, if you disable Cookies, you may not be able to
              access functionality on our website correctly or at all. We never
              place Personally Identifiable Information in Cookies.
            </div>
            {/* section 20 */}
            <div className="pb-10 space-y-4">
              <h2 className="text-lg font-semibold mb-2">
                20. Remarketing Services{" "}
              </h2>
              <strong>20.1.</strong>We use remarketing services. What Is
              Remarketing? In digital marketing, remarketing (or retargeting) is
              the practice of serving ads across the internet to people who have
              already visited your website. It allows your company to seem like
              they're “following" people around the internet by serving ads on
              the websites and platforms they use most.
            </div>
            {/* section 21 */}
            <div className="pb-10 space-y-4">
              <h2 className="text-lg font-semibold mb-2">
                21. Payment Details
              </h2>
              <strong>21.1.</strong>In respect to any credit card or other
              payment processing details you have provided us, we commit that
              this confidential information will be stored in the most secure
              manner possible.
            </div>
            {/* section 22 */}
            <div className="pb-10 space-y-4">
              <h2 className="text-lg font-semibold mb-2">22. Kids' Privacy</h2>
              <strong>22.1.</strong>We do not address anyone under the age of
              18. We do not knowingly collect personally identifiable
              information from anyone under the age of 18. If you are a parent
              or guardian and You are aware that your child has provided us with
              Personal Data, please contact Us. If We become aware that we have
              collected Personal Data from anyone under the age of 18 without
              verification of parental consent, We take steps to remove that
              information from our servers.
            </div>
            {/* section 23 */}
            <div className="pb-10 space-y-4">
              <h2 className="text-lg font-semibold mb-2">
                23. Changes To Our Privacy Policy{" "}
              </h2>
              <strong>23.1.</strong>If we decide to change our privacy policy,
              we will post those changes on this page, and/or update the Privacy
              Policy modification date below.
            </div>
            {/* section 24 */}
            <div className="pb-10 space-y-4">
              <h2 className="text-lg font-semibold mb-2">
                24. Third-Party Services
              </h2>
              {thirdPartyServices.map((el, i) => (
                <SubSections body={el.body} index={i + 1} number={"24"} />
              ))}
            </div>
            {/* section 25 */}
            <div className="pb-10 space-y-4">
              <h2 className="text-lg font-semibold mb-2">
                25. Facebook Pixel{" "}
              </h2>
              <strong>25.1.</strong>Facebook pixel is an analytics tool that
              allows you to measure the effectiveness of your advertising by
              understanding the actions people take on your website. You can use
              the pixel to: Make sure your ads are shown to the right people.
              Facebook pixel may collect information from your device when you
              use the service. Facebook pixel collects information that is held
              in accordance with its Privacy Policy.
            </div>
            {/* section 26 */}
            <div className="pb-10 space-y-4">
              <h2 className="text-lg font-semibold mb-2">
                26. Tracking Technologies
              </h2>
              <p>
                <strong>26.1 Cookies</strong>
              </p>
              <strong>26.1.1</strong>We use cookies to enhance the performance
              and functionality of our service but are non-essential to their
              use. However, without these cookies, certain functionality like
              videos may become unavailable or you would be required to enter
              your login details every time you visit our service as we would
              not be able to remember that you had logged in previously.
            </div>
            {/* section 27 */}
            <div className="pb-10 space-y-4">
              <h2 className="text-lg font-semibold mb-2">
                27. Information about the Global Data Protection Regulation
                (GDPR) and the Nigerian Data Protection Regulation (2019)
              </h2>
              <strong>27.1.</strong>We may be collecting and using information
              from you if you are from the European Economic Area (EEA) and from
              the Federal Republic of Nigeria, and in this section of our
              Privacy Policy we are going to explain exactly how and why this
              data is collected, and how we maintain this data under protection
              from being replicated or used in the wrong way.
            </div>
            {/* section 28 */}
            <div className="pb-10 space-y-4">
              <h2 className="text-lg font-semibold mb-2">28. What is GDPR?</h2>
              <strong>28.1.</strong>GDPR is an EU-wide privacy and data
              protection law that regulates how EU residents' data is protected
              by companies and enhances the control the EU residents have, over
              their personal data.
            </div>
            {/* section 29 */}
            <div className="pb-10 space-y-4">
              <h2 className="text-lg font-semibold mb-2">29. What is NDPR? </h2>
              <strong>29.1.</strong>NDPR is a Nigerian data protection
              regulation law that regulates how Nigerian residents' data is
              protected by companies and enhances the control the Nigerian
              residents have, over their personal data.
            </div>
            {/* section 30 */}
            <div className="pb-10 space-y-4">
              <h2 className="text-lg font-semibold mb-2">
                30. What is personal data?{" "}
              </h2>
              30.1 Any data that relates to an identifiable or identified
              individual. Both the GDPR and NDPR cover a broad spectrum of
              information that could be used on its own, or in combination with
              other pieces of information, to identify a person. Personal data
              extends beyond a person's name or email address. Some examples
              include financial information, political opinions, genetic data,
              biometric data, IP addresses, physical address, sexual
              orientation, and ethnicity. The Data Protection Principles include
              requirements such as:
              {personalData.map((el, i) => (
                <SubSections number={"30.1"} index={i + 1} body={el.body} />
              ))}
            </div>
            {/* section 31 */}
            <div className="pb-10 space-y-4">
              <h2 className="text-lg font-semibold mb-2">
                31. Why is GDPR important?{" "}
              </h2>
              <strong>31.1.</strong>The GDPR and NDPR add some new requirements
              regarding how companies should protect individuals' personal data
              that they collect and process. It also raises the stakes for
              compliance by increasing enforcement and imposing greater fines
              for breach. Beyond these facts it's simply the right thing to do.
              At COZA Global we strongly believe that your data privacy is very
              important and we already have solid security and privacy practices
              in place that go beyond the requirements of this new regulation.
            </div>
            {/* section 32 */}
            <div className="pb-10 space-y-4">
              <h2 className="text-lg font-semibold mb-2">
                32. Individual Data Subject's Rights - Data Access, Portability
                and Deletion
              </h2>
              <strong>32.1.</strong>We are committed to helping our customers
              meet the data subject rights requirements of GDPR. We process or
              store all personal data in fully vetted, DPA compliant vendors. We
              do store all conversation and personal data for up to 6 years
              unless your account is deleted. In which case, we dispose of all
              data in accordance with our Terms of Service and Privacy Policy,
              but we will not hold it longer than 60 days.
              <strong>32.2.</strong>We are aware that if you are working with EU
              customers, you need to be able to provide them with the ability to
              access, update, retrieve and remove personal data. We got you!
              We've been set up as self-service from the start and have always
              given you access to your data. Our customer support team is here
              for you to answer any questions you might have.
            </div>

            {/* Contact us */}
            <div className="pb-10 space-y-4">
              <h2 className="text-lg font-semibold mb-2">Contact Us</h2>
              <p>Don't hesitate to contact us if you have any questions.</p>
              <ul>
                <li>
                  {" "}
                  <a href="mailto:admin@coza.org.ng ">
                    Via Email: admin@coza.org.ng
                  </a>
                </li>
                <li>
                  <a href="tel:2348072785537">
                    Via Phone Number: + 2348072785537
                  </a>{" "}
                </li>
                <li>
                  <a href="https://coza.org.ng/">
                    Via this Link: https://coza.org.ng/{" "}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default PrivacyPolicyPage;
