import React, { useEffect, useState } from "react";
import { IoMdRemoveCircleOutline } from "react-icons/io";
import { AiOutlineDollar } from "react-icons/ai";

import useGetAllPermissions from "../controllers/getAllPermissions";
import useAssignUserPermissions from "../controllers/assignUserPermission";
import Loader from "../../../../generalComponents/Loader";
import useGetUserDetailsManager from "../../../settings/controllers/get_UserDetails_controller";
import CustomButton from "../../../../generalComponents/Button";
import useRemoveUserPermissionManager from "../controllers/removeUserPermission";

const Permission = ({ userId, permissions = [] }) => {
  const { data, isLoading } = useGetAllPermissions({ enabled: true });
  const [userPermissions, setUserPermissions] = useState([]);
  const { updateCaller, isLoading: updating } = useAssignUserPermissions();
  const { updateCaller: removePermission, isLoading: removing } =
    useRemoveUserPermissionManager();

  useEffect(() => {
    if (permissions) {
      setUserPermissions(permissions);
      console.log(permissions);
    }
  }, [permissions]);

  const checkPermission = (permission) => {
    return userPermissions.some((p) => p === permission?.name);
  };

  const style =
    "py-3.5 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-widest";

  if (isLoading) {
    return <Loader isSmall={true} />;
  }
  return (
    <div className="scrollbar-hide h-[60vh] overflow-y-scroll relative">
      <table class="relative w-full lg:divide-gray-200 lg:divide-y ">
        <thead class="hidden lg:table-header-group top-0 sticky bg-white">
          <tr>
            <th class={style}>Description</th>

            <th class={style}>Module</th>

            <th class={style}>Action</th>
          </tr>
        </thead>

        <tbody>
          {data?.data?.map((item, index) => {
            const hasPermission = checkPermission(item);
            return (
              <tr
                key={index}
                className={`${
                  index % 2 === 0 ? "bg-gray-50" : "bg-white"
                } w-full`}
              >
                <td class="px-4 py-4 text-sm text-gray-900 align-top lg:align-middle whitespace-nowrap">
                  <div class="flex items-center mr-0 md:mr-8">
                    <div
                      className={`h-3 w-3 bg-brandOrange rounded-full mr-2`}
                    ></div>
                    {item?.description}
                  </div>
                  <div class="mt-1 space-y-2 font-medium pl-11 lg:hidden">
                    <div class="flex items-center">{item?.module}</div>

                    <div class="flex items-center pt-3 space-x-4">
                      <button
                        onClick={() => {
                          if (hasPermission) {
                            // revokeAccess(item);
                            const details = {
                              user: userId,
                              permissions: [item?.name],
                            };
                            removePermission(details);
                          } else {
                            // grantAccess(item);
                            const details = {
                              user: userId,
                              permissions: [item?.name],
                            };
                            updateCaller(details);
                          }
                        }}
                        class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 transition-all duration-200 bg-gray-100 border border-gray-300 rounded-md shadow-sm hover:bg-offCoffee focus:outline-none hover:text-white hover:border-offCoffee focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        {hasPermission ? "Revoke Access" : "Grant Access"}
                      </button>
                    </div>
                  </div>
                </td>

                <td class="hidden px-4 py-4 text-sm font-medium text-gray-900 lg:table-cell whitespace-nowrap">
                  <div class="flex items-center">{item?.module}</div>
                </td>

                <td class="hidden px-4 py-4 lg:table-cell whitespace-nowrap">
                  <div class="flex items-center space-x-4">
                    <button
                      onClick={() => {
                        if (hasPermission) {
                          // revokeAccess(item);
                          const details = {
                            user: userId,
                            permissions: [item.name],
                          };
                          console.log(item);
                          removePermission(details);
                        } else {
                          // grantAccess(item.name);
                          const details = {
                            user: userId,
                            permissions: [item.name],
                          };
                          updateCaller(details);
                        }
                      }}
                      class={`inline-flex items-center px-3 py-2 text-sm font-medium  transition-all duration-200 ${
                        hasPermission
                          ? "bg-brandPurple text-whiteColor"
                          : "bg-gray-100 text-gray-700"
                      } border border-gray-300 rounded-md shadow-sm hover:bg-offCoffee focus:outline-none hover:text-white hover:border-offCoffee focus:ring-2 focus:ring-offset-2 `}
                    >
                      {hasPermission ? "Revoke Access" : "Grant Access"}
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Permission;
