import React from "react";
import { FaMinus, FaPlus } from "react-icons/fa";
import { Disclosure } from "@headlessui/react";

import { useNavigate } from "react-router-dom";
import CustomButton from "./Button";

const FAQSection = () => {
  const navigate = useNavigate();
  const faqs = [
    {
      question: "What is Raizon?",
      answer:
        "Raizon is a fashion profile management platform designed to help both busy tailors and style-savvy individuals create, manage, and share fashion profiles effortlessly. It streamlines the process of tracking measurements and offers a simplified approach to achieving fashion excellence.",
    },
    {
      question: "How can Raizon benefit me?",
      answer:
        "Raizon offers several benefits, including the ability to create and manage multiple fashion profiles with ease, streamline your workflow with bulk profile uploads, stay updated with curated fashion tips, and enjoy priority support for all your inquiries and assistance needs. Whether you're an individual looking to organize your wardrobe or a fashion house managing client databases, Raizon has you covered.",
    },
    {
      question: "How do I get started with Raizon?",
      answer:
        "Getting started with Raizon is simple. You can create a measurement profile for yourself, your family, or your clients. You can also share your profile with stylists and others, and track changes to your measurements. If you own a fashion house, Raizon allows you to manage and track all your clients' measurement databases in one place. Sign up for a free account today to get started!",
    },
    {
      question: "Is my information on Raizon secure and private?",
      answer:
        "Yes, Raizon takes your privacy and security seriously. We implement measures to protect your personal information and provide you with control over the visibility of your content. You can manage your privacy settings to ensure your data is kept private and secure.",
    },
    {
      question: "How can I contact Raizon's support team?",
      answer:
        "If you have any questions, concerns, or need assistance, you can easily reach out to Raizon's support team. We offer various communication channels, including email and phone support, to ensure that you receive prompt and helpful assistance.",
    },
  ];

  return (
    <div className="pb-24">
      <div className="">
        <div className="mx-auto max-w-7xl py-16 px-6 sm:py-24 lg:px-8">
          <div className="text-center">
            <p className="mt-1 text-3xl font-bold tracking-tight text-blackColor sm:text-4xl lg:text-5xl">
              Frequently Asked Questions
            </p>
            <p className="mx-auto mt-5 max-w-xl text-xl text-blackColor">
              We are available to answer your questions.
            </p>
            <CustomButton
              className={"mt-14"}
              buttonText={"Contact Us"}
              onClick={() => {
                navigate("/support");
              }}
            />
          </div>
        </div>
      </div>
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:py-40 lg:px-8 bg-lightGrey/10 rounded-xl mb-24">
        <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
            Frequently asked questions
          </h2>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">
                          {faq.question}
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <FaMinus className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <FaPlus className="h-6 w-6" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-600">
                        {faq.answer}
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default FAQSection;
