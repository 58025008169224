import AvatarWithTitle from "generalComponents/AvatarWithTitle";
import { formatDate } from "utils/formatDate";

export const validTableKeys = {
  name: {
    title: "Name",
    addBadge: false,
    isImage: false,
    addCopyPreview: false,
    showOnMobile: true,
  },
  email: {
    title: "Email",
    addBadge: false,
    isSortable: false,
    isImage: false,
    addCopyPreview: false,
    showOnMobile: true,
  },
  phone: {
    title: "phone",
    addBadge: false,
    isSortable: false,
    isImage: false,
    addCopyPreview: false,
    showOnMobile: true,
  },
  church: {
    title: "Church",
    addBadge: false,
    isSortable: false,
    isImage: false,
    addCopyPreview: false,
    sortByString: false,
    showOnMobile: true,
  },
  join_date: {
    title: "Join Date",
    addBadge: false,
    isSortable: false,
    isImage: false,
    addCopyPreview: false,
    showOnMobile: true,
  },
};
export function modifyData(data) {
  if (!data.length) return [];
  const newArray = data?.map((item) => ({
    ...item,
    church: item?.campus?.name,
    join_date: formatDate(item?.createdAt),
    name: (
      <AvatarWithTitle
        image={item?.profile_picture}
        title={`${item?.first_name} ${item?.last_name}`}
      />
    ),
  }));

  return newArray;
}
