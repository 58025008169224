import React, { useState } from "react";
import useGetSingleFormManager from "../controllers/getSingleFormController";
import Loader from "../../../../generalComponents/Loader";
import ErrorManager from "../../../../generalComponents/ErrorManager";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import FormDetailTile from "../../../../generalComponents/FormDetailTile";
import separateWords from "../wordSeparator";
import { formatDate } from "utils/formatDate";

const MaritalClassesForm = ({ details }) => {
  const formData = [
    {
      name: "Form Status",
      info: details?.status,
    },
    {
      name: "Applicant's Name",
      info: `${details?.user?.first_name} ${details?.user?.last_name}`,
    },
    {
      name: "Partner's Name",
      info: `${details?.partner?.name || ""}`,
    },
    {
      name: "Partner's Email",
      info: `${details?.partner?.email}`,
    },
    {
      name: "Applicant's Phone No.",
      info: details?.user?.phone,
    },
    {
      name: "Partner's Phone No.",
      info: details?.partner?.phone,
    },
    {
      name: "Applicant's Address",
      info: `${details?.user?.location?.address}, ${details?.user?.location?.city}, ${details?.user?.location?.state}, ${details?.user?.location?.country}`,
    },
    {
      name: "Partner's Address",
      info: details?.partner?.address,
    },
    {
      name: "Applicant's Membership Duration",
      info: details?.member_duration,
    },
    {
      name: "Partner's Membership Duration",
      info: details?.partner?.member_duration,
    },
    {
      name: "Applicant's Born Again Date",
      info: details?.born_again_date,
    },
    {
      name: "Partner's Born Again Date",
      info: details?.partner?.born_again_date,
    },
    {
      name: "Applicant's Courtship Duration Answer",
      info: details?.courtship_duration,
    },
    {
      name: "Partner's Courtship Duration Answer",
      info: details?.partner?.courtship_duration,
    },
    {
      name: "Applicant's Wedding Date Answer",
      info: details?.wedding_date,
    },
    {
      name: "Partner's Wedding Date Answer",
      info: details?.partner?.wedding_date,
    },
    {
      name: "Applicant's Wedding Location Answer",
      info: details?.wedding_location,
    },
    {
      name: "Partner's Wedding Location Answer",
      info: details?.partner?.wedding_location,
    },
    {
      name: "Applicant's Signature",
      info: details?.signature,
    },
    {
      name: "Partner's Signature",
      info: details?.partner?.signature,
    },
    {
      name: "Applicant's Passport",
      info: details?.passport,
    },
    {
      name: "Partner's Passport",
      info: details?.partner?.passport,
    },

    {
      name: "Guarantor",
      info: details?.guarantor,
    },
    {
      name: "Applicant's Partnership Status",
      info: details?.partnership_status,
    },
    {
      name: "Applicant's Partnership Id",
      info: details?.partnership_id,
    },

    {
      name: "Allow Social Media Share",
      info: details?.allow_social_share ? "Yes" : "No",
    },
    {
      name: "Submitted On",
      info: formatDate(details?.createdAt),
    },
    {
      name: "Updated On",
      info: formatDate(details?.updatedAt),
    },
  ];

  return (
    <div>
      <div class="flow-root mt-16 ">
        <dl class="-my-3 divide-y divide-gray-100 text-sm mb-20">
          {formData.map((details, index) => (
            <FormDetailTile details={details} index={index} />
          ))}
        </dl>
      </div>
    </div>
  );
};

export default MaritalClassesForm;
