import { Menu, Transition } from "@headlessui/react";
import { avatar } from "assets/images";
import useLoggedInUser from "constants/loggedInUser";
import { capitalizeFirstLetter } from "constants/textFormatters";
import { forwardRef, Fragment } from "react";
import { Link } from "react-router-dom";
import { useIsAdmin, useUserStore } from "store/userStore";
import { twMerge } from "tailwind-merge";

const MenuItem = forwardRef(
  ({ icon, label, className, logout, ...rest }, ref) =>
    logout ? (
      <button
        ref={ref}
        className={twMerge(
          "flex items-center p-2.5 gap-5 w-full rounded-md",
          logout ? "bg-[#F1070714]" : "hover:bg-textLightGrey",
          className
        )}
        {...rest}
      >
        {icon ? icon : null}

        <span className={twMerge("text-textDark", logout && "text-[#F10707]")}>
          {label}
        </span>
      </button>
    ) : (
      <Link
        className={twMerge(
          "flex items-center p-2.5 gap-5 w-full rounded-md",
          logout ? "bg-[#F1070714]" : "hover:bg-textLightGrey",
          className
        )}
        {...rest}
      >
        {icon ? icon : null}

        <span className={twMerge("text-textDark", logout && "text-[#F10707]")}>
          {label}
        </span>
      </Link>
    )
);

const UserMenu = () => {
  const user = useUserStore((state) => state.user);
  const { logout } = useLoggedInUser();
  const { isAdmin, isSuperAdmin } = useIsAdmin();
  return (
    // <div className="fixed top-16 w-56 text-right">
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="relative grid place-content-center size-[40px]  rounded-full ">
          <div
            className={
              "semi-circle absolute inset-0 size-full m-auto border-[1.5px] rounded-full border-white"
            }
          ></div>
          <div className="size-[32px] overflow-hidden rounded-full">
            <img
              src={user?.profile_picture ?? avatar}
              className="object-contain rounded-full w-full bg-white"
              alt=""
            />
          </div>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-2 w-60 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none p-4">
          <div className="pb-4 flex items-center gap-2 border-b border-[#E4E7EC]">
            <div className="size-10 overflow-hidden rounded-full border-[1.7px] border-purpleBg">
              <img
                src={user?.profile_picture ?? avatar}
                className="object-contain rounded-full w-full"
                alt=""
              />
            </div>

            <div className="flex flex-col ">
              <span className="text-textDark text-sm font-semibold">{`${user?.first_name} ${user?.last_name}`}</span>
              <span className="text-textGrey text-xs">{`${capitalizeFirstLetter(
                user?.roles[0]?.name
              )}: ${user?.campus?.name}`}</span>
            </div>
          </div>
          <div className="border-b-[1px] border-[#E4E7EC] py-2">
            <Menu.Item>
              {({ active }) => (
                <MenuItem
                  to={"/user-profile"}
                  icon={<UserIcon />}
                  label={"Profile Settings"}
                />
              )}
            </Menu.Item>
            {(isAdmin || isSuperAdmin) && (
              <Menu.Item>
                {({ active }) => (
                  <MenuItem
                    to={"/admin/dashboard"}
                    icon={<AdminIcon />}
                    label={"Admin Dashboard"}
                  />
                )}
              </Menu.Item>
            )}
          </div>
          <div className="border-b border-[#E4E7EC] py-2">
            <Menu.Item>
              {({ active }) => (
                <MenuItem
                  onClick={logout}
                  icon={<LogoutIcon />}
                  label={"Logout"}
                  logout
                />
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
    // </div>
  );
};

export default UserMenu;

// ICONS
function UserIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1601 10.87C12.0601 10.86 11.9401 10.86 11.8301 10.87C9.45006 10.79 7.56006 8.84 7.56006 6.44C7.56006 3.99 9.54006 2 12.0001 2C14.4501 2 16.4401 3.99 16.4401 6.44C16.4301 8.84 14.5401 10.79 12.1601 10.87Z"
        stroke="#8311B3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.15997 14.56C4.73997 16.18 4.73997 18.82 7.15997 20.43C9.90997 22.27 14.42 22.27 17.17 20.43C19.59 18.81 19.59 16.17 17.17 14.56C14.43 12.73 9.91997 12.73 7.15997 14.56Z"
        stroke="#8311B3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function AdminIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="#8311B3"
      className="size-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11.42 15.17 17.25 21A2.652 2.652 0 0 0 21 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 1 1-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 0 0 4.486-6.336l-3.276 3.277a3.004 3.004 0 0 1-2.25-2.25l3.276-3.276a4.5 4.5 0 0 0-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437 1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008Z"
      />
    </svg>
  );
}

function LogoutIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.24 22.2705H15.11C10.67 22.2705 8.53002 20.5205 8.16002 16.6005C8.12002 16.1905 8.42002 15.8205 8.84002 15.7805C9.25002 15.7405 9.62002 16.0505 9.66002 16.4605C9.95002 19.6005 11.43 20.7705 15.12 20.7705H15.25C19.32 20.7705 20.76 19.3305 20.76 15.2605V8.74047C20.76 4.67047 19.32 3.23047 15.25 3.23047H15.12C11.41 3.23047 9.93002 4.42047 9.66002 7.62047C9.61002 8.03047 9.27002 8.34047 8.84002 8.30047C8.42002 8.27047 8.12001 7.90047 8.15001 7.49047C8.49001 3.51047 10.64 1.73047 15.11 1.73047H15.24C20.15 1.73047 22.25 3.83047 22.25 8.74047V15.2605C22.25 20.1705 20.15 22.2705 15.24 22.2705Z"
        fill="#F10707"
      />
      <path
        d="M14.88 12.75H2C1.59 12.75 1.25 12.41 1.25 12C1.25 11.59 1.59 11.25 2 11.25H14.88C15.29 11.25 15.63 11.59 15.63 12C15.63 12.41 15.3 12.75 14.88 12.75Z"
        fill="#F10707"
      />
      <path
        d="M12.65 16.0998C12.46 16.0998 12.27 16.0298 12.12 15.8798C11.83 15.5898 11.83 15.1098 12.12 14.8198L14.94 11.9998L12.12 9.17984C11.83 8.88984 11.83 8.40984 12.12 8.11984C12.41 7.82984 12.89 7.82984 13.18 8.11984L16.53 11.4698C16.82 11.7598 16.82 12.2398 16.53 12.5298L13.18 15.8798C13.03 16.0298 12.84 16.0998 12.65 16.0998Z"
        fill="#F10707"
      />
    </svg>
  );
}
