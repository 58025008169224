import React, { forwardRef } from "react";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

export const RoundedButton = forwardRef((props, ref) => {
  const {
    children,
    className,
    url,
    external,
    onClick,
    isLoading,
    ...otherProps
  } = props;
  if (url && external)
    return (
      <a
        href={url}
        target="_blank"
        rel="noreferrer"
        className={twMerge(
          "rounded-3xl px-5 text-sm py-3 text-white  bg-purpleBg w-fit block duration-300 hover:scale-[0.98]",
          otherProps?.disabled &&
          "opacity-55 pointer-events-none cursor-not-allowed",
          className
        )}
        {...otherProps}
      >
        {children}
      </a>
    );

  if (url)
    return (
      <Link
        ref={ref}
        to={url}
        className={twMerge(
          "rounded-3xl px-5 text-sm py-3 text-white  bg-purpleBg w-fit duration-300 hover:scale-[0.98]",
          otherProps?.disabled &&
          "opacity-55 pointer-events-none cursor-not-allowed",
          className
        )}
        {...otherProps}
      >
        {isLoading ? "Loading..." : children}
      </Link>
    );
  return (
    <button
      ref={ref}
      onClick={onClick}
      className={twMerge(
        "rounded-3xl px-5 text-sm py-4.py-3 text-white  bg-purpleBg duration-300 hover:scale-[0.98]",
        otherProps?.disabled &&
        "opacity-55 pointer-events-none cursor-not-allowed",
        className
      )}
      {...otherProps}
    >
      {isLoading ? "Loading..." : children}
    </button>
  );
});

/**
 * 
 * @param {React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>} param
 * @param {boolean} param.isLoading
 * @returns 
 */

const CustomButton = ({
  buttonText,
  textColor,
  className,
  onClick,
  buttonColor,
  radius,
  isLoading,
  type,
  loader,
  ...props
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={props.disabled || isLoading}
      className={`${buttonColor
        ? buttonColor.includes("bg-")
          ? buttonColor
          : `bg-${buttonColor}`
        : `${props.disabled ? 'bg-textGrey' : 'bg-brandPurple'} `
        }
       py-[6px] md:py-[8px] px-[14px] md:px-[25px] font-medium ${props.disabled ? 'hover:bg-textGrey disabled' : 'hover:bg-brandOrange hover:shadow-xl hover:scale-y-105 hover:text-blackColor'} duration-300 text-${!textColor ? "whiteColor" : textColor
        } ${radius ?? "rounded-[6px]"} ${className}`}
      {...props}
    >
      {isLoading ? loader ?? "loading..." : buttonText}
    </button>
  );
};

export default CustomButton;
