import React from "react";
import { AiOutlineSearch } from "react-icons/ai";

const SearchComponent = ({ value, onChange, marginRight }) => {
  return (
    <div
      className={` flex items-center justify-start p-3 h-[40px] ${
        marginRight ?? "mr-[35px]"
      } border text-[#667185] border-[#E4E7EC] bg-whiteColor rounded-[4px] w-full  relative`}
    >
      <AiOutlineSearch size={23} className="mr-2" />
      <input
        className="text-[14px] font-normal text-blackColor bg-transparent outline-none focus:outline-none w-full"
        placeholder="Search here..."
        type="text"
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default SearchComponent;
