import { useQuery } from "react-query";
import AxiosWithToken from "../../../constants/api_management/MyHttpHelperWithToken";

const useGetUserDetailsManager = (enabled = true) => {
  return useQuery(
    ["userDetails"],
    async () => {
      try {
        const [response] = [await AxiosWithToken.get(`/user`)];
        console.log(`i am checking this ${response.data.data.email}`);
        return response.data;
      } catch (error) {
        console.log(error.response.data);
        throw new Error(`Sorry: ${error.response.data.message}`);
      }
    },
    {
      enabled: enabled,
      staleTime: 60000, // Set the time (in milliseconds) before data is considered stale (e.g., 1 minute)
      cacheTime: 3600000,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );
};

export default useGetUserDetailsManager;
