import { useEffect, useLayoutEffect, useState } from "react";
import { TableFooterDiv } from "./TableFooter";
import { AiOutlineLink } from "react-icons/ai";
import { RiArrowUpDownFill } from "react-icons/ri";
import { DataTableHelpers } from "./DataTableHelpers";
import CustomCheckBox from "./CustomCheckbox";

export default function DataTable(props) {
  const {
    tableData,
    validTableKeys,
    onRowClick,
    totalItemCount,
    noOfItemsPerPage,
    onItemsPerPageChange,
    pageIndex,
    onPageIndexChange,
    onCheckClick,
    onPaymentLinkBtnClick,
    showIndex,
    ignoreResponsiveTable,
    hideFooter,
  } = props;

  const [showMobileView, setShowMobileView] = useState(false);
  const [currentData, setCurrentData] = useState(tableData);

  useEffect(() => {
    setCurrentData(tableData);
  }, [tableData]);

  let sortColumn = (key, sortByString = true) => {
    onPageIndexChange(0);
    let newObjArr = DataTableHelpers.sortObjArrByColumn(
      currentData,
      key,
      sortByString
    );
    setCurrentData(newObjArr);
  };

  useLayoutEffect(() => {
    function updateSize() {
      const width = window.innerWidth;
      if (!ignoreResponsiveTable) {
        if (width <= 1024 && !showMobileView) {
          setShowMobileView(true);
        } else if (width > 1024 && showMobileView) {
          setShowMobileView(false);
        }
      }
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, [ignoreResponsiveTable, showMobileView]);

  const noOfPages = Math.ceil(totalItemCount / noOfItemsPerPage);

  return (
    <div className="w-full h-full flex flex-col py-5">
      {!showMobileView ? (
        <div className="overflow-auto h-full">
          <table id="dataTable" className="min-w-full bg-white">
            <thead className="sticky top-0 text-black bg-slate-100 font-medium rounded-lg">
              <tr className="border-b sticky top-0 rounded-lg">
                {currentData && showIndex && <th className="py-2 px-4">#</th>}
                {Object.entries(validTableKeys).map(([key, value]) => {
                  if (value?.isSortable) {
                    return (
                      <th
                        key={key + value}
                        className="py-2 px-4 cursor-pointer"
                        onClick={() => sortColumn(key, value.sortByString)}
                      >
                        {value.title}{" "}
                        <RiArrowUpDownFill className="inline-block ml-2" />
                      </th>
                    );
                  }
                  return (
                    <th key={key + value} className="py-2 px-4">
                      {value.title}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {currentData.map((data, index) => {
                const idx = pageIndex * noOfItemsPerPage + index + 1;
                return (
                  <tr
                    key={index}
                    className="border-b hover:bg-slate-50 cursor-pointer [&>td]:text-center"
                    onClick={() => onRowClick(data)}
                  >
                    {showIndex && <td className="py-2 px-4">{idx}</td>}
                    {Object.entries(validTableKeys).map(([key, value]) => {
                      if (value?.showOnOnlyMobile) {
                        return <td key={key + value} />;
                      }
                      if (value?.isCheckBox) {
                        return (
                          <td key={key + value} className="py-2 px-4">
                            <CustomCheckBox
                              check={data?.[key]}
                              onClick={(e) => {
                                e.stopPropagation();
                                onCheckClick(data);
                              }}
                            />
                          </td>
                        );
                      }
                      if (value?.isPaymentLinkBtn) {
                        if (data?.variants?.length > 0) {
                          return <td key={key + value}></td>;
                        } else {
                          return (
                            <td key={key + value} className="py-2 px-4">
                              <div
                                className="flex items-center cursor-pointer text-blue-600"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  onPaymentLinkBtnClick(data);
                                }}
                              >
                                <AiOutlineLink className="mr-2" />
                                <span>Payment Link</span>
                              </div>
                            </td>
                          );
                        }
                      }
                      if (value?.isImage) {
                        return (
                          <td key={key + value} className="py-2 px-4">
                            <img
                              src={`${data?.[key]}`}
                              alt="squad img"
                              className="w-16 h-16 object-cover"
                            />
                          </td>
                        );
                      }
                      if (value?.showDot) {
                        return (
                          <td key={key + value} className="py-2 px-4">
                            <span
                              className={`inline-block h-2 w-2 rounded-full bg-${data?.[
                                key
                              ]?.toLowerCase()}`}
                            ></span>
                            {data?.[key]}
                          </td>
                        );
                      }
                      if (value?.addBadge) {
                        return (
                          <td key={key + value} className="py-2 px-4">
                            <span className="inline-block bg-blue-100 text-blue-800 text-xs px-2 py-1 rounded">
                              {data?.[key]}
                            </span>
                          </td>
                        );
                      }
                      if (value?.addCopyPreview) {
                        return (
                          <td key={key + value} className="py-2 px-4">
                            {data?.[key]}
                            <button
                              className="ml-2 text-blue-600 hover:underline"
                              onClick={(e) => {
                                e.stopPropagation();
                                DataTableHelpers.copyLink(e, data[key]);
                              }}
                            >
                              copy
                            </button>
                            <button
                              className="ml-2 text-blue-600 hover:underline"
                              onClick={(e) => {
                                e.stopPropagation();
                                DataTableHelpers.previewlink(data[key]);
                              }}
                            >
                              preview
                            </button>
                          </td>
                        );
                      }
                      if (value?.addCopy) {
                        return (
                          <td key={key + value} className="py-2 px-4">
                            {data?.[key]}
                            <button
                              className="ml-2 text-blue-600 hover:underline"
                              onClick={(e) => {
                                e.stopPropagation();
                                DataTableHelpers.copyLink(e, data[key]);
                              }}
                            >
                              copy
                            </button>
                          </td>
                        );
                      }
                      return (
                        <td key={key + value} className="py-2 px-4">
                          {data?.[key]}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="flex flex-col space-y-4">
          {currentData.map((data, index) => {
            // const mobileKeys = Object.fromEntries(
            //   Object.entries(validTableKeys)
            // );
            var keys = Object.keys(validTableKeys);
            const values = keys?.map((mappedKey) => data[mappedKey]);
            // let status = data?.[keys?.[1]];
            // if (mobileKeys?.[keys?.[1]]?.showDot) {
            //   status = (
            //     <>
            //       <span
            //         className={`inline-block h-2 w-2 rounded-full bg-${data?.[
            //           keys?.[1]
            //         ].toLowerCase()} mr-2`}
            //       ></span>
            //       {data?.[keys?.[1]]}
            //     </>
            //   );
            // }

            return (
              <div
                className="bg-white p-4 rounded-lg shadow-md cursor-pointer"
                key={index}
                onClick={() => onRowClick(data)}
              >
                {/* <h2 className="text-lg font-semibold">
                  {data?.[keys?.[0]]}{" "}
                  {data?.icon && (
                    <span className="inline-block ml-2">{data?.icon}</span>
                  )}
                </h2> */}
                <div className="flex flex-col gap-2">
                  {values?.map((item, index) => (
                    <span key={index}>{item}</span>
                  ))}
                  {/* <span>{status}</span>
                  <span>{data?.[keys?.[2]]}</span> */}
                </div>
              </div>
            );
          })}
        </div>
      )}

      {!hideFooter ? (
        <TableFooterDiv
          noOfPages={noOfPages}
          pageIndex={pageIndex}
          onBtnClick={(idx) => onPageIndexChange(idx)}
          itemsPerPage={noOfItemsPerPage}
          itemsPerPageChange={(no) => {
            onPageIndexChange(0);
            onItemsPerPageChange(no);
          }}
        />
      ) : null}
    </div>
  );
}
