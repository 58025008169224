export const capitalizeFirstLetter = (word) =>
  word ? word.charAt(0).toUpperCase() + word.slice(1) : "";

export function abbreviateNumber(num) {
  if (num === 0) return "0";
  if (num >= 1e12) {
    return (num / 1e12).toFixed(1).replace(/\.0$/, "") + "T";
  }
  if (num >= 1e9) {
    return (num / 1e9).toFixed(1).replace(/\.0$/, "") + "B";
  }
  if (num >= 1e6) {
    return (num / 1e6).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1e3) {
    return (num / 1e3).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num.toString();
}
