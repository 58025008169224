import { useQuery } from "react-query";

import AxiosWithToken from "../../../../constants/api_management/MyHttpHelperWithToken";

const useGetSermonListManager = ({
  page = "1",
  searchQuery = "",
  pageSize = "20",
  type = "video",
  category = "",
}) => {
  return useQuery(
    ["allSermons", searchQuery, page, type, category, pageSize],
    async () => {
      try {
        const [response] = [
          await AxiosWithToken.get(
            `/sermon/list?page=${page}&pageSize=${pageSize}&content_type=${type}`,
            {
              params: {
                category: category === "" ? undefined : category,
                title: searchQuery === "" ? undefined : searchQuery,
                // pageSize,
              },
            }
          ),
        ];
        console.log(`i am checking this ${response.data}`);
        return response.data;
      } catch (error) {
        console.log(error.response.data);
        throw new Error(`Sorry: ${error.response.data.message}`);
      }
    }
  );
};

export default useGetSermonListManager;
