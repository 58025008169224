import React from "react";

const FormDetailTile = ({ index, details }) => {
  const isImageLink = (url) => {
    return /\.(jpeg|jpg|gif|png|svg|webp)$/i.test(url);
  };

  return (
    <div
      key={index}
      className="grid grid-cols-1 gap-1 py-3 sm:grid-cols-3 sm:gap-4"
    >
      <dt className="font-medium text-black text-[12px]">{details.name}</dt>
      <dd className="text-gray-700 sm:col-span-2">
        {String(details.info).startsWith("http") ? (
          isImageLink(details.info) ? (
            <img
              src={details.info}
              alt="Detail"
              className="w-full max-w-[232px] object-contain"
            />
          ) : (
            <a href={details.info} target="_blank" rel="noopener noreferrer">
              Click here
            </a>
          )
        ) : (
          details.info
        )}
      </dd>
    </div>
  );
};

export default FormDetailTile;
