import { useQuery } from "react-query";

import AxiosWithToken from "../../../../constants/api_management/MyHttpHelperWithToken";

const useGetGalleryCategoriesManager = ({
  page,
  searchQuery = "",
  enabled = true,
  group = "",
  pageSize = "20",
}) => {
  return useQuery(
    ["galleryCategories"],
    async () => {
      try {
        const [response] = [await AxiosWithToken.get(`/gallery/category/list`)];
        console.log(`i am checking this ${response.data}`);
        return response.data;
      } catch (error) {
        console.log(error.response.data);
        throw new Error(`Sorry: ${error.response.data.message}`);
      }
    },
    { enabled: enabled }
  );
};

export default useGetGalleryCategoriesManager;
