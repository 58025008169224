import useDeleteManager from "constants/controller_templates/delete_controller_template";

export const DeletePraiseReportManager = (testimonyId) => {
  const isMultiKey = false;
  const { deleteCaller, isLoading, isSuccess, error, data } = useDeleteManager(
    `/testimony/${testimonyId}`,
    ["praiseReports"],
    isMultiKey
  );
  const deletePraiseReport = async () => {
    try {
      console.log(testimonyId);
      await deleteCaller();
    } catch (error) {
      // Handle error if necessary
      console.error("delete error:", error);
    }
  };
  return {
    deletePraiseReport,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
