// import React, { useEffect, useState } from "react";
import GiveItem from "./GiveItem";
import {
  ChurchGivingIcon,
  CozaCityIcon,
  FirstFruitIcon,
  PartnershipIcon,
  // ProphetOfferingIcon,
  TitheIcon,
} from "./Icons";
import GiveOptionsModal from "./GiveOptionsModal";
import { useGetCampuses } from "modules/pageChurches/controllers/get_controller";
import { useNavigate } from "react-router-dom";
import { eChurchLinks } from "../giveLinks";
import FirstFruitModal from "./FirstFruitModal";
import PartnershipModal from "./PartnershipModal";
import CozaCityProjectsModal from "./CozaCityProjectsModal";

const GiveOptions = () => {
  const navigate = useNavigate();
  // const [data, setData] = useState();
  const { data: churches } = useGetCampuses();
  const hQ = churches?.data?.filter((church) =>
    church?.name?.includes("Guzape")
  )[0];
  // console.log({ churches, hQ });

  // useEffect(() => {
  //   if (data !== undefined) window["give-options"]?.showModal();
  // }, [data]);

  const options = [
    {
      label: "Tithe and Offering (HQ)",
      onClick: () => window["give-options"]?.showModal(),
      icon: <TitheIcon />,
    },
    {
      label: "Church Givings",
      onClick: () => navigate("/coza-give/church-givings"),
      icon: <ChurchGivingIcon />,
    },
    {
      label: "First Fruit Offering",
      onClick: () => window["first-fruit-option"]?.showModal(),
      icon: <FirstFruitIcon />,
    },
    // {
    //   label: "Prophet Offering",
    //   onClick: () => null,
    //   icon: <ProphetOfferingIcon />,
    // },
    {
      label: "Partnership",
      onClick: () => window["partnership-option"]?.showModal(),
      icon: <PartnershipIcon />,
    },
    {
      label: "COZA City Project",
      onClick: () => window["coza-city-option"]?.showModal(),
      icon: <CozaCityIcon />,
    },
  ];
  return (
    <>
      <div className="grid gap-14 h-full overflow-scroll py-10 px-5">
        {options.map((item, index) => (
          <GiveItem key={index} {...item} />
        ))}
      </div>

      <GiveOptionsModal
        // onClose={() => setData(undefined)}
        id="give-options"
        data={hQ}
        links={{
          USD: eChurchLinks?.dollar,
          GBP: eChurchLinks?.pounds,
        }}
      />
      <FirstFruitModal
        // onClose={() => setData(undefined)}
        id="first-fruit-option"
        // data={hQ}
        // links={{
        //   USD: eChurchLinks?.dollar,
        //   GBP: eChurchLinks?.pounds,
        // }}
      />
      <PartnershipModal
        // onClose={() => setData(undefined)}
        id="partnership-option"
        // data={hQ}
        // links={{
        //   USD: eChurchLinks?.dollar,
        //   GBP: eChurchLinks?.pounds,
        // }}
      />
      <CozaCityProjectsModal
        // onClose={() => setData(undefined)}
        id="coza-city-option"
        // data={hQ}
        // links={{
        //   USD: eChurchLinks?.dollar,
        //   GBP: eChurchLinks?.pounds,
        // }}
      />
    </>
  );
};

export default GiveOptions;
