import React from "react";

const FileInput = ({
  name,
  value,
  onChange,
  label,
  className,
  labelClass,
  otherProps,
  allowedFileTypes,
  required,
  src = "",
}) => {
  return (
    <div className="flex flex-col items-center gap-1 max-w-[100px]">
      <div className="size-20 rounded bg-[#FFF3DF] grid place-content-center relative overflow-hidden">
        <input
          type="file"
          className="size-full absolute z-1 opacity-0 cursor-pointer"
          // value={value}
          onChange={onChange}
          accept={
            allowedFileTypes
              ? allowedFileTypes
              : "image/png, image/jpeg, image/jpg"
          }
          required={required}
        />
        {src && src !== "" ? (
          <img src={src} alt="" className="object-fit" />
        ) : (
          <svg
            width="23"
            height="24"
            viewBox="0 0 23 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.375 1.71484V22.4648"
              stroke="#F28500"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M1 12.0898L21.75 12.0898"
              stroke="#F28500"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        )}
      </div>

      <label
        className={`text-sm font-medium text-center ${labelClass}`}
        htmlFor={name}
      >
        {label}
      </label>

      {value ? (
        <span className="text-xs text-purpleOverlay max-w-full truncate">
          {value?.name}
        </span>
      ) : null}
    </div>
  );
};

export default FileInput;
