import { FilterIcon } from "@heroicons/react/outline";
import PopoverMenu from "generalComponents/PopoverMenu";
import SelectInput from "generalComponents/SelectInput";
import React, { useMemo, useState } from "react";
import { MdClear } from "react-icons/md";

const Pill = ({ name, clear }) => {
  return (
    <button
      onClick={clear}
      className="outline-none border-none flex items-center gap-3 bg-purpleBg/5 text-purpleOverlay p-2 px-4 rounded-full"
    >
      {name} <MdClear />
    </button>
  );
};

const Filter = ({ churches, filterValues, setFilterValues }) => {
  const [filters, setFilters] = useState({});
  const handleChange = (key, value) => {
    setFilters((vals) => ({
      ...vals,
      [key]: value,
    }));
  };

  const churchList = useMemo(() => {
    const array = churches?.data?.map((item) => ({
      label: item?.name,
      value: item?.id,
    }));
    return array;
  }, [churches]);

  const clearFilter = () => {
    setFilters({});
    setFilterValues({});
  };

  const filterList = useMemo(() => {
    const array = Object.keys(filterValues)?.map((item) => ({
      name:
        item === "campus"
          ? churchList?.filter(
              (church) => church?.value === filterValues[item]
            )[0]?.label
          : filterValues[item],
      key: item,
    }));
    return array;
  }, [churchList, filterValues]);
  return (
    <div className="flex flex-col items-start md:flex-row md:items-center gap-5">
      <PopoverMenu
        title={
          <div className="flex gap-1 items-center">
            <FilterIcon className="w-5 h-5" /> Filter By
          </div>
        }
        position={"right"}
      >
        <div className="flex flex-col gap-1 mb-3">
          <label htmlFor="">Date</label>
          <input
            type="date"
            value={filters?.createdAt}
            onChange={(e) => handleChange("createdAt", e.target?.value)}
            className="outline-none bg-white rounded-md border border-[#D0D5DD] p-2 focus:border-purpleBg duration-200"
          />
        </div>
        <SelectInput
          name="campus"
          placeholder={"Select church"}
          label={"Church"}
          value={filters?.campus}
          onChange={(e) => handleChange("campus", e.target?.value)}
          options={churchList}
          className={"p-2"}
        />
        <button
          onClick={() => setFilterValues(filters)}
          className="w-full bg-purpleBg text-white rounded-md hover:brightness-105 mt-3 p-2"
        >
          Filter
        </button>
      </PopoverMenu>

      {filterList?.length > 0 && (
        <div className="flex items-center gap-3">
          {filterList?.map((item, index) => {
            if (!filterValues[item?.key] || filterValues[item?.key] === "")
              return null;
            return (
              <Pill
                key={index}
                name={item?.name}
                clear={() => {
                  setFilters((vals) => ({
                    ...vals,
                    [item?.key]: undefined,
                  }));
                  setFilterValues((vals) => ({
                    ...vals,
                    [item?.key]: undefined,
                  }));
                }}
              />
            );
          })}

          {/* <Pill name={"Clear All"} clear={clearFilter} /> */}
          <button
            onClick={clearFilter}
            className="border-none outline-none text-purpleOverlay underline"
          >
            Clear All
          </button>
        </div>
      )}
    </div>
  );
};

export default Filter;
