import ModalManagement from "generalComponents/ModalManagement";
import React, { useEffect, useRef, useState } from "react";
import useCreateGalleryManager, {
  CreateGalleryManager,
} from "../controller/createGalleryController";
import useEditGalleryManager, {
  EditGalleryManager,
} from "../controller/editGalleryController";
import useFileUpload from "modules/fileupload/fileUploadController";
import InputWithFullBoarder from "generalComponents/InputWithFullBoarder";
import CustomDropdown from "generalComponents/CustomDropdown";
import BigUploadFileComponent from "generalComponents/BigUploadFileComponent";
import UploadFileComponent from "generalComponents/UploadFileComponent";
import { convertBytesToMB } from "utils/fileSize";
import CustomButton from "generalComponents/Button";

const AddEditGallery = ({ details, categories }) => {
  const { createGallery, isLoading } = CreateGalleryManager();
  const { editGallery, isLoading: editing } = EditGalleryManager(details?.id);
  const [mediaFile, setMediaFile] = useState(null);
  const [images, setImages] = useState([]);
  const mediaRef = useRef();
  const imageRef = useRef();
  const [uploadingImages, setUploadingImages] = useState(false);

  const [uploadingMedia, setUploadingMedia] = useState(false);
  const {
    progress,
    handleFileUpload,
    isLoading: fileLoading,
  } = useFileUpload();
  const initialData = {
    title: "",
    description: "",
    date: "",
    cover_image: {
      thumbnail: "",
      url: "",
    },
    images: [
      {
        thumbnail: "",
        url: "",
      },
    ],
    categories: [],
  };

  const [formData, setFormData] = useState(initialData);

  useEffect(() => {
    if (details) {
      setFormData({ ...formData, ...details });
    } else {
      setFormData(initialData);
    }
  }, [details]);

  return (
    <ModalManagement id={"add_gallery"} title={"Gallery"}>
      <div className="w-full flex flex-col md:w-[500px]">
        <div className="flex items-center justify-between mb-4 ">
          <p className="text-18px font-bold">
            {details ? "Edit Gallery" : "Create Gallery"}
          </p>
        </div>
        <BigUploadFileComponent
          buttonText={"Upload Cover"}
          inputRef={mediaRef}
          isLoading={uploadingMedia}
          image={
            mediaFile
              ? URL.createObjectURL(mediaFile)
              : details && formData?.cover_image?.thumbnail
          }
          progress={progress}
          accept={"image/*"}
          buttonClick={() => setMediaFile(null)}
          onChange={async (e) => {
            const file = e.target.files[0];
            setMediaFile(file);
          }}
        />
        <InputWithFullBoarder
          label={"Title"}
          className={"mb-4"}
          placeholder={"Enter title"}
          value={formData.title}
          onChange={(e) => setFormData({ ...formData, title: e.target.value })}
        />
        <InputWithFullBoarder
          label={"Event Date"}
          className={"mb-4"}
          placeholder={"Enter date"}
          type={"date"}
          value={formData.date}
          onChange={(e) => setFormData({ ...formData, date: e.target.value })}
        />

        <InputWithFullBoarder
          label={"Description"}
          isTextArea={true}
          className={"mb-4"}
          value={formData.description}
          onChange={(e) =>
            setFormData({ ...formData, description: e.target.value })
          }
        />
        <div className="mb-4 w-full flex flex-col">
          <label className="text-sm font-medium mb-2" htmlFor={"content_type"}>
            {"Category"}
          </label>
          <CustomDropdown
            placeholder={"Select Category"}
            isFirstStyle={false}
            options={categories && categories.map((el) => el?.name)}
            value={formData.categories[0]}
            onChange={(e) => {
              const categoryId = categories.find(
                (cat) => cat?.name === e.target.value
              ).id;
              setFormData({
                ...formData,
                categories: [categoryId],
              });
            }}
          />
        </div>

        <UploadFileComponent
          description={`Upload Images`}
          inputRef={imageRef}
          isLoading={uploadingImages}
          format={"Images"}
          multiple={true}
          files={images}
          maxSize={mediaFile ? convertBytesToMB(mediaFile.size) : `20`}
          fileName={images ? images.name : null}
          progress={progress}
          accept={"image/*"}
          buttonClick={() => setImages([])}
          onChange={async (e) => {
            if (e.target.files.length > 0) {
              setImages(e.target.files);
            }
          }}
        />

        <CustomButton
          buttonText={details ? "Edit Gallery" : "Create Gallery"}
          isLoading={uploadingImages || uploadingMedia || editing || isLoading}
          loader={
            uploadingMedia
              ? `Cover Image ${progress}%`
              : uploadingImages
              ? `Gallery Images ${progress}%`
              : "Loading..."
          }
          onClick={async () => {
            const updatedFormData = { ...formData };
            if (mediaFile) {
              setUploadingMedia(true);
              const mediaUrl = await handleFileUpload(mediaFile);
              updatedFormData.cover_image = {
                url: mediaUrl,
                thumbnail: mediaUrl,
              };
              setUploadingMedia(false);
            }
            if (images.length > 0) {
              const fileUrls = [];
              setUploadingImages(true);
              for (let i = 0; i < images.length; i++) {
                const url = await handleFileUpload(images[i]);
                fileUrls.push({
                  url: url,
                  thumbnail: url,
                });
              }
              updatedFormData.images = fileUrls;
              setUploadingImages(false);
            }
            if (details) {
              await editGallery(updatedFormData);
            } else {
              await createGallery(updatedFormData);
            }
            setFormData(initialData);
            document.getElementById("add_gallery").close();
          }}
        />
      </div>
    </ModalManagement>
  );
};

export default AddEditGallery;
