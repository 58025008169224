import React from "react";

export const ImageUploadIcon = () => {
  return (
    <svg
      width="43"
      height="43"
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="21.14" cy="21.14" r="21.14" fill="#FFF0FE" />
      <g clipPath="url(#clip0_839_17998)">
        <path
          d="M28.5625 14.8125V14.125C28.5609 13.5785 28.3431 13.0548 27.9567 12.6683C27.5702 12.2819 27.0465 12.0641 26.5 12.0625H12.75C12.2035 12.0641 11.6798 12.2819 11.2933 12.6683C10.9069 13.0548 10.6891 13.5785 10.6875 14.125V25.125C10.6891 25.6715 10.9069 26.1952 11.2933 26.5817C11.6798 26.9681 12.2035 27.1859 12.75 27.1875H13.4375"
          stroke="#2F0640"
          strokeWidth="1.62577"
          strokeLinejoin="round"
        />
        <path
          d="M29.3364 15.5H16.1011C15.0097 15.5 14.125 16.3847 14.125 17.4761V27.9614C14.125 29.0528 15.0097 29.9375 16.1011 29.9375H29.3364C30.4278 29.9375 31.3125 29.0528 31.3125 27.9614V17.4761C31.3125 16.3847 30.4278 15.5 29.3364 15.5Z"
          stroke="#2F0640"
          strokeWidth="1.62577"
          strokeLinejoin="round"
        />
        <path
          d="M26.0238 20.7504C26.754 20.7504 27.346 20.1627 27.346 19.4377C27.346 18.7127 26.754 18.125 26.0238 18.125C25.2936 18.125 24.7017 18.7127 24.7017 19.4377C24.7017 20.1627 25.2936 20.7504 26.0238 20.7504Z"
          stroke="#2F0640"
          strokeWidth="1.62577"
          strokeMiterlimit="10"
        />
        <path
          d="M24.7018 25.9912L20.957 22.2791C20.7176 22.0426 20.3974 21.9057 20.0611 21.8961C19.7247 21.8864 19.3972 22.0047 19.1446 22.2271L14.125 26.6559"
          stroke="#2F0640"
          strokeWidth="1.62577"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.397 29.9375L26.4926 24.8787C26.7271 24.6475 27.039 24.5113 27.3679 24.4965C27.6969 24.4816 28.0198 24.5892 28.2741 24.7984L31.3129 27.3125"
          stroke="#2F0640"
          strokeWidth="1.62577"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_839_17998">
          <rect
            width="22"
            height="22"
            fill="white"
            transform="translate(10 10)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const TagIcon = () => (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.79119 15.2173C3.37471 13.8008 2.66646 13.0925 2.40293 12.1736C2.13939 11.2548 2.36461 10.2788 2.81505 8.32685L3.07482 7.20122C3.45377 5.55907 3.64326 4.73798 4.20551 4.17572C4.76776 3.61348 5.58885 3.42399 7.23101 3.04503L8.35664 2.78527C10.3086 2.33483 11.2845 2.1096 12.2034 2.37314C13.1223 2.63668 13.8306 3.34492 15.247 4.76141L16.9239 6.43828C19.3884 8.90275 20.6206 10.135 20.6206 11.6662C20.6206 13.1974 19.3884 14.4296 16.9239 16.8941C14.4594 19.3586 13.2272 20.5908 11.696 20.5908C10.1648 20.5908 8.93253 19.3586 6.46806 16.8941L4.79119 15.2173Z"
      stroke="#2F0640"
      strokeWidth="1.62577"
    />
    <path
      d="M9.64279 9.86156C10.3586 9.14573 10.3586 7.98513 9.64279 7.2693C8.92696 6.55346 7.76636 6.55346 7.05053 7.2693C6.33469 7.98513 6.33469 9.14573 7.05053 9.86156C7.76636 10.5774 8.92696 10.5774 9.64279 9.86156Z"
      stroke="#2F0640"
      strokeWidth="1.62577"
    />
    <path
      d="M11.0361 17.3828L17.4325 10.9863"
      stroke="#2F0640"
      strokeWidth="1.62577"
      strokeLinecap="round"
    />
  </svg>
);
