import React, { useMemo } from 'react';

export const ProgressBar = ({ progress }) => {
    // Ensuring that progress is between 0 and 100
    const percentage = useMemo(() => Math.min(Math.max(progress, 0), 100), [progress]);

    return (
        <div className="w-full bg-gray-200 rounded-full h-2 mt-4 relative">
            <div
                className="h-full rounded-full transition-all relative"
                style={{
                    width: `${percentage}%`,
                    backgroundColor: percentage === 100 ? '#4CAF50' : '#4D90FE', // Green when complete, blue while progressing
                    transition: 'width 0.3s ease-out',
                }}
            />
        </div>
    );
};

