import React, { useEffect, useState } from "react";

import InputWithFullBoarder from "../../../generalComponents/InputWithFullBoarder";
import { Link, useNavigate } from "react-router-dom";
import AuthenticationBase from "../components/AuthenticationBase";
import useSignupManager from "../controllers/signup_controller";
import { toast } from "react-toastify";

const CreateAccountPage = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [passwordError, setPasswordError] = useState(false);

  const { postCaller, data, isSuccess, isLoading, error } = useSignupManager(
    formData.email
  );

  useEffect(() => {
    if (isSuccess) {
      toast.success(data.message);
    }

    if (error) {
      toast.error(error.message);
    }
  }, [isSuccess, data, error]);

  const handleSubmission = async (e) => {
    e.preventDefault();
    await postCaller(formData);

    setFormData({
      first_name: "",
      last_name: "",
      username: "",
      email: "",
      password: "",
    });
  };

  useEffect(() => {
    if (
      formData.password !== "" &&
      formData.confirmPassword !== "" &&
      formData.confirmPassword !== formData.password
    ) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
  }, [formData.confirmPassword, formData.password]);

  return (
    <AuthenticationBase
      title={"Create Account"}
      subtitle={
        <>
          Already have an account?{" "}
          <Link to={"/login"} className="text-purpleBg">
            Login
          </Link>
        </>
      }
      inputFields={
        <div>
          <div className="grid grid-cols-2 gap-4">
            <InputWithFullBoarder
              label={"First Name"}
              type="text"
              id="firstname"
              value={formData.first_name}
              onChange={(e) =>
                setFormData({ ...formData, first_name: e.target.value })
              }
            />
            <InputWithFullBoarder
              label={"Last Name"}
              type="text"
              id="lastname"
              value={formData.last_name}
              onChange={(e) =>
                setFormData({ ...formData, last_name: e.target.value })
              }
            />
          </div>
          <InputWithFullBoarder
            label={"User Name"}
            type="text"
            id="usename"
            value={formData.username}
            onChange={(e) =>
              setFormData({ ...formData, username: e.target.value })
            }
          />
          <InputWithFullBoarder
            label={"Email address"}
            type="text"
            id="email"
            value={formData.email}
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
          />
          <InputWithFullBoarder
            label={"Password"}
            type={"password"}
            id="password"
            value={formData.password}
            onChange={(e) =>
              setFormData({ ...formData, password: e.target.value })
            }
          />
          <div className="relative">
            <InputWithFullBoarder
              label={"Confirm Password"}
              type={"password"}
              id="confirmPassword"
              value={formData.confirmPassword}
              onChange={(e) =>
                setFormData({ ...formData, confirmPassword: e.target.value })
              }
            />
            {passwordError && (
              <span className="text-xs text-red-500 absolute -bottom-5 left-0">
                Password does not match
              </span>
            )}
          </div>

          <div className="mt-8 flex gap-2 items-start">
            <input
              type="checkbox"
              className="accent-purpleBg text-white indeterminate:fill-white"
            />{" "}
            <label htmlFor="" className=" text-xs">
              I understand and agree to{" "}
              <Link
                to={"/terms-and-condition"}
                className="text-purpleBg underline inline"
              >
                Terms and Conditions
              </Link>{" "}
              and{" "}
              <Link
                to={"/privacy-policy"}
                className="text-purpleBg underline inline"
              >
                Privacy Policy
              </Link>
            </label>
          </div>
        </div>
      }
      buttonText={"Sign up"}
      onClick={handleSubmission}
      isLoading={isLoading}
      // afterElements={
      //   <div>
      //     <p className="text-[14px] md:text-[20px] font-semibold py-5 text-blackColor text-center">
      //       Already have an account?
      //       <Link to={"/login"}>
      //         <span className="hover:text-blackColor/60 hover:scale-110 duration-300 underline md:ml-3 text-blackColor">
      //           {" "}
      //           Login
      //         </span>
      //       </Link>
      //     </p>
      //   </div>
      // }
    />
  );
};

export default CreateAccountPage;
