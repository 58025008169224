import InputWithFullBoarder from "generalComponents/InputWithFullBoarder";
import ModalManagement from "generalComponents/CustomModal";
import React, { useState } from "react";
import { ImageUploadIcon, TagIcon } from "./Icons";
import useGetCategories from "modules/pageSermons/controllers/get_categories_controller";
import SwitchInput from "generalComponents/SwitchInput";
import useFileUpload from "modules/fileupload/fileUploadController";
import { format } from "date-fns";
import usePostPraiseReport from "../controllers/post_controller";
import { Spinner } from "generalComponents/Loader";

const CreatePraiseReport = ({ id, title }) => {
  const [anonymous, setAnonymous] = useState(false);
  const [file, setFile] = useState(null);
  const [mediaType, setMediaType] = useState();
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    category: "",
  });
  const handleChange = (e, name) => {
    const value = e.target?.value;
    setFormData((val) => ({
      ...val,
      [name]: value,
    }));
  };

  const handleFileChange = (e, name) => {
    setFile(e.target.files[0]);
    if (e.target?.files[0]?.type?.includes("image")) {
      setMediaType("image");
    } else if (e.target?.files[0]?.type?.includes("video")) {
      setMediaType("video");
    }
  };

  const { data } = useGetCategories({
    group: "testimony",
  });

  const {
    // error: fileUploadError,
    handleFileUpload: uploadFile,
    isLoading: isUploadingFlie,
    // data: uploadUrl,
  } = useFileUpload();

  const { postCaller, isLoading } = usePostPraiseReport({
    onSuccess: () => window[id]?.close(),
  });

  async function handleFileUpload() {
    try {
      const url = await uploadFile(file);
      return url;
    } catch (error) {
      return error;
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (file) {
      const url = await handleFileUpload();
      const body = {
        title: formData?.title,
        description: formData?.description,
        category: formData?.category,
        date: format(new Date(), "yyyy-MM-dd"),
        anonymous,
        imageUrl: mediaType === "image" ? url : "",
        mediaType: mediaType ? mediaType : "",
        mediaUrl: mediaType === "video" ? url : "",
      };
      postCaller(body);
    } else {
      const body = {
        title: formData?.title,
        description: formData?.description,
        category: formData?.category,
        date: format(new Date(), "yyyy-MM-dd"),
        mediaType: "",
        anonymous,
      };
      postCaller(body);
    }
  };
  return (
    <ModalManagement id={id} title={"Share what God has done for you"}>
      <form onSubmit={handleSubmit} className="w-full">
        <SwitchInput
          label={"Upload praise report as anonymous"}
          checked={anonymous}
          setChecked={setAnonymous}
        />
        <InputWithFullBoarder
          id="title"
          label="Title"
          type={"text"}
          value={formData?.title}
          placeholder={"Enter title"}
          onChange={(e) => handleChange(e, "title")}
          className={"bg-[#F2F2F2] border-none text-purpleOverlay px-5"}
          otherProps={{
            required: true,
          }}
        />
        <InputWithFullBoarder
          isTextArea
          id="description"
          label="Description"
          type={"text"}
          value={formData?.description}
          placeholder={"Describe what God did for you"}
          onChange={(e) => handleChange(e, "description")}
          className={"bg-[#F2F2F2] border-none text-purpleOverlay px-5"}
          otherProps={{
            required: true,
          }}
        />

        <div className="flex items-center justify-between">
          <div className="flex items-center gap-1">
            <div className="size-fit relative">
              <input
                className="opacity-0 absolute z-1 cursor-pointer size-full"
                type="file"
                accept="image/png, image/jpg, imagejpeg, video/*"
                onChange={handleFileChange}
                // value={file}
              />
              <ImageUploadIcon />
            </div>
            <div className="h-[42px] relative flex items-center">
              <div className="size-[42px] bg-[#FFE0D2] rounded-full grid place-content-center relative z-[2]">
                <TagIcon />
              </div>
              <select
                name=""
                id=""
                value={formData?.category}
                onChange={(e) => handleChange(e, "category")}
                className="text-xs h-full bg-[#F2F2F2] text-purpleOverlay px-5 min-w-[90px] -translate-x-4 rounded-r-full outline-none"
                required
              >
                <option value="" disabled>
                  Select category
                </option>
                {data?.data?.categories?.length &&
                  data?.data?.categories?.map((item, index) => (
                    <option key={index} value={item?.id}>
                      {item?.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <button
            type="submit"
            className="bg-purpleOverlay text-white rounded-full px-5 py-2.5 text-xs grid place-content-center"
          >
            {isLoading || isUploadingFlie ? <Spinner /> : "Submit"}
          </button>
        </div>
      </form>
    </ModalManagement>
  );
};

export default CreatePraiseReport;
