import React, { useEffect, useState } from "react";

// import BaseDashboardNavigation from "../../../../generalComponents/BaseDashboardNavigation";

import AdminTopPage from "generalComponents/AdminTopPage";
import StatusButton from "generalComponents/StatusButton";
import AddEditNotificationModal from "../component/AddEditNotificationModal";
import useGetBroadcastsManager from "../controller/getBroadcastsController";
import useDebounce from "utils/UseDebounce";
import Loader from "generalComponents/Loader";
import AdminWrapper from "modules/admin/AdminWrapper";

const BroadcastPage = () => {
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(`&title=${searchValue}`, 1000);
  const [currentPage, setCurrentPage] = useState(1);
  const [selected, setSelected] = useState(null);

  const {
    data: broadcasts,

    isLoading,
  } = useGetBroadcastsManager({
    page: currentPage,
    searchQuery: searchValue ? debouncedSearchValue : "",
    enabled: true,
  });

  useEffect(() => {
    if (selected) {
      document.getElementById("add_notification").showModal();
    }
  }, [selected]);

  return (
    <AdminWrapper>
      <AdminTopPage
        subtitle={"30 broadcasts in total"}
        title={"Broadcast"}
        buttonText={"Create Broadcast"}
        onclickButton={() => {
          setSelected(null);
          document.getElementById("add_notification").showModal();
        }}
      />
      <div className="p-4 md:p-5 flex flex-col lg:flex-row items-center lg:items-start  ">
        <div class="grid grid-cols-1 gap-6  sm:grid-cols-2 lg:h-[75vh] overflow-y-auto w-full scrollbar-hide  lg:grid-cols-3 2xl:grid-cols-4 lg:mr-10">
          {isLoading ? (
            <Loader />
          ) : (
            broadcasts?.data.map((el, index) => (
              <div
                onClick={() => {
                  setSelected(el);
                  if (selected?.id === el?.id) {
                    document.getElementById("add_notification").showModal();
                  }
                }}
                className="rounded-[16px] border p-5 cursor-pointer"
              >
                <div className="flex items-center justify-between">
                  <p className="text-16px text-[#131313]">{el?.title}</p>
                  <StatusButton status={"General"} />
                </div>
                <p className="text-13px text-[#636E72] font-thin">{el?.body}</p>
              </div>
            ))
          )}
        </div>
      </div>
      <AddEditNotificationModal details={selected} />
    </AdminWrapper>
  );
};

export default BroadcastPage;
