import { useMutation, useQueryClient } from "react-query";

import { toast } from "react-toastify";
import AxiosWithToken from "../../../../constants/api_management/MyHttpHelperWithToken";

const useRemoveUserPermissionManager = () => {
  const queryClient = useQueryClient();
  const updateController = async (details) => {
    try {
      const [response] = [
        await AxiosWithToken.put(`/permissions/remove`, details),
      ];
      console.log(`i am checking this ${response.status}`);
      return response.data;
    } catch (error) {
      console.log(error.response.data);
      throw new Error(`Sorry: ${error.response.data.message}`);
    }
  };

  const mutation = useMutation(updateController, {
    onSuccess: async (data) => {
      toast.error(data.message);
      // Update other caches using useQuery
      const updateQueryKeys = ["singleUser", "allUsers"];
      if (updateQueryKeys.length) {
        updateQueryKeys.forEach((key) => queryClient.invalidateQueries(key));
      }
    },
    onError: (error) => {
      toast.error(error.message);
      // Handle error if necessary
      console.error("Update error:", error);
      throw new Error(`Sorry: ${error.response.data.message}`);
    },
  });

  const updateCaller = async (details) => {
    try {
      await mutation.mutateAsync(details);
    } catch (error) {
      // Handle error if necessary
      console.error("Update error:", error);
    }
  };

  return {
    updateCaller,
    data: mutation.data,
    isLoading: mutation.isLoading,
    isSuccess: mutation.isSuccess,
    error: mutation.error,
  };
};

export default useRemoveUserPermissionManager;
