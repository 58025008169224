import React, { useEffect, useState } from "react";
import useMobile from "./hooks/useMobile";
import Sidebar from "./Sidebar";
import { RiMenu2Fill } from "react-icons/ri";
import Header from "./Header";

const AdminWrapper = ({ children, onChange, value, hideSearch }) => {
  const [menuOpen, setMenuOpen] = useState(true);
  const showMenu = () => setMenuOpen(true);
  const hideMenu = () => setMenuOpen(false);
  const toggleMenu = () => setMenuOpen((val) => !val);
  const isMobile = useMobile();

  useEffect(() => {
    if (isMobile) {
      setMenuOpen(false);
    } else {
      setMenuOpen(true);
    }
  }, [isMobile]);
  return (
    <div className="flex h-[100dvh] w-[100dvw] bg-purpleOverlay p-1 overflow-hidden">
      <Sidebar {...{ isMobile, menuOpen, showMenu, hideMenu, toggleMenu }} />
      <div className="flex-1 h-full overflow-auto bg-white rounded-3xl">
        <div className="w-full border-b border-[#F0F2F5] py-3 px-7 flex items-center gap-10">
          {isMobile && (
            <button className="text-purpleOverlay" onClick={toggleMenu}>
              <RiMenu2Fill className="fill-purpleOverlay size-7" />
            </button>
          )}

          <Header {...{ onChange, value, hideSearch }} />
        </div>
        <div className="max-w-full overflow-auto p-7 text-black">
          {children}
        </div>
      </div>
    </div>
  );
};

export default AdminWrapper;
