import HeaderFooter from "generalComponents/HeaderFooter";
import TextGradient from "generalComponents/TextGradient";
import SectionNav from "generalComponents/SectionNav";
import { sectionList } from "../constants";
import { useParams } from "react-router-dom";
import MyLibrary from "./MyLibrary";
import Notes from "./Notes";

const Library = () => {
  const params = useParams();

  const sections = {
    "my-library": <MyLibrary />,
    notes: <Notes />,
  };
  return (
    <HeaderFooter solid>
      <TextGradient>E-Library</TextGradient>
      <div className="pt-[300px] max-w-section mx-auto">
        <h1 className="font-semibold text-[52px] lg:text-[60px] lg:leading-[70px] text-purpleOverlay px-5">
          E-Library
        </h1>

        <div className="max-w-section mx-auto pt-5 pb-20 px-5">
          <SectionNav list={sectionList} />
          {sections[params?.section]}
        </div>
      </div>
    </HeaderFooter>
  );
};

export default Library;
