import React, { useEffect, useState } from "react";
import InputWithFullBoarder from "../../../../generalComponents/InputWithFullBoarder";
import CustomButton from "../../../../generalComponents/Button";
import { EditPaymentLinKManager } from "../controller/editPaymentLinkController";

import ModalManagement from "generalComponents/ModalManagement";
import { CreatePaymentLinkManager } from "../controller/createPaymentLinkController";
import CustomDropdown from "generalComponents/CustomDropdown";

const AddEditPaymentLinkComponent = ({ details, onClick, campusList }) => {
  const { createLink, isLoading } = CreatePaymentLinkManager();
  const { editLink, isLoading: editing } = EditPaymentLinKManager(details?._id);

  const initialData = {
    campus: "",
    title: "",
    link: "",
    vendor: "",
  };
  const [paymentDetails, setPaymentDetails] = useState(initialData);
  useEffect(() => {
    if (details) {
      setPaymentDetails({
        ...paymentDetails,
        campus: details?.campus?.id,
        title: details?.title,
        link: details?.link,
        vendor: details?.vendor,
        // _id: details?.id,
      });
    } else {
      setPaymentDetails(details);
    }
  }, [details]);
  function getCampusNameById() {
    if (details) {
      const campus = campusList.find(
        (campus) => campus.id === details?.campus?.id
      );
      return campus ? campus.name : "Campus Not Found";
    }
  }

  const handleSubmit = async () => {
    console.log(paymentDetails);
    if (details) {
      await editLink(paymentDetails);
    } else {
      await createLink(paymentDetails);
    }
    document.getElementById("add_paymentLink").close();
  };
  return (
    <ModalManagement title={"Manage Payment Link"} id="add_paymentLink">
      <div className="rounded-[20px] max-w-full  p-5 bg-white py-10 md:w-[500px]">
        <div
          onClick={onClick}
          className="text-[40px] mb-10 w-full justify-between items-center flex"
        >
          <p className="text-18px font-bold w-[80%]">{`Edit Payment (${getCampusNameById()})`}</p>
        </div>

        <div className="w-full mb-3">
          <p className="text-13px md:text-16px font-semibold mb-2   ">
            Change Campus
          </p>
          <CustomDropdown
            options={campusList && campusList.map((el, i) => el?.name)}
            value={paymentDetails?.campus}
            placeholder={"Select Campus"}
            onChange={(e) => {
              const campusId = campusList.find(
                (campus) => campus.name === e.target.value
              ).id;
              setPaymentDetails({
                ...paymentDetails,
                campus: campusId,
              });
            }}
          />
        </div>

        <InputWithFullBoarder
          label={"Bank Name"}
          placeholder={"Enter bank name"}
          className={"mb-4 w-[90%]"}
          type={"text"}
          value={paymentDetails?.vendor}
          onChange={(e) =>
            setPaymentDetails({
              ...paymentDetails,
              vendor: e.target.value,
            })
          }
        />

        <InputWithFullBoarder
          label={"Account Number"}
          placeholder={"Enter account number"}
          className={"mb-4"}
          type={"number"}
          value={paymentDetails?.link}
          onChange={(e) =>
            setPaymentDetails({
              ...paymentDetails,
              link: e.target.value,
            })
          }
        />

        <div className="flex items-center">
          <div className="flex w-full">
            <div className="w-full">
              <InputWithFullBoarder
                label={"Account Name"}
                placeholder={"Enter account name"}
                type={"text"}
                className={"mb-4 w-[90%]"}
                value={paymentDetails?.title}
                onChange={(e) =>
                  setPaymentDetails({
                    ...paymentDetails,
                    title: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>

        <CustomButton
          buttonText={details ? "Edit Payment" : "Create Payment"}
          onClick={handleSubmit}
          isLoading={isLoading || editing}
        />
      </div>
    </ModalManagement>
  );
};

export default AddEditPaymentLinkComponent;
