// import useWindowInactivity from "@toluade/use-window-inactivity";
import AudioControls from "modules/AudioProvider/components/AudioControls";
import AudioProgress from "modules/AudioProvider/components/AudioProgress";
import { useAudioContext } from "modules/AudioProvider/view";
import { Link, useParams } from "react-router-dom";
import SermonDisc from "./SermonDisc";
import AudioDiskWrapper from "./AudioDiskWrapper";
import { useGetSermonById } from "modules/pageSermons/controllers/get_sermons_contoller";
import Loader from "./Loader";
import { useEffect } from "react";
import HeaderFooter from "./HeaderFooter";

const AudioPlayer = ({ id }) => {
  const { currentAudio, setCurrentAudio, setIsAudioPage } = useAudioContext();
  const params = useParams();
  //   const inactive = useWindowInactivity(5);
  const { data, isLoading, isFetched } = useGetSermonById(params?.id);

  useEffect(() => {
    if (isFetched && data?.data?.content) {
      setCurrentAudio(data?.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data, isFetched]);

  useEffect(() => {
    setIsAudioPage(true);

    return () => {
      setIsAudioPage(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <HeaderFooter solid>
      <div className="w-[100svw] h-[100svh] md:overflow-hidden md:flex items-center justify-center">
        <div className="fixed left-0 top-0 w-full h-[50%] bg-gradient-to-br from-purpleBg/30 to-white fade-b-20"></div>
        <div className="fixed left-0 top-[50%] w-full h-[50%] bg-gradient-to-br to-orangeBg/30 from-[#FFFFFF] fade-y-20"></div>

        {isLoading || !currentAudio ? (
          <Loader isSmall ball />
        ) : (
          <div className="relative z-10 max-w-[560px] mx-auto md:max-h-[90%] my-auto rounded-[20px] shadow-xl p-5 md:overflow-scroll no-scrollbar bg-white/50">
            <div className="relative w-full mb-16">
              <Link
                to={"/sermon"}
                className="text-xs text-[#2F0640] flex items-center gap-3 mb-2 pb-8 "
              >
                <svg
                  width="9"
                  height="16"
                  viewBox="0 0 9 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="rotate-180"
                >
                  <path
                    d="M1 1L6.81206 6.81206C7.46814 7.46814 7.46814 8.53186 6.81206 9.18794L1 15"
                    stroke="#2F0640"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
                <span>Go to sermons</span>
              </Link>

              <p className="text-lg font-medium text-purpleOverlay absolute inset-0 top-5 m-auto w-fit h-fit">
                Sermon Details
              </p>
            </div>
            <AudioDiskWrapper large className={"mx-auto mb-9"}>
              <SermonDisc
                image={currentAudio?.cover_image?.url}
                className={"border-purpleBg"}
              />
            </AudioDiskWrapper>
            <div className="flex items-center justify-between gap-5 mb-8">
              <div className="">
                <p className="text-xl text-purpleOverlay font-semibold">
                  {currentAudio?.title}
                </p>
                <p className="text-xs text-orangeBg">
                  {currentAudio?.facilitator}
                </p>
              </div>
              <div className="flex items-center">
                <a href={currentAudio?.content} download={currentAudio?.title}>
                  <svg
                    width="50"
                    height="50"
                    viewBox="0 0 50 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.5"
                      y="0.5"
                      width="49"
                      height="49"
                      rx="24.5"
                      fill="#FFF3E5"
                    />
                    <rect
                      x="0.5"
                      y="0.5"
                      width="49"
                      height="49"
                      rx="24.5"
                      stroke="#FF8A00"
                    />
                    <path
                      d="M24.7733 16.3574V28.0486M24.7733 28.0486L29.7838 23.0381M24.7733 28.0486L19.7628 23.0381"
                      stroke="#FF8A00"
                      strokeWidth="1.58426"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M31.4541 33.0586H24.7734H18.0928"
                      stroke="#FF8A00"
                      strokeWidth="1.58426"
                      strokeLinecap="round"
                    />
                  </svg>
                </a>
              </div>
            </div>
            <div className="flex flex-col items-center gap-10">
              <AudioProgress />
              <AudioControls large />
            </div>

            <div className="mt-5">
              <p className="font-semibold text-purpleOverlay text-lg mt-5">
                Description
              </p>
              <p className="text-sm text-purpleOverlay whitespace-pre-line">
                {currentAudio?.description}
              </p>
            </div>
          </div>
        )}
      </div>
    </HeaderFooter>
  );
};

export default AudioPlayer;
