import { format } from "date-fns";
import HeaderFooter from "generalComponents/HeaderFooter";
import React, { useRef, useState } from "react";
import { BiBold, BiItalic, BiListOl, BiListUl } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import FormatButton from "./FormatButton";
import {
  useCreateNote,
  useDeleteNote,
  useGetNoteById,
  useUpdateNote,
} from "../controllers/notes_controller";
import { RoundedButton } from "generalComponents/Button";
import showToast from "constants/showToast";
import GoBackComponent from "generalComponents/GoBackComponent";
import { useQueryClient } from "react-query";
import { MdDeleteOutline } from "react-icons/md";

const CreateEditNote = () => {
  const params = useParams();
  const navigate = useNavigate();
  // const [charactersLeft, setCharactersLef] = useState(455);
  const [newNote, setNewNote] = useState({
    title: "",
    body: "Body",
  });
  const editorRef = useRef(null);

  const today = new Date();

  const { data: note } = useGetNoteById({
    enabled: params?.id !== "new",
    id: params?.id,
    onSuccess: (data) => {
      setNewNote({
        title: data?.data?.data?.title,
        body: data?.data?.data?.body,
      });
      if (editorRef.current) {
        editorRef.current.innerHTML = data?.data?.data?.body;
      }
    },
  });
  const createNote = useCreateNote();
  const updateNote = useUpdateNote(params?.id);
  const deleteNote = useDeleteNote(params?.id);
  const queryClient = useQueryClient();

  async function handleCreateNote() {
    const body = {
      title: newNote.title,
      body: editorRef.current?.innerHTML,
    };

    try {
      const data = await createNote.mutateAsync(body);
      console.log({ data });
      if (data) {
        showToast("Note saved");
        await queryClient.invalidateQueries(["notes"]);
        await queryClient.invalidateQueries(["notes-by-id"]);
        navigate(`/library/notes/${data?.data?.data?.id}`);
      }
    } catch (error) {
      console.log({ error });
    }
  }

  async function handleUpdateNote() {
    const body = {
      title: newNote.title,
      body: editorRef.current?.innerHTML,
    };

    try {
      const data = await updateNote.mutateAsync(body);
      console.log({ data });
      if (data) {
        showToast("Note saved");
        await queryClient.invalidateQueries(["notes"]);
        await queryClient.invalidateQueries(["notes-by-id"]);
      }
    } catch (error) {
      console.log({ error });
    }
  }

  async function handleDeleteNote() {
    console.log({ note });
    try {
      const data = await deleteNote.mutateAsync(note?.id);
      if (data) {
        showToast("Note deleted");
        await queryClient.invalidateQueries(["notes"]);
        await queryClient.invalidateQueries(["notes-by-id"]);
        navigate(`/library/notes`);
      }
    } catch (error) {
      console.log({ error });
    }
  }

  return (
    <HeaderFooter solid>
      <div className="py-28 lg:py-40 max-w-section mx-auto px-2">
        <GoBackComponent path={"/library/notes"} />
        <h1 className="text-purpleOverlay text-xl font-semibold mt-2">Note</h1>

        <div className="mt-5">
          <span className="text-textGrey">
            {params?.id === "new"
              ? format(today, "do MMMM, yyyy. hh:mm a")
              : format(
                  new Date(note?.data?.data?.createdAt ?? today),
                  "do MMMM, yyyy. hh:mm a"
                )}
          </span>

          <div className="mt-2 rounded-md">
            <div className="rounded-md  flex justify-between items-center gap-5 mb-3 py-2">
              <input
                placeholder="Title"
                value={newNote.title}
                onChange={(e) =>
                  setNewNote({ ...newNote, title: e.target.value })
                }
                className="text-xl lg:text-3xl bg-transparent outline-none border-none text-purpleOverlay placeholder:text-textGrey flex-1 font-semibold"
              />

              <RoundedButton
                onClick={
                  params?.id === "new" ? handleCreateNote : handleUpdateNote
                }
                className="py-3 px-6"
              >
                Save
              </RoundedButton>
            </div>

            <div className="border border-gray-200 rounded-md ">
              <div className=" p-1 flex justify-between items-center gap-2 bg-[#FFF0FE] w-full">
                <div className="flex gap-2">
                  <FormatButton
                    title="Bold"
                    command={"bold"}
                    focus={() => editorRef.current?.focus()}
                  >
                    <BiBold className="w-5 h-5" />
                  </FormatButton>
                  <FormatButton
                    command="italic"
                    title="Italic"
                    focus={() => editorRef.current?.focus()}
                  >
                    <BiItalic className="w-5 h-5" />
                  </FormatButton>
                  <FormatButton
                    command={"insertUnorderedList"}
                    title="Bullet List"
                    focus={() => editorRef.current?.focus()}
                  >
                    <BiListUl className="w-5 h-5" />
                  </FormatButton>
                  <FormatButton
                    command={"insertOrderedList"}
                    title="Ordered List"
                    focus={() => editorRef.current?.focus()}
                  >
                    <BiListOl className="w-5 h-5" />
                  </FormatButton>
                  {/* <button
                  variant="ghost"
                  size="icon"
                  onClick={handleImage}
                  title="Add Image"
                                >
                  <ImageIcon className="w-5 h-5" />
                                </button> */}
                </div>

                <div className="flex justify-end items-center text-purpleBg">
                  {params?.id !== "new" && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteNote();
                      }}
                    >
                      <MdDeleteOutline className="size-6" />
                    </button>
                  )}
                </div>
              </div>

              {/* Rich text editot */}
              <div
                ref={editorRef}
                contentEditable
                spellCheck
                className="p-2 min-h-52 focus:outline-none text-purpleOverlay list-inside"
                onKeyDown={(e) => {
                  if (e.key === "Tab") {
                    e.preventDefault();
                    document.execCommand("indent", false, null);
                  }
                }}
                onPaste={(e) => {
                  e.preventDefault();
                  const text = e.clipboardData.getData("text/plain");
                  document.execCommand("insertText", false, text);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </HeaderFooter>
  );
};

export default CreateEditNote;
