import React from "react";
import Hero from "../components/Hero";
import Vision from "../components/Vision";
import Mision from "../components/Mission";
import GlobalSP from "../components/GlobalSP";
import GlobalGSPM from "../components/GlobalGSPM";
import Tenets from "../components/Tenets";
import HeaderFooter from "generalComponents/HeaderFooter";

const AboutUs = () => {
  return (
    <HeaderFooter>
      <Hero />
      <Vision />
      <Mision />
      <GlobalSP />
      <GlobalGSPM />
      <Tenets />
    </HeaderFooter>
  );
};

export default AboutUs;
