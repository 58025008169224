import HeaderFooter from "generalComponents/HeaderFooter";
import React, { useState } from "react";
import Hero from "../components/Hero";
import EventItem from "../components/EventItem";
import TextGradient from "generalComponents/TextGradient";
import useGetEvents from "../controllers/get_events_controller";
import SingleEventModal from "../components/SingleEventModal";
import Loader from "generalComponents/Loader";
import CustomPagination from "generalComponents/CustomPagination";

const Events = () => {
  const [event, setEvent] = useState({});
  const [page, setPage] = useState(1);
  const pageSize = 9;
  const { data, isLoading } = useGetEvents({
    page,
    pageSize,
  });

  return (
    <HeaderFooter>
      <TextGradient>Events</TextGradient>
      <div className="pt-[300px] pb-20 max-w-section mx-auto px-5">
        <h1 className="font-semibold text-[52px] lg:text-[60px] lg:leading-[70px] text-purpleOverlay">
          Events
        </h1>

        <Hero />

        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="grid lg:grid-cols-3 gap-10 lg:gap-5">
              {data?.data?.map((item, index) => (
                <EventItem
                  key={index}
                  image={item?.cover_image.url}
                  title={item?.name}
                  description={item.description}
                  readMore={() => setEvent(item)}
                  event={item}
                />
              ))}
            </div>
            <div className="flex justify-end mt-10 min-w-full">
              <CustomPagination
                count={data?.pagination?.total}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
              />
            </div>
          </>
        )}
      </div>

      <SingleEventModal id="eventModal" event={event} />
    </HeaderFooter>
  );
};

export default Events;
