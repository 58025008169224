import AxiosWithToken from "constants/api_management/MyHttpHelperWithToken";
import showToast from "constants/showToast";
import { useMutation, useQuery, useQueryClient } from "react-query";

export const usePostSermonComment = () => {
  const queryClient = useQueryClient();

  const postRequest = async (body) => {
    try {
      const response = await AxiosWithToken.post(`/sermon/comment`, body);
      return response.data;
    } catch (error) {
      throw new Error(error?.response?.data?.message);
    }
  };

  const mutation = useMutation({
    mutationKey: ["sermon_comments"],
    mutationFn: postRequest,
    onSuccess: (data) => {
      // showToast("Comment posted successfully", "success");
      queryClient.invalidateQueries(["sermon_comments"]);
      // onSuccess();
    },
    onError: (error) => {
      showToast(error, "error");
    },
  });

  const postComment = async (body) => {
    try {
      const data = await mutation.mutateAsync(body);
      return data;
    } catch (error) {
      return error;
    }
  };

  return {
    postComment,
    ...mutation,
  };
};

export const useGetSermonComments = (id) => {
  return useQuery(
    ["sermon_comments", id],
    async () => {
      try {
        const response = await AxiosWithToken.get(`/sermon/${id}/comments`);
        //console.log(`this is the current subscription status ${response}`);
        return response.data;
      } catch (error) {
        //console.log(error.response.data);
        throw new Error(`Sorry: ${error.response.data.message}`);
      }
    },
    {
      select: (data) => data.data,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const useDeleteSermonComment = () => {
  const queryClient = useQueryClient();

  const deleteRequest = async (id) => {
    try {
      const response = await AxiosWithToken.delete(`/sermon/comment/${id}`);
      return response.data;
    } catch (error) {
      throw new Error(error?.response?.data?.message);
    }
  };

  const mutation = useMutation({
    mutationKey: ["sermon_comments"],
    mutationFn: deleteRequest,
    onSuccess: (data) => {
      showToast("Comment deleted successfully", "success");
      queryClient.invalidateQueries(["sermon_comments"]);
      // onSuccess();
    },
    onError: (error) => {
      showToast(error, "error");
    },
  });

  const deleteComment = async (id) => {
    try {
      const data = await mutation.mutateAsync(id);
      return data;
    } catch (error) {
      throw error;
    }
  };

  return {
    deleteComment,
    ...mutation,
  };
};
