import React from "react";
import Banner from "../components/Banner";
import GiveOptions from "../components/GiveOptions";
import HeaderFooter from "generalComponents/HeaderFooter";

const CozaGive = () => {
  return (
    <HeaderFooter>
      <section className="grid lg:grid-cols-2 items-center py-10 px-5 gap-12 max-w-section mx-auto pt-48 lg:h-[100svh] overflow-hidden">
        <Banner />
        <GiveOptions />
      </section>
    </HeaderFooter>
  );
};

export default CozaGive;
