import FolderIcon from "assets/icons/FolderIcon";
import React from "react";
import { twMerge } from "tailwind-merge";

const EmptyState = ({
  className,
  message,
  messageClass,
  icon: Icon,
  heading,
  headingClass,
  scripture,
  scriptureText,
  button,
}) => {
  return (
    <div className="w-full flex flex-col items-center gap-5">
      <div
        className={twMerge(
          "flex flex-col lg:flex-row justify-center items-center gap-10 min-h-[320px] w-full",
          className
        )}
      >
        {Icon ? (
          <Icon
            className={
              "fill-purpleOverlay/60 size-[250px] lg:size-[300px] animate-pulse"
            }
          />
        ) : (
          <FolderIcon
            className={
              "fill-purpleOverlay/60 size-[250px] lg:size-[300px] animate-pulse"
            }
          />
        )}
        <div>
          {heading && (
            <p
              className={twMerge(
                "kaushan-script text-purpleOverlay/80 text-5xl mb-2 text-center lg:text-left",
                headingClass
              )}
            >
              {heading}
            </p>
          )}

          {message && (
            <p
              className={twMerge(
                "text-purpleOverlay/50 text-lg max-w-[560px] text-center lg:text-left",
                messageClass
              )}
            >
              {message}
            </p>
          )}

          {button && (
            <div className="mt-3 max-lg:flex justify-center">{button}</div>
          )}
        </div>
      </div>

      {scripture && (
        <blockquote
          className={twMerge(
            "text-purpleOverlay/50 text-xl max-w-[560px] text-center lg:text-left",
            messageClass
          )}
        >
          <cite>
            <p className="kaushan-script">{scriptureText}</p>

            <footer className="text-right">{scripture}</footer>
          </cite>
        </blockquote>
      )}
    </div>
  );
};

export default EmptyState;
