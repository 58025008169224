import React from "react";
import { Puff, TailSpin } from "react-loader-spinner";
import CozaLoader from "./CozaLoader";

export const Spinner = ({ width, height, color }) => (
  <div>
    <TailSpin
      visible={true}
      height={height ?? "25"}
      width={width ?? "25"}
      color={color ?? "#ffffff"}
      ariaLabel="tail-spin-loading"
      radius="1"
      wrapperStyle={{}}
      wrapperClass=""
    />
  </div>
);

const Loader = ({ isSmall = false, ball = false }) => {
  const containerStyles = isSmall
    ? {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: `100%`,
        minHeight: "300px",
        width: "100%",
      }
    : {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: `100svh`,
      };
  return (
    <div style={containerStyles}>
      {ball ? (
        <CozaLoader />
      ) : (
        <Puff
          height="50"
          width="50"
          radius={1}
          color="#8311B3"
          ariaLabel="puff-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      )}
    </div>
  );
};

export default Loader;
