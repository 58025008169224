import ModalManagement from "generalComponents/ModalManagement";
import React, { useState } from "react";

import CustomButton from "generalComponents/Button";

import { UpdateFormStatusManager } from "../controllers/updateFormStatusController";
import BabyChristineningDetailForm from "./BabyChristineningDetailForm";
import WeddingAnniversaryForm from "./WeddingAnniversaryForm";
import ChildDedicationForm from "./ChildDedicationForm";
import MaritalClassesForm from "./MaritalClassesForm";
import RelationshipRegistrationForm from "./RelationshipRegistrationForm";
import VehicleDedicationForm from "./VehicleDedicationForm";
import WeddingThanksgivingForm from "./WeddingThanksgivingForm";
import BusinessDedicationForm from "./BusinessDedicationForm";
import HouseDedicationForm from "./HouseDedicationForm";
import MarriageApplicationForm from "./MarriageApplicationForm";
import WhistleBlowerForm from "./WhistleBlowerForm";
import InputWithFullBoarder from "generalComponents/InputWithFullBoarder";

const ViewFormDetails = ({ details }) => {
  const { update, isLoading } = UpdateFormStatusManager({
    formId: details?._id,
    formSlug: details?.form,
  });
  const [isApprove, setIsApprove] = useState(false);
  const [message, setMessage] = useState("");
  const formComponents = [
    { slug: "baby-christening", component: BabyChristineningDetailForm },
    { slug: "wedding-anniversary", component: WeddingAnniversaryForm },
    { slug: "child-dedication", component: ChildDedicationForm },
    { slug: "marital-class", component: MaritalClassesForm },
    {
      slug: "relationship-registration",
      component: RelationshipRegistrationForm,
    },
    { slug: "vehicle-dedication", component: VehicleDedicationForm },
    { slug: "wedding-thanksgiving", component: WeddingThanksgivingForm },
    { slug: "business-dedication", component: BusinessDedicationForm },
    { slug: "house-dedication", component: HouseDedicationForm },
    { slug: "wedding-application", component: MarriageApplicationForm },
    { slug: "whistle-blower", component: WhistleBlowerForm },
  ];

  // Find the component that matches the formSlug
  const matchingComponent = formComponents.find(
    (componentObj) => componentObj.slug === details?.form
  );
  const ComponentToRender = matchingComponent?.component;
  return (
    <ModalManagement id={"view_formDetails"} title={"Form Details"}>
      <div className="w-full md:w-[500px] flex flex-col gap-5 mt-5">
        {matchingComponent ? (
          <div className="w-full flex flex-col">
            <ComponentToRender details={details} />
            <div className="flex flex-col w-full">
              <div className="flex items-center gap-5 w-full my-2">
                <CustomButton
                  buttonText={"Accept"}
                  buttonColor={"bg-success"}
                  className={"w-full"}
                  isLoading={isLoading && isApprove}
                  radius={"rounded-[40px]"}
                  onClick={async () => {
                    setIsApprove(true);
                    const info = { status: "accepted" };
                    await update(info);
                    document.getElementById("view_formDetails").close();
                  }}
                />
                <CustomButton
                  buttonText={"Decline"}
                  buttonColor={"bg-brandRed"}
                  isLoading={isLoading && !isApprove}
                  onClick={async () => {
                    document.getElementById("decline_message").showModal();
                  }}
                  className={"w-full"}
                  radius={"rounded-[40px]"}
                />
              </div>
            </div>
          </div>
        ) : (
          <div>
            <p>No matching component found for the provided formSlug.</p>
          </div>
        )}
      </div>
      <ModalManagement id={"decline_message"} title={"Decline Reason"}>
        <div className="md:w-[500px] flex flex-col gap-10">
          {" "}
          <InputWithFullBoarder
            label={"Description"}
            isTextArea={true}
            className={"mb-4"}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <CustomButton
            buttonText={"Decline"}
            buttonColor={"bg-brandRed"}
            isLoading={isLoading && !isApprove}
            onClick={async () => {
              setIsApprove(false);
              const info = { status: "declined", message: message };
              await update(info);
              document.getElementById("decline_message").close();
              document.getElementById("view_formDetails").close();
            }}
            className={"w-full"}
            radius={"rounded-[40px]"}
          />
        </div>
      </ModalManagement>
    </ModalManagement>
  );
};

export default ViewFormDetails;
