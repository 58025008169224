import React from "react";
import SinglePraiseReport from "../components/SinglePraiseReport";
import { useGetPraiseReportById } from "../controllers/get_praise_reports_contoller";
import { useParams } from "react-router-dom";
import Loader from "generalComponents/Loader";

const SingleReportPage = () => {
  const params = useParams();
  const { data, isLoading } = useGetPraiseReportById(params?.id);

  if (isLoading) return <Loader ball />;
  return (
    <div className="h-[100svh] w-[100svw]">
      <SinglePraiseReport praiseReport={data?.data} />
    </div>
  );
};

export default SingleReportPage;
