import ModalManagement from "generalComponents/ModalManagement";
import React from "react";
import useDeleteCampusManager from "../controllers/deleteCampusController";
import GlobalVariables from "constants/GlobalVariables";
import { HiLocationMarker } from "react-icons/hi";
import { copy, dottedLines } from "assets/icons";
import { copyToClipboard } from "utils/copyToClipboard";
import CustomButton from "generalComponents/Button";

const CampusDetail = ({ details }) => {
  return (
    <ModalManagement title={"Campus Detail"} id={"campus_detail"}>
      <div className="w-full md:w-450px] flex flex-col mt-5">
        <img
          className="object-cover w-full h-[200px] rounded-[15px]"
          src={
            details?.images.length > 0
              ? details?.images[0]
              : GlobalVariables.defaultProfilePicture
          }
          alt=""
        />
        <div className="my-5 w-full flex flex-col gap-1">
          <p className="text-24px font-semibold">{details?.name}</p>
          <p className="text-[13px] font-light text-[#8311B3] flex items-center ">
            <HiLocationMarker size={15} />
            {details?.location ? (
              <span className=" ">{`${details?.location?.address}`}</span>
            ) : (
              <span className="">No location</span>
            )}
          </p>
        </div>
        <p className="text-[15px] text-purpleOverlay w-full text-center flex gap-4 items-center justify-center">
          {details?.location?.city} <span className="text-gray4">|</span>{" "}
          {details?.location?.state}
          <span className="text-gray4">|</span>
          {details?.location?.country}
        </p>
        <p className="text-18px font-semibold text-blackColor mb-3">Contacts</p>
        <div className="flex flex-col gap-4 w-full relative">
          {details?.contacts.length > 0 && (
            <div className="w-full gap-4 flex flex-col">
              {details?.contacts.map((el, i) => (
                <div key={i} className="flex gap-3 items-center w-full">
                  <p className="text-14px font-light w-full max-w-fit">
                    {el?.name}
                  </p>
                  <img src={dottedLines} alt="" className="max-w-full w-full" />
                  <div className="flex items-center w-fit">
                    <p className="text-14px font-semibold ">{el?.phone}</p>
                    <img
                      src={copy}
                      alt=""
                      onClick={() => {
                        copyToClipboard(el?.phone);
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="w-full flex gap-10 mt-10">
          <button
            onClick={() => {
              document.getElementById("campus_detail").close();
              document.getElementById("add_campus").showModal();
            }}
            className="w-full rounded-[40px] h-[50px] bg-brandPurple text-whiteColor text-[15px]"
          >
            Edit
          </button>
          <button
            onClick={() => {
              document.getElementById("admin_delete").showModal();
            }}
            className="w-full rounded-[40px] bg-[#CC0000] h-[50px] text-whiteColor text-[15px]"
          >
            Delete
          </button>
        </div>
      </div>
    </ModalManagement>
  );
};

export default CampusDetail;
