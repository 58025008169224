import React, { useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { Link, useLocation, useNavigate } from "react-router-dom";
import IconsWithText from "./IconsWithText";
import { TiThMenu } from "../../node_modules/react-icons/ti/index.esm";
import { IoClose } from "../../node_modules/react-icons/io5/index.esm";
import NotificationList from "../modules/notification/NotificationList";
import useGetUserDetailsManager from "../modules/settings/controllers/get_UserDetails_controller";
import Loader from "./Loader";
import ModalManagement from "./ModalManagement";
import CustomButton from "./Button";
import { adminMenu, otherProtectedPages } from "../constants/menu";
import { cozaLogo, notification } from "assets/icons";

const BaseDashboardNavigation = ({
  children,
  title,
  onChange,
  value,
  hideSearch,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  const [authPaths, setAuthPaths] = useState([]);
  const { data, isError, error, isLoading, isSuccess } =
    useGetUserDetailsManager();
  const [isAdmin, setIsAdmin] = useState(false);
  const handleOpenMenu = () => {
    setShowMenu(!showMenu);
  };
  const location = useLocation();
  const authorizedPaths = [];

  const navigate = useNavigate();

  console.log("---->>user-me", data);

  const hasPathPermission = (path, userPermissions) => {
    const menuEntry = adminMenu.find((entry) => entry.path === path);

    // console.log('menuEntry', menuEntry, menuEntry &&
    //   (menuEntry.permission === "all" || userPermissions.includes(menuEntry.permission)))
    return (
      menuEntry &&
      (menuEntry.permission === "all" ||
        userPermissions.some((permission) =>
          permission.includes(menuEntry.permission)
        ))
    );
  };

  const isProtected = (path, userPermissions) => {
    const menuEntry = otherProtectedPages.find((entry) =>
      path.startsWith(entry.path)
    );

    // console.log('menuEntry', menuEntry, menuEntry &&
    //   (menuEntry.permission === "all" || userPermissions.includes(menuEntry.permission)))
    return (
      menuEntry &&
      (menuEntry.permission === "all" ||
        userPermissions.some((permission) =>
          permission.includes(menuEntry.permission)
        ))
    );
  };

  // checking if it is admin or not
  useEffect(() => {
    if (isSuccess && data) {
      // Call getUserRole when data is successfully loaded
      console.log(data.data.roles[0].name);

      const getUserRole = () => {
        setIsAdmin(data.data.roles[0].name === "super-admin");
      };
      // if (data.data.roles[0].name !== "super-admin") {
      //   if (data.data.first_name === "" || data.data.phone === "") {
      //     navigate("/dashboard/settings");
      //     window.my_modal_1.showModal();
      //   }
      // }
      getUserRole(); // Call the function immediately
    }
  }, [isLoading, isSuccess, data]);

  // useEffect(() => {
  //   // console.log('location---->>', location)
  //   if (data?.data && data?.data?.roles[0]?.name !== "super-admin") {
  //     if (!hasPathPermission(location.pathname, data?.data?.permissions)) {
  //       navigate("/unauthorized");
  //     }
  //   }
  // }, [data]);

  useEffect(() => {
    // console.log('location---->>', location)
    // const isAdmin = data?.data?.roles[0]?.name === "super-admin";
    const isPathProtected = isProtected(
      location.pathname,
      data?.data?.permissions ?? []
    );
    const hasPathPermissionOrIsProtected =
      hasPathPermission(location.pathname, data?.data?.permissions ?? []) ||
      isPathProtected;

    if (
      !data?.data?.roles[0]?.name === "super-admin" &&
      !hasPathPermissionOrIsProtected
    ) {
      navigate("/unauthorized");
    }
  }, [data, location.pathname]);

  if (isError) {
    return `error.message`;
  }

  if (isLoading) {
    return <Loader />;
  }

  const handleLogout = async () => {
    localStorage.clear();
    // Wait for localStorage to be cleared
    await new Promise((resolve) => setTimeout(resolve, 0));
    window.location.href = "/login";
  };

  // const tokenExists = localStorage.getItem("token") !== null;

  return (
    <div className="relative lg:fixed flex h-[100vh] w-full font-inter text-blackColor bg-purpleOverlay">
      <ModalManagement
        id={"my_modal_1"}
        hideCancel={true}
        className={"bg-white"}
      >
        <div className="text-center text-blackColor space-y-8 m-5 bg-white">
          <p className=" font-bold text-28px">Welcome</p>
          <p className="">
            We noticed you have not set up your profile yet. To continue, please
            set up your account profile.
          </p>
          <CustomButton
            buttonText={"Setup my profile"}
            onClick={() => navigate("/dashboard/settings")}
          />
        </div>
      </ModalManagement>
      <div
        className={`hidden lg:flex ease-in-out duration-500 min-w-[20%] bg-purpleOverlay z-50 h-full pt-11  lg:overflow-auto scrollbar-hide  flex-col  items-start gap-3  pb-10 relative`}
      >
        <img
          className="object-contain w-[70px] ml-14"
          src={cozaLogo}
          alt="COZA logo"
        />

        {adminMenu.map((el, i) => {
          if (el.title === "Logout") {
            return (
              <button key={i} onClick={handleLogout}>
                <IconsWithText icon={el.icon} iconSize="28px" text={el.title} />
              </button>
            );
          }

          const hasPermission =
            data?.data?.permissions.some((permission) =>
              permission.includes(`${el.permission}`)
            ) ||
            el.permission === "all" ||
            isAdmin;

          if (hasPermission) {
            return (
              <Link key={i} to={el.path} className="w-full relative">
                <IconsWithText
                  icon={el.icon}
                  iconSize="28px"
                  path={el.path}
                  text={el.title}
                />
              </Link>
            );
          }

          return null; // Or any other default action when permission is not present
        })}
      </div>
      {/* this is where the mobile menu is */}
      <div
        className={`fixed lg:hidden ${
          showMenu ? "left-0" : "left-[-100%]"
        } ease-in-out duration-500 w-3/5 sm:w-1/3 bg-purpleOverlay z-50 h-full overflow-y-scroll scrollbar-hide pt-11 flex flex-col  items-start space-y-10 mr-7`}
      >
        <img
          className="object-contain w-[70px] mx-7"
          src={cozaLogo}
          alt="COZA logo"
        />

        {adminMenu.map((el, i) => {
          if (el.title === "Logout") {
            return (
              <button key={i} onClick={handleLogout}>
                <IconsWithText icon={el.icon} iconSize="28px" text={el.title} />
              </button>
            );
          }

          const hasPermission =
            data?.data?.permissions.some((permission) =>
              permission.includes(`${el.permission}`)
            ) ||
            el.permission === "all" ||
            isAdmin;

          if (hasPermission) {
            authorizedPaths.push(el.path);
            return (
              <Link key={i} to={el.path}>
                <IconsWithText
                  icon={el.icon}
                  iconSize="28px"
                  path={el.path}
                  text={el.title}
                />
              </Link>
            );
          }

          return null; // Or any other default action when permission is not present
        })}
      </div>
      {/* this is where the top section starts */}
      <div className="w-screen rounded-[24px] bg-[#FDFDFD] ">
        <div className="flex flex-col gap-10  max-h-[100vh]">
          <div className="flex bg-whiteColor h-[64px] border border-transparent py-5 border-b-lightGrey/35 flex-col lg:flex-row items-center justify-between text-left px-7 z-40">
            <div className="w-full lg:w-auto flex items-center justify-between">
              <h3 className="text-[16px] font-semibold">{title}</h3>
              <div
                onClick={handleOpenMenu}
                className=" lg:hidden  bg-white shadow-md p-3.5 h-14 border w-14 rounded-full"
              >
                {showMenu ? (
                  <IoClose size={25} color="blackColor" />
                ) : (
                  <TiThMenu size={25} color="blackColor" />
                )}
              </div>
            </div>
            <div className="hidden lg:flex justify-end items-center max-w-[523px] w-full">
              <div
                className={` ${
                  hideSearch ? "hidden" : "flex"
                }  items-center justify-start p-3 h-[40px] mr-[35px] border text-[#667185] border-[#E4E7EC] bg-whiteColor rounded-[4px] w-full  relative`}
              >
                <AiOutlineSearch size={23} className="mr-2" />
                <input
                  className="text-[14px] font-normal text-blackColor bg-transparent outline-none focus:outline-none w-full"
                  placeholder="Search here..."
                  type="text"
                  value={value}
                  onChange={onChange}
                />
              </div>
              <button onClick={() => setShowNotification(!showNotification)}>
                <img src={notification} alt="" />
              </button>
              {/* notification display */}
              {showNotification && (
                <NotificationList showNotification={showNotification} />
              )}
              <div>
                <img
                  className="h-[37px] w-[37px] object-cover rounded-full ml-[15px] mr-[22px]"
                  src={
                    data?.data?.profile_picture
                      ? data?.data?.profile_picture
                      : `https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png`
                  }
                  alt="user avatar"
                />
              </div>
            </div>
          </div>
          <div className="max-h-[90%]  ml-7   pb-10"> {children}</div>
        </div>
      </div>
    </div>
  );
};

export default BaseDashboardNavigation;
