import React from "react";
import CustomButton from "./Button";
import SearchComponent from "./SearchComponent";
import InputWithFullBoarder from "./InputWithFullBoarder";

const AdminTopPage = ({
  title,
  subtitle,
  onclickButton,
  searchValue,
  setSearchValue,
  buttonText,
}) => {
  return (
    <div className="w-[97%] mx-auto flex flex-col gap-5">
      {" "}
      <div className="flex justify-between flex-col gap-5 md:flex-row w-full">
        <div className=" flex flex-col items-start">
          <p className="text-[24px] font-semibold">{title}</p>
          <p className="text-14px">{subtitle}</p>
        </div>
        <div className="mb-3">
          <CustomButton
            onClick={onclickButton}
            // isLoading={isLoading}
            buttonText={buttonText}
            radius={`[5px]`}
            className={`max-w-max`}
          />
        </div>
      </div>
      <div className="flex items-center justify-between flex-col gap-5 md:flex-row">
        <div className="max-w-[291px]">
          <SearchComponent
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
        <InputWithFullBoarder type={"date"} />
      </div>
    </div>
  );
};

export default AdminTopPage;
