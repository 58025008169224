import React, { useCallback, useEffect, useMemo, useState } from "react";
import ModalManagement from "generalComponents/ModalManagement";
import CustomButton from "generalComponents/Button";
import { formatDate } from "date-fns";
import { useInitialiseNewAttendance } from "../controllers/initialiseNewAttendance";
import ComboboxInput from "generalComponents/ComboboxInput";
import { uploadFilesWithPooling } from "../controllers/uploadInBatches";
import { useMutation } from "react-query";
import Dropzone from "generalComponents/DropZone";
import useGetCampusListManager from "modules/admin/campus/controllers/getCampusListController";
import useGetServiceListManager from "../controllers/getServiceListManager";

const InitiateNewAttendance = ({ unMount }) => {
  const { data: services, isLoading: loadingServices } = useGetServiceListManager({}, { enabled: true });

  const { data } = useGetCampusListManager()
  const campuses = data?.data

  const { initialiseNewAttendance, isLoading: processLoading } = useInitialiseNewAttendance();

  const pastServices = useMemo(() => services?.filter(service => new Date(service?.serviceTime).getTime() < new Date().getTime()), [services])

  const serviceOptions = useMemo(() => {
    return pastServices?.map((service) => ({
      ...service,
      label: `${service?.name}  ${formatDate(service?.serviceTime, "yyyy-MM-dd (p)")}`,
      value: service?._id,
    }));

  }, [pastServices]);

  const campusOptions = useMemo(() => {
    return campuses?.map((campus) => ({
      ...campus,
      label: `${campus?.name}`,
      value: campus?._id,
    }));

  }, [campuses]);

  const [selectedService, setSelectedService] = useState();
  const [selectedCampus, setSelectedCampus] = useState();

  const handleImaageAnalysis = () => {

    try {
      initialiseNewAttendance({
        bucket: process.env.REACT_APP_S3_BUCKET_KEY,
        folderPath: `coza-app-face-recognition/${selectedService?.name?.replaceAll(' ', '_') ?? 'service'}_${selectedService?.serviceTime}`,
        campusName: selectedCampus?.name,
        serviceName: selectedService?.name,
        serviceStartTime: selectedService?.serviceTime,
        serviceId: selectedService?._id,
        campusId: selectedCampus?._id
      })

      document.getElementById("add_serviceAttendance").close();
      unMount()
    } catch (error) {
    }
  }

  useEffect(() => {
    if (services?.length > 0 && !loadingServices) {
      setSelectedService(services[0])
    }
  }, [services, loadingServices])

  return (
    <ModalManagement id={"add_serviceAttendance"} title={"Service Attendance"}>
      <div className="rounded-[20px] w-full md:w-[560px] mt-5 space-y-4">
        <div className="flex space-x-4 w-full">
          <ComboboxInput
            required={true}
            label="Select Service"
            options={serviceOptions}
            value={selectedService}
            placeholder="Select Service"
            className='w-full'
            setValue={(value) => setSelectedService(value)}
          />
          <ComboboxInput
            required={true}
            label="Select Campus"
            options={campusOptions}
            value={selectedCampus}
            placeholder="Select Campus"
            className='w-full'
            setValue={(value) => setSelectedCampus(value)}
          />
        </div>
        <UploadMultipleFiles
          campus={selectedCampus}
          service={selectedService}
          processLoading={processLoading}
          onUploadSuccess={handleImaageAnalysis}
        />
      </div>
    </ModalManagement>
  );
};

export default React.memo(InitiateNewAttendance);

const UploadMultipleFiles = React.memo(({ service, campus, onUploadSuccess, processLoading }) => {
  const [files, setFiles] = useState([]);
  const [progress, setProgress] = useState({});
  const initialStatus = "Start Upload and Analysis"
  const [status, setStatus] = useState(initialStatus)
  const [isUploading, setIsUploading] = useState()

  // Function to upload files in batches
  const { mutateAsync } = useMutation((params) => uploadFilesWithPooling(params));

  const handleUpload = useCallback(
    async () => {
      setStatus("Uploading for Image Analysis...")

      await mutateAsync({
        files,
        batchSize: 10,  // Set batch size
        expirySeconds: 3600,
        campus,
        service,
        onUploadUpdate: (arg) => setIsUploading(arg),
        bucketName: process.env.REACT_APP_S3_BUCKET_KEY,
        onProgressUpdate: (key, progressValue) => {
          setProgress((prev) => {
            return { ...prev, [key]: progressValue }
          });
        },
      });

      setFiles([])
    }
    , [files, campus, service, mutateAsync])

  const handleFilesAdded = (newFiles) => {
    setFiles(newFiles);
  };

  const progresArray = useMemo(() => Object.values(progress) ?? [], [progress])
  const totalProgress = useMemo(() => (progresArray?.reduce((a, b) => a + b, 0)) === (100 * progresArray.length), [progresArray])
  const isUploadComplete = progresArray?.length > 0 && totalProgress

  useEffect(() => {
    if (isUploadComplete) {
      setStatus("Image Analysis Initialising...")
      onUploadSuccess()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUploadComplete])

  const canProceed = !!service && !!campus && files?.length > 0

  return (
    <div className="upload-container space-y-4">
      {!isUploadComplete && <Dropzone progress={progress} onFilesAdded={handleFilesAdded} accept={{
        'image/png': ['.png'],
        'image/jpeg': ['.jpeg', '.jpg'],
      }} />}
      <CustomButton
        buttonText={status}
        radius={"rounded-[40px]"}
        className={"w-full"}
        loader={status}
        disabled={!canProceed}
        isLoading={isUploading}
        onClick={handleUpload}
      />
    </div>
  );
});