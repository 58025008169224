import HeaderFooter from "generalComponents/HeaderFooter";
import React, { Fragment, useState } from "react";
import GalleryItem from "../components/GalleryItem";
import TextGradient from "generalComponents/TextGradient";
import { useGetGallery } from "../controllers/get_controller";
import Loader from "generalComponents/Loader";
import CustomPagination from "generalComponents/CustomPagination";

const Gallery = () => {
  const [page, setPage] = useState(1);
  const pageSize = 12;
  const { data, isLoading } = useGetGallery({
    page,
    pageSize,
  });
  return (
    <HeaderFooter>
      <TextGradient>Gallery</TextGradient>
      <div className="pt-[300px] pb-20 max-w-section mx-auto px-5">
        <h1 className="font-semibold text-[52px] lg:text-[60px] lg:leading-[70px] text-purpleOverlay mb-9">
          Gallery
        </h1>

        {isLoading ? (
          <Loader />
        ) : (
          <Fragment>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-10 lg:gap-5">
              {data?.data?.map((item, index) => (
                <GalleryItem key={index} item={item} />
              ))}
            </div>
            <div className="flex justify-end mt-10 min-w-full">
              <CustomPagination
                count={data?.pagination?.total}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
              />
            </div>
          </Fragment>
        )}
      </div>
    </HeaderFooter>
  );
};

export default Gallery;
