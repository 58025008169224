import { AiOutlineCheck, AiOutlineMinus } from "react-icons/ai";

export default function CustomCheckbox({
  check,
  checkWidth,
  checkHeight,
  onClick,
  icon,
}) {
  return (
    <div
      onClick={onClick}
      className="flex items-center justify-center cursor-pointer"
    >
      <div
        style={{
          backgroundColor: check ? "#8311B3" : "transparent",
          borderColor: "#8311B3",
          width: checkWidth ?? "2rem",
          height: checkHeight ?? "2rem",
        }}
        className="relative flex items-center justify-center border border-solid rounded-sm"
      >
        {check && (
          <>
            {icon === "dash" ? (
              <AiOutlineMinus className="absolute text-white" size={16} />
            ) : (
              <AiOutlineCheck className="absolute text-white" size={16} />
            )}
          </>
        )}
      </div>
    </div>
  );
}
