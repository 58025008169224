import ModalManagement from "generalComponents/ModalManagement";
import React, { useEffect, useRef, useState } from "react";
import useCreateGalleryManager, {
  CreateGalleryManager,
} from "../controller/createGalleryController";
import useEditGalleryManager, {
  EditGalleryManager,
} from "../controller/editGalleryController";
import useFileUpload from "modules/fileupload/fileUploadController";
import InputWithFullBoarder from "generalComponents/InputWithFullBoarder";
import CustomDropdown from "generalComponents/CustomDropdown";
import BigUploadFileComponent from "generalComponents/BigUploadFileComponent";
import UploadFileComponent from "generalComponents/UploadFileComponent";
import { convertBytesToMB } from "utils/fileSize";
import CustomButton from "generalComponents/Button";
import { AddGalleryCategoryManager } from "../controller/addGalleryCategoryController";
import { EditGalleryCategoryManager } from "../controller/editGalleryCategoryController";

const AddEditGalleryCategory = ({ details, categories }) => {
  const { createCategory, isLoading } = AddGalleryCategoryManager();
  const { editGalleryCategory, isLoading: editing } =
    EditGalleryCategoryManager(details?.id);
  const [name, setName] = useState("");

  useEffect(() => {
    if (details) {
      setName(details?.name);
    } else {
      setName("");
    }
  }, [details]);

  return (
    <ModalManagement id={"add_galleryCategory"} title={"Gallery Category"}>
      <div className="w-full flex flex-col md:w-[500px]">
        <div className="flex items-center justify-between mb-4 ">
          <p className="text-18px font-bold">
            {details ? "Edit Category" : "Create Category"}
          </p>
        </div>

        <InputWithFullBoarder
          label={"Name"}
          className={"mb-4"}
          placeholder={"Enter title"}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        <CustomButton
          buttonText={details ? "Edit Gallery" : "Create Gallery"}
          isLoading={editing || isLoading}
          onClick={async () => {
            if (details) {
              await editGalleryCategory({ name: name });
            } else {
              await createCategory({ name: name });
            }
            setName("");
            document.getElementById("add_galleryCategory").close();
          }}
        />
      </div>
    </ModalManagement>
  );
};

export default AddEditGalleryCategory;
