import { aboutUsGspm } from "assets/images";
import ContainerWithVerticalText from "generalComponents/ContainerWithVerticalText";
import React from "react";

const GlobalGSPM = () => {
  return (
    <div className="flex flex-wrap justify-around items-center md:grid grid-cols-2 gap-10 py-10 max-w-section mx-auto">
      <div className="flex md:justify-start px-5 md:px-0">
        <img src={aboutUsGspm} alt="" className="object-cover min-h-[400px] " />
      </div>
      <ContainerWithVerticalText
        text={
          <>
            Global Senior Pastor Missions
            {/* <br /> & Operations */}
          </>
        }
        className="px-5 md:mr-auto"
      >
        <h2 className="font-semibold text-3xl lg:text-5xl text-black mb-6 ">
          <span className="text-xl text-purpleBg font-normal tracking-[0.5em]">
            PASTOR
          </span>{" "}
          <br />
          MODELE <br className="hidden md:block" />
          FATOYINBO
        </h2>
        <p className="text-sm text-gray-500">
          Pastor Modele Fatoyinbo is a dedicated minister, dynamic leader, and
          an integral part of the Commonwealth of Zion Assembly (COZA). As the
          First Lady and Co-Pastor of COZA, she plays a pivotal role in the
          ministry, providing spiritual guidance, mentorship, and support to
          thousands of believers. Her warm, compassionate nature, prayers and
          profound teachings have made a lasting impact on many lives.
          <br />
          <br />
          Pastor Modele's ministry is characterised by a passion for empowering
          women, strengthening families, and nurturing spiritual growth. She is
          known for her powerful, intense prayers and instructional teachings
          that inspire and equip individuals to lead lives of purpose and
          influence.
          <br />
          As Global Senior Pastor Missions and Operations, she works closely
          with her husband, Pastor Biodun Fatoyinbo, in overseeing the church's
          various programmes and initiatives. Her leadership style is marked by
          compassion, integrity, and a genuine desire to see people develop an
          intimate relationship with God, grow and succeed in their walk with
          God.
        </p>
        {/* <RoundedButton className="px-5 py-3 flex items-center gap-2 mt-5">
        <span>Know more</span>
        <ArrowRight />
      </RoundedButton> */}
      </ContainerWithVerticalText>
    </div>
  );
};

export default GlobalGSPM;
