import { AxiosFaceRecognitionApp } from "constants/api_management/MyHttpHelperWithToken";
import usePostManager from "constants/controller_templates/post_controller_template";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";

export const useInitialiseNewAttendance = () => {
  const queryClient = useQueryClient();
  const { postCaller, isLoading, isSuccess, error, data } = usePostManager(
    `${process.env.REACT_APP_FACE_RECOGNITION_APP_BASE_URL}/attendance/match-members`,
    ["serviceAttendance"],
    false,
    AxiosFaceRecognitionApp
  );

  const initialiseNewAttendance = async (details) => {
    try {
      await postCaller(details);
      await queryClient.invalidateQueries(["serviceAttendance"])
      toast.success("Image analysis initialised, kindly check back once completed");
    } catch (error) {
      // Handle error if necessary
    }
  };
  return {
    initialiseNewAttendance,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
