import AxiosWithToken from "constants/api_management/MyHttpHelperWithToken";
import { useQuery } from "react-query";
import { useUserStore } from "store/userStore";

export const useGetUser = () => {
  const setUser = useUserStore((state) => state.setUser);
  return useQuery(
    ["getUser"],
    async () => {
      try {
        const [response] = [await AxiosWithToken.get(`/user`)];
        //console.log(`this is the current subscription status ${response}`);
        setUser(response.data?.data);
        return response.data;
      } catch (error) {
        //console.log(error.response.data);
        throw new Error(`Sorry: ${error.response.data.message}`);
      }
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    }
  );
};
