import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

// type Props = {
//   children: ReactNode;
//   extraClass?: string;
//   titleExtraClass?: string;
//   onOpenTitleExtraClass?: string;
//   titleBtnProps?: React.ComponentProps<"button">;
//   disabled?: boolean;
//   itemExtraClass?: string;
//   items: {
//     label: string | JSX.Element;
//     linkUrl?: string;
//     style?: string;
//     disabled?: boolean;
//     onClick?: (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
//   }[];
// };

export default function MenuItems(props) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      {({ open }) => (
        <Fragment>
          <Menu.Button
            disabled={props.disabled}
            onClick={(e) => e.stopPropagation()}
            className={twMerge(
              "flex items-center gap-2 hover:bg-bg-gray rounded-md p-2 disabled:opacity-60",
              props.titleExtraClass,
              open && "bg-bg-gray",
              open && props.onOpenTitleExtraClass
            )}
            {...props.titleBtnProps}
          >
            {props.children}
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              className={twMerge(
                "absolute left-0 md:right-0 md:left-auto z-[99] origin-top-right translate-y-1 divide-y divide-gray-100",
                "mt-1 min-w-[150px] w-fit",
                "rounded-md bg-white custom-shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none",
                "border border-gray-100 overflow-hidden",
                props.extraClass
              )}
            >
              {props.items.map((item, i) => {
                return (
                  <Menu.Item key={i}>
                    {({ active, close }) => {
                      return item.linkUrl ? (
                        <Link
                          to={item.linkUrl}
                          onClick={(e) => {
                            e.stopPropagation();
                            item.onClick?.(e);
                            close();
                          }}
                          className={twMerge(
                            `block whitespace-nowrap px-4 py-2 w-full text-left`,
                            active ? "bg-gray-200" : "",
                            props.itemExtraClass,
                            item.style
                          )}
                        >
                          {item.label}
                        </Link>
                      ) : (
                        <button
                          disabled={item.disabled}
                          onClick={(e) => {
                            e.stopPropagation();
                            item.onClick?.(e);
                            close();
                          }}
                          type="button"
                          className={twMerge(
                            `whitespace-nowrap px-4 py-2 w-full disabled:text-light text-left`,
                            active ? "bg-gray-200" : "",
                            props.itemExtraClass,
                            item.style
                          )}
                        >
                          {item.label}
                        </button>
                      );
                    }}
                  </Menu.Item>
                );
              })}
            </Menu.Items>
          </Transition>
        </Fragment>
      )}
    </Menu>
  );
}
