import React, { useEffect, useMemo, useRef, useState } from "react";
import { format } from "date-fns";
import BaseDashboardNavigation from "../../../../generalComponents/BaseDashboardNavigation";

import PaginationRounded from "../../../../generalComponents/Pagination";

import useGetCategories from "modules/pageSermons/controllers/get_categories_controller";
import useDebounce from "utils/UseDebounce";
import VideoItem from "generalComponents/VideoItem";

import AudioItem from "generalComponents/AudioItem";
import CustomButton from "generalComponents/Button";
import SearchComponent from "generalComponents/SearchComponent";
import InputWithFullBoarder from "generalComponents/InputWithFullBoarder";
import CustomDropdown from "generalComponents/CustomDropdown";
import TabsManagement from "generalComponents/TabsManagement";
import Loader from "generalComponents/Loader";

import AdminDeleteConfirmationModal from "generalComponents/AdminDeleteConfirmationModal";

import AddEditKids from "../components/AddEditKids";
import ViewKidsContent from "../components/ViewKidsContent";
import { DeleteChildrenContentManager } from "../controllers/deleteChildrenContentController";
import useGetChildrenContentManager from "../controllers/getChildrenContentListController";
import CategoryButton from "generalComponents/CategoryButton";
import AdminWrapper from "modules/admin/AdminWrapper";
import SelectInput from "generalComponents/SelectInput";
import { twMerge } from "tailwind-merge";

const AdminChildrenPage = () => {
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(`&title=${searchValue}`, 1000);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const tabs = ["Audio", "Video"];
  const [selected, setSelected] = useState(null);
  const [selectedCat, setSelectedCat] = useState("children");
  const { deleteContent, isLoading: deleting } = DeleteChildrenContentManager({
    contentId: selected?.id,
  });
  const [selectedCategory, setSelectedCategory] = useState("");

  const { data: categories } = useGetCategories({
    group: selectedCat,
  });
  const categoryList = useMemo(() => {
    const list =
      categories?.data?.categories.map((el) => ({
        label: el?.name,
        value: el?.id,
      })) || [];
    return list;
  }, [categories]);

  const { data, isLoading } = useGetChildrenContentManager({
    page: currentPage,
    searchQuery: searchValue,
    type: currentIndex === 0 ? "video" : "audio",
    category: selectedCategory,
  });

  const type = ["video", "audio"];
  const groupList = [
    { name: "Kids Devotional", value: "children" },
    { name: "Kids Animation", value: "kidsAnimation" },
    { name: "Kids Games", value: "kidsGames" },
  ];

  useEffect(() => {
    if (selected) {
      document.getElementById("view_kidsContent").showModal();
    }
  }, [selected]);

  return (
    <AdminWrapper
      title={"COZA Kids"}
      value={searchValue}
      onChange={(e) => {
        setSearchValue(e.target.value);
      }}
    >
      <div className="w-full flex flex-col">
        <div className="flex justify-between flex-col gap-5 md:flex-row w-full mb-10">
          <div className=" flex flex-col items-start">
            <p className="text-[24px] font-semibold">COZA Kids</p>
            <p className="text-14px">Showing data over the last 30 days</p>
          </div>
          <div className="mb-3">
            <CustomButton
              buttonText={`+Upload Content`}
              onClick={() => {
                setSelected(null);
                document.getElementById("add_kidsContent").showModal();
              }}
              radius={`[5px]`}
              className={`max-w-max`}
            />
          </div>
        </div>
        <div className="flex gap-2 max-w-[1240px] overflow-x-scroll scrollbar-hide mb-5">
          {groupList.map((group, index) => (
            <CategoryButton
              datatip="use this button to determine the list of category to display."
              key={index}
              group={group.name}
              selected={selectedCat === group.value}
              onClick={() => {
                setSelectedCat(group.value);
              }}
            />
          ))}
        </div>
        <div className="flex items-center justify-between flex-col gap-5 md:flex-row">
          <div className="max-w-[501px] w-full flex items-center flex-col gap-5 md:flex-row">
            <SearchComponent
              value={searchValue}
              marginRight={"mr-3"}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <SelectInput
              placeholder={"Category"}
              options={[{ label: "All", value: "" }, ...categoryList]}
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              className={twMerge(
                "text-xs py-2.5 mb-0 h-10 md:min-w-[140px]",
                selectedCategory === "" ? "text-gray-500" : ""
              )}
              containerClass="mb-0 p-0"
            />
          </div>
          <InputWithFullBoarder type={"date"} />
        </div>

        <div className="w-full my-3">
          <TabsManagement
            options={type}
            currentView={currentIndex}
            setCurrentView={setCurrentIndex}
          />
        </div>
        <div className="md:h-[67vh] relative md:overflow-y-auto scrollbar-hide">
          {isLoading ? (
            <Loader />
          ) : (
            <div className="flex flex-wrap gap-5 mx-auto">
              {data?.data?.items?.map((message, mIndex) =>
                currentIndex === 0 ? (
                  <VideoItem
                    key={mIndex}
                    message={message}
                    isAdmin={true}
                    showPrice={message?.subscription_type === "paid"}
                    onClick={() => {
                      setSelected(message);
                      if (selected?.id === message?.id) {
                        document.getElementById("view_kidsContent").showModal();
                      }
                    }}
                  />
                ) : (
                  <AudioItem
                    key={mIndex}
                    message={message}
                    isAdmin={true}
                    showPrice={message?.subscription_type === "paid"}
                    onClick={() => {
                      setSelected(message);
                      if (selected?.id === message?.id) {
                        document.getElementById("view_kidsContent").showModal();
                      }
                    }}
                  />
                )
              )}
            </div>
          )}
        </div>

        {data?.data?.pagination?.total > 0 && (
          <div className="flex justify-end mt-10 min-w-full">
            <PaginationRounded
              count={data?.pagination?.pageTotal}
              defaultPage={data?.pagination?.currentPage}
              onChange={(page) => {
                setCurrentPage(page);
              }}
            />
          </div>
        )}
      </div>
      {selected && <ViewKidsContent details={selected} />}
      <AddEditKids
        details={selected}
        categories={categories?.data?.categories}
      />
      <AdminDeleteConfirmationModal
        title={"Delete content"}
        body={`Do you really want to delete ${selected?.title} from the list of COZA Kids?`}
        buttonText={`Yes, Delete`}
        onClick={async () => {
          await deleteContent();
          document.getElementById("admin_delete").close();
          document.getElementById("view_kidsContent").close();
        }}
        isLoading={deleting}
      />
    </AdminWrapper>
  );
};

export default AdminChildrenPage;
