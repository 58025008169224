import { format } from "date-fns";
import StatusButton from "generalComponents/StatusButton";

export const validTableKeys = {
  serviceName: {
    title: "Service",
    addBadge: false,
    isImage: false,
    addCopyPreview: false,
    showOnMobile: true,
  },
  campusName: {
    title: "Campus",
    addBadge: false,
    isImage: false,
    addCopyPreview: false,
    showOnMobile: true,
  },
  serviceStartTime: {
    title: "Service Date & Time",
    addBadge: false,
    isSortable: false,
    isImage: false,
    addCopyPreview: false,
    showOnMobile: true,
  },
  photoUploadCount: {
    title: "Images Uploaded",
    addBadge: false,
    isSortable: false,
    isImage: false,
    addCopyPreview: false,
    showOnMobile: true,
  },
  // matchCount: {
  //   title: "Match Count",
  //   addBadge: false,
  //   isSortable: false,
  //   isImage: false,
  //   addCopyPreview: false,
  //   sortByString: false,
  //   showOnMobile: true,
  // },
  // noMatchCount: {
  //   title: "No Match Count",
  //   addBadge: false,
  //   isSortable: false,
  //   isImage: false,
  //   addCopyPreview: false,
  //   sortByString: false,
  //   showOnMobile: true,
  // },
  status: {
    title: "Status",
    addBadge: false,
    isSortable: false,
    isImage: false,
    addCopyPreview: false,
    sortByString: false,
    showOnMobile: true,
  }
};
export function modifyData(data) {
  if (!data?.length) return [];
  const newArray = data?.map((item) => ({
    ...item,
    serviceAttendanceId: item._id,
    serviceId: item.serviceId,
    campusId: item.campusId,
    noMatchCount: item?.unmatchedAttendeeIds?.length,
    status: <StatusButton status={item?.status} />,
    process_status: item.status,
    serviceStartTime: format(item?.serviceStartTime, "MMM dd, yyyy (p)"),
    processStartTime: format(item?.processStartTime, "MMM dd, yyyy (pp)"),
    processEndTime: format(item?.processEndTime, "MMM dd, yyyy (pp)"),
  }));

  return newArray;
}
