import { format, isValid, parse } from "date-fns";

export const formatDate = (dateString) => {
  const date = new Date(dateString);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    return "Invalid Date";
  }

  return format(date, "MMMM d, yyyy");
};

export const convertTo24hr = (time) => {
  let parsedTime;

  // Try parsing as 12-hour format
  parsedTime = parse(time, "hh:mma", new Date());
  if (!isValid(parsedTime)) {
    // Try parsing as 24-hour format if 12-hour format is invalid
    parsedTime = parse(time, "HH:mm", new Date());
  }

  // If the parsed time is still invalid, return an empty string
  if (!isValid(parsedTime)) {
    return "";
  }

  // Format the time to 24-hour format
  return format(parsedTime, "HH:mm");
};
