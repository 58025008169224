const AccordionItem = ({ id, label, content, isSelected, allowMultiple }) => {
  return (
    <div className="grid grid-rows-[auto_0fr] has-[input:checked]:grid-rows-[auto_1fr] transition-[grid-template-rows] duration-300 overflow-hidden ease-out">
      <label className="flex justify-between items-center pl-8 font-bold cursor-pointer group self-start bg-gradient-to-r from-purpleOverlay via-purpleBg to-[#7A10A6]/20">
        <div className="w-full">{label}</div>
        <span>
          {/* <CircleChevronIcon className="has-[~_input:checked]:-rotate-90 group-hover:[&:not(&:has(~_input:checked))]:scale-125_ has-[~_input:checked]:opacity-30 transition-all duration-300 ease-out" /> */}
          <input
            hidden
            type={allowMultiple ? "checkbox" : "radio"}
            name="accordion"
            defaultChecked={isSelected}
            value={id}
          />
        </span>
      </label>
      <div className="text-black px-8 pb-0 [&:is(:has(input:checked)_+_*)]:py-8 transition-[padding] duration-300 overflow-hidden ease-out">
        {/* <hr className="pb-4" /> */}
        <div>{content}</div>
      </div>
    </div>
  );
};

const Accordion = ({ list, allowMultiple }) => {
  const uuid = new Date().getTime();

  return (
    <form className="gap-1 grid pb-3">
      {list?.map((itm, idx) => (
        <AccordionItem
          key={idx}
          id={`${uuid}-${idx}`}
          {...itm}
          allowMultiple={allowMultiple}
        />
      ))}
    </form>
  );
};

export default Accordion;
