import React from "react";
import { twMerge } from "tailwind-merge";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";

/**
 * @param {React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>} param
 * @param {string} param.status
 * @param {string} param.color
 * @param {boolean} param.capitalise
 * @param {VoidFunction} param.onClick
 * @param {string} param.className
 * @param {string} param.backgroundColor
 * @returns
 */

const StatusButton = ({
  status,
  onClick,
  className,
  color,
  capitalise = true,
  backgroundColor,
  ...props
}) => {
  let styles = {};

  switch (status?.toLowerCase()) {
    case "processing":
      styles = {
        backgroundColor: "#FFF3CD",
        color: "#856404",
        text: "Processing",
      };
      break;
    case "pending":
      styles = {
        backgroundColor: "#FFF3CD",
        color: "#856404",
        text: "Pending",
      };
      break;
    case "under_review":
      styles = {
        backgroundColor: "#FFF3CD",
        color: "#856404",
        text: "Under Review",
      };
      break;
    case "completed":
      styles = {
        backgroundColor: "#E7F6EC",
        color: "#036B26",
        text: "Completed",
      };
      break;
    case "approved":
      styles = {
        backgroundColor: "#E7F6EC",
        color: "#036B26",
        text: "Approved",
      };
      break;
    case "open":
      styles = {
        backgroundColor: "#E7F6EC",
        color: "#036B26",
        text: "Open",
      };
      break;
    case "accepted":
      styles = {
        backgroundColor: "#E7F6EC",
        color: "#036B26",
        text: "Accepted",
      };
      break;
    case "cancelled":
    case "aborted":
      styles = {
        backgroundColor: "#F8D7DA",
        color: "#721C24",
        text: "Cancelled",
      };
      break;
    case "declined":
    case "failed":
      styles = {
        backgroundColor: "#F8D7DA",
        color: "#721C24",
        text: "Declined",
      };
      break;
    case "unapproved":
      styles = {
        backgroundColor: "#F8D7DA",
        color: "#721C24",
        text: "Unapproved",
      };
      break;
    case "delete":
      styles = {
        backgroundColor: "#F8D7DA",
        color: "#721C24",
        text: "Delete",
      };
      break;
    default:
      styles = {
        backgroundColor: backgroundColor ?? "#E2E3E5",
        color: color ?? "#383D41",
        text: capitalise ? capitalizeFirstLetter(status) : status,
      };
      break;
  }

  return (
    <button
      onClick={onClick}
      className={twMerge("text-14px rounded-[20px] px-3 py-1", className)}
      style={{ backgroundColor: styles.backgroundColor, color: styles.color }}
      {...props}
    >
      {styles.text}
    </button>
  );
};

export default StatusButton;
