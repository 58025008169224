import React, { useEffect, useMemo, useState } from "react";
// import { format } from "date-fns";
// import BaseDashboardNavigation from "../../../../generalComponents/BaseDashboardNavigation";

import InputWithFullBoarder from "../../../../generalComponents/InputWithFullBoarder";

import useGetPraiseReportListManager from "../controllers/getPraiseReportListController";

// import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";

import SearchComponent from "generalComponents/SearchComponent";
// import TablesComponent from "generalComponents/TablesComponent";
import useDebounce from "utils/UseDebounce";
// import AvatarWithTitle from "generalComponents/AvatarWithTitle";
// import { formatDate } from "utils/formatDate";
// import PaginationRounded from "generalComponents/Pagination";
import ButtonWithIcon from "generalComponents/ButtonWithIcon";
import { MdAddCircleOutline } from "react-icons/md";
// import CustomDropdown from "generalComponents/CustomDropdown";
// import StatusButton from "generalComponents/StatusButton";
import ViewPraiseReport from "../component/ViewPraiseReport";
import AdminDeleteConfirmationModal from "generalComponents/AdminDeleteConfirmationModal";
import { DeletePraiseReportManager } from "../controllers/deletePraiseReportController";
import AddEditPraiseReport from "../component/AddEditPraiseReport";
import useGetCategoryListManager from "modules/admin/categories/controllers/getCategoryListController";
import AdminWrapper from "modules/admin/AdminWrapper";
import DataTable from "generalComponents/DataTable";
import { modifyData, validTableKeys } from "../utils";
import Loader from "generalComponents/Loader";
import SelectInput from "generalComponents/SelectInput";
import { twMerge } from "tailwind-merge";

const AdminPraiseReportPage = () => {
  const [searchValue, setSearchValue] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const debouncedSearchValue = useDebounce(`&first_name=${searchValue}`, 1000);
  // const [currentPage, setCurrentPage] = useState(1);
  const [selected, setSelected] = useState(null);
  const [tableValues, setTableValues] = useState({
    pageIndex: 0,
    perPage: 20,
  });
  function setter(name, value) {
    setTableValues((val) => ({
      ...val,
      [name]: value,
    }));
  }

  const { deletePraiseReport, isLoading: deleting } = DeletePraiseReportManager(
    selected?.id
  );

  const { data, isLoading, refetch } = useGetPraiseReportListManager({
    page: tableValues?.pageIndex + 1,
    pageSize: tableValues?.perPage,
    searchQuery: searchValue ? debouncedSearchValue : "",
    category: selectedCategory === "" ? undefined : selectedCategory,
    enabled: true,
  });

  const { data: categories, isLoading: loadingCategories } =
    useGetCategoryListManager({
      enabled: true,
      group: "?group=testimony",
    });

  const categoryList = useMemo(() => {
    const list =
      categories?.data?.categories.map((el) => ({
        label: el?.name,
        value: el?.id,
      })) || [];
    return list;
  }, [categories]);

  function onRowClick(rowData) {
    setSelected(rowData);
    if (selected?.id === rowData?.id) {
      document.getElementById("view_praiseReport")?.showModal();
    }
  }

  useEffect(() => {
    if (selected) {
      document.getElementById("view_praiseReport")?.showModal();
    }
  }, [selected]);

  // const tableHeads = ["Name", "Email", "Category", "Status", "Date", "Action"];
  // const getFormattedValue = (el, index) => {
  //   return [
  //     <AvatarWithTitle
  //       image={el?.user?.profile_picture}
  //       title={`${el?.user?.first_name} ${el?.user?.last_name}`}
  //     />,
  //     el?.user?.email,
  //     el?.category?.name,
  //     <StatusButton status={el?.approved ? "approved" : "pending"} />,
  //     formatDate(el?.createdAt),
  //   ];
  // };

  return (
    <AdminWrapper
      title={"Praise Reports"}
      value={searchValue}
      onChange={(e) => {
        setSearchValue(e.target.value);
      }}
    >
      <div className="flex flex-col h-[90vh] pr-10">
        <div className="flex justify-between flex-col gap-5 md:flex-row w-full mb-10">
          <div className=" flex flex-col items-start">
            <p className="text-[24px] font-semibold">Praise Report</p>
            <p className="text-14px">
              {data?.data?.pagination?.total} Praise reports in total
            </p>
          </div>
          <div className="mb-3">
            <ButtonWithIcon
              onClick={async () => {
                setSelected(null);
                document.getElementById("add_praiseReport").showModal();
              }}
              icon={MdAddCircleOutline}
              buttonText={`Upload a Praise report `}
              radius={`[5px]`}
              className={`max-w-max`}
            />
          </div>
        </div>
        <div className="flex items-center justify-between flex-col md:flex-row">
          <div className="max-w-max w-full flex items-center gap-3">
            <SearchComponent
              value={searchValue}
              marginRight=""
              onChange={(e) => setSearchValue(e.target.value)}
            />
            {/* <CustomDropdown
              placeholder={"Categories"}
              options={categories?.data?.categories.map((el) => el?.name)}
            /> */}
            <SelectInput
              placeholder={"Category"}
              options={[{ label: "All", value: "" }, ...categoryList]}
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              className={twMerge(
                "text-xs py-2.5 mb-0 h-10 md:min-w-[140px]",
                selectedCategory === "" ? "text-gray-500" : ""
              )}
              containerClass="mb-0 p-0"
            />
          </div>
          <InputWithFullBoarder type={"date"} />
        </div>

        {/* user tables */}
        <div className="md:h-[67vh] relative md:overflow-y-auto scrollbar-hide">
          {isLoading ? (
            <Loader isSmall />
          ) : (
            <DataTable
              tableData={modifyData(data?.data?.testimonies)}
              totalItemCount={data?.data?.pagination?.total}
              validTableKeys={validTableKeys}
              onRowClick={onRowClick}
              noOfItemsPerPage={tableValues?.perPage}
              onCheckClick={() => null}
              onItemsPerPageChange={(newPage) => setter("perPage", newPage)}
              pageIndex={tableValues?.pageIndex}
              onPageIndexChange={(idx) => setter("pageIndex", idx)}
              showFooter
            />
          )}
          {/* <TablesComponent
            data={data?.data?.testimonies}
            headers={tableHeads}
            isLoading={isLoading}
            getFormattedValue={getFormattedValue}
            buttonFunction={(val) => {
              console.log(val);
              setSelected(val);
              if (selected?.id === val?.id) {
                document.getElementById("view_praiseReport")?.showModal();
              }
            }}
          /> */}
        </div>

        {/* {data?.users?.length && (
          <div className="mb-10 flex items-center justify-center">
            <PaginationRounded
              count={data?.pagination?.pageTotal}
              defaultPage={data?.pagination?.currentPage}
              onChange={(page) => {
                setCurrentPage(page);
              }}
            />
          </div>
        )} */}
      </div>
      {selected && <ViewPraiseReport details={selected} />}
      <AddEditPraiseReport
        details={selected}
        categories={categories?.data?.categories}
      />
      <AdminDeleteConfirmationModal
        title={"Delete Praise Report"}
        body={`Do you really want to delete ${selected?.title} from the list of praise reports?`}
        buttonText={`Yes, Delete`}
        onClick={async () => {
          await deletePraiseReport();
          document.getElementById("admin_delete").close();
          document.getElementById("view_praiseReport").close();
        }}
        isLoading={deleting}
      />
    </AdminWrapper>
  );
};

export default AdminPraiseReportPage;
