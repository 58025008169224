import usePostManager from "constants/controller_templates/post_controller_template";
import { useQueryClient } from "react-query";

export const CreatePraiseReportManager = () => {
  const queryClient = useQueryClient();
  const { postCaller, isLoading, isSuccess, error, data } = usePostManager(
    `/testimony/create`,
    ["praiseReports"]
  );
  const createPraiseReport = async (details) => {
    try {
      await postCaller(details);
      await queryClient.invalidateQueries(["praiseReports"])
    } catch (error) {
      // Handle error if necessary
      console.error("post error:", error);
    }
  };
  return {
    createPraiseReport,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
