import React, { forwardRef } from "react";

const SermonEngagementButton = forwardRef((props, ref) => {
  const { className, children, ...rest } = props;
  return (
    <button
      ref={ref}
      {...rest}
      className={`px-4 py-1.5 rounded-full bg-neutral-100 text-neutral-900 duration-300 hover:scale-95 ${className}`}
    >
      {children}
    </button>
  );
});

export default SermonEngagementButton;
