import React, { useRef } from "react";
import { twMerge } from "tailwind-merge";
import { useAudioContext } from "../view";

function secondsToHMS(seconds) {
  // Calculate hours, minutes, and seconds
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = Math.floor(seconds % 60);

  // Pad hours, minutes, and seconds with leading zeros if necessary
  const paddedHours = String(hours).padStart(2, "0");
  const paddedMinutes = String(minutes).padStart(2, "0");
  const paddedSeconds = String(secs).padStart(2, "0");

  // Return formatted time
  return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
}

const AudioProgress = ({ className }) => {
  const { duration, currentProgress, audioElement } = useAudioContext();
  const progressBar = useRef();
  const click = (e) => {
    // e.stopPropagation();
    // console.log("click");
    const width = e.target?.getBoundingClientRect()?.width;
    const rectX = e.target?.getBoundingClientRect()?.x;
    const clientX = e?.clientX;
    const xClick = Math.floor(clientX - rectX);
    const left = (xClick * 100) / width;
    const newProgress = (left / 100) * duration;
    audioElement.currentTime = newProgress;
  };
  const drag = (e) => {
    // console.log("drag");
    // console.log({
    //   rect: e?.target?.getBoundingClientRect(),
    //   client: e?.clientX,
    //   width: window.getComputedStyle(progressBar?.current)?.width,
    // });
    // const progressWidth = window.getComputedStyle(progressBar?.current)?.width;
    // const distance = e?.clientX - e?.target?.getBoundingClientRect()?.x;
    // const progress = (distance * duration) / progressWidth;
    // audioElement.currentTime = progress;
  };
  return (
    <div className={twMerge("w-full px-5", className)}>
      <div className="flex justify-between items-center w-full text-xs text-purpleBg ">
        <p>{secondsToHMS(currentProgress)}</p>
        <p>{secondsToHMS(duration)}</p>
      </div>

      <div
        ref={progressBar}
        onClick={click}
        className="my-2.5 h-[3px] bg-[#E0E0E0] w-full relative cursor-pointer"
      >
        <div
          onDrag={drag}
          draggable
          style={{
            left: `calc(100%*${currentProgress}/${duration})`,
          }}
          className={twMerge(
            "size-[13px] bg-purpleBg rounded-full absolute left-0 top-[50%] bottom-[50%] my-auto"
          )}
        ></div>
        <div
          style={{
            width: `calc(100%*${currentProgress}/${duration})`,
          }}
          className={twMerge("h-full bg-purpleBg absolute left-0 top-0")}
        ></div>
      </div>
    </div>
  );
};

export default AudioProgress;
