import { ClockIcon } from "@heroicons/react/outline";
import { capitalizeFirstLetter } from "constants/textFormatters";
import React from "react";
import { IoMdTrash } from "react-icons/io";
import { twMerge } from "tailwind-merge";
import { useDeleteAppointment } from "../controllers/delete_controller";
import useDisclosure from "constants/useDisclosure";
import CustomModalHeadless from "generalComponents/CustomModalHeadless";

const Column = ({
  title,
  value,
  className,
  titleClass,
  valueClass,
  badge,
  badgeColor,
  waiting,
  center,
}) => (
  <p
    className={twMerge(
      "flex flex-col gap-1 md:mx-auto",
      center && "items-center",
      className
    )}
  >
    <span
      className={twMerge(
        "hidden md:block text-[10px] text-purpleOverlay",
        titleClass
      )}
    >
      {title}
    </span>
    <span
      className={twMerge(
        "block font-medium text-purpleOverlay",
        center && "text-center",
        badge &&
          "rounded-[13px] px-2.5 py-1 text-white text-[10px] font-normal",
        badgeColor === "orange" && "bg-orangeBg",
        badgeColor === "green" && "bg-[#4CB050]",
        badgeColor === "purple" && "bg-purpleBg",
        badgeColor === "red" && "bg-red-150",
        // waiting && "text-gray-400 italic",
        valueClass
      )}
    >
      {!waiting ? (
        value
      ) : (
        <span className="flex items-center md:items-start gap-1">
          <ClockIcon className="h-4 w-4 text-purpleOverlay md:hidden" /> Date to
          be determined
        </span>
      )}
    </span>
  </p>
);

const AppointmentItem = ({
  title,
  date,
  time,
  requested_person,
  status,
  id,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { deleteAppointment } = useDeleteAppointment();
  return (
    <div className="rounded-[10px] border border-gray-300 py-3 px-2 md:px-5 flex flex-col items-start md:grid grid-cols-2 md:grid-cols-5 gap-3 md:gap-0">
      <Column title="Title" value={title} />
      <Column
        title="Date"
        value={`${date} (${time})`}
        center
        waiting={status?.toLowerCase() === "requested"}
      />
      {/* <Column
        title="Time"
        value={time}
        waiting={status?.toLowerCase() === "requested"}
      /> */}
      <Column
        title="With"
        center
        value={requested_person}
        valueClass={"font-light md:font-medium"}
        // waiting={status?.toLowerCase() === "requested"}
      />
      <Column
        title="Status"
        value={capitalizeFirstLetter(status)}
        center
        // className={"ml-auto md:ml-0"}
        badge
        badgeColor={
          status?.toLowerCase() === "requested"
            ? "orange"
            : status?.toLowerCase() === "accepted"
            ? "green"
            : status?.toLowerCase() === "assigned"
            ? "purple"
            : status?.toLowerCase() === "declined" && "red"
        }
      />

      <button
        onClick={onOpen}
        className="flex items-center gap-2 ml-auto mt-auto"
      >
        <IoMdTrash className="h-6 w-6 text-red-150" />{" "}
        <span className="text-red-150">Delete</span>{" "}
      </button>

      {isOpen && (
        <CustomModalHeadless
          {...{
            isOpen,
            onClose,
            showCloseButton: false,
            dialogClass: "p-0",
          }}
        >
          <div className="w-full flex flex-col divide-y divide-lightGrey">
            <button
              onClick={async () => {
                try {
                  await deleteAppointment(id);
                } catch (error) {
                  console.error(error);
                }
              }}
              className="text-red-500 text-sm font-medium w-full py-3 hover:bg-textLightGrey"
            >
              Delete
            </button>
            <button
              onClick={onClose}
              className="text-textDark text-sm font-medium w-full py-3 hover:bg-textLightGrey"
            >
              Cancel
            </button>
          </div>
        </CustomModalHeadless>
      )}
    </div>
  );
};

export default AppointmentItem;

export const appointmentList = [
  {
    title: "Youths Donations",
    date: "",
    time: "",
    assigned_to: "",
    status: "Requested",
  },
  {
    title: "Youths Donations",
    date: "23rd July 2024 ",
    time: "12:00pm",
    assigned_to: "Pastor Ayo",
    status: "Assigned",
  },
  {
    title: "Youths Donations",
    date: "23rd July 2024 ",
    time: "12:00pm",
    assigned_to: "Pastor Ayo",
    status: "Assigned",
  },
  {
    title: "Youths Donations",
    date: "23rd July 2024 ",
    time: "12:00pm",
    assigned_to: "Pastor Ayo",
    status: "Rescheduled",
  },
];
