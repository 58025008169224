import React, { useEffect, useState } from "react";

import Loader from "./Loader";
import OptionsPopup from "./PopupOptions";
import { MdOutlineVisibility } from "react-icons/md";
import { renderData } from "../utils/renderData";
import { moreMore, rightArrowButton } from "../assets/icons";

const TablesComponent = ({
  data,
  isLoading,
  hideActionButton = false,
  headers,
  options = [],
  popUpFunction,
  showCheckBox = true,
  buttonFunction,
  getFormattedValue,
  toggleRowFunction,
  toggleSelectAllFunction,
  setSelectedRows,
  selectedRows = [],
}) => {
  const [selected, setSelected] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div class=" bg-white  w-full relative h-full overflow-y-auto scrollbar-hide">
      <div class="w-full relative h-full">
        <table class="lg:divide-gray-200 lg:divide-y w-full h-full relative">
          <thead class="hidden lg:table-header-group sticky top-0 bg-[#F0F2F5] z-10 w-full">
            <tr>
              {headers.map((header, i) => (
                <th
                  key={i}
                  class="py-3.5 px-4 text-left  font-medium text-black text-[12px] "
                >
                  <div className="flex gap-3 items-center max-w-max">
                    {header}
                  </div>
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {data &&
              data.map((eachRow, index) => {
                const formatedValue = getFormattedValue(eachRow, index);
                return (
                  <tr
                    key={index}
                    class={`bg-white ${
                      index === 9
                        ? ""
                        : "border border-transparent border-b-[#E5E7EB] pb-2.5"
                    }`}
                  >
                    <td class="px-4 py-5 text-[14px]  text-gray-900 align-top lg:align-middle whitespace-nowrap">
                      <div className="flex items-center gap-3">
                        {renderData(formatedValue[0])}
                      </div>

                      <div class="mt-1 space-y-2 pl-11 lg:hidden">
                        {formatedValue
                          .slice(1)
                          .map((mobileContent, mobileIndex) => (
                            <div className="flex flex-col">
                              {renderData(mobileContent)}
                            </div>
                          ))}
                        {!hideActionButton && (
                          <ActionButton
                            setSelected={setSelected}
                            eachRow={eachRow}
                            options={options}
                            setShowOptions={setShowOptions}
                            index={index}
                            currentIndex={currentIndex}
                            showOptions={showOptions}
                            buttonFunction={buttonFunction}
                            setCurrentIndex={setCurrentIndex}
                            popUpFunction={popUpFunction}
                            selected={selected}
                          />
                        )}
                      </div>
                    </td>
                    {formatedValue.slice(1).map((item, i) => (
                      <td
                        key={i}
                        class="hidden px-4 py-5 text-sm font-medium text-gray-900 lg:table-cell whitespace-nowrap"
                      >
                        {renderData(item)}
                      </td>
                    ))}
                    {!hideActionButton && (
                      <td class="hidden px-4 py-5 lg:table-cell whitespace-nowrap">
                        <ActionButton
                          setSelected={setSelected}
                          eachRow={eachRow}
                          options={options}
                          setShowOptions={setShowOptions}
                          index={index}
                          currentIndex={currentIndex}
                          showOptions={showOptions}
                          buttonFunction={buttonFunction}
                          setCurrentIndex={setCurrentIndex}
                          popUpFunction={popUpFunction}
                          selected={selected}
                        />
                      </td>
                    )}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TablesComponent;

const ActionButton = ({
  setSelected,
  eachRow,
  options,
  setShowOptions,
  index,
  currentIndex,
  showOptions,
  buttonFunction,
  setCurrentIndex,
  popUpFunction,
  selected,
}) => {
  return (
    <div class="flex items-center space-x-4">
      <button
        type="button"
        onClick={() => {
          setSelected(eachRow);
          if (options.length > 0) {
            setShowOptions(index === currentIndex ? !showOptions : true);
          } else {
            buttonFunction(eachRow);
          }
          setCurrentIndex(index);
        }}
        class="text-14px font-medium text-brandGreen"
      >
        {options.length > 0 ? (
          <img src={moreMore} alt="" />
        ) : (
          <img src={rightArrowButton} alt="" />
        )}
      </button>
      <div className="relative">
        {currentIndex === index && showOptions && (
          <OptionsPopup
            options={options}
            popUpFunction={(option, inx) => {
              setShowOptions(false);
              popUpFunction(option, inx, selected);
              console.log(option, inx, selected);
            }}
          />
        )}
      </div>
    </div>
  );
};
