import React from "react";
import { twMerge } from "tailwind-merge";

const GradientText = ({ text, className = "" }) => (
  <span
    className={twMerge(
      "font-bold sm:text-[400px] sm:leading-[400px] m-0 p-0 ",
      "bg-clip-text text-transparent bg-gradient-to-b from-textLightGrey to-white",
      className
    )}
  >
    {text}
  </span>
);

export default GradientText;
