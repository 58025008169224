import React from "react";

const CategoryButton = ({ group, onClick, selected, datatip }) => {
  return (
    <button
      onClick={onClick}
      data-tip={datatip}
      className={`block py-1 md:py-3 rounded-[50px] text-xs md:text-base text-gray-400 border border-gray-400 font-medium px-4 md:px-5 ",
      ${selected && "bg-orangeBg text-white border-none"}`}
    >
      {group}
    </button>
  );
};

export default CategoryButton;
