import { cozaLogoWhite } from "assets/icons";
import React, { useState } from "react";

const CopyIcon = (props) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      //   xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 115.77 122.88"
      className="size-5 fill-purpleOverlay outline-none"
      //   xml:space="preserve"
      {...props}
    >
      <style type="text/css"></style>
      <g>
        <path
          //   class="st0"
          d="M89.62,13.96v7.73h12.19h0.01v0.02c3.85,0.01,7.34,1.57,9.86,4.1c2.5,2.51,4.06,5.98,4.07,9.82h0.02v0.02 v73.27v0.01h-0.02c-0.01,3.84-1.57,7.33-4.1,9.86c-2.51,2.5-5.98,4.06-9.82,4.07v0.02h-0.02h-61.7H40.1v-0.02 c-3.84-0.01-7.34-1.57-9.86-4.1c-2.5-2.51-4.06-5.98-4.07-9.82h-0.02v-0.02V92.51H13.96h-0.01v-0.02c-3.84-0.01-7.34-1.57-9.86-4.1 c-2.5-2.51-4.06-5.98-4.07-9.82H0v-0.02V13.96v-0.01h0.02c0.01-3.85,1.58-7.34,4.1-9.86c2.51-2.5,5.98-4.06,9.82-4.07V0h0.02h61.7 h0.01v0.02c3.85,0.01,7.34,1.57,9.86,4.1c2.5,2.51,4.06,5.98,4.07,9.82h0.02V13.96L89.62,13.96z M79.04,21.69v-7.73v-0.02h0.02 c0-0.91-0.39-1.75-1.01-2.37c-0.61-0.61-1.46-1-2.37-1v0.02h-0.01h-61.7h-0.02v-0.02c-0.91,0-1.75,0.39-2.37,1.01 c-0.61,0.61-1,1.46-1,2.37h0.02v0.01v64.59v0.02h-0.02c0,0.91,0.39,1.75,1.01,2.37c0.61,0.61,1.46,1,2.37,1v-0.02h0.01h12.19V35.65 v-0.01h0.02c0.01-3.85,1.58-7.34,4.1-9.86c2.51-2.5,5.98-4.06,9.82-4.07v-0.02h0.02H79.04L79.04,21.69z M105.18,108.92V35.65v-0.02 h0.02c0-0.91-0.39-1.75-1.01-2.37c-0.61-0.61-1.46-1-2.37-1v0.02h-0.01h-61.7h-0.02v-0.02c-0.91,0-1.75,0.39-2.37,1.01 c-0.61,0.61-1,1.46-1,2.37h0.02v0.01v73.27v0.02h-0.02c0,0.91,0.39,1.75,1.01,2.37c0.61,0.61,1.46,1,2.37,1v-0.02h0.01h61.7h0.02 v0.02c0.91,0,1.75-0.39,2.37-1.01c0.61-0.61,1-1.46,1-2.37h-0.02V108.92L105.18,108.92z"
        />
      </g>
    </svg>
  );
};

const AccountCard = ({ item }) => {
  const [copyText, setCopyText] = useState("");
  const onCopy = (e, data) => {
    e.preventDefault();
    e.stopPropagation();
    navigator.clipboard.writeText(data);
    setCopyText("Copied!");

    setTimeout(() => {
      setCopyText("");
    }, 5000);
  };
  return (
    <div
      role="button"
      onClick={(e) => onCopy(e, item?.accountNumber)}
      className="flex items-center gap-5 p-5 rounded-[20px] shadow-lg border border-purpleOverlay/40"
    >
      <div className="grid place-content-center bg-purpleOverlay p-2 rounded-[10px] size-fit">
        <img src={cozaLogoWhite} alt="" className="!size-12" />
      </div>
      <div className="max-w-[80%]">
        <p className="text-purpleOverlay font-medium text-sm">
          {item?.accountName} ({item?.currency})
        </p>
        <p className="flex items-center gap-2 text-purpleOverlay text-lg font-bold">
          {item?.accountNumber}{" "}
          {copyText !== "" ? (
            <span className="font-light text-xs">{copyText}</span>
          ) : (
            <CopyIcon role="button" />
          )}
        </p>
        <p className="text-purpleOverlay">{item?.bank}</p>
      </div>
    </div>
  );
};

export default AccountCard;
