import AxiosWithToken from "constants/api_management/MyHttpHelperWithToken";
import { useQuery } from "react-query";

const useGetCart = (params = {}) => {
  return useQuery(
    ["cart", params],
    async () => {
      try {
        const [response] = [await AxiosWithToken.get(`/cart`, { params })];
        //console.log(`this is the current subscription status ${response}`);
        return response.data;
      } catch (error) {
        //console.log(error.response.data);
        throw new Error(`Sorry: ${error.response.data.message}`);
      }
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const useGetStoreList = (params = {}) => {
  return useQuery(
    ["store", params],
    async () => {
      try {
        const [response] = [
          await AxiosWithToken.get(`/store/list`, { params }),
        ];
        //console.log(`this is the current subscription status ${response}`);
        return response.data;
      } catch (error) {
        //console.log(error.response.data);
        throw new Error(`Sorry: ${error.response.data.message}`);
      }
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const useGetWalletConfrimation = ({ enabled = false, query = "" }) => {
  return useQuery({
    queryKey: ["wallet"],
    queryFn: () => AxiosWithToken.get(`/wallet/confirmation${query}`),
    enabled: enabled,
  });
};

export default useGetCart;
