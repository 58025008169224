import React from "react";

const GiveItem = ({ label, onClick = () => null, icon }) => {
  return (
    <div
      onClick={onClick}
      className="rounded-[20px] shadow-2xl shadow-[#9747FF]/20 h-[90px] w-full flex items-center justify-between pl-5 pr-7 cursor-pointer relative"
    >
      <div className="h-[60px] w-[60px] bg-gradient-to-b from-[#FFF0FE] to-white rounded-[20px] absolute left-5 -top-[30px] shadow-xl shadow-[#9747FF]/20 flex justify-center items-center">
        {icon}
      </div>
      <span className="text-purpleOverlay font-semibold text-xl mt-10">
        {label}
      </span>
      <svg
        width="9"
        height="16"
        viewBox="0 0 9 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1L6.81206 6.81206C7.46814 7.46814 7.46814 8.53186 6.81206 9.18794L1 15"
          stroke="#8311B3"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
};

export default GiveItem;
