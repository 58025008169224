import React from "react";

const CustomDropdown = ({
  options = [],
  onChange,
  title,
  value = "",
  placeholder,
  isFirstStyle = true,
}) => {
  return (
    <div className="mb-0 w-full">
      <h3 className="text-[12px] text-black">{title}</h3>
      <div
        className={
          isFirstStyle
            ? "h-[36px] w-full flex flex-row justify-between items-center border border-[#667185] bg-white p-2 rounded-md placeholder:text-[12px]  focus:outline-none"
            : "border border-[#D0D5DD] bg-lightGrey/20 p-2 py-3 rounded-md outline-none focus:outline-none focus:border-purpleBg duration-200"
        }
      >
        <select
          onChange={onChange}
          value={value}
          className="w-full text-left text-[12px] text-black h-[22.91px] flex justify-between items-center bg-transparent outline-none"
        >
          <option value="" disabled hidden>
            {placeholder}
          </option>
          {options.map((option, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default CustomDropdown;
