import React from "react";
import { twMerge } from "tailwind-merge";

const CloseButton = (props) => {
  const { className, ...rest } = props;
  return (
    <button
      {...rest}
      className={twMerge(
        "absolute top-2 right-5 rounded-full p-1 duration-300 bg-[#FCF5FF] outline-none z-[100]",
        className
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="size-6 stroke-purpleOverlay"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6 18 18 6M6 6l12 12"
        />
      </svg>
    </button>
  );
};

export default CloseButton;
