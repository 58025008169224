import React from "react";
import ModalManagement from "./ModalManagement";

import CustomButton from "./Button";

const AdminDeleteConfirmationModal = ({
  title,
  body,
  onClick,
  isLoading,
  buttonText,
}) => {
  return (
    <ModalManagement id={`admin_delete`}>
      <div className="flex flex-col items-center w-[456px] h-fit gap-3 justify-center">
        <div className="flex flex-col w-full items-start text-left">
          <p className="text-brandBlack text-16px font-medium">{title}</p>
          <p className="text-14px text-brandBlack text-center">{body}</p>
        </div>
        <div className="flex items-center gap-3 mt-10 w-full ">
          <CustomButton
            buttonText={`Cancel`}
            buttonColor={"transparent"}
            textColor={`text-brandBlack`}
            onClick={() => {
              document.getElementById("admin_delete").close();
            }}
            className={`w-full border border-lightGrey`}
          />
          <CustomButton
            buttonText={buttonText}
            className={`w-full`}
            onClick={onClick}
            isLoading={isLoading}
          />
        </div>
      </div>
    </ModalManagement>
  );
};

export default AdminDeleteConfirmationModal;
