import { useQuery } from "react-query";

import AxiosWithToken from "../../../../constants/api_management/MyHttpHelperWithToken";
import { useParams } from "react-router-dom";

const useGetUserFormsManager = ({ userId = "", searchQuery = "" }) => {
  return useQuery(["forms", searchQuery], async () => {
    try {
      console.log(`/family-process/${userId}/${searchQuery}`);
      const [response] = [
        await AxiosWithToken.get(`/family-process/${userId}/${searchQuery}`),
      ];
      console.log(`i am checking this ${response.data}`);
      return response.data;
    } catch (error) {
      console.log(error.response.data);
      throw new Error(`Sorry: ${error.response.data.message}`);
    }
  });
};

export default useGetUserFormsManager;
