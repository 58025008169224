import AxiosWithToken from "constants/api_management/MyHttpHelperWithToken";
import showToast from "constants/showToast";
import { useMutation, useQueryClient } from "react-query";

const usePostFamilyProcess = ({
  endpoint,
  mutationKey = [],
  onSuccess = () => null,
}) => {
  const queryClient = useQueryClient();

  const postRequest = async (body) => {
    try {
      const response = await AxiosWithToken.post(
        `/family-process/${endpoint}`,
        body
      );
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  };

  const mutation = useMutation({
    mutationKey,
    mutationFn: postRequest,
    onSuccess: (data) => {
      showToast(data.message, "success");
      if ([...mutationKey]?.length) {
        queryClient.invalidateQueries([...mutationKey]);
      }
      onSuccess();
    },
    onError: (error) => {
      showToast(error?.message, "error");
    },
  });

  const postCaller = async (body) => {
    try {
      const data = await mutation.mutateAsync(body);
      return data;
    } catch (error) {
      console.log({ error });
      throw error;
    }
  };

  return {
    postCaller,
    ...mutation,
  };
};

export default usePostFamilyProcess;
