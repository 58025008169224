import React, { useState } from "react";
import useGetSingleFormManager from "../controllers/getSingleFormController";
import Loader from "../../../../generalComponents/Loader";
import ErrorManager from "../../../../generalComponents/ErrorManager";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import FormDetailTile from "../../../../generalComponents/FormDetailTile";
import separateWords from "../wordSeparator";
import { formatDate } from "utils/formatDate";

const BusinessDedicationForm = ({ details }) => {
  const { formType } = useParams();

  const formData = [
    {
      name: "Form Status",
      info: details?.status,
    },
    {
      name: "Applicant's Name",
      info: `${details?.user?.first_name} ${details?.user?.last_name}`,
    },

    {
      name: "Phone No.",
      info: details?.user?.phone,
    },
    {
      name: "Applicant's Address",
      info: `${details?.user?.location?.address}, ${details?.user?.location?.city}, ${details?.user?.location?.state}, ${details?.user?.location?.country}`,
    },
    {
      name: "Business Name",
      info: details?.business_name,
    },
    {
      name: "Business Type",
      info: details?.business_type,
    },
    {
      name: "Business Owner's Name",
      info: `${details?.business_owner_name}`,
    },
    {
      name: "Applicant's Signature",
      info: details?.signature,
    },
    {
      name: "Guarantor",
      info: details?.guarantor,
    },
    {
      name: "Applicant's Partnership Status",
      info: details?.partnership_status,
    },
    {
      name: "Applicant's Partnership Id",
      info: details?.partnership_id,
    },
    {
      name: "Submitted On",
      info: formatDate(details?.createdAt),
    },
    {
      name: "Updated On",
      info: formatDate(details?.updatedAt),
    },
  ];

  return (
    <div>
      <div class="flow-root mt-16 ">
        <dl class="-my-3 divide-y divide-gray-100 text-sm mb-20">
          {formData.map((details, index) => (
            <FormDetailTile details={details} index={index} />
          ))}
        </dl>
      </div>
    </div>
  );
};

export default BusinessDedicationForm;
