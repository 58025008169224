import ModalManagement from "generalComponents/ModalManagement";
import React, { useEffect, useState } from "react";

import CustomButton from "generalComponents/Button";

import { formatDate } from "utils/formatDate";
import { convertTo12HourFormat } from "utils/timeToAmPm";
import GlobalVariables from "constants/GlobalVariables";
import InputWithFullBoarder from "generalComponents/InputWithFullBoarder";
import useAllUsersManager from "modules/admin/userManagement/controllers/getAllUsersController";
import useDebounce from "utils/UseDebounce";
import CustomDropdown from "generalComponents/CustomDropdown";
import { ReviewAppointmentManager } from "../controllers/reviewAppointmentController";
import { convertISOToDateInput } from "utils/isoToInputDate";

const ViewAppointment = ({ details }) => {
  const { editAppointment, isLoading } = ReviewAppointmentManager(details?.id);
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(`&first_name=${searchValue}`, 1000);
  const [isApprove, setIsApprove] = useState(false);
  const [selectedName, setSelectedName] = useState("");

  const [message, setMessage] = useState("");
  const { data, isLoading: searching } = useAllUsersManager({
    page: "1",
    searchQuery: searchValue ? debouncedSearchValue : "",
    enabled: true,
  });

  const initialData = {
    status: "",
    message: "",
    assigned_to: "",
    schedule: {
      date: "",
      time: "",
    },
  };
  const [formData, setFormData] = useState(initialData);

  useEffect(() => {
    if (details) {
      setFormData({
        status: details?.status,
        message: details?.message,
        assigned_to: details?.assigned_to,
        schedule: {
          date: convertISOToDateInput(details?.date),
          time: details?.time,
        },
      });
    }
  }, [details]);

  useEffect(() => {
    if (data?.users.length === 1) {
      setFormData({
        ...formData,
        assigned_to: data?.users[0]?.id,
      });
    }
  }, [data]);
  return (
    <ModalManagement id={"view_appointment"} title={"Content Details"}>
      <div className="w-full md:w-[500px] flex flex-col gap-6 mt-5">
        <div className="flex flex-col mx-auto">
          <img
            className="rounded-full object-cover w-[150px] h-[150px] mx-auto"
            src={
              details?.user?.profile_picture
                ? details?.user?.profile_picture
                : GlobalVariables.defaultProfilePicture
            }
            alt=""
          />
          <p className="text-[30px] text-[#111111] font-bold text-center">{`${details?.user?.first_name} ${details?.user?.last_name}`}</p>
          <p className="text-[15px] text-textGrey font-bold text-center">{`${details?.user?.email} `}</p>
        </div>
        <div className="w-full flex flex-col">
          <p className="text-[15px] text-purpleOverlay">Title</p>
          <p className="text-20px text-purpleOverlay font-semibold">
            {details?.title}
          </p>
        </div>
        {details?.status === "accepted" && (
          <div className="flex items-center my-2 w-full justify-start gap-32 leading-tight">
            <div className="flex flex-col items-start gap-2 ">
              <p className="text-[15px] text-purpleOverlay">Date</p>

              <p className="text-20px text-purpleOverlay font-semibold">
                {formatDate(details?.date)}
              </p>
            </div>
            <div className="flex flex-col items-start gap-2">
              <p className="text-[15px] text-purpleOverlay">Time</p>
              <p className="text-20px text-purpleOverlay font-semibold">
                {convertTo12HourFormat(details?.time)}
              </p>
            </div>
          </div>
        )}
        <div className="flex flex-col w-full">
          <p className="text-[15px] text-purpleOverlay">About</p>
          <p className="text-12px text-textGrey">{details?.details}</p>
        </div>

        <div className="flex flex-col w-full gap-2">
          {details?.status === "accepted" ? (
            <CustomButton
              buttonText={
                details?.status === "accepted" ? "Reschedule" : "Approve"
              }
              onClick={async () => {
                document.getElementById("view_appointment").close();
                document.getElementById("schedule_appointment").showModal();
              }}
              buttonColor={"bg-[#E0E0E0]"}
              textColor={"purpleOverlay"}
              radius={"rounded-[40px]"}
            />
          ) : (
            <div className="flex items-center gap-5 w-full my-2">
              <CustomButton
                buttonText={"Approve"}
                buttonColor={"bg-purpleOverlay"}
                className={"w-full"}
                radius={"rounded-[40px]"}
                onClick={() => {
                  document.getElementById("view_appointment").close();
                  document.getElementById("schedule_appointment").showModal();
                }}
              />
              <CustomButton
                buttonText={"Decline"}
                buttonColor={"bg-brandRed"}
                onClick={() => {
                  document.getElementById("view_appointment").close();
                  document
                    .getElementById("decline_appointment_message")
                    .showModal();
                }}
                className={"w-full"}
                radius={"rounded-[40px]"}
              />
            </div>
          )}
          <CustomButton
            buttonText={details?.status === "assigned" ? "Assigned" : "Assign"}
            onClick={async () => {
              document.getElementById("view_appointment").close();
              document.getElementById("assign_appointment").showModal();
            }}
            buttonColor={"bg-brandOrange"}
            radius={"rounded-[40px]"}
          />
        </div>
      </div>
      <ModalManagement id={"schedule_appointment"}>
        <div className="md:w-[500px] flex flex-col gap-10">
          <div className="flex gap-4 items-center">
            <div className="w-full">
              <InputWithFullBoarder
                label={"Date"}
                className={"mb-0"}
                placeholder={"Enter Date"}
                type={"date"}
                value={formData.schedule.date}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    schedule: {
                      ...formData.schedule,
                      date: e.target.value,
                    },
                  })
                }
              />
            </div>
            <div className="w-full">
              <InputWithFullBoarder
                label={"Time"}
                className={"mb-0"}
                placeholder={"Enter time"}
                type={"time"}
                value={formData.schedule.time}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    schedule: {
                      ...formData.schedule,
                      time: e.target.value,
                    },
                  })
                }
              />
            </div>
          </div>
          <CustomButton
            buttonText={
              details?.status === "accepted" ? "Reschedule" : "Schedule"
            }
            buttonColor={"bg-brandOrange"}
            isLoading={isLoading}
            onClick={async () => {
              setIsApprove(false);
              const info = {
                status: "accepted",
                schedule: formData.schedule,
              };
              await editAppointment(info);

              document.getElementById("schedule_appointment").close();
              setFormData(initialData);
              document.getElementById("view_appointment").close();
            }}
            className={"w-full"}
            radius={"rounded-[40px]"}
          />
        </div>
      </ModalManagement>
      <ModalManagement
        id={"decline_appointment_message"}
        title={"Decline Reason"}
      >
        <div className="md:w-[500px] flex flex-col gap-10">
          {" "}
          <InputWithFullBoarder
            label={"Description"}
            isTextArea={true}
            className={"mb-4"}
            value={formData.message}
            onChange={(e) =>
              setFormData({ ...formData, message: e.target.value })
            }
          />
          <CustomButton
            buttonText={"Decline"}
            buttonColor={"bg-brandRed"}
            isLoading={isLoading && !isApprove}
            onClick={async () => {
              setIsApprove(false);
              const info = { status: "declined", message: formData.message };
              await editAppointment(info);
              document.getElementById("decline_appointment_message").close();
              setFormData(initialData);

              document.getElementById("view_appointment").close();
            }}
            className={"w-full"}
            radius={"rounded-[40px]"}
          />
        </div>
      </ModalManagement>
      <ModalManagement id={"assign_appointment"} title={"Assign Appointment"}>
        <div className="md:w-[500px] flex flex-col gap-10">
          <CustomDropdown
            title={searching ? "Searching..." : "Find User"}
            placeholder={"Select User"}
            isFirstStyle={false}
            options={data?.users.map(
              (el) => `${el?.first_name} ${el?.last_name}`
            )}
            value={selectedName}
            onChange={(e) => {
              const userId = data?.users.find(
                (el) => `${el?.first_name} ${el?.last_name}` === e.target.value
              ).id;
              console.log(userId);
              setSelectedName(e.target.value);
              setFormData({
                ...formData,
                assigned_to: userId,
              });
            }}
          />
          <InputWithFullBoarder
            value={searchValue}
            placeholder={"Enter user name"}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
          />
          <CustomButton
            buttonText={"Assign"}
            buttonColor={"bg-brandOrange"}
            isLoading={isLoading}
            onClick={async () => {
              setIsApprove(false);
              const info = {
                status: "assigned",
                assigned_to: formData.assigned_to,
              };
              console.log(info);
              await editAppointment(info);
              document.getElementById("assign_appointment").close();
              setFormData(initialData);

              document.getElementById("view_appointment").close();
            }}
            className={"w-full"}
            radius={"rounded-[40px]"}
          />
        </div>
      </ModalManagement>
    </ModalManagement>
  );
};

export default ViewAppointment;
