import React, { useEffect, useState } from "react";
import useGetSingleFormManager from "../controllers/getSingleFormController";
import Loader from "../../../../generalComponents/Loader";
import ErrorManager from "../../../../generalComponents/ErrorManager";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import FormDetailTile from "../../../../generalComponents/FormDetailTile";
import separateWords from "../wordSeparator";
import { formatDate } from "utils/formatDate";

const MarriageApplicationForm = ({ details }) => {
  const [formData, setFormData] = useState([]);

  useEffect(() => {
    if (details && details?.references) {
      setFormData([
        {
          name: "Form Status",
          info: details?.status,
        },
        {
          name: "Applicant's Name",
          info: `${details?.user?.first_name} ${details?.user?.last_name}`,
        },
        {
          name: "Applicant's Address",
          info: `${details?.user?.location?.address}, ${details?.user?.location?.city}, ${details?.user?.location?.state}, ${details?.user?.location?.country}`,
        },
        {
          name: "Applicant's Email",
          info: `${details?.user?.email}`,
        },
        {
          name: "Facebook",
          info: `${details?.socials?.facebook ?? ""}`,
        },
        {
          name: "Snapchat",
          info: `${details?.socials?.snapchat ?? ""}`,
        },
        {
          name: "Twitter",
          info: `${details?.socials?.twitter ?? ""}`,
        },
        {
          name: "Instagram",
          info: `${details?.socials?.instagram ?? ""}`,
        },

        {
          name: "Phone No.",
          info: details?.user?.phone,
        },
        {
          name: "Date of Birth",
          info: details?.dob,
        },
        {
          name: "Occupation",
          info: details?.occupation,
        },
        {
          name: "Office Address",
          info: details?.office_address,
        },
        {
          name: "Name of Spouse",
          info: details?.spouse_name,
        },
        {
          name: "Occupation of Spouse",
          info: details?.spouse_occupation,
        },
        {
          name: "Date of Birth of Spouse",
          info: details?.spouse_occupation,
        },
        {
          name: "Date of First Contact with Spouse",
          info: details?.date_of_first_contact,
        },
        {
          name: "Occupation /Place of Work/Business of Spouse",
          info: details?.spouse_office_address,
        },
        {
          name: "Address of Spouse’s place of work /Business",
          info: details?.spouse_office_address,
        },
        {
          name: "Place and circumstance of first contact with your spouse",
          info: details?.place_of_first_contact,
        },
        {
          name: "Date the relationship started",
          info: details?.relationship_start_date,
        },
        {
          name: "Did you report your relationship to the senior pastor or Leadership of church?",
          info: details?.was_relationship_reported ? "Yes" : "No",
        },
        {
          name: "Date of such report if any",
          info: details?.was_relationship_reported ? "Yes" : "No",
        },
        {
          name: "Proposed Wedding Date?",
          info: details?.wedding_dates?.date1 ?? "N/A",
        },
        {
          name: "Date joined Coza",
          info: details?.date_joined_coza,
        },
        {
          name: "Date spouse joined COZA (if spouse is a member):",
          info: details?.date_joined_coza_spouse,
        },
        {
          name: "Are you a partner in COZA?",
          info: details?.coza_partner ? "Yes" : "No",
        },
        {
          name: "Start date of partnership",
          info: details?.partnership_start_date,
        },
        {
          name: "Any other information about your involvement with COZA?",
          info: details?.other_invlovement_coza,
        },
        {
          name: "What date did you receive the life of Christ?",
          info: details?.date_received_life_of_christ,
        },
        {
          name: "Where did you receive the life of Christ?",
          info: details?.location_received_life_of_christ,
        },
        {
          name: "Do you speak in tongues?",
          info: details?.speak_in_tongues ? "Yes" : "No",
        },
        {
          name: "What date did your spouse receive the life of Christ?",
          info: details?.date_received_life_of_christ_spouse,
        },
        {
          name: "Does your spouse /Partner speak in tongues?",
          info: details?.speak_in_tongues_spouse ? "Yes" : "No",
        },
        {
          name: "Do you or your spouse require a refresher training on the rudiments of Christian living?",
          info: details?.require_refresher_training ? "Yes" : "No",
        },
        {
          name: "Monthly income of the Groom to be",
          info: details?.monthly_income_groom,
        },
        {
          name: "State source of Income",
          info: details?.source_of_income_partner,
        },
        {
          name: "Partner’s monthly income",
          info: details?.monthly_income_partner,
        },
        {
          name: "State Partner’s source of income",
          info: details?.source_of_income,
        },
        {
          name: "Do the Intending Groom own the house or property he presently occupies?",
          info: details?.is_groom_house_owner ? "Yes" : "No",
        },

        {
          name: "If no, does he pay rent? ….state current rental status ",
          info: details?.groom_rental_status,
        },
        {
          name: "If groom does not pay rent , kindly state the relationship status with property owner",
          info: details?.relationship_with_property_owner,
        },
        {
          name: "City of domicile after wedding",
          info: details?.domicile_city_after_wedding,
        },

        {
          name: "Has any of the parties been married before?",
          info: details?.previously_married ? "Yes" : "No",
        },
        {
          name: "If yes kindly state date and nature of wedding : traditional /registry /church",
          info: details?.nature_of_marriage,
        },
        {
          name: "Present marital status : Separated /Divorced",
          info: details?.present_marital_status,
        },
        {
          name: "Name of Church currently being attended",
          info: details?.references?.current_church ?? "N/A",
        },
        {
          name: "Physical Address of church",
          info: details?.references?.church_address,
        },
        {
          name: "Website of church",
          info: details?.references?.church_website,
        },
        {
          name: "Email address of church",
          info: details?.references?.church_email,
        },
        {
          name: "Name of Pastor",
          info: details?.references?.pastors_name,
        },
        {
          name: "Official email of pastor",
          info: details?.references?.pastors_email,
        },
        {
          name: "Phone number of pastor",
          info: details?.references?.pastors_phone,
        },
        {
          name: "Length of Membership",
          info: details?.references?.length_of_membership,
        },
        {
          name: "Any other information about your involvement in your church",
          info: details?.references?.other_church_involvements,
        },
        {
          name: "Name of referee/ Supervising Official",
          info: details?.supervisor,
        },
        {
          name: "Comments by the Supervisor who must be a Minister or Married Head of a Department",
          info: details?.supervisor_comments,
        },
        {
          name: "DECLARATION BY REFEREE /Supervisor",
          info: details?.is_supervisor_declaration
            ? "Was given"
            : "Was not given",
        },
        {
          name: "Supervisor declaration Date",
          info: details?.supervisor_declaration_date,
        },
        {
          name: "Supervisor declaration signature",
          info: details?.supervisor_signature,
        },
        {
          name: "COUPLE ATTESTATION",
          info: details?.is_couple_attestation ? "Was given" : "Was not given",
        },
        {
          name: "Couple Attestation Date",
          info: details?.supervisor_declaration_date,
        },
        {
          name: "Groom signature",
          info: details?.groom_signature,
        },
        {
          name: "Bride signature",
          info: details?.bride_signature,
        },
        {
          name: "Submitted On",
          info: formatDate(details?.createdAt),
        },
        {
          name: "Updated On",
          info: formatDate(details?.updatedAt),
        },
      ]);
    }
  }, [details]);

  if (!details) {
    return <div>No data available.</div>;
  }

  return (
    <div>
      {
        <div class="flow-root mt-16 ">
          <dl class="-my-3 divide-y divide-gray-100 text-sm mb-20">
            {formData.map((details, index) => (
              <FormDetailTile details={details} index={index} />
            ))}
          </dl>
        </div>
      }
    </div>
  );
};

export default MarriageApplicationForm;
