import React, { useEffect, useRef, useState } from "react";

import BaseDashboardNavigation from "../../../../generalComponents/BaseDashboardNavigation";
import InputWithFullBoarder from "../../../../generalComponents/InputWithFullBoarder";

import Loader from "../../../../generalComponents/Loader";
import ErrorManager from "../../../../generalComponents/ErrorManager";
import CustomButton from "../../../../generalComponents/Button";
import useFileUpload from "../../../fileupload/fileUploadController";
import useGetCampusListManager from "../controllers/getCampusListController";

import useDebounce from "utils/UseDebounce";
import GlobalVariables from "constants/GlobalVariables";
import { formatDate } from "utils/formatDate";
import AdminHeaderTop from "generalComponents/AdminHeaderTop";
import SearchComponent from "generalComponents/SearchComponent";
import TablesComponent from "generalComponents/TablesComponent";
import CampusDetail from "../component/CampusDetail";
import AddEditCampus from "../component/AddEditCampus";
import AdminDeleteConfirmationModal from "generalComponents/AdminDeleteConfirmationModal";
import { DeleteCampusManager } from "../controllers/deleteCampusController";
import AdminWrapper from "modules/admin/AdminWrapper";
import DataTable from "generalComponents/DataTable";
import { modifyData, validTableKeys } from "../utils";

const AdminAllCampusesPage = () => {
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(`&name=${searchValue}`, 1000);
  const [selected, setSelected] = useState(null);
  const [tableValues, setTableValues] = useState({
    pageIndex: 0,
    perPage: 20,
  });
  function setter(name, value) {
    setTableValues((val) => ({
      ...val,
      [name]: value,
    }));
  }
  const { data, isSuccess, isLoading } = useGetCampusListManager(
    searchValue ? debouncedSearchValue : ""
  );

  const { deleteCampus, isLoading: deleting } = DeleteCampusManager();

  // const tableHeads = [
  //   "Campus Name",
  //   "Location",
  //   "City",
  //   "State",
  //   "Contacts",
  //   "Action",
  // ];
  // const getFormattedValue = (el, index) => {
  //   return [
  //     <div class="flex items-center mr-0 md:mr-8">
  //       <img
  //         class="flex-shrink-0 object-cover w-[50px] h-[50px] mr-3 rounded-[10px]"
  //         src={
  //           !el.images.length > 0
  //             ? GlobalVariables.defaultProfilePicture
  //             : el?.images[0]
  //         }
  //         alt=""
  //       />
  //       {`${el?.name} `}
  //     </div>,
  //     <p className="truncate1 max-w-[302px]">{el?.location?.address}</p>,
  //     el?.location?.city,
  //     el?.location?.state,
  //     el?.contacts.length,
  //   ];
  // };

  function onRowClick(rowData) {
    if (selected === rowData) {
      document.getElementById("campus_detail")?.showModal();
    } else {
      setSelected(rowData);
    }
  }

  useEffect(() => {
    if (selected) {
      document.getElementById("campus_detail")?.showModal();
    }
  }, [selected]);

  return (
    <AdminWrapper
      title={"Campuses"}
      value={searchValue}
      onChange={(e) => {
        setSearchValue(e.target.value);
        console.log(searchValue);
      }}
    >
      <div className="flex flex-col h-[90vh]">
        <div className="flex justify-between flex-col gap-5 md:flex-row w-full mb-10">
          <AdminHeaderTop
            title={"Campuses"}
            subtitle={`${data?.data?.length} campuses in total`}
          />
          <div className="mb-3">
            <CustomButton
              onClick={async () => {
                setSelected(null);
                document.getElementById("add_campus").showModal();
              }}
              buttonText={`+ Add Campus`}
              radius={`[5px]`}
              className={`max-w-max`}
            />
          </div>
        </div>
        <div className="flex items-center justify-between mb-10">
          <div className="max-w-[291px]">
            <SearchComponent
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
        </div>

        {/* user tables */}

        <div className="md:h-[67vh] relative md:overflow-y-auto scrollbar-hide">
          {isLoading ? (
            <Loader isSmall />
          ) : (
            <DataTable
              tableData={modifyData(data?.data)}
              totalItemCount={data?.data?.length}
              validTableKeys={validTableKeys}
              onRowClick={onRowClick}
              noOfItemsPerPage={tableValues?.perPage}
              onCheckClick={() => null}
              onItemsPerPageChange={(newPage) => setter("perPage", newPage)}
              pageIndex={tableValues?.pageIndex}
              onPageIndexChange={(idx) => setter("pageIndex", idx)}
              showFooter
            />
          )}
          {/* <TablesComponent
            data={data?.data}
            headers={tableHeads}
            isLoading={isLoading}
            getFormattedValue={getFormattedValue}
            buttonFunction={(val) => {
              console.log(val);
              if (selected === val) {
                document.getElementById("campus_detail")?.showModal();
              } else {
                setSelected(val);
              }
            }}
          /> */}
        </div>
      </div>
      {selected && <CampusDetail details={selected} />}
      {<AddEditCampus details={selected} />}
      <AdminDeleteConfirmationModal
        title={"Delete Campus"}
        body={`Do you really want to delete ${selected?.name} from the list of campuses?`}
        buttonText={`Yes, Delete`}
        onClick={async () => {
          const details = {
            campusIds: [selected?.id],
          };

          await deleteCampus(details);
          document.getElementById("admin_delete").close();
        }}
        isLoading={deleting}
      />
    </AdminWrapper>
  );
};

export default AdminAllCampusesPage;
