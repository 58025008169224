import ModalManagement from "generalComponents/ModalManagement";
import useFileUpload from "modules/fileupload/fileUploadController";
import React, { useEffect, useRef, useState } from "react";
import Switch from "react-switch";
import InputWithFullBoarder from "generalComponents/InputWithFullBoarder";
import CustomDropdown from "generalComponents/CustomDropdown";
import UploadFileComponent from "generalComponents/UploadFileComponent";
import CustomButton from "generalComponents/Button";
import { format } from "date-fns";
import { convertBytesToMB } from "utils/fileSize";
import useDebounce from "utils/UseDebounce";

import BigUploadFileComponent from "generalComponents/BigUploadFileComponent";
import { CreateEventManager } from "../controller/createEventController";
import { EditEventManager } from "../controller/editEventController";
import SelectInput from "generalComponents/SelectInput";

const AddEditEvents = ({ details, categories }) => {
  const { createEvent, isLoading } = CreateEventManager();
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(`&name=${searchValue}`, 1000);
  const { editEvent, isLoading: editing } = EditEventManager(details?.id);

  const initialFormData = {
    category: "",
    name: "",
    stream_link: "",
    description: "",
    video_url: "",
    registration_link: "",
    date: "",
    time: "",
    featured: false,
    cover_image: {
      thumbnail: "",
      url: "",
    },
  };

  const [formData, setFormData] = useState(initialFormData);
  useEffect(() => {
    if (details) {
      setFormData({ ...formData, ...details });
    } else {
      setFormData(initialFormData);
    }
  }, [details]);
  const mediaRef = useRef();
  const [mediaFile, setMediaFile] = useState(null);
  const [uploadingMedia, setUploadingMedia] = useState(false);
  const coverImageRef = useRef();
  const [coverFile, setCoverFile] = useState(null);
  const [uploadingCover, setUploadingCover] = useState(false);
  const {
    progress,
    handleFileUpload,
    isLoading: fileLoading,
  } = useFileUpload();

  return (
    <ModalManagement id={"add_event"} title={"Events"}>
      <div className="rounded-[20px] w-full md:w-[500px] mt-5">
        <BigUploadFileComponent
          buttonText={"Upload Cover"}
          inputRef={coverImageRef}
          isLoading={uploadingCover}
          image={
            coverFile
              ? URL.createObjectURL(coverFile)
              : details && formData?.cover_image?.thumbnail
          }
          progress={progress}
          accept={"image/*"}
          buttonClick={() => setCoverFile(null)}
          onChange={(e) => {
            const file = e.target.files[0];
            if (file) {
              setCoverFile(file);
            } else {
              setCoverFile(null);
            }
          }}
        />

        <InputWithFullBoarder
          label={"Title"}
          placeholder={"Enter title"}
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
        />
        <div className="flex gap-4 items-center">
          <div className="w-full">
            <InputWithFullBoarder
              label={"Date"}
              className={"mb-0"}
              placeholder={"Enter Date"}
              type={"date"}
              value={formData.date}
              onChange={(e) =>
                setFormData({ ...formData, date: e.target.value })
              }
            />
          </div>
          <div className="w-full">
            <InputWithFullBoarder
              label={"Time"}
              className={"mb-0"}
              placeholder={"Enter time"}
              type={"time"}
              value={formData.time}
              onChange={(e) =>
                setFormData({ ...formData, time: e.target.value })
              }
            />
          </div>
        </div>
        <InputWithFullBoarder
          label={"Stream Link (Optional)"}
          placeholder={"Enter sream link"}
          value={formData.stream_link}
          onChange={(e) =>
            setFormData({ ...formData, stream_link: e.target.value })
          }
        />
        <InputWithFullBoarder
          label={"Registration Link (Optional)"}
          placeholder={"Enter registration link"}
          value={formData.registration_link}
          onChange={(e) =>
            setFormData({ ...formData, registration_link: e.target.value })
          }
        />
        {/* <div className="mb-4 w-full flex flex-col">
          <label className="text-sm font-medium mb-2" htmlFor={"content_type"}>
            {"Category"}
          </label> */}
        <SelectInput
          label={"Category"}
          placeholder={"Select Category"}
          isFirstStyle={false}
          options={
            categories &&
            categories.map((el) => ({
              label: el?.name,
              value: el?.id,
            }))
          }
          value={formData.category}
          onChange={(e) => {
            setFormData((val) => ({
              ...val,
              category: e.target?.value,
            }));
          }}
        />
        {/* </div> */}

        <InputWithFullBoarder
          label={"Description"}
          isTextArea={true}
          className={"mb-4"}
          value={formData.description}
          onChange={(e) =>
            setFormData({ ...formData, description: e.target.value })
          }
        />

        <UploadFileComponent
          description={`Upload Event trailer`}
          inputRef={mediaRef}
          isLoading={uploadingMedia}
          format={"Video"}
          maxSize={mediaFile ? convertBytesToMB(mediaFile.size) : `20`}
          fileName={mediaFile ? mediaFile.name : null}
          progress={progress}
          accept={"video/*"}
          buttonClick={() => setMediaFile(null)}
          onChange={async (e) => {
            const file = e.target.files[0];
            setMediaFile(file);
          }}
        />

        <CustomButton
          buttonText={details ? "Update Event" : "Create Event"}
          radius={"rounded-[40px]"}
          className={"w-full"}
          isLoading={fileLoading || editing || isLoading}
          loader={
            uploadingCover
              ? `Cover Image ${progress}%`
              : uploadingMedia
              ? `Trailer video ${progress}%`
              : "Submitting..."
          }
          onClick={async () => {
            const updatedFormData = { ...formData };
            if (mediaFile) {
              setUploadingMedia(true);
              const mediaUrl = await handleFileUpload(mediaFile);
              updatedFormData.video_url = mediaUrl;
              setUploadingMedia(false);
            }
            if (coverFile) {
              setUploadingCover(true);
              const coverUrl = await handleFileUpload(coverFile);
              updatedFormData.cover_image = {
                url: coverUrl,
                thumbnail: coverUrl,
              };
              setUploadingCover(false);
            }

            console.log(updatedFormData);
            if (details) {
              await editEvent(updatedFormData);
            } else {
              await createEvent(updatedFormData);
            }
            document.getElementById("add_event").close();
            setMediaFile(null);
            setFormData(initialFormData);
          }}
        />
      </div>
    </ModalManagement>
  );
};

export default AddEditEvents;
