import ModalManagement from "generalComponents/ModalManagement";
import useFileUpload from "modules/fileupload/fileUploadController";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Switch from "react-switch";
import InputWithFullBoarder from "generalComponents/InputWithFullBoarder";
import CustomDropdown from "generalComponents/CustomDropdown";
import UploadFileComponent from "generalComponents/UploadFileComponent";
import CustomButton from "generalComponents/Button";
import { format, formatDate } from "date-fns";
import { convertBytesToMB } from "utils/fileSize";
import { CreatePraiseReportManager } from "../controllers/createPraiseReportController";
import { EditPraiseReportManager } from "../controllers/editPraiseReportController";
import useGetCategoryListManager from "modules/admin/categories/controllers/getCategoryListController";
import useAllUsersManager from "modules/admin/userManagement/controllers/getAllUsersController";
import useDebounce from "utils/UseDebounce";
import SelectSearch from "generalComponents/SelectSearch";
import ComboboxInput from "generalComponents/ComboboxInput";
import SelectInput from "generalComponents/SelectInput";
import ReactIf from "generalComponents/ReactIf";
import SwitchInput from "generalComponents/SwitchInput";

const AddEditPraiseReport = ({ details, categories }) => {
  const { createPraiseReport, isLoading } = CreatePraiseReportManager();
  const [searchValue, setSearchValue] = useState("");
  const [removeMedia, setRemoveMedia] = useState(false);
  const [removeImage, setRemoveImage] = useState(false);
  const debouncedSearchValue = useDebounce(`&first_name=${searchValue}`, 1000);
  const { editPraiseReport, isLoading: editing } = EditPraiseReportManager(
    details?.id
  );

  const { data, isLoading: searching } = useAllUsersManager({
    page: "1",
    searchQuery: searchValue ? debouncedSearchValue : "",
    enabled: true,
  });

  const userOptions = useMemo(() => {
    const users = data?.users?.map((user) => ({
      ...user,
      label: `${user.first_name} ${user.last_name}`,
      value: user.id,
    }));

    return users;
  }, [data]);
  const [selectedUser, setSelectedUser] = useState(
    details
      ? userOptions?.filter((option) => option.value === details?.user)[0]
      : {}
  );

  const categoryOptions = useMemo(() => {
    const options = categories?.map((category) => ({
      ...category,
      label: category.name,
      value: category.id,
    }));

    return options;
  }, [categories]);

  const initialFormData = {
    user: "",
    name: "",
    category: "",
    title: "",
    description: "",
    date: "",
    anonymous: false,
    imageUrl: "",
    mediaType: "video",
    mediaUrl: "",
  };

  const contentType = ["audio", "video"];

  const [formData, setFormData] = useState(initialFormData);

  // useEffect(() => {
  //   if (data?.users.length === 1) {
  //     setFormData({
  //       ...formData,
  //       userId: data?.users[0]?.id,
  //     });
  //   }
  // }, [data]);

  useEffect(() => {
    if (details) {
      setFormData({
        ...formData,
        ...details,
        date: formatDate(details?.date, "yyyy-MM-dd"),
      });
      setSelectedUser(
        userOptions?.filter((option) => option.value === details?.user?.id)[0]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details]);

  const mediaRef = useRef();
  const [mediaFile, setMediaFile] = useState(null);
  const {
    progress,
    handleFileUpload,
    isLoading: fileLoading,
  } = useFileUpload();

  async function onSubmit(e) {
    e.preventDefault();

    const updatedFormData = {
      ...formData,
      user: selectedUser?.id,
      name: selectedUser?.label,
    };
    delete updatedFormData.status;
    if (mediaFile) {
      const mediaUrl = await handleFileUpload(mediaFile);
      updatedFormData.mediaUrl = mediaUrl;
    }
    console.log(updatedFormData);
    if (details) {
      if (removeImage) {
        updatedFormData.imageUrl = "";
      }
      if (removeMedia) {
        updatedFormData.mediaUrl = "";
      }

      try {
        console.log("editing", updatedFormData);
        await editPraiseReport(updatedFormData);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        await createPraiseReport(updatedFormData);
      } catch (error) {
        console.log(error);
      }
    }
    document.getElementById("add_praiseReport").close();
    setMediaFile(null);
    setFormData(initialFormData);
  }

  return (
    <ModalManagement id={"add_praiseReport"} title={"Praise Report"}>
      <div className="rounded-[20px] w-full md:w-[500px] mt-5">
        <ComboboxInput
          options={userOptions}
          label={"Name of Testifier"}
          value={selectedUser}
          setValue={setSelectedUser}
          placeholder={"Select user"}
          required={true}
        />
        {/* <SelectSearch
          label={"User"}
          placeholder={"Select user"}
          options={userOptions}
        /> */}
        {/* <CustomDropdown
          title={searching ? "Searching..." : "Find User"}
          placeholder={"Select User"}
          isFirstStyle={false}
          options={data?.users.map(
            (el) => `${el?.first_name} ${el?.last_name}`
          )}
          value={formData.userId}
          onChange={(e) => {
            const userId = data?.users.find(
              (el) => `${el?.first_name} ${el?.last_name}` === e.target.value
            ).id;
            setFormData({
              ...formData,
              userId: userId,
            });
          }}
        />
        <InputWithFullBoarder
          value={searchValue}
          placeholder={"Enter user name"}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
        /> */}

        <InputWithFullBoarder
          label={"Title"}
          placeholder={"Enter title"}
          otherProps={{
            required: true,
          }}
          value={formData.title}
          onChange={(e) => setFormData({ ...formData, title: e.target.value })}
        />

        <InputWithFullBoarder
          label={"When it happened"}
          className={"mb-0"}
          placeholder={"Enter Date"}
          type={"date"}
          value={formData.date}
          onChange={(e) => setFormData({ ...formData, date: e.target.value })}
        />
        <SelectInput
          label="Category"
          value={formData?.category}
          onChange={(e) =>
            setFormData((val) => ({ ...val, category: e.target.value }))
          }
          placeholder={"Select category"}
          options={categoryOptions}
        />

        <InputWithFullBoarder
          label={"Description"}
          isTextArea={true}
          className={"mb-4"}
          value={formData.description}
          onChange={(e) =>
            setFormData({ ...formData, description: e.target.value })
          }
        />
        <div className="flex flex-col mb-6">
          <label className="text-sm font-medium mb-2" htmlFor={"content_type"}>
            {"Media Content Type"}
          </label>
          <div className="flex items-center gap-5">
            {contentType.map((option) => (
              <label key={option} className="flex items-center capitalize">
                <input
                  type="radio"
                  name="content_type"
                  value={option}
                  checked={formData.mediaType === option}
                  onChange={(e) =>
                    setFormData({ ...formData, mediaType: option })
                  }
                  className="mr-2"
                />
                {option}
              </label>
            ))}
          </div>
        </div>

        <ReactIf
          condition={details?.mediaUrl && details?.mediaUrl !== ""}
          element={
            <>
              <video
                src={details?.mediaUrl}
                className={`w-full max-h-48 object-contain my-10 duration-200  ${
                  removeMedia ? "opacity-20 grayscale" : ""
                }`}
                controls
              />

              <SwitchInput
                label={"Remove Media"}
                checked={removeMedia}
                setChecked={setRemoveMedia}
                switchClass={removeMedia && "bg-red-600"}
                className={"mt-5"}
              />
            </>
          }
        />

        <ReactIf
          condition={details?.imageUrl && details?.imageUrl !== ""}
          element={
            <>
              <img
                src={details?.imageUrl}
                className={`w-auto max-h-48 object-contain my-10 duration-200 ${
                  removeImage ? "opacity-20 grayscale" : ""
                }`}
                alt=""
              />

              <SwitchInput
                label={"Remove Image"}
                checked={removeImage}
                setChecked={setRemoveImage}
                switchClass={removeImage && "bg-red-600"}
                className={"mt-5"}
              />
            </>
          }
        />

        <div className="flex justify-between items-center gap-0 md:gap-2 mb-5">
          <label htmlFor="forFree" className="text-12px font-medium mt-1">
            Make Anonymous?
          </label>
          <Switch
            id="status_update"
            checked={formData.anonymous}
            onChange={() => {
              setFormData({ ...formData, anonymous: !formData.anonymous });
            }}
            onColor="#FE8235"
            offColor="#828282"
            checkedIcon={false}
            uncheckedIcon={false}
            checkedHandleIcon={<div className="dot dot-on bg-brandOrange" />}
            uncheckedHandleIcon={<div className="dot dot-off bg-grey3" />}
            height={19} // Adjust the height of the switch
            width={30} // Adjust the width of the switch
            handleDiameter={15} // Adjust the size of the handle
          />
        </div>

        <UploadFileComponent
          description={`Upload Media`}
          inputRef={mediaRef}
          isLoading={fileLoading}
          format={formData.mediaType}
          maxSize={mediaFile ? convertBytesToMB(mediaFile.size) : `20`}
          fileName={mediaFile ? mediaFile.name : null}
          progress={progress}
          accept={"video/*,audio/*"}
          buttonClick={() => setMediaFile(null)}
          onChange={async (e) => {
            const file = e.target.files[0];
            setMediaFile(file);
          }}
        />

        <CustomButton
          buttonText={details ? "Update Praise Report" : "Upload Praise Report"}
          radius={"rounded-[40px]"}
          className={"w-full"}
          isLoading={fileLoading || editing || isLoading}
          loader={fileLoading ? `Uploading ${progress}%` : "Submitting..."}
          onClick={onSubmit}
        />
      </div>
    </ModalManagement>
  );
};

export default AddEditPraiseReport;
