import useUpdateManager from "constants/controller_templates/put_controller_template";

export const UpdateTicketStatusManager = ({ ticketId }) => {
  const isMultiKey = true;
  const { updateCaller, isLoading, isSuccess, error, data } = useUpdateManager(
    `/support/tickets/${ticketId}`,
    ["allTickets", "singleTicket"],
    isMultiKey
  );
  const updateStatus = async (details) => {
    try {
      await updateCaller(details);
    } catch (error) {
      // Handle error if necessary
      console.error("error:", error);
    }
  };
  return {
    updateStatus,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
