import AxiosWithToken from "constants/api_management/MyHttpHelperWithToken";
import showToast from "constants/showToast";
import { useMutation, useQueryClient } from "react-query";

export const useCreateAppointment = ({ onSuccess = () => null } = {}) => {
  const queryClient = useQueryClient();

  const postRequest = async (body) => {
    try {
      const response = await AxiosWithToken.post(`/appointment/create`, body);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  };

  const mutation = useMutation({
    mutationKey: ["appointment"],
    mutationFn: postRequest,
    onSuccess: (data) => {
      showToast(data.message, "success");
      queryClient.invalidateQueries(["appointment"]);
      onSuccess();
    },
    onError: (error) => {
      showToast(error?.message, "error");
    },
  });

  const postCaller = async (body) => {
    try {
      const data = await mutation.mutateAsync(body);
      return data;
    } catch (error) {
      console.log({ error });
      throw error;
    }
  };

  return {
    postCaller,
    ...mutation,
  };
};
