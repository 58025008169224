import React, { useState } from "react";
import useGetSingleFormManager from "../controllers/getSingleFormController";
import Loader from "../../../../generalComponents/Loader";
import ErrorManager from "../../../../generalComponents/ErrorManager";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import FormDetailTile from "../../../../generalComponents/FormDetailTile";
import separateWords from "../wordSeparator";
import { formatDate } from "utils/formatDate";

const WhistleBlowerForm = ({ details }) => {
  const formData = [
    {
      name: "Form Status",
      info: details?.status,
    },
    {
      name: "Name",
      info: `${details?.user?.first_name} ${details?.user?.last_name}`,
    },

    {
      name: "Phone No.",
      info: details?.user?.phone,
    },

    {
      name: "Details",
      info: details?.details,
    },
    {
      name: "Evidence",
      info: `${details?.evidence}`,
    },

    {
      name: "Submitted On",
      info: formatDate(details?.createdAt),
    },
    {
      name: "Updated On",
      info: formatDate(details?.updatedAt),
    },
  ];

  return (
    <div>
      <div class="flow-root mt-16 ">
        <dl class="-my-3 divide-y divide-gray-100 text-sm mb-20">
          {formData.map((details, index) => (
            <FormDetailTile details={details} index={index} />
          ))}
        </dl>
      </div>
    </div>
  );
};

export default WhistleBlowerForm;
