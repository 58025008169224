import React, { useRef } from "react";
import ModalManagement from "./CustomModal";
import { cozaLogoWhite } from "assets/icons";

const PlayVideo = ({ id, src }) => {
  const videoRef = useRef();
  function onClose() {
    if (!videoRef.current) return;
    videoRef.current?.pause();
  }
  return (
    <ModalManagement
      id={id}
      onClose={onClose}
      className={
        "w-[95svw]  max-w-[95svw] h-[100svh] bg-gradient-to-b from-purpleOverlay/10 to-purpleOverlay relative"
      }
    >
      <video
        ref={videoRef}
        controls
        className="w-auto h-[90%] max-w-[85svw] mx-auto rounded-[20px]"
      >
        <source src={src} type="video/mp4" />
      </video>
      <img
        src={cozaLogoWhite}
        alt=""
        className="absolute bottom-5 right-5 size-12 pointer-events-none"
      />
    </ModalManagement>
  );
};

export default PlayVideo;
