import { RoundedButton } from "generalComponents/Button";
import HeaderFooter from "generalComponents/HeaderFooter";
import TextGradient from "generalComponents/TextGradient";
import React, { useState } from "react";
import AppointmentItem from "../components/AppointmentItem";
import DatePickerComponent from "generalComponents/DatePickerComponent";
import ModalManagement from "generalComponents/CustomModal";
import CreateAppointment from "../components/CreateAppointment";
import { useGetAppointments } from "../controllers/get_controller";
import useQueryParams from "constants/useQueryParams";
import CustomPagination from "generalComponents/CustomPagination";
import EmptyState from "generalComponents/EmptyState";
import EditIcon from "assets/icons/EditIcon";
import Loader from "generalComponents/Loader";

const ScheduleAppointment = () => {
  const { params, setParam } = useQueryParams({
    page: 1,
    pageSize: 5,
  });
  const [selected, setSelected] = useState();

  const { data, isLoading } = useGetAppointments();
  return (
    <HeaderFooter>
      <TextGradient>Schedule Appointment</TextGradient>

      <div className="pt-[300px] pb-20 max-w-section mx-auto px-5">
        <h1 className="font-semibold text-[52px] lg:text-[60px] lg:leading-[70px] text-purpleOverlay mb-3">
          Schedule Appointment
        </h1>

        <div className="flex flex-col sm:flex-row justify-between sm:items-center gap-5 mb-10">
          <h2 className="font-medium text-[30px] text-gray-300">
            My Appointment
          </h2>
          <RoundedButton
            onClick={() => window["ap-modal"]?.showModal()}
            className="py-3 px-5 "
          >
            + Make Appointment
          </RoundedButton>
        </div>

        <div className="grid xl:grid-cols-3">
          <div className="col-span-2 flex flex-col w-full gap-4">
            {isLoading ? (
              <Loader isSmall />
            ) : data?.data?.appointments?.length ? (
              data?.data?.appointments?.map((item, index) => (
                <AppointmentItem key={index} {...item} />
              ))
            ) : (
              <EmptyState
                icon={EditIcon}
                heading={"Hmm..."}
                message={
                  "It looks like you have no appointment scheduled yet. Click the Make Appointment button to schecdule an appointment."
                }
                scriptureText={
                  "In their hearts humans plan their course, but the Lord establishes their steps."
                }
                scripture={"Proverbs 16:9 (NIV)"}
              />
            )}

            {data?.data?.pagination?.total > 0 && (
              <div className="flex justify-end mt-10 min-w-full">
                <CustomPagination
                  count={parseInt(data?.data?.pagination?.total)}
                  page={parseInt(params?.page)}
                  pageSize={parseInt(params?.pageSize) ?? 5}
                  setPage={(newPage) => setParam("page", newPage)}
                  isLoading={isLoading}
                />
              </div>
            )}
          </div>
          <div className="hidden xl:flex justify-end">
            <DatePickerComponent
              className={"pointer-events-none"}
              mode={"range"}
              selected={selected}
              onSelect={setSelected}
              disabledPeriod={[
                {
                  before: new Date(),
                },
              ]}
            />
          </div>
        </div>
      </div>

      <ModalManagement
        id={"ap-modal"}
        title={"Make an Appointment"}
        className={""}
      >
        <CreateAppointment />
      </ModalManagement>
    </HeaderFooter>
  );
};

export default ScheduleAppointment;
