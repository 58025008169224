import AudioItem from "generalComponents/AudioItem";
// import BookItem from "generalComponents/BookItem";
// import CategoriesNav from "generalComponents/CategoriesNav";
import TabComponent from "generalComponents/TabComponent";
import VideoItem from "generalComponents/VideoItem";
import React, { useEffect, useState } from "react";
import { useGetPurchasedItems } from "../controllers/get_library_contoller";
// import useGetSermons from "modules/pageSermons/controllers/get_sermons_contoller";
// import { filterSermonsByType } from "constants/filters";
// import useGetCategories from "modules/pageSermons/controllers/get_categories_controller";
import useQueryParams from "constants/useQueryParams";
import CustomPagination from "generalComponents/CustomPagination";
import EmptyState from "generalComponents/EmptyState";
import SermonDetails from "generalComponents/SermonDetails";

// const librraryNav = [
//   {
//     name: "My Library",
//   },
//   {
//     name: "Favorites",
//   },
//   {
//     name: "Downloads",
//   },
//   {
//     name: "Notes",
//   },
// ];

const tabs = [
  "Video",
  "Audio",
  // "Books"
];

const MyLibrary = () => {
  const { params, setParam } = useQueryParams({
    page: 1,
    pageSize: 28,
  });
  const [tabIdx, setTabIdx] = useState(0);
  const tabUpdate = (type) => {
    setParam(
      "content_type",
      type?.toLowerCase() === "books" ? "ebook" : type?.toLowerCase()
    );
  };
  const [selectedVideo, setSelectedVideo] = useState();
  const [selectedAudio, setSelectedAudio] = useState();

  const onSelectVideo = (data) => {
    setSelectedVideo(data);
  };
  const onCloseVideoDetails = () => {
    setSelectedVideo(undefined);
  };
  const onSelectAudio = (data) => {
    setSelectedAudio(data);
  };
  const onCloseAudioDetails = () => {
    setSelectedAudio(undefined);
  };

  const { data: library, isLoading } = useGetPurchasedItems({
    ...params,
  });
  // console.log({ library });
  // const { data: categories } = useGetCategories({
  //   group: "library",
  // });
  // console.log({ categories });

  const tabContent = [
    <div className="flex flex-wrap gap-5 mx-auto">
      {library?.items?.length > 0 ? (
        library?.items?.map((message, mIndex) => (
          <VideoItem
            key={mIndex}
            message={message?.item}
            onShowDetails={onSelectVideo}
          />
        ))
      ) : (
        <EmptyState
          heading="Whoops!"
          message="Looks like you do not have any video sermons in your library 😔. But don't worry, there's a treasure trove of inspiring content waiting for you in the store. Go ahead and purchase sermons to have them in your library! 😀"
          scriptureText={"Your word is a lamp for my feet, a light on my path."}
          scripture={"Psalm 119:105 (NIV)"}
        />
      )}
    </div>,
    <div className="flex flex-wrap gap-5 mx-auto">
      {library?.items?.length > 0 ? (
        library?.items?.map((message, mIndex) => (
          <AudioItem
            key={mIndex}
            message={message?.item}
            onShowDetails={onSelectAudio}
          />
        ))
      ) : (
        <EmptyState
          heading="Ohh no!"
          message="It seems you do not have any audio sermons in your library 😔. Purchase audio sermons from the store to have them in your library! 😀"
          scriptureText={
            "Consequently, faith comes from hearing the message, and the message is heard through the word about Christ."
          }
          scripture={"Romans 10:17 (NIV)"}
        />
      )}
    </div>,
    // <div className="w-full flex items-center flex-wrap gap-7">
    //   {library?.data?.items?.length > 0 ? (
    //     library?.data?.items?.map((item, index) => (
    //       <BookItem key={index} book={item} />
    //     ))
    //   ) : (
    //     <EmptyState
    //       heading="Umm..."
    //       message="Looks like your eBook library is empty 😔. But don't worry, simply purchase life-changing and life-applicable books from the store and have them in your library. 😀"
    //       scriptureText={
    //         "Get wisdom, get understanding; do not forget my words or turn away from them."
    //       }
    //       scripture={"Proverbs 4:5 (NIV)"}
    //     />
    //   )}
    // </div>,
  ];

  useEffect(() => {
    if (!params?.content_type) {
      setParam("content_type", "video");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (params?.content_type === "video") {
      setTabIdx(0);
    } else if (params?.content_type === "audio") {
      setTabIdx(1);
    }
    // else if (params?.content_type === "ebook") {
    //   setTabIdx(2);
    // }
  }, [params]);

  useEffect(() => {
    if (selectedVideo !== undefined) {
      window["video-details"]?.showModal();
    } else {
      window["video-details"]?.close();
    }
  }, [selectedVideo]);
  useEffect(() => {
    if (selectedAudio !== undefined) {
      window["audio-details"]?.showModal();
    } else {
      window["audio-details"]?.close();
    }
  }, [selectedAudio]);
  return (
    <>
      <TabComponent
        tabButtons={tabs}
        tabContent={tabContent}
        isLoading={isLoading}
        tabIdx={tabIdx}
        tabUpdate={tabUpdate}
      />

      {library?.data?.pagination?.total > 0 && (
        <div className="flex justify-end mt-10 min-w-full">
          <CustomPagination
            count={parseInt(library?.data?.pagination?.total)}
            page={parseInt(params?.page)}
            pageSize={parseInt(params?.pageSize) ?? 20}
            setPage={(newPage) => setParam("page", newPage)}
            isLoading={isLoading}
          />
        </div>
      )}

      {selectedVideo && (
        <SermonDetails
          onClose={onCloseVideoDetails}
          message={selectedVideo}
          id={"video-details"}
          type="video"
          title="Video Details"
        />
      )}

      {selectedAudio && (
        <SermonDetails
          onClose={onCloseAudioDetails}
          message={selectedAudio}
          id={"audio-details"}
          type="audio"
          title="Audio Details"
        />
      )}
    </>
  );
};

export default MyLibrary;
