import { cozaLogo } from "assets/icons";
import { RoundedButton } from "generalComponents/Button";
import SermonDisc from "generalComponents/SermonDisc";
import React from "react";
import { twMerge } from "tailwind-merge";
import SermonDetails from "../../../generalComponents/SermonDetails";
import { useNavigate } from "react-router-dom";

const SermonCard = ({ sermonImage, title, author, className, sermon }) => {
  const navigate = useNavigate();
  return (
    <div
      className={twMerge(
        "bg-gradient-to-r from-purpleOverlay  to-purpleBg w-fit h-[300px] lg:w-[1191px] rounded-[20px] py-2 lg:py-[25px] px-2.5 lg:px-12 flex lg:items-center justify-between shrink-0",
        className
      )}
    >
      <div className="flex flex-col pb-2">
        <img
          src={cozaLogo}
          alt=""
          className="hidden lg:block w-[12] h-12 mb-11"
        />
        {sermon?.content_type === "audio" ? (
          <SermonDisc image={sermonImage} className="lg:hidden mb-5 mx-auto" />
        ) : (
          <img
            src={sermonImage}
            alt=""
            className="block w-[250px] rounded-[12px] mx-auto object-cover lg:hidden mb-5"
          />
        )}
        <p className="text-white font-semibold text-lg lg:text-[30px] text-center lg:text-left">
          {title}
        </p>
        <p className="text-orangeBg text-sm lg:text-xl text-center lg:text-left">
          {author}
        </p>

        <RoundedButton
          onClick={() => window[`tile-${sermon?.id}`]?.showModal()}
          className="py-4 px-[30.5px] mt-auto lg:mt-8 w-full text-center lg:w-fit "
        >
          Open Sermon
        </RoundedButton>
      </div>

      <div className="hidden lg:flex justify-center items-center h-full relative">
        {sermon?.content_type === "audio" ? (
          <SermonDisc
            image={sermonImage}
            className="mr-[27px] hidden lg:flex"
          />
        ) : (
          // <SermonDisc
          //   image={message?.cover_image?.url}
          //   className={"mb-2 border-purpleBg size-[200px]"}
          //   small={true}
          // />
          <img
            src={sermonImage}
            alt=""
            className="size-full rounded-[12px] object-cover max-w-[440px]"
          />
        )}
        {sermon?.content_type === "video" ? (
          <button
            onClick={() => navigate(`/sermon/video/${sermon?.id}`)}
            className="size-[65px] bg-purpleOverlay/50 absolute inset-0 m-auto rounded-full flex justify-center items-center"
          >
            <svg
              width="19"
              height="21"
              viewBox="0 0 19 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.598389 3.71242C0.598389 1.29048 3.23006 -0.2147 5.31762 1.01328L16.8575 7.8015C18.916 9.01231 18.916 11.989 16.8575 13.1998L5.31762 19.988C3.23006 21.216 0.598389 19.7108 0.598389 17.2889V3.71242Z"
                fill="white"
              />
            </svg>
          </button>
        ) : null}
      </div>

      <SermonDetails
        message={sermon}
        id={`tile-${sermon?.id}`}
        type={sermon?.content_type}
        title="Sermon Details"
      />
    </div>
  );
};

export default SermonCard;
