import React, { useEffect, useRef, useState } from "react";
import { UpdateTicketStatusManager } from "../controller/updateStatusTicket";
import { useParams } from "react-router-dom";
import useGetSingleTicketManager from "../controller/getSingleTicketController";
import useFileUpload from "modules/fileupload/fileUploadController";
import useGetTicketMessagesManager from "../controller/getTicketMessagesController";
import { ReplyTicketManager } from "../controller/replyTicketController";
import { formatDate } from "utils/formatDate";
import Loader from "generalComponents/Loader";
import BaseDashboardNavigation from "generalComponents/BaseDashboardNavigation";
import GoBackButton from "generalComponents/GoBackButton";
import ReplyTile from "generalComponents/ReplyTile";
import InputWithFullBoarder from "generalComponents/InputWithFullBoarder";
import UploadFileComponent from "generalComponents/UploadFileComponent";
import { convertBytesToMB } from "utils/fileSize";
import CustomButton from "generalComponents/Button";
import { markInReview, markResolved } from "assets/icons";
import StatusButton from "generalComponents/StatusButton";
import AdminWrapper from "modules/admin/AdminWrapper";

const AdminTicketsDetailsPage = () => {
  const { ticketId } = useParams();
  const initialData = {
    ticket: ticketId,
    message: "",
    attachments: [],
  };
  const [formData, setFormData] = useState(initialData);
  const { updateStatus, isLoading: updating } = UpdateTicketStatusManager({
    ticketId: ticketId,
  });
  const { data, isLoading } = useGetSingleTicketManager({
    ticketId: ticketId,
    enabled: true,
  });

  const {
    progress,
    handleFileUpload,
    isLoading: uploadingFile,
  } = useFileUpload();

  const { data: messages, isLoading: loadingMessages } =
    useGetTicketMessagesManager({ ticketId: ticketId });

  const { replyTicket, isLoading: replying, isSuccess } = ReplyTicketManager();

  useEffect(() => {
    if (isSuccess) {
      setFormData(initialData);
    }
  }, [isSuccess]);

  const attachmentRef = useRef(null);
  const [attachmentFile, setAttachmentFile] = useState(null);

  const ticket = data?.data;
  const msg = messages?.data;

  const content = [
    { name: "Subject", value: ticket?.title },
    {
      name: "Name",
      value: `${ticket?.user?.first_name} ${ticket?.user?.last_name}`,
    },
    { name: "Email", value: ticket?.user?.email },
    { name: "Created", value: formatDate(ticket?.createdAt) },
  ];

  if (isLoading || loadingMessages) {
    return <Loader />;
  }
  return (
    <AdminWrapper>
      <div className="flex flex-col w-full h-[80vh] overflow-auto">
        <div className="flex items-center justify-between w-full">
          <GoBackButton />
          {ticket?.status !== "closed" && (
            <div className="flex items-center justify-center gap-3 w-full">
              <img
                role="button"
                onClick={() => {
                  updateStatus({ status: "under_review" });
                }}
                src={markInReview}
                alt=""
              />
              <img
                role="button"
                onClick={() => {
                  updateStatus({ status: "closed" });
                }}
                src={markResolved}
                alt=""
              />
            </div>
          )}
        </div>
        <div className="flex items-start mt-10 gap-10">
          <div className="w-full flex flex-col gap-4 scrollbar-hide bg-white rounded-[12px] p-10 ">
            {content.map((el, i) => (
              <div
                key={i}
                className="flex items-center justify-between text-12px"
              >
                <p className="text-grey3">{el.name}</p>
                <p className="text-brandBlack font-medium text-15px">
                  {el.value}
                </p>
              </div>
            ))}
            <div className="flex items-center justify-between text-12px">
              <p className="text-grey3">{"Status"}</p>
              <StatusButton status={ticket?.status} />
            </div>
            <p className="text-grey3">{"Message"}</p>
            <p className="text-10px text-brandBlack">{ticket?.description}</p>

            <p className="text-[12.5px] font-medium">Replies</p>

            <div className="flex flex-col w-full">
              {msg && msg.map((el, i) => <ReplyTile key={i} details={el} />)}
            </div>
            {ticket?.status !== "closed" && (
              <InputWithFullBoarder
                label={`Reply`}
                placeholder={`Enter text`}
                className={`w-full h-[200px]`}
                isTextArea={true}
                value={formData.message}
                onChange={(e) =>
                  setFormData({ ...formData, message: e.target.value })
                }
              />
            )}
            {ticket?.status !== "closed" && (
              <UploadFileComponent
                description={`Upload your attachment`}
                inputRef={attachmentRef}
                isLoading={uploadingFile}
                format={`Image/PDF/Video`}
                maxSize={
                  attachmentFile ? convertBytesToMB(attachmentFile.size) : `20`
                }
                fileName={attachmentFile ? attachmentFile.name : null}
                progress={progress}
                accept={"video/*,application/pdf,image/*"}
                buttonClick={() => setAttachmentFile(null)}
                onChange={async (e) => {
                  const file = e.target.files[0];
                  setAttachmentFile(file);
                }}
              />
            )}
            {ticket?.status !== "closed" && (
              <CustomButton
                buttonText={`Submit`}
                className={`w-full`}
                loader={
                  uploadingFile ? `Attachment ${progress}%` : "Submitting..."
                }
                isLoading={uploadingFile || replying}
                onClick={async () => {
                  const updatedFormData = { ...formData };

                  updatedFormData.ticket = ticketId;
                  if (attachmentFile) {
                    const url = await handleFileUpload(attachmentFile);
                    updatedFormData.attachments = [url];
                  }
                  console.log(updatedFormData);
                  replyTicket(updatedFormData);
                }}
              />
            )}
          </div>
        </div>
      </div>
    </AdminWrapper>
  );
};

export default AdminTicketsDetailsPage;
