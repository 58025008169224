import React from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { twMerge } from "tailwind-merge";

const DatePickerComponent = ({
  mode,
  selected,
  onSelect,
  disabledPeriod,
  className,
}) => {
  return (
    <div className=" px-5 py-4 w-fit text-black rounded-[10px] shadow-[0_10px_30px_0_#8311B326]">
      <DayPicker
        // showOutsideDays={true}
        mode={mode ?? "single"}
        className={twMerge("p-3", className)}
        selected={selected}
        onSelect={onSelect}
        // captionLayout={"dropdown"}
        // initialFocus
        disabled={disabledPeriod ?? false}
        classNames={{
          today: "border-[1.5px] border-purpleBg", // Add a border to today's date
          selected: "bg-[#FBF2FF] border-[1.5px] border-purpleBg text-black", // Highlight the selected day
          // calendar: `shadow-lg p-5`, // Add a shadow to the calendar
          chevron: "fill-purpleBg", // Change the color of the chevron
          day: "rounded-[10px]",
          outside: "opacity-[0.4]",
          disabled: "opacity-[0.4] pointer-events-none",
          range_start: "bg-[#FBF2FF] border-2",
          range_end: "bg-[#FBF2FF]",
        }}
      />
    </div>
  );
};

export default DatePickerComponent;
