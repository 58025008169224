import React from "react";
import HeaderFooter from "../../../generalComponents/HeaderFooter";
// import AdminDashboard from "../../admin/AdminDashboard";
// import LoginPage from "../../authentication/view/LoginPage";
import HomeHero from "../component/HomeHero";
import UpcomingEventsSection from "../component/UpcomingEventsSection";
// import AboutUs from "../component/AboutUs";
import LatestSermon from "../component/LatestSermon";
import Features from "../component/Features";
import DownloadApps from "../component/DownloadApps";
// import { ReactLenis } from "lenis/react";

const HomePage = () => {
  // const tokenExists = localStorage.getItem("token") !== null;
  // return <HeaderFooter />;
  // return tokenExists ? <AdminDashboard /> : <LoginPage />;
  return (
    // <ReactLenis root>
    <div className="w-full relative overflow-hidden overflow-y-clip scroll-smooth">
      <HeaderFooter solid={false}>
        <HomeHero />
        <UpcomingEventsSection />
        <Features />
        {/* <AboutUs /> */}
        <LatestSermon />
        <DownloadApps />
      </HeaderFooter>
    </div>
    // {/* </ReactLenis> */}
  );
};

export default HomePage;
