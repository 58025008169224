// import { avatar, praiseReport1, praiseReport2 } from "assets/images";
import { capitalizeFirstLetter } from "constants/textFormatters";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { twMerge } from "tailwind-merge";
import PlayButton from "./PlayButton";
import MuteButton from "./MuteButton";
import LikeButton from "./LikeButton";
import ShareButton from "./ShareButton";
import { useLikePraiseReport } from "../controllers/put_controller";
import { useUserStore } from "store/userStore";
import showToast from "constants/showToast";
// import PraiseReportModal from "./PraiseReportModal";
import SinglePraiseReport from "./SinglePraiseReport";
import { avatar as placeholder } from "assets/images";
import CustomModalHeadless from "generalComponents/CustomModalHeadless";
import useDisclosure from "constants/useDisclosure";
import CloseButton from "generalComponents/CloseButton";

const appBaseUrl = process.env.REACT_APP_APP_BASE_URL;

const PraiseReportItem = forwardRef(
  (
    {
      avatar,
      fullname,
      time,
      title,
      image,
      likes,
      description,
      praiseReportData,
      visible,
    },
    ref
  ) => {
    const user = useUserStore((state) => state.user);
    const observer = useRef();
    const reportDialogRef = useRef();
    const [fullDescription, setFullDescription] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [muted, setMuted] = useState(true);
    const [showComments, setShowComments] = useState(false);

    const { isOpen, onOpen, onClose } = useDisclosure();

    const praiseReportLink = `${appBaseUrl}/praise-reports/${praiseReportData?.id}`;
    const onCopy = (e, data) => {
      e.preventDefault();
      e.stopPropagation();
      navigator.clipboard.writeText(data);
      showToast("Link copied", "success");
    };
    const handleCopy = (e) => {
      onCopy(e, praiseReportLink);
    };

    const { likePraiseReport } = useLikePraiseReport({
      id: praiseReportData?.id,
      like: "like",
    });
    const { likePraiseReport: unlikePraiseReport } = useLikePraiseReport({
      id: praiseReportData?.id,
      like: "unlike",
    });

    const userLiked = useMemo(() => {
      const returnedUser = praiseReportData?.recent_likes?.filter(
        (item) => item?.user?.id === user?.id
      );

      if (returnedUser?.length > 0) return true;
      else return false;
    }, [praiseReportData?.recent_likes, user?.id]);

    const videoElementRef = useCallback(
      (node) => {
        if (observer.current) observer.current.disconnect();
        const video = document.querySelector(`#report-${praiseReportData?.id}`);

        observer.current = new IntersectionObserver((entries) => {
          if (entries[0].isIntersecting && visible && !showComments) {
            video?.play()?.catch((err) => console.log(err));
          } else {
            video?.pause()?.catch((err) => console.log(err));
          }
        });

        if (node) observer.current.observe(node);
      },
      [praiseReportData?.id, visible, showComments]
    );

    const onVideoClick = (e) => {
      const video = document.querySelector(`#report-${praiseReportData?.id}`);
      if (isPlaying) {
        video?.pause();
      } else {
        video?.play();
      }
    };

    const onShowComments = () => {
      setShowComments(true);
      reportDialogRef.current?.focus();
    };

    useEffect(() => {
      document.addEventListener("keydown", function (event) {
        if ((event.key === "Escape" || event.keyCode === 27) && showComments) {
          setShowComments(false);
        }
      });

      return () =>
        document.removeEventListener("keydown", function (event) {
          if (
            (event.key === "Escape" || event.keyCode === 27) &&
            showComments
          ) {
            setShowComments(false);
          }
        });
    }, [showComments]);

    return (
      <div
        ref={ref}
        className="py-6 [&>*]:px-5 mx-auto border-b border-b-gray-300 md:px-3"
      >
        <div className="flex items-center gap-2.5 w-fit mb-4">
          {praiseReportData?.anonymous ? (
            // <div className="size-10 bg-black rounded-full"></div>
            <img
              src={placeholder}
              alt=""
              className="w-10 h-10 rounded-full filter grayscale"
            />
          ) : (
            <img src={avatar} alt="" className="w-10 h-10 rounded-full" />
          )}
          <div>
            <p className="font-medium text-[13px] text-[#1D1D1D]">
              {praiseReportData?.anonymous
                ? "Anonymous"
                : fullname !== ""
                ? fullname
                : `${capitalizeFirstLetter(
                    praiseReportData?.user?.first_name
                  )} ${capitalizeFirstLetter(
                    praiseReportData?.user?.last_name
                  )}`}
            </p>
            <p className="text-[10px] text-[#BDBDBD]">{time}</p>
          </div>
        </div>

        <p className="font-semibold text-xl text-purpleOverlay mb-2">{title}</p>
        <div className="relative !px-0 min-h-[360px] max-h-[560px]">
          {" "}
          {praiseReportData?.mediaType === "video" ? (
            <>
              <video
                id={`report-${praiseReportData?.id}`}
                onClick={onVideoClick}
                onPlaying={() => setIsPlaying(true)}
                onPause={() => setIsPlaying(false)}
                ref={videoElementRef}
                controls={false}
                muted={muted}
                loop={false}
                src={praiseReportData?.mediaUrl}
                className="mx-auto"
              ></video>
              <button
                onClick={() => setMuted((val) => !val)}
                className="absolute bottom-3 right-3 z-10 bg-purpleOverlay p-2 rounded-full outline-none"
              >
                <MuteButton muted={muted} />
              </button>

              {!isPlaying && (
                <>
                  <PlayButton
                    onClick={onVideoClick}
                    className="absolute inset-0 m-auto z-[3]"
                  />
                  <div className="absolute h-full w-full top-0 left-0 bg-gradient-to-b from-transparent to-black/50 z-[2]"></div>
                </>
              )}
            </>
          ) : (
            <img
              src={
                praiseReportData?.mediaUrl
                  ? praiseReportData?.mediaUrl
                  : praiseReportData?.anonymous
                  ? placeholder
                  : image
              }
              alt=""
              className={twMerge(
                "w-full mb-3  max-h-[560px] object-cover bg-black",
                praiseReportData?.anonymous && "filter grayscale"
              )}
            />
          )}
        </div>
        <div className="flex items-center justify-between mt-2.5">
          <div className="flex items-center gap-1">
            <LikeButton
              onClick={userLiked ? unlikePraiseReport : likePraiseReport}
              liked={userLiked}
              className="duration-500 hover:scale-[0.9] hover:opacity-70"
            />
            {likes > 0 && (
              <span className="font-medium text-xs text-purpleOverlay">
                {likes} Likes
              </span>
            )}
          </div>
          <ShareButton
            onClick={handleCopy}
            className="duration-500 hover:scale-[0.9] hover:opacity-70"
          />
        </div>
        <div className="flex items-center gap-1 mt-1.5">
          <div className="flex -space-x-2 items-center">
            {praiseReportData?.recent_likes
              ?.filter((_filteredItem, index) => index < 4)
              ?.map((item, idx) => (
                <img
                  key={idx}
                  src={item?.user?.profile_picture ?? placeholder}
                  alt="avatar"
                  className={twMerge(
                    "w-6 h-6 rounded-full object-cover border border-white",
                    !item?.user?.profile_picture & "filter grayscale"
                  )}
                />
              ))}
          </div>
          {praiseReportData?.like_count > 0 && (
            <p
              role="button"
              onClick={onOpen}
              className="text-xs text-purpleOverlay"
            >
              Liked by{" "}
              <span className="font-semibold">
                {praiseReportData?.recent_likes[0]?.user?.first_name}
              </span>{" "}
              {praiseReportData?.like_count > 1 ? "and others" : ""}
            </p>
          )}
        </div>

        <p className="text-[#828282] text-sm whitespace-pre-line mt-2.5">
          <span
            className={twMerge(
              "max-w-full",
              !fullDescription && "line-clamp-2"
            )}
          >
            {description}
          </span>
          {!fullDescription && (
            <span
              onClick={() => setFullDescription(true)}
              role="button"
              className="text-purpleBg"
            >
              Read more
            </span>
          )}
        </p>

        <button
          onClick={onShowComments}
          className="outline-none text-orangeBg text-sm"
        >
          View all{" "}
          {praiseReportData?.comment_count > 0
            ? praiseReportData?.comment_count
            : ""}{" "}
          comments
        </button>

        {/* <PraiseReportModal
          id={`report_${praiseReportData.id}`}
          data={praiseReportData}
        /> */}

        {showComments && (
          <div
            role="dialog"
            ref={reportDialogRef}
            className="fixed top-0 left-0 h-[100svh] w-[100svw] z-[99] animate-fade-in !p-0"
          >
            <button
              onClick={() => setShowComments(false)}
              className="absolute top-2 right-5 rounded-full p-1 duration-300 bg-[#FCF5FF] outline-none z-[100]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6 stroke-purpleOverlay"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </button>
            <SinglePraiseReport praiseReport={praiseReportData} />
          </div>
        )}

        {showComments && (
          <div
            role="dialog"
            ref={reportDialogRef}
            className="fixed left-0 top-0 z-[99] h-[100svh] w-[100svw] animate-fade-in !p-0"
          >
            <CloseButton onClick={() => setShowComments(false)} />
            <SinglePraiseReport praiseReport={praiseReportData} />
          </div>
        )}
        {/* </CustomModalHeadless>
        )} */}

        {isOpen && (
          <CustomModalHeadless
            {...{
              isOpen,
              onClose,
              title: `Likes (${praiseReportData?.like_count})`,
            }}
          >
            {praiseReportData?.recent_likes?.map((item, index) => (
              <div
                key={index}
                className="flex items-center w-full gap-2.5 pb-2.5"
              >
                <img
                  src={item?.user?.profile_picture ?? avatar}
                  alt=""
                  className="w-9 h-9 rounded-full"
                />
                <div>
                  <p className="text-sm text-purpleOverlay font-medium whitespace-pre-line">
                    {`${item?.user?.first_name} ${item?.user?.last_name}`}
                  </p>
                </div>
              </div>
            ))}
          </CustomModalHeadless>
        )}
      </div>
    );
  }
);

export default PraiseReportItem;
