import { Popover, Transition } from "@headlessui/react";
import { lowerCase } from "lodash";
import { Fragment, useRef } from "react";
import { twMerge } from "tailwind-merge";

const PopoverMenu = ({
  title,
  titleExtraClass,
  isTitleDisabled,
  position,
  onOpenTitleExtraClass,
  children,
  contentExtraClass,
}) => {
  const btnRef = useRef(null);

  const onContainerClick = (e) => {
    const tagName = lowerCase(e.target?.tagName);

    if (
      tagName === "button" &&
      e.target?.classList?.contains("filter-submit-btn")
    ) {
      setTimeout(() => {
        console.log("Close popover");
        btnRef.current?.click(); //click to hide popover
      }, 200);
    }
  };
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            ref={btnRef}
            disabled={isTitleDisabled}
            className={twMerge(
              "group inline-flex items-center gap-2 px-3 py-2 rounded-md bg-neutral-100 hover:bg-neutral-200/80 duration-200",
              "disabled:opacity-40",
              titleExtraClass,
              open ? onOpenTitleExtraClass || "bg-neutral-200/80" : ""
            )}
          >
            {title}
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              className={twMerge(
                "absolute z-50 mt-3 w-fit left-0 shadow-md",
                position === "left" && "left-0",
                position === "right" && "right-0",
                (position === "center" || position == null) &&
                  "left-1/2 -translate-x-1/2"
              )}
            >
              <div
                onMouseDown={(e) => onContainerClick(e)}
                className={twMerge(
                  "bg-neutral-50 p-4 lg:max-w-3xl min-w-[200px]",
                  "custom-shadow-lg rounded-md",
                  contentExtraClass
                )}
              >
                {children}
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default PopoverMenu;
