import { formatDate } from "utils/formatDate";

export const validTableKeys = {
  name: {
    title: "Name",
    addBadge: false,
    isImage: false,
    addCopyPreview: false,
    showOnMobile: true,
  },
  date: {
    title: "Date",
    addBadge: false,
    isSortable: false,
    isImage: false,
    addCopyPreview: false,
    showOnMobile: true,
  },
};
export function modifyData(data) {
  if (!data.length) return [];
  const newArray = data?.map((item) => ({
    ...item,
    date: formatDate(item?.createdAt),
  }));

  return newArray;
}
