import React, { useEffect, useState } from "react";
import { authentication, topPattern } from "../../../assets/images";
import InputWithFullBoarder from "../../../generalComponents/InputWithFullBoarder";
import { Link, useNavigate } from "react-router-dom";
import AuthenticationBase from "../components/AuthenticationBase";
import useLoginManager from "../controllers/login.controller";
import useAdminLoginManager from "../controllers/adminLoginController";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { toast } from "react-toastify";

const AdminLoginPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [viewPassword, setViewPassword] = useState(false);
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const { postCaller, data, isSuccess, isLoading, error } =
    useAdminLoginManager(email);

  useEffect(() => {
    if (isSuccess) {
      toast.success(data.message);
    }
    if (error) {
      toast.error(error.message);
    }
  }, [isSuccess, error, data]);

  const handleSubmission = async (e) => {
    e.preventDefault();
    const details = {
      id: email,
      password: password,
    };

    await postCaller(details);

    setEmail("");
    setPassword("");
  };
  return (
    <AuthenticationBase
      title={"App Admin Portal "}
      subtitle={"Securely sign into your COZA app"}
      inputFields={
        <div>
          <InputWithFullBoarder
            label={"Email address"}
            type="text"
            id="email"
            value={email}
            onChange={handleEmailChange}
          />
          <InputWithFullBoarder
            label={"Password"}
            type={viewPassword ? "text" : "password"}
            hasSuffix={true}
            icon={
              viewPassword ? (
                <AiOutlineEyeInvisible
                  size={22}
                  onClick={() => setViewPassword(!viewPassword)}
                />
              ) : (
                <AiOutlineEye
                  size={22}
                  onClick={() => setViewPassword(!viewPassword)}
                />
              )
            }
            id="password"
            value={password}
            onChange={handlePasswordChange}
          />
          <div className="w-full flex items-end justify-end">
            <button
              onClick={() => navigate("/forgot-password")}
              className="text-[14px] md:text-[20px] w-full font-semibold text-blackColor text-right mx-auto hover:scale-125 hover:text-blackColor duration-300"
            >
              Forgot Password?
            </button>
          </div>
        </div>
      }
      buttonText={"Login"}
      isLoading={isLoading}
      onClick={handleSubmission}
      afterElements={
        <div className="flex flex-col items-center ">
          {/* <p className="text-[14px] md:text-[20px] font-semibold py-5 text-blackColor text-center">
            Don’t have an account?
            <Link to={"/create-account"}>
              <span className="hover:text-blackColor/60 hover:scale-110 duration-300 underline md:ml-3 text-blackColor">
                {"Register"}
              </span>
            </Link>
          </p> */}
        </div>
      }
    />
  );
};

export default AdminLoginPage;
