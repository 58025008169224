import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import AudioBottomDialog from "../components/AudioBottomDialog";
import AudioModal from "../components/AudioModal";

const AudioContext = createContext();

export const useAudioContext = () => {
  const context = useContext(AudioContext);
  if (!context) {
    throw new Error("Audio context can only be used within the AudioProvider");
  }

  return context;
};

const AudioProvider = ({ children }) => {
  const { Provider } = AudioContext;
  const [currentAudio, setCurrentAudio] = useState();
  const [duration, setDuration] = useState(0);
  const [isReady, setIsReady] = React.useState(false);
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [currentProgress, setCurrentProgress] = useState(0);
  const [minimize, setMinimize] = useState(true);
  const [isAudioPgae, setIsAudioPage] = useState(false);

  const audioRef = useRef();

  const togglePlay = () =>
    !isPlaying ? audioRef.current?.play() : audioRef.current?.pause();

  const value = {
    currentAudio,
    setCurrentAudio,
    duration,
    setDuration,
    isReady,
    setIsReady,
    isPlaying,
    setIsPlaying,
    currentProgress,
    setCurrentProgress,
    audioElement: audioRef.current,
    togglePlay,
    minimize,
    setMinimize,
    isAudioPgae,
    setIsAudioPage,
  };

  useEffect(() => {
    if (!currentAudio) {
      window["audio_modal"]?.close();
      setIsReady(false);
    }
    if (currentAudio && !minimize) {
      window["audio_modal"]?.showModal();
    }
  }, [currentAudio, minimize]);

  useEffect(() => {
    if (currentAudio && !isAudioPgae) {
      audioRef.current?.play();
    }
  }, [currentAudio, isAudioPgae]);
  return (
    <Provider value={value}>
      {children}
      {currentAudio && (
        <audio
          ref={audioRef}
          preload="metadata"
          onDurationChange={(e) => setDuration(e.currentTarget.duration)}
          onCanPlay={(e) => {
            // e.currentTarget.volume = volume;
            setIsReady(true);
          }}
          onPlaying={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
          onTimeUpdate={(e) => {
            setCurrentProgress(e.currentTarget.currentTime);
            // handleBufferProgress(e);
          }}
        >
          <source type="audio/mpeg" src={currentAudio?.content} />
        </audio>
      )}

      {!isAudioPgae && (
        <>
          <AudioBottomDialog />
          <AudioModal id="audio_modal" />{" "}
        </>
      )}
    </Provider>
  );
};

export default AudioProvider;
