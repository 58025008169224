import React, { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { twMerge } from "tailwind-merge";

const InputWithFullBoarder = ({
  id,
  name,
  type,
  value,
  onChange,
  label,
  checked,
  onClick,
  className,
  placeholder,
  hasSuffix,
  row = "10",
  icon,
  accept,
  isTextArea = false,
  labelClass,
  otherProps,
}) => {
  const [viewPassword, setViewPassword] = useState(false);
  return (
    <div className="flex flex-col mb-4">
      <label className={`text-sm font-medium mb-2 ${labelClass}`} htmlFor={id}>
        {label}
      </label>
      {isTextArea ? (
        <textarea
          id={id}
          name={name}
          className={twMerge(
            "border border-[#D0D5DD] bg-lightGrey/20 p-2 py-3 rounded-md outline-none focus:outline-none focus:border-purpleBg duration-200",
            className
          )}
          cols="30"
          rows={row}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          {...otherProps}
        ></textarea>
      ) : type === "password" ? (
        <div
          className={`bg-lightGrey/20 rounded-md border border-[#D0D5DD] outline-none focus:outline-none flex items-center justify-between  pr-2 focus-within:border-purpleBg duration-200 ${className}`}
        >
          <input
            onClick={onClick}
            type={viewPassword ? "text" : "password"}
            id={id}
            name={name}
            accept={accept}
            placeholder={placeholder}
            checked={checked}
            color="white"
            value={value}
            onChange={onChange}
            className={`bg-lightGrey/20 outline-none focus:outline-none w-full h-full mr-4 p-2 py-3`}
            {...otherProps}
          />{" "}
          <div>
            {viewPassword ? (
              <AiOutlineEyeInvisible
                size={22}
                onClick={() => setViewPassword(!viewPassword)}
              />
            ) : (
              <AiOutlineEye
                size={22}
                onClick={() => setViewPassword(!viewPassword)}
              />
            )}
          </div>
        </div>
      ) : hasSuffix ? (
        <div
          className={`group bg-lightGrey/20 rounded-md border border-[#D0D5DD] focus:border-2 p-2  py-3 outline-none focus:outline-none flex items-center justify-between  focus-within:border-purpleBg duration-200 ${className}`}
        >
          <input
            onClick={onClick}
            type={type}
            id={id}
            name={name}
            accept={accept}
            placeholder={placeholder}
            checked={checked}
            color="white"
            value={value}
            onChange={onChange}
            className={`bg-lightGrey/20 outline-none focus:outline-none w-full mr-4 ${className}`}
            {...otherProps}
          />{" "}
          <div>{icon}</div>
        </div>
      ) : (
        <input
          onClick={onClick}
          type={type}
          id={id}
          name={name}
          accept={accept}
          placeholder={placeholder}
          checked={checked}
          color="white"
          value={value}
          onChange={onChange}
          className={twMerge(
            "bg-lightGrey/20 rounded-md border border-[#D0D5DD] p-2 py-3 outline-none focus:outline-none focus:border-purpleBg duration-200",
            className
          )}
          {...otherProps}
        />
      )}
    </div>
  );
};

export default InputWithFullBoarder;
