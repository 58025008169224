import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import { logo, whiteLogo } from "../assets/images";
import { userMenu, floatingMenu } from "../constants/menu";
// import { Menu } from "@mui/material";
// import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { twMerge } from "tailwind-merge";
import { bufferIcon, cozaLogo } from "assets/icons";
import { RoundedButton } from "./Button";
import useDetectScroll from "constants/scrollDetect";
// import useLoggedInUser from "constants/loggedInUser";
import UserMenu from "./UserMenu";
import { useGetUser } from "modules/pageUserProfile/controller/get_controller";
import NotificationPopover from "modules/notification/NotificationPopover";
import { useUserStore } from "store/userStore";

const MobileMenu = ({ className }) => {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen((val) => !val);
  // const { logout } = useLoggedInUser();
  return (
    <div
      className={twMerge(
        "w-full flex flex-col transition duration-300 ease-out",
        open && "open",
        className
        // open ? "grid-rows-[auto_1fr] gap-5" : "grid-rows-[auto_0fr]"
      )}
    >
      <div className="relative flex items-center justify-center">
        {!open ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-10 h-10 absolute left-5 fill-white cursor-pointer"
            onClick={toggleOpen}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-10 h-10 absolute left-5 fill-white cursor-pointer"
            onClick={toggleOpen}
          >
            <path
              fillRule="evenodd"
              d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z"
              clipRule="evenodd"
            />
          </svg>
        )}
        <Link to="/">
          <img src={cozaLogo} alt="" className="w-16 h-16 " />
        </Link>

        <div className="absolute right-6 z-10">
          <UserMenu />
        </div>
      </div>

      <div
        className={twMerge(
          "grid transition-[grid-template-rows] duration-300 overflow-hidden",
          open ? "grid-rows-[1fr]" : "grid-rows-[0fr]"
        )}
      >
        <div
          className={twMerge(
            " bg-white w-full px-5 transition-all duration-300 overflow-hidden"
          )}
        >
          <div className="py-5 w-full">
            <FloatingMenu className={twMerge("mx-auto w-fit")} />
          </div>
          {userMenu.map((item, index) => (
            <Link
              key={index}
              to={item.path}
              onClick={() => {
                setTimeout(() => {
                  toggleOpen();
                }, 300);
              }}
              className={twMerge(
                "text-lg py-6 text-gray-500 w-full border-b border-b-gray-300 block"
              )}
            >
              {item.title}
            </Link>
          ))}

          <div className="mt-10 pb-10">
            <RoundedButton
              url="/coza-tv"
              className="bg-orangeBg flex items-center gap-1 font-semibold text-white"
            >
              <img src={bufferIcon} alt="" /> <span>COZA TV</span>
            </RoundedButton>

            {/* <button
              title="Logout"
              onClick={logout}
              className="flex gap-2.5 items-center text-gray-500 mt-5"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6 rotate-180"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15m-3 0-3-3m0 0 3-3m-3 3H15"
                />
              </svg>
              Logout
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const DesktopMenu = ({ className }) => {
  const location = useLocation();
  // const { logout } = useLoggedInUser();
  return (
    <div className="hidden @container lg:flex items-center justify-between py-3 px-5 max-w-section mx-auto relative">
      <div className="flex gap-5 @2lg:gap-9">
        {userMenu.map((item, index) => (
          <Link
            key={index}
            to={item.path}
            className={twMerge(
              "text-sm text-[#98A2B3] w-full pr-3 text-nowrap",
              item.path === location.pathname &&
                "text-white border-b border-b-orangeBg"
            )}
          >
            {item.title}
          </Link>
        ))}
      </div>

      <Link to="/">
        <img
          src={cozaLogo}
          alt=""
          className="w-16 h-16 absolute inset-0 m-auto"
        />
      </Link>

      <div className="flex justify-end items-center space-x-4">
        {/* <div className="flex items-center gap-1 w-fit mr-4">
          <img src={ukIcon} alt="" />
          <select
            name=""
            id=""
            className="outline-none text-sm font-semibold bg-transparent"
          >
            <option value="eng">EN</option>
          </select>
        </div> */}

        <RoundedButton
          url="/coza-tv"
          className="bg-orangeBg flex items-center gap-1 font-semibold text-white "
        >
          <img src={bufferIcon} alt="" /> <span>COZA TV</span>
        </RoundedButton>

        <NotificationPopover />

        <UserMenu />
        {/* <button title="Logout" onClick={logout}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6 rotate-180"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15m-3 0-3-3m0 0 3-3m-3 3H15"
            />
          </svg>
        </button> */}
      </div>
    </div>
  );
};

const FloatingMenu = ({ className }) => {
  const location = useLocation();
  return (
    <div
      className={twMerge(
        "py-[5px] px-1.5 grid grid-cols-3 items-center bg-purpleBg text-white rounded-[50px] w-fit scale-95 md:scale-100",
        className
      )}
    >
      {floatingMenu.map((item, index) => (
        <Link
          key={index}
          to={item.path}
          className={twMerge(
            "flex items-center justify-center gap-1 w-[120px] lg:w-[146px] h-8 lg:h-10 rounded-[50px]",
            location.pathname === item.path && "bg-orangeBg"
            // item.path === "/store" && "pointer-events-none"
          )}
        >
          {location.pathname === item.path ? item.activeIcon : item.icon}{" "}
          <span>{item.title}</span>
        </Link>
      ))}
    </div>
  );
};

const Header = ({
  clickHome,
  clickFeatures,
  clickSolutions,

  clickDownload,

  headerClass,
  solid = true,
}) => {
  // const navigate = useNavigate();
  // const [mobile, setMobile] = useState(false);

  // const isMobile = () => {
  //   setMobile(!mobile);
  // };
  useGetUser();
  const ref = useRef();
  const user = useUserStore((state) => state.user);
  const navigate = useNavigate();

  const { scrollDir, scrollPosition } = useDetectScroll();

  useEffect(() => {
    if (user?.phone === "" || !user.campus) {
      navigate("/user-profile?tab=1");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
      <header
        ref={ref}
        className={twMerge(
          "py-3 fixed z-50 w-full backdrop-blur-xl duration-300",
          scrollPosition?.top === 0
            ? "bg-gradient-to-b from-purpleOverlay to-purpleOverlay/0"
            : "bg-purpleOverlay",
          solid && "bg-purpleOverlay",
          headerClass
        )}
      >
        <DesktopMenu />
        <MobileMenu className="lg:hidden" />
      </header>
      <FloatingMenu
        className={twMerge(
          "hidden lg:flex fixed top-[102px] left-0 right-0 mx-auto w-fit z-40",
          scrollDir === "up" && "animate-slide-down",
          scrollDir === "down" &&
            scrollPosition?.top > 100 &&
            "animate-slide-up"
        )}
      />
    </>
    // <div
    //   className={twMerge(
    //     "fixed w-full top-0 z-50 bg-gradient-to-b from-[#2F0640] to-transparent backdrop-blur-md",
    //     headerClass
    //   )}
    // >
    //   <div className="absolute inset-0 -z-10 bg-[#2F0640]/40"></div>
    //   <header class="bg-opacity-30 py-3 mx-auto max-w-section">
    //     <div class="px-4 mx-auto sm:px-6 lg:px-8">
    //       <div class="flex items-center justify-between h-16 lg:h-20">
    //         <div
    //           className={`flex md:w-auto justify-end duration-200 ${
    //             mobile ? "" : ""
    //           }`}
    //         >
    //           <button
    //             onClick={isMobile}
    //             type="button"
    //             class="inline-flex p-2 text-black transition-all duration-200 rounded-md lg:hidden relative z-20"
    //           >
    //             {mobile ? (
    //               <AiOutlineClose className="w-10 h-10 fill-black stroke-black" />
    //             ) : (
    //               <AiOutlineMenu className="w-10 h-10 fill-white" />
    //             )}
    //           </button>
    //         </div>

    //         <ul
    //           onClick={isMobile}
    //           className={`fixed md:hidden pt-24 ${
    //             mobile ? "left-0" : "left-[-100%]"
    //           } top-0 w-[60%] h-full bg-white text-whiteColor ease-in-out duration-500`}
    //         >
    //           {/* <div className="bg-white">
    //             <img
    //               className="object-contain w-[8rem] mt-16 ml-5"
    //               src={cozaLogo}
    //               alt="COZA logo"
    //             />
    //           </div> */}
    //           <div className="bg-white text-black">
    //             {userMenu.map((el, i) => (
    //               <li
    //                 key={i}
    //                 onClick={() => {
    //                   navigate(el.path);
    //                 }}
    //                 className="p-4 ml-5"
    //               >
    //                 {el.title}
    //               </li>
    //             ))}

    //             <li className="p-4 ml-5 ">
    //               <div className="">
    //                 <button
    //                   onClick={() => navigate("/login")}
    //                   class=" lg:inline-flex items-center w-[80%] justify-center px-5 py-1.5 text-[16px] transition-all duration-200 bg-brandRed hover:text-white  font-medium text-blackColor border border-blackColor hover:border-offCoffee rounded-[25px] mr-3 mb-3"
    //                 >
    //                   Sign in
    //                 </button>
    //                 <button
    //                   onClick={() => navigate("/create-account")}
    //                   class=" lg:inline-flex items-center justify-center px-5 w-[80%] py-1.5 text-[16px] transition-all duration-200 hover:bg-brandOrange hover:text-black focus:text-black  font-medium text-white bg-offCoffee rounded-[25px]"
    //                 >
    //                   Sign up
    //                 </button>
    //               </div>
    //             </li>
    //           </div>
    //         </ul>

    //         <div class="hidden lg:flex lg:items-center lg:justify-center lg:space-x-10">
    //           {userMenu.map((el, i) => (
    //             <button
    //               key={i}
    //               onClick={() => {
    //                 navigate(el.path);
    //               }}
    //               title=""
    //               class="text-base text-whiteColor uppercase transition-all duration-200 hover:text-opacity-80"
    //             >
    //               {el.title}
    //             </button>
    //           ))}
    //         </div>

    //         <div class="flex-shrink-0">
    //           <a href="/" title="" class="flex">
    //             <img class="w-auto h-[52px]" src={cozaLogo} alt="COZA logo" />
    //           </a>
    //         </div>

    //         <div>
    //           <button
    //             onClick={() => navigate("/login")}
    //             class="hidden lg:inline-flex items-center justify-center px-6 py-1.5 text-[16px] transition-all duration-200 bg-brandRed text-whiteColor font-medium rounded-[25px] mr-3 "
    //           >
    //             Sign in
    //           </button>
    //           <button
    //             onClick={() => navigate("/create-account")}
    //             class="hidden lg:inline-flex items-center justify-center px-6 py-1.5 text-[16px] transition-all duration-200 font-medium text-black bg-brandOrange rounded-[25px]"
    //           >
    //             Create an account
    //           </button>
    //         </div>
    //       </div>
    //     </div>
    //   </header>
    // </div>
  );
};

export default Header;
