export const eChurchLinks = {
  dollar: {
    title: "The Commonwealth of Zion Assembly",
    link: "https://buy.stripe.com/14k7uyf7L1Z71Ow4gg",
    vendor: "Zenith Bank PLC",
  },
  pounds: {
    title: "The Commonwealth of Zion Assembly",
    link: "https://buy.stripe.com/5kAdR5c3P8aLgdW4gj",
    vendor: "Zenith Bank PLC",
  },
};
export const birminghamLinks = {
  pounds: {
    title: "The Commonwealth of Zion Assembly",
    link: "https://buy.stripe.com/8wMbIX2tffDd8Lu289",
    vendor: "stripe",
  },
};

export const firstFruitLinks = {
  name: "First Fruit",

  NGN: {
    _id: "658e9d55bc6a7700105d23b2",
    campus: "65893031bc6a7700105ccc23",
    title: "The Commonwealth of Zion Assembly",
    link: "1014449841",
    vendor: "Zenith Bank PLC",
  },
  USD: {
    _id: "658e9d55bc6a7700105d23b2",
    campus: "65893031bc6a7700105ccc23",
    title: "The Commonwealth of Zion Assembly",
    link: "https://bit.ly/cozafirstfruits-usd",
    vendor: "Zenith Bank PLC",
  },
  GBP: {
    _id: "658e9d55bc6a7700105d23b2",
    campus: "65893031bc6a7700105ccc23",
    title: "The Commonwealth of Zion Assembly",
    link: "https://bit.ly/cozafirstfruit-gbp",
    vendor: "Zenith Bank PLC",
  },
};

export const partnerShipLinks = {
  name: "COZA Partnership",
  NGN: {
    _id: "658e9d55bc6a7700105d23b2",
    campus: "65893031bc6a7700105ccc23",
    title: "The Commonwealth of Zion Assembly",
    link: "0090412012",
    vendor: "Access Bank",
  },
  USD: {
    _id: "658e9d55bc6a7700105d23b2",
    campus: "65893031bc6a7700105ccc23",
    title: "The Commonwealth of Zion Assembly",
    link: "1013275906",
    vendor: "Zenith Bank PLC",
  },
  GBP: {
    _id: "658e9d55bc6a7700105d23b2",
    campus: "65893031bc6a7700105ccc23",
    title: "The Commonwealth of Zion Assembly",
    link: "https://partners.coza.org.ng",
    vendor: "Zenith Bank PLC",
  },
};

export const cozaCityProjectAccounts = [
  {
    accountName: "COZA CITY PROJECT ACCOUNT",
    accountNumber: "2002865750",
    bank: "FCMB",
    currency: "NGN",
  },
  {
    accountName: "COZA CITY PROJECT ACCOUNT",
    accountNumber: "1310105861",
    bank: "Zenith Bank",
    currency: "NGN",
  },
  {
    accountName: "COZA CITY PROJECT ACCOUNT",
    accountNumber: "1856109406",
    bank: "Access Bank",
    currency: "NGN",
  },
  {
    accountName: "COZA CITY PROJECT ACCOUNT",
    accountNumber: "0040112815",
    bank: "PremiumTrust Bank Limited",
    currency: "NGN",
  },
  {
    accountName: "COZA CITY PROJECT ACCOUNT",
    accountNumber: "0330059062",
    bank: "PremiumTrust Bank Limited",
    currency: "USD",
  },
];
