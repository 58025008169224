import React from "react";
import { Link, useParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";

const SectionNav = ({ list }) => {
  const params = useParams();
  return (
    <div className="max-w-section mx-auto overflow-x-scroll no-scrollbar flex gap-[5px]">
      {list.map((section, index) => (
        <Link
          key={index}
          to={section.path}
          className={twMerge(
            "h-[37px] rounded-[50px] flex items-center gap-1 text-gray-400 border border-gray-400 font-medium px-5 text-nowrap",
            params?.section === section?.slug &&
              "bg-orangeBg text-white border-none"
          )}
        >
          {params?.section === section?.slug
            ? section.activeIcon
            : section?.icon}{" "}
          {section.name}
        </Link>
      ))}
    </div>
  );
};

export default SectionNav;
