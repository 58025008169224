import AxiosWithToken from "constants/api_management/MyHttpHelperWithToken";
import showToast from "constants/showToast";
import { useMutation, useQueryClient } from "react-query";

export const useDeleteAppointment = ({ onSuccess = () => null } = {}) => {
  const queryClient = useQueryClient();

  const deleteRequest = async (id) => {
    try {
      const response = await AxiosWithToken.delete(`/appointment/${id}`);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  };

  const mutation = useMutation({
    mutationKey: ["appointment"],
    mutationFn: deleteRequest,
    onSuccess: (data) => {
      showToast(data.message, "success");
      queryClient.invalidateQueries(["appointment"]);
      onSuccess();
    },
    onError: (error) => {
      showToast(error?.message, "error");
    },
  });

  const deleteAppointment = async (id) => {
    try {
      const data = await mutation.mutateAsync(id);
      return data;
    } catch (error) {
      console.log({ error });
      throw error;
    }
  };

  return {
    deleteAppointment,
    ...mutation,
  };
};
