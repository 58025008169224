import AxiosWithToken from "constants/api_management/MyHttpHelperWithToken";
import { useQuery } from "react-query";

export const useGetAppointments = (params = {}) => {
  const getAppointments = async () => {
    try {
      const [response] = [await AxiosWithToken.get("/appointment", { params })];
      //console.log(`i am checking this ${response.status}`);
      return response.data;
    } catch (error) {
      //console.log(error.response.data);
      throw new Error(`Sorry: ${error.response.data.message}`);
    }
  };

  return useQuery({
    queryKey: ["appointment", params],
    queryFn: getAppointments,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};
