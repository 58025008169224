import { useIsTouchDevice } from "constants/useIsTouchDevice";
import React, { useEffect, useRef } from "react";
import { MdDeleteOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { useDeleteNote } from "../controllers/notes_controller";
import { useQueryClient } from "react-query";
import showToast from "constants/showToast";

const NotePreview = ({ note }) => {
  const navigate = useNavigate();
  const bodyRef = useRef(null);

  const isTouchDevice = useIsTouchDevice();

  const deleteNote = useDeleteNote();
  const queryClient = useQueryClient();

  async function handleDeleteNote() {
    console.log({ note });
    try {
      const data = await deleteNote.mutateAsync(note?.id);
      if (data) {
        showToast("Note deleted");
        await queryClient.invalidateQueries(["notes"]);
        await queryClient.invalidateQueries(["notes-by-id"]);
        // navigate(`/library/notes/${data?.data?.data?.id}`);
      }
    } catch (error) {
      console.log({ error });
    }
  }

  useEffect(() => {
    if (note && bodyRef.current) {
      bodyRef.current.innerHTML = note?.body;
    }
  }, [note]);
  return (
    <div
      role="button"
      onClick={() => navigate(`/library/notes/${note?.id}`)}
      className="group px-2 rounded-md border-l-[3px] border-l-orangeBg hover:bg-orangeBg/5 duration-200 flex items-center justify-between gap-5"
    >
      <div className="flex flex-col gap-1">
        <p className="font-semibold text-purpleOverlay text-lg">
          {note?.title}
        </p>
        <div ref={bodyRef} className="line-clamp-2 text-textGrey text-sm"></div>
      </div>

      <div
        className={twMerge(
          "duration-200 items-center text-purpleOverlay group-hover:flex",
          isTouchDevice ? "flex" : "hidden group-hover:flex"
        )}
      >
        <button
          onClick={(e) => {
            e.stopPropagation();
            handleDeleteNote();
          }}
        >
          <MdDeleteOutline className="size-6" />
        </button>
      </div>
    </div>
  );
};

export default NotePreview;
