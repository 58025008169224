import React, { useRef } from "react";
import InputWithFullBoarder from "./InputWithFullBoarder";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { twMerge } from "tailwind-merge";
import { format } from "date-fns";

const DatePickerInput = ({
  name,
  value,
  label,
  onChange,
  labelClass,
  placeholder,
  className,
  disabledPeriod,
}) => {
  const dialogRef = useRef();
  const openModal = () =>
    dialogRef.current ? dialogRef.current?.showModal() : null;
  const closeModal = () =>
    dialogRef.current ? dialogRef.current?.close() : null;
  return (
    <div className="" onClick={openModal}>
      <InputWithFullBoarder
        label={label}
        type="text"
        id={name}
        value={value ? format(value, "PPP") : ""}
        placeholder={placeholder}
        labelClass={labelClass}
        className={twMerge(
          "bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-xs cursor-pointer",
          className
        )}
        otherProps={{
          readOnly: true,
        }}
        hasSuffix
        icon={
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 12C2 8.22876 2 6.34315 3.17157 5.17157C4.34315 4 6.22876 4 10 4H14C17.7712 4 19.6569 4 20.8284 5.17157C22 6.34315 22 8.22876 22 12V14C22 17.7712 22 19.6569 20.8284 20.8284C19.6569 22 17.7712 22 14 22H10C6.22876 22 4.34315 22 3.17157 20.8284C2 19.6569 2 17.7712 2 14V12Z"
              stroke="#8311B3"
              strokeWidth="1.5"
            />
            <path
              opacity="0.5"
              d="M7 4V2.5"
              stroke="#8311B3"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
            <path
              opacity="0.5"
              d="M17 4V2.5"
              stroke="#8311B3"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
            <path
              opacity="0.5"
              d="M2.5 9H21.5"
              stroke="#8311B3"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
            <path
              d="M18 17C18 17.5523 17.5523 18 17 18C16.4477 18 16 17.5523 16 17C16 16.4477 16.4477 16 17 16C17.5523 16 18 16.4477 18 17Z"
              fill="#8311B3"
            />
            <path
              d="M18 13C18 13.5523 17.5523 14 17 14C16.4477 14 16 13.5523 16 13C16 12.4477 16.4477 12 17 12C17.5523 12 18 12.4477 18 13Z"
              fill="#8311B3"
            />
            <path
              d="M13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17Z"
              fill="#8311B3"
            />
            <path
              d="M13 13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13C11 12.4477 11.4477 12 12 12C12.5523 12 13 12.4477 13 13Z"
              fill="#8311B3"
            />
            <path
              d="M8 17C8 17.5523 7.55228 18 7 18C6.44772 18 6 17.5523 6 17C6 16.4477 6.44772 16 7 16C7.55228 16 8 16.4477 8 17Z"
              fill="#8311B3"
            />
            <path
              d="M8 13C8 13.5523 7.55228 14 7 14C6.44772 14 6 13.5523 6 13C6 12.4477 6.44772 12 7 12C7.55228 12 8 12.4477 8 13Z"
              fill="#8311B3"
            />
          </svg>
        }
      />

      <dialog
        ref={dialogRef}
        id="custom_date_picker_input"
        className="text-black rounded-[10px] shadow-lg bg-white"
      >
        <DayPicker
          showOutsideDays={true}
          mode={"single"}
          className="p-3"
          selected={value}
          onSelect={onChange}
          onDayClick={() => {
            setTimeout(() => {
              closeModal();
            }, 300);
          }}
          defaultMonth={value}
          captionLayout={"dropdown"}
          initialFocus
          //   fromMonth={1}
          // fromYear={new Date().getFullYear() - 100}
          toYear={new Date().getFullYear() + 2}
          //   formatters={(date)}
          disabled={disabledPeriod ?? false}
          classNames={{
            today: "border-[1.5px] border-purpleBg", // Add a border to today's date
            selected: "bg-[#FBF2FF] border-[1.5px] border-purpleBg text-black", // Highlight the selected day
            // calendar: `shadow-lg p-5`, // Add a shadow to the calendar
            chevron: "fill-purpleBg", // Change the color of the chevron
            day: "rounded-[10px]",
            outside: "opacity-[0.4]",
            disabled: "opacity-[0.4] pointer-events-none",
          }}
        />
      </dialog>
    </div>
  );
};

export default DatePickerInput;
