import { calendarIcon } from "assets/icons";
import ArrowRight from "assets/icons/ArrowRight";
// import { homeEvent } from "assets/images";
import { RoundedButton } from "generalComponents/Button";
import React from "react";
import { twMerge } from "tailwind-merge";
import { useProTime } from "@toluade/protime-react-component";
import useGetEvents from "modules/pageEvents/controllers/get_events_controller";
import { format } from "date-fns";
import { convertTo24hr } from "constants/formatDate";
import PlayVideo from "generalComponents/PlayVideo";
// import { purplePaint } from "../../../assets/icons";

const startDate = `${new Date().getFullYear()}-01-01T00:00`;

const UpcomingEventsSection = () => {
  const { data, isLoading } = useGetEvents({
    page: 1,
    pageSize: 2,
  });
  const endDate = !isLoading
    ? `${format(data?.data[0]?.date, "yyyy-MM-dd")}T${convertTo24hr(
        data?.data[0]?.time
      )}`
    : startDate;
  const { days, hours, minutes, seconds } = useProTime(
    startDate,
    endDate,
    true
  );
  return (
    <div
      style={{
        backgroundImage: "url('/assets/home-purple_paint.svg')",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      className={twMerge("py-20 relative z-20 ", "-mt-32")}
    >
      {/* <img
        src={purplePaint}
        alt=""
        className="absolute inset-0 object-cover -z-10 w-full min-h-full"
      /> */}
      <div className="max-w-section mx-auto grid lg:grid-cols-2 items-center">
        <div className="px-5 mx-auto lg:mr-auto lg:px-10">
          <div className="flex items-center gap-5">
            <img src={calendarIcon} alt="" />
            <p className="w-full px-3.5 py-2 bg-gradient-to-r from-orangeBg to-orangeBg/0 font-medium">
              Upcoming Event Scheduling
            </p>
          </div>
          <div className="lg:pl-[93px]">
            <p className="font-semibold text-2xl lg:text-4xl mb-3">
              {isLoading ? "Loading..." : data?.data[0]?.name}
            </p>
            <div className="flex gap-4 mb-6">
              <TimeUnit value={days} unit="Days" />
              <TimeUnit value={hours} unit="Hours" />
              <TimeUnit value={minutes} unit="Minutes" />
              <TimeUnit value={seconds} unit="Seconds" />
            </div>
            <RoundedButton
              url="/events"
              className="group flex gap-3 items-center px-5 py-3"
            >
              <span>View All Events</span>{" "}
              <ArrowRight className={"group-hover:animate-bounce-forward"} />
            </RoundedButton>
          </div>
        </div>
        <div className="hidden lg:flex justify-center items-center relative w-fit mx-auto rounded-[15px] overflow-hidden">
          <img
            src={data?.data[0]?.cover_image?.url}
            alt=""
            className="rounded-[15px] max-h-[400px] object-contain"
          />
          {data?.data[0]?.video_url !== "" ? (
            <>
              <svg
                role="button"
                onClick={() =>
                  window[`event_${data?.data[0]?.id}`]?.showModal()
                }
                width="56"
                height="62"
                viewBox="0 0 56 62"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="absolute inset-0 m-auto z-[3]"
              >
                <path
                  opacity="0.7"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0 9.77249C0 2.22576 8.20024 -2.46434 14.705 1.36201L50.6633 22.514C57.0773 26.2869 57.0773 35.5622 50.6633 39.3349L14.705 60.487C8.20024 64.3133 0 59.6231 0 52.0765V9.77249Z"
                  fill="white"
                />
              </svg>

              <div className="w-full h-full absolute z-[2] inset-0 bg-black/40"></div>
            </>
          ) : null}

          {data?.data[0]?.video_url !== "" && (
            <PlayVideo
              id={`event_${data?.data[0].id}`}
              src={data?.data[0]?.video_url}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default UpcomingEventsSection;

const TimeUnit = ({ value, unit }) => (
  <div className="flex flex-col items-center gap-2.5">
    <p
      className={twMerge(
        "bg-blackBg text-white font-semibold text-2xl w-12 h-12 lg:w-20 lg:h-20",
        "flex justify-center items-center"
      )}
    >
      {value}
    </p>
    <p className={twMerge("text-white font-medium")}>{unit}</p>
  </div>
);
