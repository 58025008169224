import React, { useEffect, useState } from "react";

import useSendSupportManager from "../controller/send_support_controller";
import HeaderFooter from "../../../generalComponents/HeaderFooter";
// import InputWithFullBoarder from "../../../generalComponents/InputWithFullBoarder";
// import CustomButton from "../../../generalComponents/Button";
import { toast } from "react-toastify";
// import { homeBanner } from "../../../assets/images";
import TextStroke from "generalComponents/TextStroke";
import ContactForm from "../components/ContactForm";
// import ContactElement from "../components/ContactElement";
// import {
//   FacebookIcon,
//   InstagramIcon,
//   LocationIcon,
//   MailIcon,
//   PhoneIcon,
//   TwitterIcon,
//   YoutubeIcon,
// } from "../components/Icons";
import { contactUs } from "assets/icons";

const SupportPage = () => {
  const [formData, setFormData] = useState({
    email: "",
    fullname: "",
    phone: "",
    description: "",
    title: "",
  });

  const handleChange = (event, name) => {
    const value = event.target.value;
    setFormData((val) => ({
      ...val,
      [name]: value,
    }));
  };
  // const [subject, setSubject] = useState("");
  // const [email, setEmail] = useState("");
  // const [supportRequest, setSupportRequest] = useState("");

  // const handleSubjectChange = (event) => {
  //   setSubject(event.target.value);
  // };

  // const handleEmailChange = (event) => {
  //   setEmail(event.target.value);
  // };

  // const handleSupportRequestChange = (event) => {
  //   setSupportRequest(event.target.value);
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Add your submit logic here
    // const details = {
    //   email: email,
    //   title: subject,
    //   description: supportRequest,
    // };
    try {
      await sendSupport(formData);

      setFormData({
        email: "",
        fullname: "",
        phone: "",
        description: "",
        title: "",
      });
    } catch (error) {
      return error;
    }

    // setEmail("");
    // setSubject("");
    // setSupportRequest("");
  };

  const { data, sendSupport, isLoading, isSuccess, error } =
    useSendSupportManager();

  useEffect(() => {
    if (isSuccess) {
      toast.success("Message submitted successfully!");
    }

    if (error) {
      toast.error(error.message);
    }
  }, [isSuccess, error, data]);
  return (
    <HeaderFooter>
      <TextStroke className="overflow-hidden max-w-[100svh]">
        Contact Us
      </TextStroke>
      <div className=" text-blackColor text-center mx-auto max-w-section pb-14">
        <div className=" mx-auto max-w-section justify-center items-start">
          <div className="mx-auto w-fit text-left px-5">
            <h2 className="font-semibold text-center text-[50px] text-purpleBg">
              Get in Touch With Us
            </h2>
            <p className="text-sm text-center text-gray-500">
              Any questions? We would be happy to help!
            </p>

            <ContactForm
              className={"mt-14"}
              {...{ handleSubmit, handleChange, formData, isLoading }}
            />

            {/* <div className="flex flex-col mt-10 gap-10">
              <ContactElement
                title="Location"
                text={
                  "Gwarinpa Estate, Gwarinpa 900108, Federal Capital Territory, Nigeria"
                }
                icon={<LocationIcon />}
              />
              <ContactElement
                title="Phone Number"
                text="+234 081 234 5678"
                icon={<PhoneIcon />}
              />
              <ContactElement
                title="Email Address"
                text="coza@COZA.com"
                icon={<MailIcon />}
              />
            </div> */}

            {/* <div className="flex items-center gap-2.5 mt-16">
              <FacebookIcon role="button" />
              <TwitterIcon role="button" />
              <InstagramIcon role="button" />
              <YoutubeIcon role="button" />
            </div> */}
          </div>
          {/* <div className="w-full px-5 text-left hidden md:block">
            <ContactForm
              // className={"hidden md:block"}
              {...{ handleSubmit, handleChange, formData, isLoading }}
            />
          </div> */}
        </div>
      </div>

      <img
        src={contactUs}
        alt=""
        className="absolute right-0 bottom-0 md:top-0 -z-10"
      />
    </HeaderFooter>
  );
};

export default SupportPage;
