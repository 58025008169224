import React from "react";
import GradientText from "./GradientText";
import OrangeBar from "./OrangeBar";

const Mission = () => {
  return (
    <div className="flex flex-col-reverse md:flex-row justify-center md:justify-end items-end pr-0 md:pr-16 px-5 max-w-section mx-auto">
      <div>
        <OrangeBar className="mb-2.5" />
        <h2 className="font-semibold text-3xl md:text-5xl text-purpleBg">
          Our Mission
        </h2>
        <p className="text-sm text-gray-500 max-w-[523px] pb-10">
          To make disciples of all nations, reaching the unchurched and
          re-establishing God’s kingdom on earth - Revelation 11:15. <br />
          At COZA, we believe that every individual is called to greatness and
          has the potential to bring about positive change in their environment.
        </p>
      </div>

      <GradientText text="02" className="text-[300px] leading-[300px]" />
    </div>
  );
};

export default Mission;
