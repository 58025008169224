import React, { useState } from "react";
import BaseDashboardNavigation from "../../../../generalComponents/BaseDashboardNavigation";
import useGetSingleCellManager from "../controllers/getSingleCellController";
import useAddCellMemberManager from "../controllers/addCellMemberController";

import useGetUnallocatedUsersManager from "../controllers/getUnallocatedUserController";
import Multiselect from "multiselect-react-dropdown";
import { useParams } from "react-router-dom";
import Loader from "../../../../generalComponents/Loader";
import ErrorManager from "../../../../generalComponents/ErrorManager";
import GlobalVariables from "../../../../constants/GlobalVariables";
import { AiOutlineArrowLeft } from "react-icons/ai";
import CustomButton from "../../../../generalComponents/Button";
import useGetCellMembersManager from "../controllers/getCellMembersController";
import CellMembersTable from "../../../../generalComponents/CellMembersTable";
import AdminWrapper from "modules/admin/AdminWrapper";

const CellDetailsPage = () => {
  const { cellId } = useParams();

  const [chosenUserId, setChosenUserId] = useState("");
  const { data, isLoading, isError, error } = useGetSingleCellManager(cellId);
  const { updateCaller: addMember, isLoading: addingMember } =
    useAddCellMemberManager(cellId);

  const {
    data: members,
    isError: membersHasError,
    error: membersError,
    isLoading: fetchingMembers,
  } = useGetCellMembersManager(cellId);
  const {
    isLoading: fetchingUsers,
    isError: userHasError,
    error: userError,
    data: users,
    isSuccess: fetchedUsers,
    refetch,
  } = useGetUnallocatedUsersManager();

  if (isLoading || fetchingUsers || fetchingMembers) {
    return <Loader />;
  }

  if (isError || userHasError || membersHasError) {
    return (
      <div>
        {isError && <ErrorManager errorMessage={error.message} />}
        {userHasError && <ErrorManager errorMessage={userError.message} />}
        {membersHasError && (
          <ErrorManager errorMessage={membersError.message} />
        )}
      </div>
    );
  }
  return (
    <AdminWrapper title={"Cell Details"}>
      <div className="md:h-[80vh] md:overflow-y-scroll mr-7  scrollbar-hide">
        <button
          onClick={() => window.history.back()}
          className="flex items-center space-x-2"
        >
          <AiOutlineArrowLeft />
          <p>Back</p>
        </button>
        {/* profile picture and current plan */}
        <div className="w-full flex flex-col md:flex-row items-center justify-between  px-2 md:px-8 mt-16 mx-auto">
          {data.data.cell_head ? (
            <div className=" flex items-center">
              <div className="h-[95px] md:h-[115px]  w-[95px] md:w-[115px] mr-3 md:mr-6">
                <img
                  className=" w-full h-full rounded-full object-cover hover:scale-150 "
                  src={
                    data.data.cell_head.profile_picture !== ""
                      ? data.data.cell_head.profile_picture
                      : GlobalVariables.defaultProfilePicture
                  }
                  alt=""
                />
              </div>
              <div>
                <p className="text-[30px] font-semibold ">
                  {`${data.data.cell_head.first_name} ${data.data.cell_head.last_name}`}
                </p>
                <p className="text-[16px] font-normal ">
                  {data.data.cell_head.email}
                </p>
                <p className="text-[16px] font-normal ">
                  {data.data.cell_head.phone}
                </p>
              </div>
            </div>
          ) : (
            <p>No Leader</p>
          )}
        </div>

        {/* other personal data */}
        <div className="w-full flex flex-col md:flex-row items-center justify-between  px-2 md:px-8 mt-4 mx-auto">
          <div className=" flex flex-col items-center w-full md:max-w-[400px] ">
            <div className="flex items-center justify-between w-full">
              <p className="text-[16px] font-semibold ">
                Cell Name:
                <span className="text-[16px] font-normal ">
                  {data.data.name}
                </span>
              </p>
            </div>
            <div className="flex items-center justify-between w-full">
              <p className="text-[16px] font-semibold ">
                Meeting days:
                <span className="text-[16px] font-normal ">
                  {data.data.meeting_day}
                </span>
              </p>
            </div>
            <div className="flex items-center justify-between w-full">
              <p className="text-[16px] font-semibold ">
                Meeting Time:{" "}
                <span className="text-[16px] font-normal ">
                  {data.data.meeting_time}
                </span>
              </p>
            </div>
          </div>
          {/* Add Members*/}
          <div className="flex flex-col justify-between items-center mb-4 ">
            <div className="flex flex-col">
              <p className="text-13px md:text-16px font-semibold mb-2   ">
                Select Campus
              </p>
              <select
                className={`border border-lightGrey mb-3 bg-lightGrey/30 p-2 block w-[95%] md:w-auto rounded-md outline-none focus:outline-none `}
                value={chosenUserId}
                onChange={(e) => setChosenUserId(e.target.value)}
              >
                {users.data.users.users.map((user) => (
                  <option
                    value={user.id}
                  >{`${user.first_name} ${user.last_name}`}</option>
                ))}
              </select>
              {/* <Multiselect
                displayValue={`first_name`}
                onKeyPressFn={function noRefCheck() {}}
                onRemove={function noRefCheck() {}}
                onSearch={function noRefCheck() {}}
                showArrow={true}
                onSelect={function noRefCheck() {}}
                className={`border border-lightGrey mb-3 text-black bg-lightGrey/30 p-2 block w-[95%] md:w-auto rounded-md outline-none focus:outline-none `}
                optionValueDecorator={function noRefCheck() {}}
                options={users.data.users.users}
              /> */}
            </div>
            <CustomButton
              buttonText={"Add Member"}
              isLoading={addingMember}
              onClick={async () => {
                const details = {
                  userIds: [chosenUserId],
                };
                await addMember(details);
                setChosenUserId("");
                refetch();
              }}
            />
          </div>
        </div>
        {/* divider */}
        <div className="divider"></div>
        {/* user tables */}
        <div className="md:h-[67vh] relative md:overflow-y-auto scrollbar-hide">
          <CellMembersTable users={members.data} />
        </div>
      </div>
    </AdminWrapper>
  );
};

export default CellDetailsPage;
