import React, { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import EyeIcon from "./EyeIcon";
import { RoundedButton } from "generalComponents/Button";
import { useResetPassword } from "../controller/put_controller";
import { Spinner } from "generalComponents/Loader";

const Input = (props) => {
  const { className, label, ...rest } = props;
  const [show, setShow] = useState(false);
  return (
    <div className="w-full mb-5 flex flex-col gap-1.5 relative">
      <label
        htmlFor={props?.name}
        className="font-medium text-sm text-textDark"
      >
        {label}
      </label>
      <div className="w-full relative">
        <input
          type={show ? "text" : "password"}
          {...rest}
          className={twMerge(
            "bg-[#F2F2F2] rounded-[10px] placeholder:text-[#8E8E8E] text-sm h-[50px] outline-none focus:outline-purpleBg outline-offset-1 border-none px-4 py-2 text-purpleOverlay duration-300 w-full",
            className
          )}
        />
        <EyeIcon
          {...{ show, setShow }}
          className="absolute m-auto top-0 bottom-0 right-3"
        />
      </div>
    </div>
  );
};

const ChangePassword = () => {
  const [formValues, setFormValues] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });
  const [confirm, setConfirm] = useState(true);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((val) => ({
      ...val,
      [name]: value,
    }));
  };

  const { putCaller, isLoading } = useResetPassword({
    onSuccess: () =>
      setFormValues({
        old_password: "",
        new_password: "",
        confirm_password: "",
      }),
  });

  const handleSubmit = async (e) => {
    e?.preventDefault();
    const { confirm_password, ...body } = formValues;

    await putCaller(body);
  };

  useEffect(() => {
    if (formValues?.confirm_password !== formValues?.new_password) {
      setConfirm(false);
    } else {
      setConfirm(true);
    }
  }, [formValues?.confirm_password, formValues?.new_password]);
  return (
    <div className="mx-auto px-5 max-w-[720px] w-full">
      <h2 className="font-semibold text-3xl lg:text-6xl text-purpleOverlay border-b border-lightGrey pb-2 mb-10">
        Change Password
      </h2>

      <form onSubmit={handleSubmit} className="max-w-[430px] w-full mx-auto">
        <Input
          name="old_password"
          label="Old Password"
          placeholder="Enter old password"
          value={formValues?.old_password}
          onChange={handleChange}
        />
        <Input
          name="new_password"
          label="New Password"
          autoComplete="off"
          placeholder="Enter new password"
          value={formValues?.new_password}
          onChange={handleChange}
        />
        <Input
          name="confirm_password"
          label="Confirm Password"
          autoComplete="off"
          placeholder="Confirm password"
          value={formValues?.confirm_password}
          onChange={handleChange}
          className={twMerge(
            !confirm ? "outline-red-500 focus:outline-red-500" : ""
          )}
        />

        <RoundedButton
          type="submit"
          className={"py-4 w-full font-medium mt-12 grid place-content-center"}
          disabled={
            !confirm ||
            formValues?.old_password === "" ||
            formValues?.new_password === ""
          }
        >
          {isLoading ? <Spinner /> : "Update Password"}
        </RoundedButton>
      </form>
    </div>
  );
};

export default ChangePassword;
