import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { format } from "date-fns";
import BaseDashboardNavigation from "../../../../generalComponents/BaseDashboardNavigation";

import useGetSermonListManager from "../controllers/getSermonListController";

import PaginationRounded from "../../../../generalComponents/Pagination";

import useGetCategories from "modules/pageSermons/controllers/get_categories_controller";
import useDebounce from "utils/UseDebounce";
import VideoItem from "generalComponents/VideoItem";

import AudioItem from "generalComponents/AudioItem";
import CustomButton from "generalComponents/Button";
import SearchComponent from "generalComponents/SearchComponent";
import InputWithFullBoarder from "generalComponents/InputWithFullBoarder";
// import CustomDropdown from "generalComponents/CustomDropdown";
import TabsManagement from "generalComponents/TabsManagement";
import Loader from "generalComponents/Loader";
import ViewSermon from "../components/ViewSermon";
import AddEditSermon from "../components/AddEditSermon";
import AdminDeleteConfirmationModal from "generalComponents/AdminDeleteConfirmationModal";
import { DeleteSermonManager } from "../controllers/deleteSermonController";
import AdminWrapper from "modules/admin/AdminWrapper";
import SelectInput from "generalComponents/SelectInput";
import { twMerge } from "tailwind-merge";
import { TableFooterDiv } from "generalComponents/DataTable/TableFooter";

const debounce = (func, wait) => {
  let timeout;

  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

const AdminSermonPage = () => {
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(`&title=${searchValue}`, 1000);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const tabs = ["Audio", "Video"];
  const [selected, setSelected] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const { deleteSermon, isLoading: deleting } = DeleteSermonManager(
    selected?.id
  );

  const { data: categories } = useGetCategories({
    group: "sermon",
  });

  const { data, isLoading } = useGetSermonListManager({
    page: currentPage,
    searchQuery: searchValue,
    type: currentIndex === 0 ? "video" : "audio",
    category: selectedCategory,
  });

  const categoryList = useMemo(() => {
    const list =
      categories?.data?.categories.map((el) => ({
        label: el?.name,
        value: el?.id,
      })) || [];
    return list;
  }, [categories]);

  const type = ["video", "audio"];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce((value) => setSearchValue(value), 1000),
    []
  );

  useEffect(() => {
    if (selected) {
      document.getElementById("view_sermon").showModal();
    }
  }, [selected]);

  return (
    <AdminWrapper
      title={"Sermons"}
      value={searchValue}
      onChange={(e) => {
        setSearchValue(e.target.value);
      }}
    >
      <div className="w-full flex flex-col pr-10">
        <div className="flex justify-between flex-col gap-5 md:flex-row w-full mb-10">
          <div className=" flex flex-col items-start">
            <p className="text-[24px] font-semibold">Sermons</p>
            <p className="text-14px">Showing data over the last 30 days</p>
          </div>
          <div className="mb-3">
            <CustomButton
              buttonText={`+Upload Sermon`}
              onClick={() => {
                setSelected(null);
                document.getElementById("add_sermon").showModal();
              }}
              radius={`[5px]`}
              className={`max-w-max`}
            />
          </div>
        </div>
        <div className="flex items-center justify-between flex-col gap-5 md:flex-row">
          <div className="max-w-[501px] w-full flex items-center flex-col gap-5 md:flex-row">
            <SearchComponent
              // value={searchValue}
              marginRight={"mr-3"}
              onChange={(e) => handleSearch(e.target?.value)}
            />
            <SelectInput
              placeholder={"Category"}
              options={[{ label: "All", value: "" }, ...categoryList]}
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              className={twMerge(
                "text-xs py-2.5 mb-0 h-10 md:min-w-[140px]",
                selectedCategory === "" ? "text-gray-500" : ""
              )}
              containerClass="mb-0 p-0"
            />
          </div>
          <InputWithFullBoarder type={"date"} />
        </div>
        <div className="w-full my-3">
          <TabsManagement
            options={type}
            currentView={currentIndex}
            setCurrentView={setCurrentIndex}
          />
        </div>
        <div className="relative md:overflow-y-auto scrollbar-hide">
          {isLoading ? (
            <Loader />
          ) : (
            <div className="flex flex-wrap gap-5 mx-auto">
              {data?.data?.items?.map((message, mIndex) =>
                currentIndex === 0 ? (
                  <VideoItem
                    key={mIndex}
                    message={message}
                    isAdmin={true}
                    showPrice={message?.subscription_type === "paid"}
                    onClick={() => {
                      setSelected(message);
                      if (selected?.id === message?.id) {
                        document.getElementById("view_sermon").showModal();
                      }
                    }}
                  />
                ) : (
                  <AudioItem
                    key={mIndex}
                    message={message}
                    isAdmin={true}
                    showPrice={message?.subscription_type === "paid"}
                    onClick={() => {
                      setSelected(message);
                      if (selected?.id === message?.id) {
                        document.getElementById("view_sermon").showModal();
                      }
                    }}
                  />
                )
              )}
            </div>
          )}
        </div>

        {data?.data?.pagination?.total > 0 && (
          <div className="flex justify-end mt-5 min-w-full">
            <TableFooterDiv
              {...{
                noOfPages:
                  data?.data?.pagination?.total /
                  data?.data?.pagination?.pageSize,
                pageIndex: data?.data?.pagination?.currentPage - 1,
                onBtnClick: (page) => setCurrentPage(page + 1),
                // itemsPerPageChange,
                // itemsPerPage: () => setCurrentPage(),
                showMobileView: true,
              }}
            />
            {/* <PaginationRounded
              count={data?.data?.pagination?.total}
              defaultPage={currentPage}
              onChange={setCurrentPage}
            /> */}
          </div>
        )}
      </div>
      {selected && <ViewSermon details={selected} />}
      <AddEditSermon
        details={selected}
        categories={categories?.data?.categories}
      />
      <AdminDeleteConfirmationModal
        title={"Delete Sermon"}
        body={`Do you really want to delete ${selected?.title} from the list of Sermons?`}
        buttonText={`Yes, Delete`}
        onClick={async () => {
          await deleteSermon();
          document.getElementById("admin_delete").close();
          document.getElementById("view_sermon").close();
        }}
        isLoading={deleting}
      />
    </AdminWrapper>
  );
};

export default AdminSermonPage;
