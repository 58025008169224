import { RoundedButton } from "generalComponents/Button";
import InputWithFullBoarder from "generalComponents/InputWithFullBoarder";
import { useEffect, useState } from "react";
import useFileUpload from "modules/fileupload/fileUploadController";
import usePostFamilyProcess from "../controllers/post_controller";
import FileInput from "generalComponents/FileInput";
import useQueryParams from "constants/useQueryParams";
import { useGetSingleFamilyProcessSubmission } from "../controllers/get_controller";
import Loader, { Spinner } from "generalComponents/Loader";
import { StatusBadge } from "generalComponents/Badges";
import { capitalizeFirstLetter } from "constants/textFormatters";
import useReadOnlyInputs from "../useReadOnlyInputs";

const WhistleBlowerModal = ({ id }) => {
  const { params } = useQueryParams();
  const { data: singleForm, isLoading: singleFormLoading } =
    useGetSingleFamilyProcessSubmission({
      formId: params?.form,
      type: "whistle-blower",
      enabled: !!params?.form,
    });
  const initialValues = {
    name: "",
    phone: "",
    details: "",
  };
  const [formData, setFormData] = useState(initialValues);

  const [files, setFiles] = useState({
    evidence: undefined,
  });
  const [filesSrc, setFilesSrc] = useState({
    evidence: undefined,
  });

  const handleFileChange = (e, name) => {
    if (e.target.files[0]) {
      setFiles((val) => ({
        ...val,
        [name]: e.target.files[0],
      }));
      setFilesSrc((val) => ({
        ...val,
        [name]: URL.createObjectURL(e.target.files[0]),
      }));
    }
  };

  const handleChange = (event, name) => {
    const value = event.target?.value;
    setFormData((data) => ({
      ...data,
      [name]: value,
    }));
  };

  const {
    // error: fileUploadError,
    handleFileUpload: uploadFile,
    isLoading: isUploadingFlie,
    // data: uploadUrl,
  } = useFileUpload();

  const { postCaller, isLoading } = usePostFamilyProcess({
    endpoint: "whistle-blower",
    mutationKey: ["family_process"],
    onSuccess: () => window[id]?.close(),
  });

  function handleFileUpload() {
    return new Promise(async (resolve, reject) => {
      try {
        const uploadPromises = Object.values(files)?.map(async (file) => {
          const fileUrl = await uploadFile(file);
          return fileUrl;
        });

        const uploadedFiles = await Promise.all(uploadPromises);
        resolve(uploadedFiles);
        return uploadedFiles;
      } catch (error) {
        reject(error);
      }
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    // console.log({ formData });

    try {
      const [evidence] = await handleFileUpload();

      await postCaller({
        ...formData,
        evidence,
      });

      setFormData(initialValues);
      setFiles({});
      setFilesSrc({});
    } catch (error) {
      console.log({ error });
    }
  }

  useEffect(() => {
    if (singleForm) {
      setFormData({
        name: singleForm?.data?.name,
        phone: singleForm?.data?.phone,
        details: singleForm?.data?.details,
      });
      setFilesSrc({
        evidence: singleForm?.data?.evidence,
      });
    }
  }, [singleForm]);

  useReadOnlyInputs(params?.form !== undefined);

  if (singleFormLoading) return <Loader />;
  return (
    <form onSubmit={handleSubmit}>
      {params?.form && (
        <div className="mb-5">
          <StatusBadge
            label={capitalizeFirstLetter(singleForm?.data?.status)}
            status={singleForm?.data?.status}
          />
        </div>
      )}
      <InputWithFullBoarder
        label={"Name"}
        type="text"
        id="name"
        value={formData.name}
        onChange={(e) => handleChange(e, "name")}
        placeholder={"Enter full name"}
        className={"bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-xs"}
        otherProps={{
          required: true,
        }}
      />
      <InputWithFullBoarder
        label={"Phone Number"}
        type="tel"
        id="phone"
        value={formData.phone}
        onChange={(e) => handleChange(e, "phone")}
        placeholder={"Enter phone number"}
        className={"bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-xs"}
        otherProps={{
          required: true,
        }}
      />
      <InputWithFullBoarder
        label={"Details"}
        type="text"
        id="details"
        value={formData.details}
        onChange={(e) => handleChange(e, "details")}
        placeholder={"Enter details"}
        className={"bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-xs"}
        isTextArea
        otherProps={{
          required: true,
        }}
      />

      <div className="mb-4">
        <label className={`text-sm font-medium mb-2`}>Evidence</label>
        {/* <p className="text-textGrey font-medium text-xs">
            Add one photo each for both parties.
          </p> */}
        <div className="flex gap-6 items-start mt-3">
          <FileInput
            // label={"Father"}
            onChange={(e) => handleFileChange(e, "evidence")}
            // required
            src={filesSrc?.evidence}
            value={files?.evidence}
          />
        </div>
      </div>

      {!params?.form && (
        <RoundedButton
          className={
            "rounded-[40px] text-base font-medium w-full py-5 mt-10 grid place-content-center"
          }
        >
          {isLoading || isUploadingFlie ? (
            <Spinner />
          ) : params?.form ? (
            "Update"
          ) : (
            "Submit"
          )}
        </RoundedButton>
      )}
    </form>
  );
};

export default WhistleBlowerModal;
