import React, { forwardRef } from "react";
import SermonCard from "./SermonCard";

const SermonHScroll = forwardRef((props, ref) => {
  const { sermons, ...rest } = props;
  return (
    <div
      ref={ref}
      {...rest}
      className="flex items-center gap-9 overflow-x-scroll lg:pl-[53px] [w-100svh] snap-x no-scrollbar pl-[10%] pr-40"
    >
      {sermons?.map((item, index) => (
        <SermonCard
          key={index}
          sermonImage={item.image}
          title={item.title}
          author={item.author}
          sermon={item?.data}
        />
      ))}
    </div>
  );
});

export default SermonHScroll;
