import ModalManagement from "generalComponents/ModalManagement";
import React, { memo, useCallback, useMemo, useState } from "react";
import Loader from "generalComponents/Loader";
import StatusButton from "generalComponents/StatusButton";
import AccordionBase from "generalComponents/AccordionBase";
import useGetAttendanceDetails from "../controllers/getAttendanceDetails";
import AvatarWithFallback from "generalComponents/AvatarWithFallback";
import { formatDate } from "date-fns";
import CustomButton from "generalComponents/Button";
import exportCSVFile from "utils/exportCSVFile";
import { MdDownload } from "react-icons/md";

const headers = [
  'first_name',
  'last_name',
  'email',
  'phone',
  'gender',
  'occupation',
  'designation',
  'location.address',
  'location.city',
  'location.state',
  'location.country',
  'status',
];

const ViewServiceAttendance = ({ serviceAttendanceId, campusId, process_status, serviceId, campusName, matchCount, matchedAttendeeIds, photoUploadCount, serviceName, serviceStartTime, unmatchedAttendeeIds, ...props }) => {
  const { data, isLoading } = useGetAttendanceDetails({ campusId, serviceId, serviceAttendanceId });

  const users = useMemo(() => data?.users.sort((a, b) => a.first_name.localeCompare(b.first_name)), [data]); // Sorted
  const usersPresent = useMemo(() => users?.filter((user) => user.status), [users])
  const usersAbsent = useMemo(() => users?.filter((user) => !user.status), [users])

  const [selected, setSelected] = useState(null);

  const onRowClick = useCallback((rowData) => {
    if (rowData?._id) {
      document.getElementById("view_userAttendanceDetails")?.showModal();
    }
  }, [])

  const accordionData = useMemo(() => [
    {
      id: 1,
      title: `Present: ${usersPresent?.length || 0}`,
      content: <div className="space-x-2 space-y-2" >{usersPresent?.map(user => <StatusButton onBlur={() => setSelected(user)} onClick={() => onRowClick(user)} color='#036B26' backgroundColor="#E7F6EC" status={`${user?.first_name} ${user?.last_name}`} />)}</div>,
    },
    {
      id: 2,
      title: `Absent: ${usersAbsent?.length || 0}`,
      content: <div className="space-x-2 space-y-2" >{usersAbsent?.map(user => <StatusButton onBlur={() => setSelected(user)} onClick={() => onRowClick(user)} color='#721C24' backgroundColor="#F8D7DA" status={`${user?.first_name} ${user?.last_name}`} />)}</div>,
    },
  ], [usersAbsent, usersPresent, onRowClick])

  const title = `${serviceName} in ${campusName} (${formatDate(serviceStartTime, "dd-MM-yyyy")})`

  const handleDownload = useCallback(() => {
    exportCSVFile(headers, users, title)
  }, [title, users])

  return (
    <ModalManagement id={"view_serviceAttendance"} title={title} className='max-w-max md:max-w-4xl'>
      {isLoading
        ? <Loader isSmall /> :
        <div className="w-full md:min-w-[500px] space-y-5 mt-5">
          <div className="flex items-center justify-end gap-3">
          </div>
          <div className="flex w-full items-center space-x-2 justify-between">
            <CustomButton
              onClick={handleDownload}
              radius={"rounded-[40px]"}
              disabled={!(users?.length > 0)}
              buttonText={<span className="flex items-center gap-4">< MdDownload size={20} />Download CSV</span>}
            />
            <div>
              <span>Processing Status:</span> <StatusButton status={process_status} onClick={onRowClick} />
            </div>
          </div>
          <AccordionBase items={accordionData} className='grid gap-4' />
        </div>}
      <UserDetails {...selected} />
    </ModalManagement>
  );
};

export default memo(ViewServiceAttendance);

const UserDetails = memo(({ first_name, last_name, campus, email, phone, gender, marital_status, occupation, profile_picture }) => {

  return (
    <ModalManagement id={"view_userAttendanceDetails"} className="space-y-4" >
      <AvatarWithFallback src={profile_picture} alt={`${first_name} ${last_name}`} className="mx-auto size-40" />
      <h3 className="text-[18px] font-semibold text-black text-center">{first_name} {last_name}</h3>
      <div className="w-full justify-start space-y-2">
        <UserInfo title="Gender" value={gender} />
        <UserInfo title="Marital status" value={marital_status} />
        <UserInfo title="Occupation" value={occupation} />
        <UserInfo title="Email" value={email} />
        <UserInfo title="Phone number" value={phone} />
      </div>
    </ModalManagement>
  )
})

const UserInfo = memo(({ title, value }) => {
  return (
    <div className="flex gap-2 w-full justify-between items-center max-w-80">
      <span className="text-gray-400" >{title}:</span>
      <span className="font-semibold truncate" >{value}</span>
    </div>
  )
})