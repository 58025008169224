import React, { useEffect, useState } from "react";
import BaseDashboardNavigation from "../../../../generalComponents/BaseDashboardNavigation";
import useCreatePaymentLinkManager from "../controller/createPaymentLinkController";
import useGetCampusListManager from "../../campus/controllers/getCampusListController";
import TablesComponent from "generalComponents/TablesComponent";
import StatusButton from "generalComponents/StatusButton";
import AddEditPaymentLinkComponent from "../components/AddEditPaymentLinkComponent";
import AdminDeleteConfirmationModal from "generalComponents/AdminDeleteConfirmationModal";
import useGetPaymentLinksManager from "../controller/getPaymentLinksController";
import AdminHeaderTop from "generalComponents/AdminHeaderTop";
import CustomButton from "generalComponents/Button";
import SearchComponent from "generalComponents/SearchComponent";
import useDebounce from "utils/UseDebounce";
import { DeletePaymentLinkManager } from "../controller/deletePaymentLinkController";
import AdminWrapper from "modules/admin/AdminWrapper";
import DataTable from "generalComponents/DataTable";
import { modifyData, validTableKeys } from "../utils";
import Loader from "generalComponents/Loader";

const PaymentLinksPage = () => {
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(`&name=${searchValue}`, 1000);

  const [selectedLink, setSelectedLink] = useState(null);
  const initialData = {
    campus: "",
    title: "",
    link: "",
    vendor: "",
  };
  const [paymentDetails, setPaymentDetails] = useState(initialData);
  const [tableValues, setTableValues] = useState({
    pageIndex: 0,
    perPage: 20,
  });
  function setter(name, value) {
    setTableValues((val) => ({
      ...val,
      [name]: value,
    }));
  }

  const { data, isLoading } = useGetPaymentLinksManager();

  const { data: campusData, isLoading: loadingCampus } =
    useGetCampusListManager("");
  const { deleteLink, isLoading: deleting } = DeletePaymentLinkManager();

  // const tableHeads = [
  //   "Name",
  //   "Account Name",
  //   "Account number",
  //   "Church",
  //   "Action",
  //   "Action",
  // ];
  // const getFormattedValue = (el, index) => {
  //   return [
  //     el?.vendor,
  //     el?.title,
  //     el?.link,
  //     el?.campus?.name,
  //     <StatusButton
  //       status={"Edit"}
  //       onClick={() => {
  //         setSelectedLink(el);
  //         document.getElementById("add_paymentLink").showModal();
  //       }}
  //     />,
  //     <StatusButton
  //       status={"Delete"}
  //       onClick={() => {
  //         setSelectedLink(el);
  //         document.getElementById("admin_delete").showModal();
  //       }}
  //     />,
  //   ];
  // };

  return (
    <AdminWrapper title={"Payment Links"}>
      <div className="flex flex-col h-[90vh] pr-10">
        <div className="flex justify-between flex-col gap-5 md:flex-row w-full mb-10">
          <AdminHeaderTop
            title={"Payment Links"}
            subtitle={`${data?.data?.length} links in total`}
          />
          <div className="mb-3">
            <CustomButton
              onClick={async () => {
                setSelectedLink(null);
                document.getElementById("add_paymentLink").showModal();
              }}
              buttonText={`+ Add a payment link`}
              radius={`[5px]`}
              className={`max-w-max`}
            />
          </div>
        </div>
        <div className="flex items-center justify-between mb-10">
          <div className="max-w-[291px]">
            <SearchComponent
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
        </div>
        <div className="md:h-[67vh] relative md:overflow-y-auto scrollbar-hide">
          {isLoading ? (
            <Loader isSmall />
          ) : (
            <DataTable
              tableData={modifyData(data?.data, setSelectedLink)}
              totalItemCount={data?.data?.length}
              validTableKeys={validTableKeys}
              onRowClick={() => null}
              noOfItemsPerPage={tableValues?.perPage}
              onCheckClick={() => null}
              onItemsPerPageChange={(newPage) => setter("perPage", newPage)}
              pageIndex={tableValues?.pageIndex}
              onPageIndexChange={(idx) => setter("pageIndex", idx)}
              showFooter
            />
          )}
          {/* <TablesComponent
            headers={tableHeads}
            getFormattedValue={getFormattedValue}
            isLoading={isLoading}
            data={data?.data}
            hideActionButton={true}
          /> */}
        </div>
      </div>
      {
        <AddEditPaymentLinkComponent
          details={selectedLink}
          campusList={campusData?.data}
        />
      }
      <AdminDeleteConfirmationModal
        title={"Delete Payment Link"}
        body={"Are you sure you want to delete this payment detail?"}
        buttonText={"Yes, Delete"}
        isLoading={deleting}
        onClick={async () => {
          await deleteLink({
            paymentIds: [selectedLink?._id],
          });
          document.getElementById("admin_delete").close();
        }}
      />
    </AdminWrapper>
  );
};

export default PaymentLinksPage;
