import React from "react";
import { twMerge } from "tailwind-merge";
import { useAudioContext } from "../view";
import { Spinner } from "generalComponents/Loader";

const AudioControls = ({ className, large }) => {
  const { togglePlay, isPlaying, audioElement, isReady } = useAudioContext();
  const fastForward = () => {
    audioElement.currentTime += 10;
  };
  const rewind = () => {
    audioElement.currentTime -= 10;
  };
  return (
    <div
      className={twMerge(
        "flex items-center justify-center gap-5",
        large && "gap-8",
        className
      )}
    >
      <button title="Rewind" onClick={rewind}>
        <span className="sr-only">Rewind</span>
        <svg
          width="19"
          height="19"
          viewBox="0 0 19 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.4047 11.5965C4.9901 10.683 4.9901 8.3183 6.4047 7.40478L14.946 1.88916C16.3208 1.00133 18.0104 2.1569 18.0104 3.98503V15.0163C18.0104 16.8444 16.3208 18 14.946 17.1121L6.4047 11.5965Z"
            fill="#8311B3"
          />
          <rect
            x="1.5835"
            y="1.58398"
            width="2.53333"
            height="15.8333"
            rx="1.26667"
            fill="#8311B3"
          />
        </svg>
      </button>
      <button
        onClick={togglePlay}
        disabled={!isReady}
        className={twMerge(
          "flex justify-center items-center size-[50px] bg-gradient-to-b from-purpleBg to-purpleOverlay rounded-full",
          large && "size-[65px]"
        )}
      >
        <span className="sr-only">Play/Pause</span>
        {!isReady ? (
          <Spinner />
        ) : isPlaying ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={3}
            stroke="white"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 5.25v13.5m-7.5-13.5v13.5"
            />
          </svg>
        ) : (
          <svg
            width="18"
            height="20"
            viewBox="0 0 18 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            // className={twMerge("size-[50px]", large && "size-[65px]")}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 3.13625C0 0.714303 2.63167 -0.790872 4.71923 0.437103L16.2592 7.22533C18.3176 8.43614 18.3176 11.4128 16.2592 12.6236L4.71923 19.4118C2.63167 20.6398 0 19.1346 0 16.7127V3.13625Z"
              fill="white"
            />
          </svg>
        )}
      </button>

      <button title="Fastforward" onClick={fastForward}>
        <span className="sr-only">Fastforward</span>
        <svg
          width="19"
          height="19"
          viewBox="0 0 19 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.5953 11.5965C14.0099 10.683 14.0099 8.3183 12.5953 7.40478L4.05396 1.88916C2.67916 1.00133 0.989582 2.1569 0.989582 3.98503V15.0163C0.989582 16.8444 2.67916 18 4.05396 17.1121L12.5953 11.5965Z"
            fill="#8311B3"
          />
          <rect
            width="2.53333"
            height="15.8333"
            rx="1.26667"
            transform="matrix(-1 0 0 1 17.4165 1.58398)"
            fill="#8311B3"
          />
        </svg>
      </button>
    </div>
  );
};

export default AudioControls;
