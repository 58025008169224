import { useState, useRef, useCallback, useEffect } from "react";

/** Enumeration for axis values */
var Axis;
(function (Axis) {
  /**
   * The x-axis represents the horizontal direction.
   */
  Axis["X"] = "x";
  /**
   * The y-axis represents the vertical direction.
   */
  Axis["Y"] = "y";
})(Axis || (Axis = {}));
/** Enumeration for direction values */
var Direction;
(function (Direction) {
  /**
   * The up direction represents the scroll direction moving towards the top.
   */
  Direction["Up"] = "up";
  /**
   * The down direction represents the scroll direction moving towards the bottom.
   */
  Direction["Down"] = "down";
  /**
   * The left direction represents the scroll direction moving towards the left.
   */
  Direction["Left"] = "left";
  /**
   * The right direction represents the scroll direction moving towards the right.
   */
  Direction["Right"] = "right";
  /**
   * The still direction represents the scroll direction when the user is not scrolling.
   */
  Direction["Still"] = "still";
})(Direction || (Direction = {}));

function useDetectScroll(props = {}) {
  const {
    thr = 0,
    axis = Axis.Y,
    scrollUp = axis === Axis.Y ? Direction.Up : Direction.Left,
    scrollDown = axis === Axis.Y ? Direction.Down : Direction.Right,
    still = Direction.Still,
  } = props;
  const [scrollDir, setScrollDir] = useState(still);
  const [scrollPosition, setScrollPosition] = useState({
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  });
  const threshold = Math.max(0, thr);
  const ticking = useRef(false);
  const lastScroll = useRef(0);
  /** Function to update scroll direction */
  const updateScrollDir = useCallback(() => {
    const scroll = axis === Axis.Y ? window.scrollY : window.scrollX;
    if (Math.abs(scroll - lastScroll.current) >= threshold) {
      setScrollDir(scroll > lastScroll.current ? scrollDown : scrollUp);
      lastScroll.current = Math.max(0, scroll);
    }
    ticking.current = false;
  }, [axis, threshold, scrollDown, scrollUp]);
  useEffect(() => {
    /** Function to update scroll position */
    const updateScrollPosition = () => {
      const top = window.scrollY;
      const left = window.scrollX;
      const bottom =
        document.documentElement.scrollHeight - window.innerHeight - top;
      const right =
        document.documentElement.scrollWidth - window.innerWidth - left;
      setScrollPosition({ top, bottom, left, right });
    };
    /** Call the update function when the component mounts */
    updateScrollPosition();
    window.addEventListener("scroll", updateScrollPosition);
    return () => {
      window.removeEventListener("scroll", updateScrollPosition);
    };
  }, []);
  useEffect(() => {
    lastScroll.current = axis === Axis.Y ? window.scrollY : window.scrollX;
    /** Function to handle onScroll event */
    const onScroll = () => {
      if (!ticking.current) {
        window.requestAnimationFrame(updateScrollDir);
        ticking.current = true;
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [axis, updateScrollDir]);
  return { scrollDir, scrollPosition };
}

export { Axis, Direction, useDetectScroll as default };
