export const CircleArrowLeft = (props) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx="20"
      cy="20"
      r="19.5"
      transform="matrix(-1 0 0 1 40 0)"
      stroke="#F0CCFF"
    />
    <path
      d="M29 19.5C29.2761 19.5 29.5 19.7239 29.5 20C29.5 20.2761 29.2761 20.5 29 20.5V19.5ZM11.6464 20.3536C11.4512 20.1583 11.4512 19.8417 11.6464 19.6464L14.8284 16.4645C15.0237 16.2692 15.3403 16.2692 15.5355 16.4645C15.7308 16.6597 15.7308 16.9763 15.5355 17.1716L12.7071 20L15.5355 22.8284C15.7308 23.0237 15.7308 23.3403 15.5355 23.5355C15.3403 23.7308 15.0237 23.7308 14.8284 23.5355L11.6464 20.3536ZM29 20.5H12V19.5H29V20.5Z"
      fill="#8311B3"
    />
  </svg>
);
export const CircleArrowRight = (props) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="20" cy="20" r="19.5" stroke="#F0CCFF" />
    <path
      d="M11 19.5C10.7239 19.5 10.5 19.7239 10.5 20C10.5 20.2761 10.7239 20.5 11 20.5V19.5ZM28.3536 20.3536C28.5488 20.1583 28.5488 19.8417 28.3536 19.6464L25.1716 16.4645C24.9763 16.2692 24.6597 16.2692 24.4645 16.4645C24.2692 16.6597 24.2692 16.9763 24.4645 17.1716L27.2929 20L24.4645 22.8284C24.2692 23.0237 24.2692 23.3403 24.4645 23.5355C24.6597 23.7308 24.9763 23.7308 25.1716 23.5355L28.3536 20.3536ZM11 20.5H28V19.5H11V20.5Z"
      fill="#8311B3"
    />
  </svg>
);
