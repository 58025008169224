import AxiosWithToken from "constants/api_management/MyHttpHelperWithToken";
import showToast from "constants/showToast";
import { useMutation, useQueryClient } from "react-query";

const usePostPraiseReport = ({ onSuccess = () => null }) => {
  const queryClient = useQueryClient();

  const postRequest = async (body) => {
    try {
      const response = await AxiosWithToken.post("/testimony/create", body);
      return response.data;
    } catch (error) {
      throw new Error(error?.response?.data?.message);
    }
  };

  const mutation = useMutation({
    mutationKey: ["praise_reports"],
    mutationFn: postRequest,
    onSuccess: (data) => {
      showToast("Praise report submitted successfully", "success");
      queryClient.invalidateQueries(["praise_reports"]);
      onSuccess();
    },
    onError: (error) => {
      showToast(error?.message, "error");
    },
  });

  const postCaller = async (body) => {
    try {
      await mutation.mutateAsync(body);
    } catch (error) {
      console.log({ error });
    }
  };

  return {
    postCaller,
    ...mutation,
  };
};

export const usePostComment = () => {
  const queryClient = useQueryClient();

  const postRequest = async (body) => {
    try {
      const response = await AxiosWithToken.post(`/testimony/comment`, body);
      return response.data;
    } catch (error) {
      throw new Error(error?.response?.data?.message);
    }
  };

  const mutation = useMutation({
    mutationKey: ["praise_reports_comments"],
    mutationFn: postRequest,
    onSuccess: (data) => {
      showToast("Comment posted successfully", "success");
      queryClient.invalidateQueries(["praise_reports_comments"]);
      // onSuccess();
    },
    onError: (error) => {
      showToast(error, "error");
    },
  });

  const postCaller = async (body) => {
    try {
      const data = await mutation.mutateAsync(body);
      return data;
    } catch (error) {
      return error;
    }
  };

  return {
    postCaller,
    ...mutation,
  };
};

export default usePostPraiseReport;
