import AxiosWithToken from "constants/api_management/MyHttpHelperWithToken";
import { useMutation, useQuery } from "react-query";

export const useGetNotes = (params = {}) => {
  const getNotes = () =>
    AxiosWithToken.get("/note/list", {
      params,
    });

  return useQuery({
    queryKey: ["notes", params],
    queryFn: getNotes,
  });
};

export const useGetNoteById = ({
  id,
  params = {},
  enabled = false,
  onSuccess,
}) => {
  const getNotes = () =>
    AxiosWithToken.get(`/note/${id}`, {
      params,
    });

  return useQuery({
    queryKey: ["notes-by-id"],
    queryFn: getNotes,
    enabled,
    onSuccess,
  });
};

export const useCreateNote = () => {
  const createNote = (body) => AxiosWithToken.post("/note/create", body);

  return useMutation({
    mutationKey: ["notes"],
    mutationFn: createNote,
  });
};

export const useUpdateNote = (id) => {
  const updateNote = (body) => AxiosWithToken.put(`/note/${id}`, body);

  return useMutation({
    mutationKey: ["notes"],
    mutationFn: updateNote,
  });
};

export const useDeleteNote = () => {
  const deleteNote = (id) => AxiosWithToken.delete(`/note/${id}`);

  return useMutation({
    mutationKey: ["notes"],
    mutationFn: deleteNote,
  });
};
