import { useQuery } from "react-query";
import AxiosWithToken from "../../../../constants/api_management/MyHttpHelperWithToken";

const useGetSingleUserManager = (profileId) => {
  return useQuery(["singleUser", profileId], async () => {
    try {
      const [response] = [await AxiosWithToken.get(`/user/${profileId}`)];
      console.log(`i am checking this ${response.data.data.first_name}`);
      return response.data;
    } catch (error) {
      console.log(error.response.data);
      throw new Error(`Sorry: ${error.response.data.message}`);
    }
  });
};

export default useGetSingleUserManager;
