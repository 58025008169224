import { useSearchParams } from "react-router-dom";

export default function useQueryParams(initialData) {
  const [searchParams, setSearchParams] = useSearchParams();

  // remove some initial query params
  const initialParams = Object.entries(initialData || {}).reduce(
    (prev, cur) => {
      prev[cur[0]] = cur[1];
      return prev;
    },
    {}
  );

  const paramsEntries = [...searchParams.entries()];
  const params = paramsEntries.reduce((prev, cur) => {
    prev[cur[0]] = cur[1];
    return prev;
  }, {});

  const refetchParams = () => params;
  const setParam = (key, value) => {
    setSearchParams((params) => {
      //   searchParams.set(key, value as string);
      params.set(key, value);

      return params;
    });
  };

  const setParams = (queries) => {
    setSearchParams((searchParams) => {
      for (const [key, value] of Object.entries(queries)) {
        searchParams.set(key, value);
      }

      return searchParams;
    });
  };

  const appendParam = (key, value) => {
    setSearchParams((params) => {
      params.append(key, value);

      return params;
    });
  };

  const deleteParam = (key) => {
    setSearchParams((params) => {
      params.delete(key);

      return params;
    });
  };

  const deleteParams = (queries) => {
    setSearchParams((searchParams) => {
      for (const key of queries) {
        searchParams.delete(key);
      }

      return searchParams;
    });
  };

  return {
    // params: { ...initialData, ...params } as T,
    params: { ...initialParams, ...params },
    refetchParams,
    setParam,
    setParams,
    appendParam,
    deleteParam,
    deleteParams,
  };
}
