import React from "react";
import GradientText from "./GradientText";
import OrangeBar from "./OrangeBar";

const Vision = () => {
  return (
    <div className="flex flex-col md:flex-row justify-center md:justify-start items-end md:pl-16 px-5 max-w-section mx-auto">
      <GradientText text="01" className="text-[300px] leading-[300px]" />
      <div>
        <OrangeBar className="mb-2.5" />
        <h2 className="font-semibold text-3xl md:text-5xl text-purpleBg">
          Our Vision
        </h2>
        <p className="text-sm text-gray-500 max-w-[523px] pb-10">
          Our vision is simple yet profound: <br />
          <q>To raise a takeover generation</q>
        </p>
      </div>
    </div>
  );
};

export default Vision;
