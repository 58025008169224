import useUpdateManager from "constants/controller_templates/put_controller_template";

export const EditChildrenContentManager = (contentId) => {
  const { updateCaller, isLoading, isSuccess, error, data } = useUpdateManager(
    `/children/${contentId}`,
    ["allChildrenContent"],
    true,
    true
  );
  const updateContent = async (details) => {
    try {
      await updateCaller(details);
    } catch (error) {
      console.error("error:", error);
    }
  };
  return {
    updateContent,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
