import { create } from "zustand";
import { persist } from "zustand/middleware";

const initialState = null;

export const useUserStore = create(
  persist(
    (set, get) => ({
      user: null,
      setUser: (item) =>
        set((state) => ({
          user: item,
        })),
      // removeUser: () =>
      //   set((state) => ({
      //     user: initialState,
      //   })),
      reset: () => set(initialState),
    }),
    {
      name: "userStore",
      //   storage: createJSONStorage(() => SecureStorage),
    }
  )
);

export const useIsAdmin = () => {
  const user = useUserStore((state) => state.user);
  const isSuperAdmin = user?.roles?.[0]?.name === "super-admin";
  const isAdmin = !!user?.permissions?.length;

  return { isAdmin, isSuperAdmin };
};
