const CartIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_855_20675)">
        <path
          d="M1 1L1.32289 1.10763C2.93186 1.64396 3.73634 1.91211 4.19648 2.55053C4.65663 3.18894 4.65663 4.03694 4.65663 5.73295V8.9227C4.65663 12.3702 4.65663 14.0939 5.72763 15.1649C6.79864 16.236 8.52239 16.236 11.9699 16.236H21.7209"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M7.7038 19.2832C8.71356 19.2832 9.53212 20.1018 9.53212 21.1115C9.53212 22.1212 8.71356 22.9398 7.7038 22.9398C6.69405 22.9398 5.87549 22.1212 5.87549 21.1115C5.87549 20.1018 6.69405 19.2832 7.7038 19.2832Z"
          stroke="white"
          strokeWidth="1.5"
        />
        <path
          d="M18.6738 19.2832C19.6835 19.2832 20.5021 20.1017 20.5021 21.1115C20.5021 22.1212 19.6835 22.9398 18.6738 22.9398C17.6641 22.9398 16.8455 22.1212 16.8455 21.1115C16.8455 20.1017 17.6641 19.2832 18.6738 19.2832Z"
          stroke="white"
          strokeWidth="1.5"
        />
        <path
          d="M11.9699 8.3125H8.31323"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M4.65674 4.65625H18.6134C21.1182 4.65625 22.3704 4.65625 22.9125 5.47809C23.4544 6.29994 22.961 7.45106 21.9743 9.75335L21.4519 10.9722C20.9913 12.047 20.7609 12.5844 20.303 12.8865C19.845 13.1884 19.2603 13.1884 18.091 13.1884H4.65674"
          stroke="white"
          strokeWidth="1.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_855_20675">
          <rect width="24.1361" height="23.9398" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CartIcon;
