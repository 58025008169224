import SermonDisc from "generalComponents/SermonDisc";
import React from "react";

const CartItem = (props) => {
  // const cart = useCartStore((state) => state);
  const itemCover = {
    audio: (
      <SermonDisc
        image={props?.cover_image?.url}
        smaller
        className={"scale-[1.5] border-purpleBg"}
      />
    ),
    video: (
      <div className="relative size-full">
        <img
          src={props?.cover_image?.url}
          alt=""
          className="w-full h-full rounded-[8px] object-cover"
        />

        <button
          // onClick={goToVideoSermonDetails}
          className="size-[27px] bg-purpleOverlay/50 absolute inset-0 m-auto rounded-full flex justify-center items-center"
        >
          <svg
            width="8"
            height="10"
            viewBox="0 0 8 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.297852 2.30573C0.297852 1.27175 1.42137 0.629155 2.3126 1.15341L7.23926 4.05146C8.11806 4.56838 8.11806 5.8392 7.23926 6.3561L2.3126 9.25416C1.42137 9.7784 0.297852 9.1358 0.297852 8.10183V2.30573Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
    ),
    ebook: (
      <img
        src={props?.cover_image?.url}
        alt=""
        className="w-auto h-full rounded-[4px] object-cover"
      />
    ),
  };
  return (
    <div className="relative bg-[#FFF8FF] rounded-[15px] p-2.5 flex gap-3 items-center">
      <div className="w-32 lg:w-48 h-full grid place-content-center">
        {itemCover[props?.content_type]}
      </div>
      <div>
        <p className="font-semibold text-sm lg:text-base text-purpleOverlay max-w-[160px] line-clamp-2">
          {props?.title}
        </p>
        <p className="text-xs text-orangeBg">{props?.facilitator}</p>
        <p className="text-lg font-semibold text-purpleBg mt-3">
          <span className="text-orangeBg">₦</span>
          {props?.price?.toLocaleString()}{" "}
          <span>x {props?.cartItem?.quantity}</span>
        </p>
      </div>
      <button
        onClick={() => props?.deleteCartItem(props.cartId)}
        className="absolute top-2.5 right-2.5 outine-none"
      >
        <span className="sr-only">Close button</span>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="19.0104" height="19.0104" rx="9.5052" fill="white" />
          <path
            d="M5.93701 5.9375L13.0736 13.0741M5.93701 13.0741L13.0736 5.9375"
            stroke="#2F0640"
            strokeWidth="1.78107"
            strokeLinecap="round"
          />
        </svg>
      </button>
    </div>
  );
};

export default CartItem;
