// import { ngnIcon } from "assets/icons";
import CartIcon from "assets/icons/CartIcon";
import useQueryParams from "constants/useQueryParams";
import AudioItem from "generalComponents/AudioItem";
// import BookItem from "generalComponents/BookItem";
import CategoriesNav from "generalComponents/CategoriesNav";
import CustomPagination from "generalComponents/CustomPagination";
import EmptyState from "generalComponents/EmptyState";
import HeaderFooter from "generalComponents/HeaderFooter";
import TabComponent from "generalComponents/TabComponent";
import TextGradient from "generalComponents/TextGradient";
import VideoItem from "generalComponents/VideoItem";
// import useGetLibrary from "modules/pageLibrary/controllers/get_library_contoller";
import useGetCategories from "modules/pageSermons/controllers/get_categories_controller";
import React, { useCallback, useEffect, useState } from "react";
// import useCartStore from "store/cartStore";
import CartModal from "../components/CartModal";
import useGetCart, { useGetStoreList } from "../controllers/get_contoller";
import StoreItemDetails from "../components/StoreItemDetails";

const tabs = [
  "Video",
  "Audio",
  // "Books"
];

const debounce = (func, wait) => {
  let timeout;

  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

const Store = () => {
  // const cartStore = useCartStore((state) => state);
  const { params, setParam, deleteParam } = useQueryParams({
    page: 1,
    pageSize: 28,
  });
  const [tabIdx, setTabIdx] = useState(0);
  const tabUpdate = (type) => {
    setParam(
      "content_type",
      type?.toLowerCase() === "books" ? "ebook" : type?.toLowerCase()
    );
  };
  const [selectedVideo, setSelectedVideo] = useState();
  const [selectedAudio, setSelectedAudio] = useState();

  const onSelectVideo = (data) => {
    setSelectedVideo(data);
  };
  const onCloseVideoDetails = (data) => {
    setSelectedVideo(undefined);
  };
  const onSelectAudio = (data) => {
    setSelectedAudio(data);
  };
  const onCloseAudioDetails = (data) => {
    setSelectedAudio(undefined);
  };

  const { data: storeList, isLoading: storeLoading } = useGetStoreList({
    // subscription_type: "paid",
    ...params,
  });

  const { data: categories } = useGetCategories({
    group: "store",
  });

  const { data: cart } = useGetCart();
  // console.log({ cart });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce(
      (value) =>
        value === "" ? deleteParam("title") : setParam("title", value),
      1000
    ),
    []
  );

  const tabContent = [
    <div className="flex flex-wrap gap-5 mx-auto">
      {storeList?.items?.length > 0 ? (
        storeList?.items?.map((message, mIndex) => (
          <VideoItem
            showPrice
            key={mIndex}
            message={message}
            onShowDetails={onSelectVideo}
          />
        ))
      ) : (
        <EmptyState
          heading="Whoops!"
          message="Looks like this category has no video stores in the store. But don't worry, there's a treasure trove of store items waiting for you in other categories."
          scriptureText={
            "Buy the truth and do not sell it—wisdom, instruction and insight as well."
          }
          scripture={"Proverbs 23:23 (NIV)"}
        />
      )}
    </div>,
    <div className="flex flex-wrap gap-5 mx-auto">
      {storeList?.items?.length > 0 ? (
        storeList?.items?.map((message, mIndex) => (
          <AudioItem
            showPrice
            key={mIndex}
            message={message}
            onShowDetails={onSelectAudio}
          />
        ))
      ) : (
        <EmptyState
          heading="Hmm.."
          message="It looks like there are no audio sermons available in this category. Explore other categories or check out our video sermons for more enriching content!"
          scriptureText={
            "My son, keep my words and store up my commands within you. Keep my commands and you will live; guard my teachings as the apple of your eye. Bind them on your fingers; write them on the tablet of your heart."
          }
          scripture={"Proverbs 7:1-3 (NIV)"}
        />
      )}
    </div>,
    // <div className="w-full flex items-center flex-wrap gap-7">
    //   {sermonList?.data?.items?.length > 0 ? (
    //     sermonList?.data?.items?.map((item, index) => (
    //       <BookItem showPrice key={index} book={item} />
    //     ))
    //   ) : (
    //     <EmptyState
    //       heading="Uh oh!"
    //       message={
    //         "It seems there are no books available in this category at the moment. But fear not, there's a wealth of other items waiting for you in our store.  Discover what our other categories have to offer!"
    //       }
    //       scriptureText={
    //         "Blessed are those who find wisdom, those who gain understanding."
    //       }
    //       scripture={"Proverbs 3:13"}
    //     />
    //   )}
    // </div>,
  ];

  useEffect(() => {
    if (!params?.content_type) {
      setParam("content_type", "video");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (params?.content_type === "video") {
      setTabIdx(0);
    } else if (params?.content_type === "audio") {
      setTabIdx(1);
    }
    // else if (params?.content_type === "ebook") {
    //   setTabIdx(2);
    // }
  }, [params]);

  useEffect(() => {
    if (selectedVideo !== undefined) {
      window["video-details"]?.showModal();
    } else {
      window["video-details"]?.close();
    }
  }, [selectedVideo]);
  useEffect(() => {
    if (selectedAudio !== undefined) {
      window["audio-details"]?.showModal();
    } else {
      window["audio-details"]?.close();
    }
  }, [selectedAudio]);
  return (
    <HeaderFooter solid>
      <TextGradient>Our E-Store</TextGradient>

      <div className="pt-[300px] pb-20">
        <div className="flex justify-between items-center mb-3 max-w-section mx-auto px-5 ">
          <div>
            <h1 className="font-semibold text-[52px] lg:text-[60px] lg:leading-[70px] text-purpleOverlay">
              Our E-Store
            </h1>
            <p className="text-purpleOverlay text-sm">
              All items purchased are soft copies and will be saved to your
              e-Library
            </p>
          </div>
          <div className="flex items-center gap-10 justify-end">
            {/* <div className="flex items-center gap-1 w-fit mr-4">
              <img src={ngnIcon} alt="" />
              <select
                name=""
                id=""
                className="outline-none text-textGrey bg-transparent"
              >
                <option value="eng">NGN</option>
              </select>
            </div> */}

            <button
              onClick={() =>
                cart?.data?.total > 0 ? window["cart_modal"]?.showModal() : null
              }
              className="size-10 bg-purpleBg rounded-[5px] grid place-content-center relative outline-none"
            >
              <CartIcon />

              {cart?.data?.total > 0 && (
                <span className="size-7 rounded-full bg-white text-purpleOverlay grid place-content-center -top-4 -left-4 absolute shadow-md">
                  {cart?.data?.total}
                </span>
              )}
            </button>
          </div>
        </div>

        <div className="max-w-section mx-auto mt-5 px-5">
          <div className="flex flex-col md:flex-row gap-5 md:items-center justify-between mb-6">
            <p className="font-medium text-[30px] text-purpleOverlay">
              Categories
            </p>

            <div className="relative">
              <input
                type="search"
                onChange={(e) => handleSearch(e.target?.value)}
                placeholder="Search"
                className="h-[50px] min-w-[300px] bg-[#F2F2F2] text-purpleOverlay placeholder:text-gray-400 outline-none border-none p-3 pl-14 rounded-[50px] accent-purpleBg"
              />
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="absolute inset-0 left-3 my-auto"
              >
                <path
                  d="M11.9791 21.8757C17.4445 21.8757 21.875 17.4451 21.875 11.9798C21.875 6.51449 17.4445 2.08398 11.9791 2.08398C6.51382 2.08398 2.08331 6.51449 2.08331 11.9798C2.08331 17.4451 6.51382 21.8757 11.9791 21.8757Z"
                  stroke="#8311B3"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M22.9166 22.9173L20.8333 20.834"
                  stroke="#8311B3"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>

          <CategoriesNav categoryList={categories?.data?.categories ?? []} />

          <TabComponent
            tabButtons={tabs}
            tabContent={tabContent}
            isLoading={storeLoading}
            tabIdx={tabIdx}
            tabUpdate={tabUpdate}
          />

          {storeList?.pagination?.total > 0 && (
            <div className="flex justify-end mt-10 min-w-full">
              <CustomPagination
                count={parseInt(storeList?.pagination?.total)}
                page={parseInt(params?.page)}
                pageSize={parseInt(params?.pageSize) ?? 20}
                setPage={(newPage) => setParam("page", newPage)}
                isLoading={storeLoading}
              />
            </div>
          )}
        </div>
      </div>

      {cart?.data?.total > 0 && <CartModal id="cart_modal" data={cart?.data} />}
      {selectedVideo && (
        <StoreItemDetails
          onClose={onCloseVideoDetails}
          message={selectedVideo}
          id={"video-details"}
          type="video"
          title="Video Details"
        />
      )}

      {selectedAudio && (
        <StoreItemDetails
          onClose={onCloseAudioDetails}
          message={selectedAudio}
          id={"audio-details"}
          type="audio"
          title="Audio Details"
        />
      )}
    </HeaderFooter>
  );
};

export default Store;
