import React from "react";

const TabsManagement = ({
  setCurrentView,
  currentView,
  options,
  showDivider = true,
  divisionWidth,
}) => {
  return (
    <div className="flex flex-col md:flex-row items-center w-full justify-start relative gap-3 md:gap-0 my-5">
      {options.map((el, i) => (
        <p
          key={i}
          role="button"
          onClick={() => setCurrentView(i)}
          className={`text-13px pb-2 px-10 capitalize w-full md:w-fit  ${
            currentView === i
              ? "font-medium text-brandBlack border border-transparent border-b-2 border-b-[#FF8A00]"
              : "text-textGrey2"
          }`}
        >
          {el}
        </p>
      ))}
      {showDivider && (
        <div
          className={`divider md:divider-[#E4E7EC] inset-y-0 absolute top-1.5 ${
            divisionWidth ?? "w-full"
          }`}
        ></div>
      )}
    </div>
  );
};

export default TabsManagement;
