import React from "react";
import { twMerge } from "tailwind-merge";

const SermonDisc = ({ image, className, small = false, smaller = false }) => {
  return (
    <div
      className={twMerge(
        "w-[250px] h-[250px] rounded-full border-2 border-white flex items-center justify-center relative overflow-hidden",
        small && "w-[150px] h-[150px]",
        smaller && "w-[64.29px] h-[64.29px] border",
        className
      )}
    >
      <div
        className={twMerge(
          "w-[100px] h-[100px] rounded-full bg-[#2F064066] flex justify-center items-center relative z-10",
          small && "w-[60px] h-[60px]",
          smaller && "w-[15.94px] h-[15.94px]"
        )}
      >
        <div
          className={twMerge(
            "bg-white rounded-full h-[62.5px] w-[62.5px]",
            small && "w-[37.5px] h-[37.5px]",
            smaller && "w-[9.96px] h-[9.96px]"
          )}
        ></div>
      </div>
      <img
        src={image}
        alt=""
        className="absolute object-cover top-0 left-0 w-full h-full -z-0"
      />
    </div>
  );
};

export default SermonDisc;
