import React from "react";
import CustomButton from "./Button";
import { useNavigate } from "react-router-dom";
import { useIsAdmin } from "store/userStore";

const Unauthorized = () => {
  const navigate = useNavigate();
  const { isAdmin, isSuperAdmin } = useIsAdmin();
  return (
    <div className="w-full h-[100vh] flex flex-col justify-center items-center text-black">
      <p className="text-[35px] sm:text-[60px] font-semibold">
        Unathorized access
      </p>
      <p className="text-[20px] mb-10">
        You don't have access to this page contact admin
      </p>
      <CustomButton
        buttonText={"Return Home"}
        onClick={() =>
          isAdmin || isSuperAdmin ? navigate("/admin/dashboard") : navigate("/")
        }
      />
    </div>
  );
};

export default Unauthorized;
