import React, { useEffect, useRef, useState } from "react";
import BaseDashboardNavigation from "../../../../generalComponents/BaseDashboardNavigation";

import TablesComponent from "generalComponents/TablesComponent";
import useGetCategoryListManager from "../controllers/getCategoryListController";
import useDebounce from "utils/UseDebounce";
import PaginationRounded from "generalComponents/Pagination";
import AdminTopPage from "generalComponents/AdminTopPage";
import CategoryButton from "generalComponents/CategoryButton";
import AddEditCategory from "../components/AddEditCategory";
import AdminWrapper from "modules/admin/AdminWrapper";

const CategoriesPage = () => {
  const [selected, setSelected] = useState(0);

  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(`&title=${searchValue}`, 1000);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCat, setSelectedCat] = useState("sermon");
  const [currentIndex, setCurrentIndex] = useState("");
  const { isLoading, data } = useGetCategoryListManager({
    page: currentPage,
    searchQuery: searchValue ? debouncedSearchValue : "",
    group: selectedCat ? `?group=${selectedCat}` : "",
    enabled: true,
  });

  const tableHeads = ["Name", "Group", "Image", "Action"];
  const getFormattedValue = (el, index) => {
    return [
      el?.name,
      el?.group,
      <img
        src={el?.cover_image?.thumbnail}
        className="h-[70px] object-contain"
      />,
    ];
  };

  useEffect(() => {
    if (selected) {
      document.getElementById("add_category").showModal();
    }
  }, [selected]);

  const groupList = [
    { name: "Sermon", value: "sermon" },
    { name: "Library", value: "library" },
    { name: "Kids Devotional", value: "children" },
    { name: "Kids Animation", value: "kidsAnimation" },
    { name: "Kids Games", value: "kidsGames" },
    { name: "Testimony", value: "testimony" },
    { name: "Event", value: "event" },
    { name: "Others", value: "others" },
  ];

  return (
    <AdminWrapper title={"Categories"} hideSearch={true}>
      <AdminTopPage
        title={"Categories"}
        subtitle={"30 Categories in total"}
        buttonText={"+Add Category"}
        onclickButton={() => {
          setSelected(null);
          document.getElementById("add_category").showModal();
        }}
      />
      <div className="flex gap-2 max-w-[1240px] overflow-x-scroll scrollbar-hide">
        {groupList.map((group, index) => (
          <CategoryButton
            datatip="use this button to determine the list of category to display."
            key={index}
            group={group.name}
            selected={selectedCat === group.value}
            onClick={() => {
              setSelectedCat(group.value);
            }}
          />
        ))}
      </div>
      <div className="md:h-[65vh] relative md:overflow-y-auto scrollbar-hide">
        <TablesComponent
          data={data?.data?.categories}
          headers={tableHeads}
          isLoading={isLoading}
          getFormattedValue={getFormattedValue}
          buttonFunction={(val) => {
            console.log(val);
            setSelected(val);
            if (val?.id === selected?.id) {
              document.getElementById("add_category").showModal();
            }
          }}
        />
      </div>

      {data?.data?.categories.length > 0 && (
        <div className="mb-10 flex items-center justify-center">
          <PaginationRounded
            count={data?.pagination?.pageTotal}
            defaultPage={data?.pagination?.currentPage}
            onChange={(page) => {
              setCurrentPage(page);
            }}
          />
        </div>
      )}
      <AddEditCategory details={selected} groupList={groupList} />
    </AdminWrapper>
  );
};

export default CategoriesPage;
