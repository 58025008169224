import React from "react";
import { twMerge } from "tailwind-merge";

const LikeButton = (props) => {
  const { liked, className, ...rest } = props;
  return (
    <svg
      width="27"
      height="26"
      viewBox="0 0 27 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="button"
      className={twMerge(
        liked ? "fill-[#FE3140] stroke-[#FE3140]" : "stroke-[#2F0640]",
        className
      )}
      {...rest}
    >
      <path
        d="M18.2874 3.82715C16.7774 3.82715 15.389 4.33774 14.303 5.21094C13.5752 5.79611 12.3673 5.79611 11.6396 5.21094C10.5536 4.33774 9.16518 3.82715 7.65519 3.82715C4.14657 3.82715 1.27588 6.69784 1.27588 10.2065C1.27588 15.6653 9.98438 21.2034 12.3721 22.6192C12.7466 22.8413 13.1964 22.8421 13.5715 22.6212C15.9609 21.2143 24.6667 15.7104 24.6667 10.2065C24.6667 6.69784 21.796 3.82715 18.2874 3.82715Z"
        // stroke="#2F0640"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default LikeButton;
