import axios from "axios";

/**
 * Fetch presigned URLs for file uploads.
 * @param {Object} params - Request parameters.
 * @param {string} params.bucketName - Name of the S3 bucket.
 * @param {Array<string>} params.objectKeys - Keys for the objects in the bucket.
 * @param {number} params.expirySeconds - Expiration time in seconds for the URLs.
 * @returns {Promise<Array<string>>} - Array of presigned URLs.
 */

export const getPresignedUrls = async ({ bucketName, objectKeys, expirySeconds }) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_WORKFORCE_APP_BASE_URL}/aws/s3/get-presigned-urls`, {
            bucketName,
            objectKeys,
            expirySeconds,
        });

        return response.data.data;
    } catch (error) {
        // throw error;
    }
};

/**
 * Fetch presigned URLs for file uploads.
 * @param {Object} params - Request parameters.
 * @param {string} params.bucketName - Name of the S3 bucket.
 * @param {string} params.objectKeys - Key for the object in the bucket.
 * @param {number} params.expirySeconds - Expiration time in seconds for the URLs.
 * @returns {Promise<string>} - Presigned URL.
 */

export const getPresignedUrl = async ({ bucketName, objectKey, expirySeconds }) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_WORKFORCE_APP_BASE_URL}/aws/s3`, {
            bucketName,
            objectKey,
            expirySeconds,
        });

        return response.data.data;
    } catch (error) {
        // throw error;
    }
};