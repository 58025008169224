import AxiosWithToken from "constants/api_management/MyHttpHelperWithToken";
import { useMutation, useQueryClient } from "react-query";

export const useLikeSermon = ({ id, like }) => {
  const queryClient = useQueryClient();

  const putLike = async () => {
    try {
      const response = await AxiosWithToken.put(`/sermon/${id}/${like}`);
      return response?.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  const mutation = useMutation({
    mutationKey: ["sermons"],
    mutationFn: putLike,
    onSuccess: (data) => {
      //   showToast(data.message, "success");
      queryClient.invalidateQueries(["sermons"]);
      queryClient.invalidateQueries(["sermon_by_id"]);
      queryClient.invalidateQueries(["sermon_likes_by_id"]);
      //   onSuccess();
    },
    onError: (error) => {
      //   showToast(error?.message, "error");
    },
  });

  const likeSermon = async () => {
    try {
      await mutation.mutateAsync();
    } catch (error) {
      console.log({ error });
    }
  };

  return {
    likeSermon,
    ...mutation,
  };
};
