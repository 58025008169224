import ModalManagement from "generalComponents/CustomModal";
import { cozaCityProjectAccounts } from "../giveLinks";
import AccountCard from "./AccountCard";

const CozaCityProjectsModal = ({ id, onClose, data, links }) => {
  return (
    <ModalManagement
      id={id}
      className={""}
      title={"COZA CITY PROJECT ACCOUNTS"}
      onClose={onClose}
    >
      <div className=" flex flex-col gap-5">
        {cozaCityProjectAccounts?.map((item, index) => (
          <AccountCard key={index} item={item} />
        ))}
      </div>
    </ModalManagement>
  );
};

export default CozaCityProjectsModal;
