import React, { useEffect, useRef, useState } from "react";
import { format } from "date-fns";

import Loader from "../../../../generalComponents/Loader";

import { useNavigate } from "react-router-dom";
import useGetPraiseReportListManager from "../../praiseReport/controllers/getPraiseReportListController";
import TablesComponent from "generalComponents/TablesComponent";
import GlobalVariables from "constants/GlobalVariables";
import { formatDate } from "utils/formatDate";
import StatusButton from "generalComponents/StatusButton";
import PaginationRounded from "generalComponents/Pagination";
import useDebounce from "utils/UseDebounce";

const UserPraiseReports = ({ userFilter = "", forUser = false }) => {
  // const [searchValue, setSearchValue] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const debouncedSearchValue = useDebounce(`&user=${userFilter}`, 1000);

  const { data, isLoading } = useGetPraiseReportListManager({
    page: currentPage,
    searchQuery: debouncedSearchValue,
    // enabled: true,
  });

  const tableHeads = ["Title", "Category", "Status", "Date", "Action"];
  const getFormattedValue = (el, index) => {
    return [
      el?.title,
      el?.category?.name,
      <StatusButton status={el?.approved ? "approved" : "unapproved"} />,
      formatDate(el?.createdAt),
    ];
  };

  const navigate = useNavigate();

  if (isLoading) {
    return <Loader isSmall={true} />;
  }

  return (
    <div className="md:h-[67vh] relative md:overflow-y-auto scrollbar-hide">
      <TablesComponent
        data={data?.data?.testimonies}
        headers={tableHeads}
        isLoading={isLoading}
        getFormattedValue={getFormattedValue}
        buttonFunction={(val) => {
          console.log(val);
          // navigate(`/admin/user-details/${val?.id}`);
        }}
      />
      {data?.data?.testimonies && (
        <div className="mb-10 flex items-center justify-center">
          <PaginationRounded
            count={data?.pagination?.pageTotal}
            defaultPage={data?.pagination?.currentPage}
            onChange={(page) => {
              setCurrentPage(page);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default UserPraiseReports;
