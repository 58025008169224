import { useQuery } from "react-query";

import AxiosWithToken from "../../../../constants/api_management/MyHttpHelperWithToken";

const useGetAllEventsManager = (searchQuery = "") => {
  return useQuery(["events", searchQuery], async () => {
    try {
      const [response] = [
        await AxiosWithToken.get(`/event/list`, {
          params: {
            name: searchQuery === "" ? undefined : searchQuery,
          },
        }),
      ];
      // console.log(`i am checking this ${response.data}`);
      return response.data;
    } catch (error) {
      console.log(error.response.data);
      throw new Error(`Sorry: ${error.response.data.message}`);
    }
  });
};

export default useGetAllEventsManager;
