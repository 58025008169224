import { cozaLogoWhite } from "assets/icons";
import React from "react";
import { twMerge } from "tailwind-merge";

const CozaLoader = ({ fullPage }) => {
  return (
    <div
      className={twMerge(
        "h-full w-full flex flex-col justify-center items-center bg-white z-10",
        fullPage ? "absolute h-[100svh] w-[100svw]" : "min-h-[300px]"
      )}
    >
      <div className="icon-ball  relative">
        <img src={cozaLogoWhite} alt="" />
      </div>
    </div>
  );
};

export default CozaLoader;
