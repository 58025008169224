import React from "react";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { IoMdMore } from "react-icons/io";
import { Link } from "react-router-dom";
import { formatDate } from "utils/formatDate";

const GalleryCard = ({ gallery, onClick, deleteCaller }) => {
  return (
    <div class="relative bg-[#FDF8FF] group rounded-[10px] max-w-[272px] w-full  h-[324px] border">
      <div className="flex items-start justify-between px-[5%] pt-5 pb-2">
        <Link
          to={`/admin/gallery-images/${gallery.id}`}
          className="flex items-start gap-3"
        >
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M21.7654 12.6759V7.59178C21.7654 6.22803 21.6938 5.05787 21.4765 4.08009C21.2564 3.08996 20.8728 2.23166 20.2033 1.56214C19.5338 0.892611 18.6755 0.509006 17.6853 0.288958C16.7076 0.0716519 15.5374 0 14.1736 0H7.59178C6.22803 0 5.05787 0.0716519 4.08009 0.288958C3.08996 0.509006 2.23166 0.892611 1.56214 1.56214C0.892611 2.23166 0.509006 3.08996 0.288958 4.08009C0.0716519 5.05787 0 6.22803 0 7.59178V14.1736C0 15.5374 0.0716519 16.7076 0.288958 17.6853C0.509006 18.6755 0.892611 19.5338 1.56214 20.2033C2.23166 20.8728 3.08996 21.2564 4.08009 21.4765C5.05787 21.6938 6.22803 21.7654 7.59178 21.7654H14.1736C15.5374 21.7654 16.7076 21.6938 17.6853 21.4765C18.6755 21.2564 19.5338 20.8728 20.2033 20.2033C20.8728 19.5338 21.2564 18.6755 21.4765 17.6853C21.6938 16.7076 21.7654 15.5374 21.7654 14.1736V12.7265C21.7659 12.7096 21.7659 12.6927 21.7654 12.6759ZM2.41365 4.55229C2.24837 5.29602 2.17654 6.2777 2.17654 7.59178V9.89892C2.95996 9.14561 3.68925 8.5293 4.39547 8.0799C5.2512 7.53538 6.12936 7.19972 7.07621 7.19972C8.02306 7.19972 8.90119 7.53538 9.757 8.0799C10.602 8.61764 11.4801 9.39439 12.4444 10.3587L14.2131 12.1275C15.2832 11.2453 16.3326 10.6362 17.462 10.4955C18.2059 10.4028 18.9075 10.5206 19.5889 10.7954V7.59178C19.5889 6.2777 19.5171 5.29602 19.3517 4.55229C19.1893 3.82093 18.9512 3.38808 18.6643 3.10118C18.3773 2.81429 17.9445 2.5762 17.2131 2.41365C16.4695 2.24837 15.4877 2.17654 14.1736 2.17654H7.59178C6.2777 2.17654 5.29602 2.24837 4.55229 2.41365C3.82093 2.5762 3.38808 2.81429 3.10118 3.10118C2.81429 3.38808 2.5762 3.82093 2.41365 4.55229Z"
              fill="#8311B3"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13.0586 6.53006C13.0586 5.32799 14.033 4.35352 15.2351 4.35352C16.4372 4.35352 17.4117 5.32799 17.4117 6.53006C17.4117 7.73213 16.4372 8.7066 15.2351 8.7066C14.033 8.7066 13.0586 7.73213 13.0586 6.53006Z"
              fill="#8311B3"
            />
          </svg>
          <div className="flex flex-col items-start">
            <p className="text-[15px] font-semibold text-[#2F0640]">
              {gallery?.title}
            </p>
            <p className="text-[12px] font-light text-[#828282]">
              {formatDate(gallery.date)}
            </p>
          </div>{" "}
        </Link>
        {/* <IoMdMore size={20} /> */}
      </div>
      <Link
        to={`/admin/gallery-images/${gallery.id}`}
        className="max-w-[90%] w-full mx-auto relative"
      >
        <img
          class="object-cover max-w-[90%] mx-auto w-full h-[234px] rounded-[10px]"
          src={gallery.cover_image.thumbnail}
          alt=""
        />
      </Link>

      <div className="flex items-center">
        <div
          onClick={onClick}
          class="absolute transition-all duration-200 opacity-0 top-4 right-16 group-hover:opacity-100 text-white bg-black p-3 rounded-full"
        >
          <AiFillEdit />
        </div>
        <div
          onClick={() => {
            deleteCaller(gallery.id);
          }}
          class="absolute transition-all duration-200 opacity-0 top-4 right-4 group-hover:opacity-100 text-red-600 bg-brandOrange p-3 rounded-full"
        >
          <AiFillDelete />
        </div>
      </div>
      <div className="absolute bottom-7 right-5 h-[35px] rounded-[60px] bg-blackColor bg-opacity-35 backdrop-blur-lg p-2 flex items-center gap-2">
        <svg
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.2827 10.4967C19.2827 11.2661 18.632 11.89 17.8293 11.89C17.0266 11.89 16.3757 11.2661 16.3757 10.4967C16.3757 9.72728 17.0266 9.10352 17.8293 9.10352C18.632 9.10352 19.2827 9.72728 19.2827 10.4967Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M18.788 5.76332C17.6841 5.62106 16.2736 5.62107 14.4928 5.62109H10.5076C8.72674 5.62107 7.31624 5.62106 6.21235 5.76332C5.07629 5.90973 4.15676 6.2182 3.4316 6.91328C2.70644 7.60837 2.38462 8.48975 2.23188 9.5787C2.08347 10.6368 2.08349 11.9888 2.0835 13.6957V13.8006C2.08349 15.5074 2.08347 16.8594 2.23188 17.9175C2.38462 19.0065 2.70644 19.8879 3.4316 20.583C4.15676 21.2781 5.07628 21.5865 6.21235 21.733C7.31625 21.8751 8.72674 21.8751 10.5076 21.8751H14.4928C16.2736 21.8751 17.6841 21.8751 18.788 21.733C19.924 21.5865 20.8436 21.2781 21.5687 20.583C22.2939 19.8879 22.6157 19.0065 22.7684 17.9175C22.9168 16.8594 22.9168 15.5074 22.9168 13.8006V13.6958C22.9168 11.9888 22.9168 10.6368 22.7684 9.5787C22.6157 8.48975 22.2939 7.60837 21.5687 6.91328C20.8436 6.2182 19.924 5.90973 18.788 5.76332ZM6.40603 7.1441C5.43113 7.26974 4.86945 7.50534 4.45937 7.89843C4.04929 8.2915 3.80349 8.82989 3.67241 9.76434C3.56728 10.5139 3.54375 11.4507 3.5385 12.676L4.02895 12.2646C5.1999 11.2825 6.9647 11.3389 8.0649 12.3935L12.2216 16.3777C12.6378 16.7767 13.293 16.8311 13.7745 16.5067L14.0635 16.3121C15.4494 15.3785 17.3245 15.4866 18.5837 16.5729L21.0917 18.7364C21.1942 18.4614 21.2716 18.1332 21.3279 17.7319C21.4618 16.7774 21.4634 15.5192 21.4634 13.7482C21.4634 11.977 21.4618 10.7188 21.3279 9.76434C21.1968 8.82989 20.951 8.2915 20.541 7.89843C20.1309 7.50534 19.5692 7.26974 18.5943 7.1441C17.5985 7.01577 16.2859 7.01429 14.4382 7.01429H10.5621C8.71449 7.01429 7.40182 7.01577 6.40603 7.1441Z"
            fill="white"
          />
          <path
            d="M17.798 2.71948C16.9024 2.60446 15.7621 2.60447 14.3406 2.60449H11.1222C9.70065 2.60447 8.56035 2.60446 7.66472 2.71948C6.73724 2.83859 5.96396 3.09236 5.35063 3.67819C4.99691 4.01606 4.7587 4.40484 4.59644 4.84333C5.12164 4.60374 5.71545 4.46256 6.38212 4.3762C7.51169 4.22988 8.95499 4.22989 10.7772 4.2299H14.8552C16.6774 4.22989 18.1206 4.22988 19.2502 4.3762C19.8325 4.45163 20.3592 4.56888 20.8331 4.75697C20.6721 4.35363 20.4425 3.99371 20.1121 3.67819C19.4987 3.09236 18.7255 2.83859 17.798 2.71948Z"
            fill="white"
          />
        </svg>
        <p className="text-[15px] text-white">{gallery?.image_count}</p>
      </div>
    </div>
  );
};

export default GalleryCard;
