import React, { useState } from "react";
import BaseDashboardNavigation from "../../../../generalComponents/BaseDashboardNavigation";
import useGetAllCellsManager from "../controllers/getAllCellsController";
import Loader from "../../../../generalComponents/Loader";
import ErrorManager from "../../../../generalComponents/ErrorManager";
import CellTable from "../../../../generalComponents/CellTable";
import CustomButton from "../../../../generalComponents/Button";
import InputWithFullBoarder from "../../../../generalComponents/InputWithFullBoarder";
import useCreateCellManager from "../controllers/createCellController";
import AdminWrapper from "modules/admin/AdminWrapper";

const AllCellsPage = () => {
  const { isLoading: creatingCell, postCaller: createCell } =
    useCreateCellManager();

  const { isLoading, isError, error, data } = useGetAllCellsManager();

  const initialData = {
    meeting_date: "2023-10-07",
    meeting_time: "",
    meeting_day: "",
    meeting_link: "",
  };

  const [cellDetails, setCellDetails] = useState(initialData);
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return (
      <div>
        <ErrorManager errorMessage={error.message} />
      </div>
    );
  }
  return (
    <AdminWrapper title={"Cells"}>
      <div className="md:flex md:justify-between md:space-x-[20px] space-y-20 md:space-y-5 relative h-full w-full md:w-[98%]">
        <div className="max-w-full md:w-[65%] overflow-x-auto scrollbar-hide">
          <CellTable cells={data?.data?.cells} />
        </div>
        {/* create or edit container */}
        <div className="rounded-[20px] max-w-full md:w-[33%]  mr-7 p-5 bg-lightGrey/20 py-10 ">
          <p className="text-18px mb-4 font-bold">Add Cell</p>

          <p className="text-13px md:text-16px font-semibold mb-2   ">
            Select Meeting Days
          </p>
          <select
            className={`border border-lightGrey mb-10 bg-lightGrey/30 p-2 block w-[95%] md:w-auto rounded-md outline-none focus:outline-none `}
            value={cellDetails.meeting_day}
            onChange={(e) =>
              setCellDetails({
                ...cellDetails,
                meeting_day: e.target.value,
              })
            }
          >
            {days.map((day) => (
              <option value={day}>{day}</option>
            ))}
          </select>

          <InputWithFullBoarder
            label={"Cell Name"}
            className={"mb-4"}
            value={cellDetails?.name}
            onChange={(e) =>
              setCellDetails({ ...cellDetails, name: e.target.value })
            }
          />
          <InputWithFullBoarder
            label={"Meeting Time"}
            className={"mb-4"}
            placeholder={"03:00pm"}
            value={cellDetails.meeting_time}
            onChange={(e) =>
              setCellDetails({
                ...cellDetails,
                meeting_time: e.target.value,
              })
            }
          />

          <CustomButton
            buttonText={"Add Cell"}
            onClick={async () => {
              console.log(cellDetails);
              await createCell(cellDetails);
              setCellDetails(initialData);
            }}
            isLoading={creatingCell}
          />
        </div>
      </div>
    </AdminWrapper>
  );
};

export default AllCellsPage;
