export const sectionList = [
  {
    name: "My Library",
    path: "/library/my-library",
    slug: "my-library",
    activeIcon: (
      <svg
        width="16"
        height="15"
        viewBox="0 0 16 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.2551 14.4006L13.255 14.4007C12.8703 14.4966 12.4801 14.2623 12.3842 13.8772L12.3842 13.8771L9.91652 3.97906L9.91643 3.97871C9.82029 3.59414 10.0544 3.20396 10.4399 3.10751L11.6942 2.79504L11.6948 2.7949C12.0785 2.69889 12.4692 2.93275 12.5658 3.31869C12.5658 3.31876 12.5658 3.31883 12.5659 3.31891L15.0334 13.2166C15.1292 13.6007 14.8949 13.9915 14.5098 14.0881C14.5096 14.0881 14.5095 14.0881 14.5094 14.0882L13.2551 14.4006ZM2.82916 1.47797C3.22566 1.47797 3.54773 1.80005 3.54773 2.19654V13.7037C3.54773 14.1002 3.22566 14.4223 2.82916 14.4223H1.55762C1.16068 14.4223 0.839053 14.1004 0.839053 13.7037V2.19654C0.839053 1.79987 1.16068 1.47797 1.55762 1.47797H2.82916ZM7.30416 1.47797C7.70066 1.47797 8.02274 1.80005 8.02274 2.19654V13.7037C8.02274 14.1002 7.70066 14.4223 7.30416 14.4223H6.02879C5.63184 14.4223 5.31022 14.1004 5.31022 13.7037V2.19654C5.31022 1.79987 5.63184 1.47797 6.02879 1.47797H7.30416Z"
          stroke="white"
          strokeWidth="1.12"
        />
      </svg>
    ),
    icon: (
      <svg
        width="16"
        height="15"
        viewBox="0 0 16 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.2553 14.4006L13.2552 14.4007C12.8705 14.4966 12.4803 14.2623 12.3844 13.8772L12.3844 13.8771L9.91676 3.97906L9.91667 3.97871C9.82053 3.59414 10.0547 3.20396 10.4401 3.10751L11.6945 2.79504L11.695 2.7949C12.0787 2.69889 12.4694 2.93275 12.566 3.31869C12.5661 3.31876 12.5661 3.31883 12.5661 3.31891L15.0337 13.2166C15.1294 13.6007 14.8951 13.9915 14.51 14.0881C14.5099 14.0881 14.5098 14.0881 14.5096 14.0882L13.2553 14.4006ZM2.82941 1.47797C3.2259 1.47797 3.54798 1.80005 3.54798 2.19654V13.7037C3.54798 14.1002 3.2259 14.4223 2.82941 14.4223H1.55787C1.16092 14.4223 0.839297 14.1004 0.839297 13.7037V2.19654C0.839297 1.79987 1.16092 1.47797 1.55787 1.47797H2.82941ZM7.30441 1.47797C7.7009 1.47797 8.02298 1.80005 8.02298 2.19654V13.7037C8.02298 14.1002 7.7009 14.4223 7.30441 14.4223H6.02903C5.63208 14.4223 5.31046 14.1004 5.31046 13.7037V2.19654C5.31046 1.79987 5.63208 1.47797 6.02903 1.47797H7.30441Z"
          stroke="#828282"
          strokeWidth="1.12"
        />
      </svg>
    ),
  },
  {
    name: "Notes",
    path: "/library/notes",
    slug: "notes",
    icon: (
      <svg
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.67236 1.49219V3.73"
          stroke="#828282"
          strokeWidth="1.1189"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.6399 1.49219V3.73"
          stroke="#828282"
          strokeWidth="1.1189"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.92627 9.69727H11.8938"
          stroke="#828282"
          strokeWidth="1.1189"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.92627 12.6816H9.65595"
          stroke="#828282"
          strokeWidth="1.1189"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.6398 2.61133C15.1238 2.7456 16.3695 3.69293 16.3695 7.19883V11.8087C16.3695 14.882 15.6235 16.4186 11.8939 16.4186H7.41824C3.68856 16.4186 2.94263 14.882 2.94263 11.8087V7.19883C2.94263 3.69293 4.18834 2.75306 6.67231 2.61133H12.6398Z"
          stroke="#828282"
          strokeWidth="1.1189"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    activeIcon: (
      <svg
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.67285 1.49219V3.73"
          stroke="white"
          strokeWidth="1.1189"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.6396 1.49219V3.73"
          stroke="white"
          strokeWidth="1.1189"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.92676 9.69727H11.8942"
          stroke="white"
          strokeWidth="1.1189"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.92676 12.6816H9.65644"
          stroke="white"
          strokeWidth="1.1189"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.6405 2.61133C15.1245 2.7456 16.3702 3.69293 16.3702 7.19883V11.8087C16.3702 14.882 15.6243 16.4186 11.8946 16.4186H7.41897C3.6893 16.4186 2.94336 14.882 2.94336 11.8087V7.19883C2.94336 3.69293 4.18907 2.75306 6.67304 2.61133H12.6405Z"
          stroke="white"
          strokeWidth="1.1189"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
];
