import React, { useEffect, useMemo, useState } from "react";
import InputWithFullBoarder from "../../../../generalComponents/InputWithFullBoarder";

import useGetServiceAttendance from "../controllers/getServiceAttendance";

import SearchComponent from "generalComponents/SearchComponent";
import ButtonWithIcon from "generalComponents/ButtonWithIcon";
import { MdAddCircleOutline } from "react-icons/md";
import ViewServiceAttendance from "../component/ViewServiceAttendance";
import InitiateNewAttendance from "../component/InitiateNewAttendance";
import AdminWrapper from "modules/admin/AdminWrapper";
import DataTable from "generalComponents/DataTable";
import { modifyData, validTableKeys } from "../utils";
import Loader from "generalComponents/Loader";
import SelectInput from "generalComponents/SelectInput";
import { twMerge } from "tailwind-merge";
import useGetServiceListManager from "modules/admin/attendance/controllers/getServiceListManager";

const AttendanceManagementPage = () => {
  const [searchValue, setSearchValue] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selected, setSelected] = useState(null);
  const [modal, setModal] = useState(true);

  const [tableValues, setTableValues] = useState({
    pageIndex: 0,
    perPage: 20,
  });

  const setter = (name, value) => {
    setTableValues((val) => ({
      ...val,
      [name]: value,
    }));
  }

  const { data, isLoading } = useGetServiceAttendance({
    page: tableValues?.pageIndex + 1,
    limit: tableValues?.perPage,
    // searchQuery: searchValue ? debouncedSearchValue : "",
    // category: selectedCategory === "" ? undefined : selectedCategory,
    enabled: true,
  });

  const { data: services, } = useGetServiceListManager({ enabled: true });

  const serviceList = useMemo(() => {
    const list =
      services?.map((service) => ({
        label: service?.name,
        value: service?._id,
      })) || [];
    return list;
  }, [services]);

  function onRowClick(rowData) {
    setSelected(rowData);
    if (selected?._id === rowData?._id) {
      document.getElementById("view_serviceAttendance")?.showModal();
    }
  }

  useEffect(() => {
    if (selected) {
      document.getElementById("view_serviceAttendance")?.showModal();
    }
  }, [selected]);

  const handleUnMountModal = () => {
    setTimeout(() => {
      setModal(false)
    }, 1000)
  }

  useEffect(() => {
    if (!modal) {
      setModal(true)
    }
  }, [modal])

  return (
    <AdminWrapper
      title={"Service Attendance"}
      value={searchValue}
      onChange={(e) => {
        setSearchValue(e.target.value);
      }}
    >
      <div className="flex flex-col h-[90vh] pr-10">
        <div className="flex justify-between flex-col gap-5 md:flex-row w-full mb-10">
          <div className=" flex flex-col items-start">
            <p className="text-[24px] font-semibold">Service Attendance</p>
          </div>
          <div className="mb-3">
            <ButtonWithIcon
              onClick={async () => {
                setSelected(null);
                document.getElementById("add_serviceAttendance").showModal();
              }}
              icon={MdAddCircleOutline}
              buttonText={`Initiate New Attendance`}
              radius={`[5px]`}
              className={`max-w-max`}
            />
          </div>
        </div>
        <div className="flex items-center justify-between flex-col md:flex-row">
          <div className="max-w-max w-full flex items-center gap-3">
            <SearchComponent
              value={searchValue}
              marginRight=""
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <SelectInput
              placeholder={"Category"}
              options={[{ label: "All", value: "" }, ...serviceList]}
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              className={twMerge(
                "text-xs py-2.5 mb-0 h-10 md:min-w-[140px]",
                selectedCategory === "" ? "text-gray-500" : ""
              )}
              containerClass="mb-0 p-0"
            />
          </div>
          <InputWithFullBoarder type={"date"} />
        </div>

        {/* user tables */}
        <div className="md:h-[67vh] relative md:overflow-y-auto scrollbar-hide">
          {isLoading ? (
            <Loader isSmall />
          ) : (
            <DataTable
              tableData={modifyData(data?.serviceAttendances)}
              totalItemCount={data?.total}
              validTableKeys={validTableKeys}
              onRowClick={onRowClick}
              noOfItemsPerPage={tableValues?.perPage}
              onCheckClick={() => null}
              onItemsPerPageChange={(newPage) => setter("perPage", newPage)}
              pageIndex={tableValues?.pageIndex}
              onPageIndexChange={(idx) => setter("pageIndex", idx)}
              showFooter
            />
          )}
        </div>
      </div>
      {selected && <ViewServiceAttendance {...selected} />}
      {modal && <InitiateNewAttendance unMount={handleUnMountModal} />}
    </AdminWrapper>
  );
};

export default AttendanceManagementPage;
