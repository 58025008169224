import React, { useRef, useState, useEffect } from "react";

function DisplayFile({ fileUrl = "" }) {
  const mediaRef = useRef(null);
  const [isPaused, setIsPaused] = useState(true);
  const [isMuted, setIsMuted] = useState(true);
  const maxHeight = window?.innerHeight * 0.5;

  const handleMediaClick = () => {
    if (mediaRef.current) {
      if (mediaRef.current.paused) {
        mediaRef.current.play();
        setIsPaused(false);
      } else {
        mediaRef.current.pause();
        setIsPaused(true);
      }
    }
  };

  const toggleMute = () => {
    if (mediaRef.current) {
      mediaRef.current.muted = !mediaRef.current.muted;
      setIsMuted(mediaRef.current.muted);
    }
  };

  if (fileUrl.endsWith(".mp4")) {
    return (
      <div className="relative">
        <video
          onClick={handleMediaClick}
          ref={mediaRef}
          className="rounded-[6px] w-full"
          muted={isMuted}
          playsInline
        >
          <source src={fileUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="absolute bottom-2 right-2 flex space-x-2">
          <button
            onClick={handleMediaClick}
            className="bg-black bg-opacity-50 text-white p-2 rounded"
          >
            {isPaused ? "Play" : "Pause"}
          </button>
          <button
            onClick={toggleMute}
            className="bg-black bg-opacity-50 text-white p-2 rounded"
          >
            {isMuted ? "Unmute" : "Mute"}
          </button>
        </div>
      </div>
    );
  } else if (fileUrl.endsWith(".mp3") || fileUrl.endsWith(".wav")) {
    return (
      <div className="relative">
        <audio ref={mediaRef} className="w-full" controls muted={isMuted}>
          <source src={fileUrl} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
        {/* <div className="absolute bottom-2 right-2 flex space-x-2">
          <button
            onClick={handleMediaClick}
            className="bg-black bg-opacity-50 text-white p-2 rounded"
          >
            {isPaused ? "Play" : "Pause"}
          </button>
          <button
            onClick={toggleMute}
            className="bg-black bg-opacity-50 text-white p-2 rounded"
          >
            {isMuted ? "Unmute" : "Mute"}
          </button>
        </div> */}
      </div>
    );
  } else if (fileUrl.endsWith(".pdf")) {
    return (
      <embed
        className="rounded-[6px]"
        src={fileUrl}
        type="application/pdf"
        width="100%"
        height="400"
      />
    );
  } else if (
    fileUrl.endsWith(".jpg") ||
    fileUrl.endsWith(".JPG") ||
    fileUrl.endsWith(".jpeg") ||
    fileUrl.endsWith(".png")
  ) {
    return (
      <img
        src={fileUrl}
        alt="file"
        className="rounded-[6px] max-h-[500px] object-contain w-fit mx-auto"
        style={{ maxHeight }}
      />
    );
  } else {
    return (
      <div className="max-w-full w-full truncate">
        {fileUrl
          ? `Unsupported file format: ${fileUrl}`
          : "No Attachment found"}
      </div>
    );
  }
}

export default DisplayFile;
