import useDeleteManager from "constants/controller_templates/delete_controller_template";

export const DeleteGalleryImageManager = (galleryId) => {
  const isMultiKey = false;
  const { deleteCaller, isLoading, isSuccess, error, data } = useDeleteManager(
    `/gallery/${galleryId}/images`,
    ["galleryImages"],
    isMultiKey
  );
  const deleteImage = async (details) => {
    try {
      console.log(details, "This is the first");
      await deleteCaller(details);
    } catch (error) {
      // Handle error if necessary
      console.error("delete error:", error);
    }
  };
  return {
    deleteImage,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
