import React from "react";

const CustomPagination = ({ count, page, pageSize, setPage, isLoading }) => {
  const numberOfPages = Math.ceil(count / pageSize);
  const nextPage = () => {
    if (page + 1 > numberOfPages) return;
    setPage(page + 1);
  };
  const prevPage = () => {
    if (page - 1 < 1) return;
    setPage(page - 1);
  };
  return (
    <div className="inline-flex items-center justify-center gap-3">
      <button
        onClick={prevPage}
        disabled={page - 1 < 1}
        className="inline-flex size-8 items-center justify-center rounded border border-gray-100 bg-purpleOverlay text-gray-900 shadow-md rtl:rotate-180
        disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-55"
      >
        <span className="sr-only">Previous Page</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="white"
        >
          <path
            fillRule="evenodd"
            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
            clipRule="evenodd"
          />
        </svg>
      </button>

      <p className="text-sm text-purpleOverlay">
        {isLoading ? "..." : page}
        <span className="mx-0.25">/</span>
        {isLoading ? "..." : numberOfPages}
      </p>

      <button
        onClick={nextPage}
        disabled={page + 1 > numberOfPages}
        className="inline-flex size-8 items-center justify-center rounded border border-gray-100 bg-purpleOverlay text-gray-900 shadow-md rtl:rotate-180 disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-55"
      >
        <span className="sr-only">Next Page</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="white"
        >
          <path
            fillRule="evenodd"
            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
            clipRule="evenodd"
          />
        </svg>
      </button>
    </div>
  );
};

export default CustomPagination;
