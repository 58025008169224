import React, { useEffect, useState } from "react";
import InputWithFullBoarder from "../../../../generalComponents/InputWithFullBoarder";
import CustomButton from "../../../../generalComponents/Button";
import useEditCellManager from "../controllers/editCellController";
import { CiSquareRemove } from "react-icons/ci/index.esm";

const EditCellComponent = ({
  cellId,
  name,
  onClick,
  meeting_date,
  meeting_time,
  meeting_day,
  meeting_link,
}) => {
  const [cellDetails, setCellDetails] = useState({
    name: name,
    meeting_date: meeting_date,
    meeting_time: meeting_time,
    meeting_day: meeting_day,
    meeting_link: meeting_link,
  });

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  useEffect(() => {
    setCellDetails({
      name: name,
      meeting_date: meeting_date,
      meeting_time: meeting_time,
      meeting_day: meeting_day,
      meeting_link: meeting_link,
      cellId: cellId,
    });
  }, [name, meeting_date, meeting_day, meeting_link, meeting_time, cellId]);
  const { updateCaller, isLoading } = useEditCellManager(cellId);

  const handleSubmit = async () => {
    const details = {
      name: cellDetails.name,
      meeting_date: cellDetails.meeting_date,
      meeting_day: cellDetails.meeting_day,
      meeting_link: cellDetails.meeting_link,
      meeting_time: cellDetails.meeting_time,
    };
    await updateCaller(details);
  };
  return (
    <div className="rounded-[20px] max-w-full  p-5 bg-white py-10 ">
      <div
        onClick={onClick}
        className="text-[40px] mb-4 w-full justify-between items-center flex"
      >
        <p className="text-18px font-bold">{`Edit Cell `}</p>
        <CiSquareRemove />
      </div>

      <InputWithFullBoarder
        label={"Cell Name"}
        className={"mb-4"}
        value={cellDetails.name}
        onChange={(e) =>
          setCellDetails({
            ...cellDetails,
            name: e.target.value,
          })
        }
      />

      <div className="flex items-center">
        <div className="flex w-full">
          <div className="flex flex-col w-[90%]">
            <p className="text-13px md:text-16px font-semibold mb-2   ">
              Meeting Days
            </p>
            <select
              className={`border border-lightGrey mb-4 bg-lightGrey/30 p-2 block w-[90%] rounded-md outline-none focus:outline-none `}
              value={cellDetails.meeting_day}
              onChange={(e) =>
                setCellDetails({
                  ...cellDetails,
                  meeting_day: e.target.value,
                })
              }
            >
              {days.map((day) => (
                <option value={day}>{day}</option>
              ))}
            </select>
          </div>
          <InputWithFullBoarder
            label={"Meeting Time"}
            className={"mb-4 w-[90%]"}
            value={cellDetails.meeting_time}
            onChange={(e) =>
              setCellDetails({
                ...cellDetails,
                meeting_time: e.target.value,
              })
            }
          />
        </div>
      </div>

      <CustomButton
        buttonText={"Edit Cell Details"}
        onClick={handleSubmit}
        isLoading={isLoading}
      />
    </div>
  );
};

export default EditCellComponent;
