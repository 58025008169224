import AxiosWithToken from "constants/api_management/MyHttpHelperWithToken";
import showToast from "constants/showToast";
import { useMutation, useQueryClient } from "react-query";

const usePutCart = ({ onSuccess = () => null } = {}) => {
  const queryClient = useQueryClient();

  const putRequest = async (body) => {
    try {
      const response = await AxiosWithToken.put("/cart", body);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  };

  const mutation = useMutation({
    mutationKey: ["cart"],
    mutationFn: putRequest,
    onSuccess: (data) => {
      showToast(data.message, "success");
      queryClient.invalidateQueries(["cart"]);
      onSuccess();
    },
    onError: (error) => {
      showToast(error?.message, "error");
    },
  });

  const putCaller = async (body) => {
    try {
      await mutation.mutateAsync(body);
    } catch (error) {
      console.log({ error });
    }
  };

  return {
    putCaller,
    ...mutation,
  };
};

export const usePutCheckout = ({ onSuccess = () => null } = {}) => {
  const queryClient = useQueryClient();

  const putRequest = async (payload) => {
    const body = {
      items: payload,
      payment_option: "card_web",
    };
    try {
      const response = await AxiosWithToken.put("/cart/checkout", body);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  };

  const mutation = useMutation({
    mutationKey: ["cart"],
    mutationFn: putRequest,
    onSuccess: (data) => {
      // showToast(data.message, "success");
      queryClient.invalidateQueries(["cart"]);
      onSuccess();
    },
    onError: (error) => {
      showToast(error?.message, "error");
    },
  });

  const putCaller = async (body) => {
    try {
      const data = await mutation.mutateAsync(body);
      return data;
    } catch (error) {
      throw new Error(error);
    }
  };

  return {
    putCaller,
    ...mutation,
  };
};

export default usePutCart;
