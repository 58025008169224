import { useQuery } from "react-query";
import { AxiosFaceRecognitionApp } from "../../../../constants/api_management/MyHttpHelperWithToken";

const useGetServiceAttendance = (props) => {
  const { page = 1, limit = 20, category } = props;
  return useQuery(["serviceAttendance", props], async () => {
    try {
      const [response] = [
        await AxiosFaceRecognitionApp.get(
          `/attendance/get-processes`,
          {
            params: {
              page,
              limit,
              category,
            },
          }
        ),
      ];

      return response.data;
    } catch (error) {
      throw new Error(`Sorry: ${error.response.data.message}`);
    }
  });
};

export default useGetServiceAttendance;