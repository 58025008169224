import BabyChristeningModal from "./components/BabyChristeningModal";
import BusinessDedicationModal from "./components/BusinessDedicationModal";
import ChildDedicationModal from "./components/ChildDedicationModal";
import HouseDedicationModal from "./components/HouseDedicationModal";
import PreMaritalClassModal from "./components/PreMaritalClassModal";
import RelationshipRegistrationModal from "./components/RelationshipRegistrationModal";
import VehicleDedication from "./components/VehicleDedication";
import WeddingAnniversaryModal from "./components/WeddingAnniversaryModal";
import WeddingThanksgivingModal from "./components/WeddingThanksgivingModal";
import WhistleBlowerModal from "./components/WhistleBlowerModal";

export const formTypes = {
  "baby-christening": {
    component: <BabyChristeningModal />,
    slug: "baby-christening",
  },
  "business-dedication": {
    component: <BusinessDedicationModal />,
    slug: "business-dedication",
  },
  "child-dedication": {
    component: <ChildDedicationModal />,
    slug: "child-dedication",
  },
  "house-dedication": {
    component: <HouseDedicationModal />,
    slug: "house-dedication",
  },
  "vehicle-dedication": {
    component: <VehicleDedication />,
    slug: "vehicle-dedication",
  },
  "marital-class": {
    component: <PreMaritalClassModal />,
    slug: "marital-class",
  },
  "relationship-registration": {
    component: <RelationshipRegistrationModal />,
    slug: "relationship-registration",
  },
  "wedding-anniversary": {
    component: <WeddingAnniversaryModal />,
    slug: "wedding-anniversary",
  },
  "wedding-thanksgiving": {
    component: <WeddingThanksgivingModal />,
    slug: "wedding-thanksgiving",
  },
  "whistle-blower": {
    component: <WhistleBlowerModal />,
    slug: "whistle-blower",
  },
};
