import { cozaLogo } from "assets/icons";
import useLoggedInUser from "constants/loggedInUser";
import { adminMenu, otherProtectedPages } from "constants/menu";
import { useEffect } from "react";
import { IoClose } from "react-icons/io5/index.esm";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useIsAdmin, useUserStore } from "store/userStore";
import { twMerge } from "tailwind-merge";

const Sidebar = ({ isMobile, menuOpen, toggleMenu }) => {
  const user = useUserStore((state) => state.user);
  const { isSuperAdmin, isAdmin } = useIsAdmin();
  const { logout } = useLoggedInUser();
  const location = useLocation();
  //   const authorizedPaths = [];

  const navigate = useNavigate();

  const hasPathPermission = (path, userPermissions) => {
    const menuEntry = adminMenu.find((entry) => entry.path === path);

    if (!isSuperAdmin && !isAdmin) return false;

    // console.log('menuEntry', menuEntry, menuEntry &&
    //   (menuEntry.permission === "all" || userPermissions.includes(menuEntry.permission)))
    return (
      menuEntry &&
      (menuEntry.permission === "all" ||
        userPermissions.some((permission) =>
          permission.includes(menuEntry.permission)
        ))
    );
  };

  const isProtected = (path, userPermissions) => {
    const menuEntry = otherProtectedPages.find((entry) =>
      path.startsWith(entry.path)
    );
    return (
      menuEntry &&
      (menuEntry.permission === "all" ||
        userPermissions.some((permission) =>
          permission.includes(menuEntry.permission)
        ))
    );
  };

  useEffect(() => {
    // console.log('location---->>', location)
    // const isAdmin = data?.data?.roles[0]?.name === "super-admin";
    const isPathProtected = isProtected(
      location.pathname,
      user?.permissions ?? []
    );
    const hasPathPermissionOrIsProtected =
      isPathProtected ||
      hasPathPermission(location.pathname, user?.permissions ?? []);

    if (!isSuperAdmin && !hasPathPermissionOrIsProtected) {
      navigate("/unauthorized");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, location.pathname]);
  return (
    <div
      className={twMerge(
        "relative flex flex-col justify-between overflow-hidden duration-500 h-[100dvh] w-[270px] bg-purpleOverlay transition ease-in-out delay-100 py-3 px-2 pb-10",
        isMobile && "absolute z-50",
        menuOpen ? "translate-x-0" : "-translate-x-[110%]"
      )}
    >
      <div className="w-full">
        {isMobile && (
          <button className="text-white w-fit ml-5" onClick={toggleMenu}>
            <IoClose className="stroke-white size-8" />
          </button>
        )}
        <Link to="/" className="py-5 pl-4">
          <img
            className="object-contain size-[70px]"
            src={cozaLogo}
            alt="COZA logo"
          />
        </Link>
        <div className="w-full max-h-[60svh] overflow-scroll mt-3 purple-scrollbar pr-2">
          {adminMenu?.map((item, index) => {
            if (item.title === "Logout") return null;

            const hasPermission =
              user?.permissions.some((permission) =>
                permission.includes(`${item.permission}`)
              ) ||
              item.permission === "all" ||
              isSuperAdmin;

            if (hasPermission) {
              return (
                <Link
                  key={index}
                  to={item.path}
                  className={twMerge(
                    "py-3 px-4 flex items-center gap-3 rounded duration-300 hover:bg-purpleBg/50",
                    location?.pathname === item.path
                      ? "bg-purpleBg hover:bg-purpleBg"
                      : ""
                  )}
                >
                  {item.icon}
                  <p className="text-sm">{item.title}</p>
                </Link>
              );
            }

            return null;
          })}
        </div>
      </div>

      <div className="absolute left-0 bottom-0 w-full px-2 pb-10 border-t border-white pt-5 bg-purpleOverlay">
        {adminMenu
          ?.filter((obj) => obj.title === "Logout")
          ?.map((item, index) => (
            <div
              role="button"
              onClick={logout}
              key={index}
              className={twMerge(
                "py-3 px-4 flex items-center gap-3 rounded duration-300 hover:bg-purpleBg/50",
                location?.pathname === item.path
                  ? "bg-purpleBg hover:bg-purpleBg"
                  : ""
              )}
            >
              {item.icon}
              <p className="text-sm">{item.title}</p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Sidebar;
