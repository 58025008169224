import React from "react";
import { twMerge } from "tailwind-merge";
import {
  CozaKidsBg,
  CozaKidsIcon,
  EventsBg,
  EventsIcon,
  FamilyProcessBg,
  FamilyProcessIcon,
  GalleryBg,
  GalleryIcon,
  PraiseReportBg,
  PraiseReportIcon,
  ScheduleBg,
  ScheduleIcon,
} from "./FeatureIcons";
import { Link } from "react-router-dom";

const featuresList = [
  {
    text: "Praise Reports",
    icon: <PraiseReportIcon />,
    bgImage: <PraiseReportBg />,
    className: "bg-[#DDFFEB] border-[#27AE601A]",
    url: "/praise-reports",
  },
  {
    text: "COZA Kids",
    icon: <CozaKidsIcon />,
    bgImage: <CozaKidsBg />,
    className: "bg-[#FF8A001A] border-[#FF8A001A]",
    url: "/coza-kids",
  },
  {
    text: "Family Process",
    icon: <FamilyProcessIcon />,
    bgImage: <FamilyProcessBg />,
    className: "lg:row-span-2 bg-[#2F80ED1A] border-[#2F80ED1A]",
    url: "/family-process",
  },
  {
    text: "Events",
    icon: <EventsIcon />,
    bgImage: <EventsBg />,
    className: "bg-[#E700001A] border-[#E700001A]",
    url: "/events",
  },
  {
    text: "Schedule Appointment",
    icon: <ScheduleIcon />,
    bgImage: <ScheduleBg />,
    className: "lg:col-span-2 bg-[#FFF0FE] border-[#B400FF1A]",
    url: "/appointment",
  },
  {
    text: "Gallery",
    icon: <GalleryIcon />,
    bgImage: <GalleryBg />,
    className: "bg-[#E800ED1A] border-[#E800ED1A]",
    url: "/gallery",
  },
];

const Features = () => {
  return (
    <div className="py-20 max-w-section mx-auto">
      <h2
        className={twMerge(
          "text-[60px] md:text-[100px] lg:text-[200px] xl:text-[300px]",
          "text-center font-bold mx-auto drop-shadow-[0_25px_25px_#e4a7ff]"
        )}
      >
        Features
      </h2>

      <div className="grid lg:grid-cols-4 lg:grid-rows-2 gap-5 px-5">
        {featuresList.map((item, index) => (
          <FeatureItem key={index} {...item} />
        ))}
      </div>
    </div>
  );
};

export default Features;

const FeatureItem = ({ icon, text, bgImage, className, url }) => (
  <Link
    to={url}
    className={twMerge(
      "min-h-[216px] rounded-[20px] border-[5px] flex flex-col gap-3 justify-end px-7 py-6 relative duration-300 hover:scale-[1.025] hover:translate-x-1 overflow-hidden",
      className
    )}
  >
    {bgImage}
    {icon}
    <p className="font-semibold text-2xl text-black">{text}</p>
  </Link>
);
