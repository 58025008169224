import React from "react";
import { formatDate } from "utils/formatDate";
import StatusButton from "./StatusButton";

const TicketTile = ({ details, onClick }) => {
  const content = [
    { name: "Subject", value: details?.title },
    { name: "Name", value: details?.user?.full_name },
    { name: "Email", value: details?.user?.email },
    { name: "Created", value: formatDate(details?.createdAt) },
  ];
  return (
    <div
      onClick={onClick}
      className="grid grid-cols-2 md:grid-cols-5 items-center justify-between p-2 md:h-[50px] rounded-[5px] w-full gap-3 md:gap-1 border border-lightGrey"
    >
      {content.map((el, i) => (
        <div key={i} className="flex flex-col items-start">
          <p className="text-[10px] text-grey3">{el.name}</p>
          <p className="text-[12px] text-brandBlack font-medium">{el.value}</p>
        </div>
      ))}
      <StatusButton status={details?.status ?? ""} />
    </div>
  );
};

export default TicketTile;
