import { useQuery } from "react-query";

import AxiosWithToken from "../../../../constants/api_management/MyHttpHelperWithToken";

const useGetCategoryListManager = ({
  page,
  searchQuery = "",
  enabled = true,
  group = "",
  pageSize = "20",
}) => {
  return useQuery(
    ["categoryList", group],
    async () => {
      try {
        const [response] = [
          await AxiosWithToken.get(
            // `/category/list?page=${page}&pageSize=${pageSize}${group}${searchQuery}`
            `/category/list${group}`
          ),
        ];
        return response.data;
      } catch (error) {
        console.log(error.response.data);
        throw new Error(`Sorry: ${error.response.data}`);
      }
    },
    { enabled: enabled }
  );
};

export default useGetCategoryListManager;
