import { useQuery } from "react-query";

import AxiosWithToken from "../../../../constants/api_management/MyHttpHelperWithToken";

const useGetLibraryContentsManager = ({
  page = "1",
  searchQuery = "",
  pageSize = "20",
  type = "ebook",
}) => {
  return useQuery(["library", searchQuery, page, type], async () => {
    try {
      const [response] = [
        await AxiosWithToken.get(
          `/library/list?page=${page}&pageSize=20&content_type=${type}${searchQuery}`
        ),
      ];
      console.log(`i am checking this ${response.data}`);
      return response.data;
    } catch (error) {
      console.log(error.response.data);
      throw new Error(`Sorry: ${error.response.data.message}`);
    }
  });
};

export default useGetLibraryContentsManager;
