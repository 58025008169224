// import useUserInteraction from "constants/useUserInteraction";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLikePraiseReport } from "../controllers/put_controller";
import { useUserStore } from "store/userStore";
// import PlayButton from "./PlayButton";
// import MuteButton from "./MuteButton";
import { twMerge } from "tailwind-merge";
import { capitalizeFirstLetter } from "constants/textFormatters";
import { formatDistance } from "date-fns";
import { useGetPraiseReportComments } from "../controllers/get_praise_reports_contoller";
import LikeButton from "./LikeButton";
import ShareButton from "./ShareButton";
import showToast from "constants/showToast";
import { usePostComment } from "../controllers/post_controller";
import { avatar } from "assets/images";
import useDisclosure from "constants/useDisclosure";
import CustomModalHeadless from "generalComponents/CustomModalHeadless";
import EllipsisIcon from "./EllipsisIcon";
import { useDeleteComment } from "../controllers/delete_controller";

const appBaseUrl = process.env.REACT_APP_APP_BASE_URL;

const SinglePraiseReport = ({ praiseReport }) => {
  const user = useUserStore((state) => state.user);
  const videoRef = useRef();
  const backgroundRef = useRef();
  const [isPlaying, setIsPlaying] = useState(false);
  // const [muted, setMuted] = useState(false);
  const [mediaLoading, setMediaLoading] = useState(true);
  const [comment, setComment] = useState("");
  const [commentId, setCommentId] = useState();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenD,
    onOpen: onOpenD,
    onClose: onCloseD,
  } = useDisclosure();

  const isTouchDevice = useMemo(() => {
    return () => {
      return (
        "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
      );
    };
  }, []);

  const praiseReportLink = `${appBaseUrl}/praise-reports/${praiseReport?.id}`;
  const onCopy = (e, data) => {
    e.preventDefault();
    e.stopPropagation();
    navigator.clipboard.writeText(data);
    showToast("Link copied", "success");
  };
  const handleCopy = (e) => {
    onCopy(e, praiseReportLink);
  };

  const { data: comments } = useGetPraiseReportComments(praiseReport?.id);

  const { postCaller } = usePostComment(praiseReport?.id);
  const postComment = async (e) => {
    e?.preventDefault();
    const body = {
      testimony: praiseReport?.id,
      comment,
      name: `${user?.first_name} ${user?.last_name}`,
    };
    try {
      const data = await postCaller(body);
      if (data) {
        setComment("");
      }
    } catch (error) {
      // console.log(error?.response?.data?.message, "error");
    }
  };

  // const userInteracted = useUserInteraction();
  const { likePraiseReport } = useLikePraiseReport({
    id: praiseReport?.id,
    like: "like",
  });
  const { likePraiseReport: unlikePraiseReport } = useLikePraiseReport({
    id: praiseReport?.id,
    like: "unlike",
  });

  const { deleteCaller } = useDeleteComment();

  const userLiked = useMemo(() => {
    const returnedUser = praiseReport?.recent_likes?.filter(
      (item) => item?.user?.id === user?.id
    );

    if (returnedUser?.length > 0) return true;
    else return false;
  }, [praiseReport?.recent_likes, user?.id]);

  const onVideoClick = () => {
    const video = videoRef.current;
    const background = backgroundRef.current;
    if (isPlaying) {
      video?.pause();
      background?.pause();
    } else {
      video?.play();
      background?.play();
    }
  };

  useEffect(() => {
    if (commentId) {
      onOpenD();
    } else {
      onCloseD();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentId]);
  // console.log({ mediaLoading });
  return (
    <div className="@container h-full w-full">
      <div className="flex flex-col @md:flex-row h-full w-full rounded-[10px] animate-fade-in">
        <div className="relative max-h-[70%] @md:max-h-full w-full grid place-content-center flex-1 bg-black">
          {praiseReport?.mediaType === "video" ? (
            <video
              onClick={onVideoClick}
              ref={backgroundRef}
              controls={false}
              muted
              loop
              src={praiseReport?.mediaUrl}
              className="absolute object-cover w-full h-full filter blur-xl z-0"
            ></video>
          ) : (
            <img
              src={
                praiseReport?.imageUrl
                  ? praiseReport?.imageUrl
                  : praiseReport?.mediaUrl
                  ? praiseReport?.mediaUrl
                  : !praiseReport?.anonymous
                  ? praiseReport?.user?.profile_picture
                  : ""
              }
              alt=""
              className="absolute object-cover w-full h-full filter blur-xl z-0 bg-black"
            />
          )}
          <div
            className={twMerge(
              "w-full h-full block bg-black/45 animate-pulse relative",
              !mediaLoading && "animate-fade-out hidden"
            )}
          ></div>
          {praiseReport?.mediaType === "video" ? (
            <div className="relative w-full h-fit">
              <video
                // onLoad={}
                onCanPlay={() => setMediaLoading(false)}
                // onClick={onVideoClick}
                onPlaying={() => setIsPlaying(true)}
                onPause={() => setIsPlaying(false)}
                ref={videoRef}
                controls={true}
                // muted={muted}
                loop
                src={praiseReport?.mediaUrl}
                className={twMerge(
                  "object-contain w-full h-auto relative z-[1] opacity-100 duration-300",
                  mediaLoading && "opacity-0"
                )}
              ></video>
              {/* <button
                onClick={() => setMuted((val) => !val)}
                className="absolute bottom-3 right-3 z-10 bg-purpleOverlay p-2 rounded-full outline-none"
              >
                <MuteButton muted={muted} />
              </button>
              {!isPlaying && (
                <>
                  <PlayButton
                    onClick={onVideoClick}
                    className="absolute inset-0 m-auto z-[3]"
                  />
                  <div className="absolute h-full w-full top-0 left-0 bg-gradient-to-b from-transparent to-black/50 z-[2]"></div>
                </>
              )} */}
            </div>
          ) : (
            <img
              onLoad={() => setMediaLoading(false)}
              src={
                praiseReport?.imageUrl
                  ? praiseReport?.imageUrl
                  : praiseReport?.mediaUrl
                  ? praiseReport?.mediaUrl
                  : !praiseReport?.anonymous
                  ? praiseReport?.user?.profile_picture
                  : ""
              }
              alt=""
              className="object-contain min-w-[calc(100svw-390px)] w-full h-auto relative z-[1] bg-black"
            />
          )}
        </div>
        <div className="w-full @md:w-[390px] h-full bg-white px-5 pt-2.5 relative overflow-hidden">
          <div className="@md:sticky top-0 left-0 flex items-center w-full gap-2.5 mb-4 border-b border-gray-200 pb-2.5">
            {praiseReport?.anonymous ? (
              <img
                src={avatar}
                alt=""
                className="w-10 h-10 rounded-full filter grayscale"
              />
            ) : (
              <img
                src={praiseReport?.user?.profile_picture}
                alt=""
                className="w-10 h-10 rounded-full"
              />
            )}
            <div>
              <p className="font-medium text-[13px] text-[#1D1D1D]">
                {praiseReport?.anonymous
                  ? "Anonymous"
                  : praiseReport?.name !== ""
                  ? praiseReport?.name
                  : `${capitalizeFirstLetter(
                      praiseReport?.user?.first_name
                    )} ${capitalizeFirstLetter(praiseReport?.user?.last_name)}`}
              </p>
              <p className="text-[10px] text-[#BDBDBD]">
                {formatDistance(new Date(praiseReport?.createdAt), new Date(), {
                  addSuffix: true,
                })}
              </p>
            </div>
          </div>
          <div className="w full h-full overflow-scroll pb-[220px] no-scrollbar">
            <div className="flex items-start w-full gap-2.5 mb-4 pb-2.5">
              {praiseReport?.anonymous ? (
                <img
                  src={avatar}
                  alt=""
                  className="w-9 h-9 rounded-full filter grayscale"
                />
              ) : (
                <img
                  src={praiseReport?.user?.profile_picture}
                  alt=""
                  className="w-9 h-9 rounded-full"
                />
              )}
              <div>
                <p className="font-medium text-base text-purpleOverlay">
                  {praiseReport?.title}
                </p>
                <p className="text-sm text-gray-500 whitespace-pre-line">
                  {praiseReport?.description}
                </p>
              </div>
            </div>
            {comments?.map((comment, index) => (
              <div
                key={index}
                className="group flex items-start w-full gap-2.5 mb-4 pb-2.5"
              >
                <img
                  src={comment?.user?.profile_picture ?? avatar}
                  alt=""
                  className="w-9 h-9 rounded-full"
                />
                <div>
                  <p className="text-sm text-purpleOverlay font-medium whitespace-pre-line">
                    {comment?.name?.includes(undefined)
                      ? `${comment?.user?.first_name} ${comment?.user?.last_name}`
                      : comment?.name}
                  </p>
                  <p className="text-sm text-gray-500 whitespace-pre-line">
                    {comment?.comment}
                  </p>
                  <span className="text-[10px] text-[#BDBDBD] flex items-center gap-2">
                    {formatDistance(new Date(comment?.createdAt), new Date(), {
                      addSuffix: true,
                    })}

                    {(user?.id === praiseReport?.user?.id ||
                      user?.id === comment?.user?.id) && (
                      <EllipsisIcon
                        onClick={() => setCommentId(comment?.id)}
                        className={twMerge(
                          "cursor-pointer invisible duration-500 hover:scale-[0.9] hover:opacity-70 group-hover:visible",
                          isTouchDevice() ? "visible" : ""
                        )}
                      />
                    )}
                  </span>
                </div>
              </div>
            ))}
          </div>
          <div className="absolute bottom-0 left-0 w-full bg-white drop-shadow-[0px_-4px_25px_rgba(0,0,0,0.15)] px-5">
            <div className=" pb-2 border-b border-gray-200">
              <div className="flex items-center justify-between gap-4 py-2">
                <div className="flex items-center gap-1">
                  <LikeButton
                    onClick={userLiked ? unlikePraiseReport : likePraiseReport}
                    liked={userLiked}
                    className="duration-500 hover:scale-[0.9] hover:opacity-70"
                  />

                  <div className="flex items-center gap-1">
                    <div className="flex -space-x-2 items-center">
                      {praiseReport?.recent_likes
                        ?.filter((_filteredItem, index) => index < 4)
                        ?.map((item, idx) => (
                          <img
                            key={idx}
                            src={item?.user?.profile_picture ?? avatar}
                            alt="avatar"
                            className={twMerge(
                              "w-6 h-6 rounded-full object-cover border border-white",
                              !item?.user?.profile_picture & "filter grayscale"
                            )}
                          />
                        ))}
                    </div>
                    {praiseReport?.like_count > 0 && (
                      <p
                        role="button"
                        onClick={onOpen}
                        className="text-xs text-purpleOverlay"
                      >
                        Liked by{" "}
                        <span className="font-semibold">
                          {praiseReport?.recent_likes[0]?.user?.first_name}
                        </span>{" "}
                        {praiseReport?.like_count > 1 ? `and others` : ""}
                      </p>
                    )}
                  </div>
                </div>
                <ShareButton
                  onClick={handleCopy}
                  className="duration-500 hover:scale-[0.9] hover:opacity-70"
                />
              </div>
            </div>
            <form
              onSubmit={postComment}
              className="py-3 flex items-center gap-4"
            >
              <img
                src={user?.profile_picture ?? avatar}
                alt=""
                className={twMerge(
                  "w-9 h-9 rounded-full",
                  !user?.profile_picture && "filter grayscale"
                )}
              />
              <textarea
                name="comment"
                placeholder="Add a comment"
                value={comment}
                onChange={(e) => setComment(e.target?.value)}
                type="text"
                className="h-auto max-h-10 p-2 w-[80%] @md:w-[240px] outline-none rounded bg-[#f4f4f4] duration-300 border border-gray-200 focus:border-gray-400 text-purpleOverlay text-sm resize-none"
                maxLength={1000}
              />
              <button
                type="submit"
                className="outline-none text-purpleBg font-medium text-sm"
              >
                Post
              </button>
            </form>
          </div>
        </div>
      </div>

      {isOpen && (
        <CustomModalHeadless
          {...{
            isOpen,
            onClose,
            title: `Likes (${praiseReport?.like_count})`,
          }}
        >
          {praiseReport?.recent_likes?.map((item, index) => (
            <div
              key={index}
              className="flex items-center w-full gap-2.5 pb-2.5"
            >
              <img
                src={item?.user?.profile_picture ?? avatar}
                alt=""
                className="w-9 h-9 rounded-full"
              />
              <div>
                <p className="text-sm text-purpleOverlay font-medium whitespace-pre-line">
                  {`${item?.user?.first_name} ${item?.user?.last_name}`}
                </p>
              </div>
            </div>
          ))}
        </CustomModalHeadless>
      )}
      {isOpenD && (
        <CustomModalHeadless
          {...{
            isOpen: isOpenD,
            onClose: onCloseD,
            showCloseButton: false,
            dialogClass: "p-0",
          }}
        >
          <div className="w-full flex flex-col divide-y divide-lightGrey">
            <button
              onClick={async () => {
                try {
                  await deleteCaller(commentId);
                  setCommentId(undefined);
                } catch (error) {
                  console.log(error);
                }
              }}
              className="text-red-500 text-sm font-medium w-full py-3 hover:bg-textLightGrey"
            >
              Delete
            </button>
            <button
              onClick={() => setCommentId(undefined)}
              className="text-textDark text-sm font-medium w-full py-3 hover:bg-textLightGrey"
            >
              Cancel
            </button>
          </div>
        </CustomModalHeadless>
      )}
    </div>
  );
};

export default SinglePraiseReport;
