import React, { useRef, useState, useEffect } from "react";
import BaseDashboardNavigation from "../../../../generalComponents/BaseDashboardNavigation";

import Loader from "../../../../generalComponents/Loader";
import { format } from "date-fns";
import useFileUpload from "../../../fileupload/fileUploadController";
import useGetAllEventsManager from "../controller/getAllEventController";
import useCreateEventManager from "../controller/createEventController";
import useEditEventManager from "../controller/editEventController";

import useGetCategoryListManager from "../../categories/controllers/getCategoryListController";

import AdminTopPage from "generalComponents/AdminTopPage";
import GlobalVariables from "constants/GlobalVariables";
import ViewEvents from "../components/ViewEvents";
import AddEditEvents from "../components/AddEditEvents";
import AdminDeleteConfirmationModal from "generalComponents/AdminDeleteConfirmationModal";
import { DeleteEventManager } from "../controller/deleteEventController";
import useGetCategories from "modules/pageSermons/controllers/get_categories_controller";
import AdminWrapper from "modules/admin/AdminWrapper";

const AdminEventPage = () => {
  const [searchValue, setSearchValue] = useState("");
  const [selected, setSelected] = useState(null);
  const { deleteEvent, isLoading: deleting } = DeleteEventManager(selected?.id);

  const { isLoading, data } = useGetAllEventsManager(searchValue);

  const { data: categories } = useGetCategories({
    group: "event",
  });

  useEffect(() => {
    if (selected) {
      document.getElementById("view_event").showModal();
    }
  }, [selected]);

  return (
    <AdminWrapper title={"Events"}>
      <AdminTopPage
        title={"Events"}
        subtitle={"30 Events in total"}
        buttonText={"+Add Event"}
        onclickButton={() => {
          setSelected(null);
          document.getElementById("add_event").showModal();
        }}
        {...{ searchValue, setSearchValue }}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <div className="w-full flex gap-5 flex-wrap">
          {data?.data?.map((el, i) => (
            <div
              onClick={() => {
                setSelected(el);
                if (el?.id === selected?.id) {
                  document.getElementById("view_event").showModal();
                }
              }}
              className="max-w-[351px] w-full cursor-pointer"
            >
              <div className="w-full rounded-[20px] h-[180px] relative">
                <img
                  src={
                    el.cover_image?.thumbnail
                      ? el?.cover_image?.thumbnail
                      : GlobalVariables.defaultProfilePicture
                  }
                  alt=""
                  className="object-cover rounded-[20px] w-full h-full"
                />
              </div>
              <p className="truncate1 text-[15px] font-semibold text-purpleOverlay">
                {el?.name}
              </p>

              <p className="text-[12px] truncate2 text-textGrey font-light">
                {el?.description}
              </p>
            </div>
          ))}
        </div>
      )}
      {selected && <ViewEvents details={selected} />}
      <AddEditEvents
        categories={categories?.data?.categories}
        details={selected}
      />
      <AdminDeleteConfirmationModal
        title={"Delete Event"}
        body={`Do you really want to delete ${selected?.name} from the list of events?`}
        buttonText={`Yes, Delete`}
        onClick={async () => {
          const details = {
            campusIds: [selected?.id],
          };
          await deleteEvent(details);
          document.getElementById("admin_delete").close();
          document.getElementById("view_event").close();
        }}
        isLoading={deleting}
      />
    </AdminWrapper>
  );
};

export default AdminEventPage;
