import { useMutation, useQueryClient } from "react-query";

import Axios from "../../../constants/api_management/MyHttpHelper";
import { useNavigate } from "react-router-dom";
import { useUserStore } from "store/userStore";

const useLoginManager = (email) => {
  const userStore = useUserStore((state) => state);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const pathname = localStorage.getItem("pathname");
  let statusCode = null;
  const loginController = async (details) => {
    try {
      const [response] = [await Axios.post(`/auth/login`, details)];
      // console.log(`i am checking this ${response.status}`);

      return response.data;
    } catch (error) {
      console.log(error.response.data);
      statusCode = error.response.status;
      throw new Error(`Sorry: ${error.response.data.message}`);
    }
  };

  const mutation = useMutation(loginController, {
    onSuccess: async (data) => {
      const token = data.data.token;
      userStore.setUser(data?.data?.user);
      localStorage.setItem("token", token);

      await new Promise((resolve) => {
        // Check for token in localStorage every 100 milliseconds
        const intervalId = setInterval(() => {
          if (localStorage.getItem("token") === token) {
            clearInterval(intervalId);
            resolve();
          }
        }, 100);
      });
      // console.log(`this is the token ${localStorage.getItem("token")} `);
      navigate(
        pathname ? pathname : `/`,
        { state: "login" }
        // data.data.user.roles[0].name === "super-admin"
        //   ? `/admin/dashboard`
        //   : data.data.user.first_name === "" || data.data.user.username === ""
        //   ? `/dashboard/settings`
        //   : `/dashboard`
      );
    },

    onError: (error) => {
      // Handle error if necessary
      console.log(`this is the status code for error: ${statusCode}`);
      if (statusCode === 402) {
        navigate(`/registration-confirmation?email=${email}`);
      }
      console.error("Signup error:", error.message);
    },
  });

  const postCaller = async (details) => {
    try {
      await mutation.mutateAsync(details);
    } catch (error) {
      // Handle error if necessary
      console.error("signup error:", error);
    }
  };

  return {
    postCaller,
    data: mutation.data,
    isLoading: mutation.isLoading,
    isSuccess: mutation.isSuccess,
    error: mutation.error,
  };
};

export default useLoginManager;
