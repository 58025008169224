import React, { useEffect, useRef, useState } from "react";
import BaseDashboardNavigation from "../../../../generalComponents/BaseDashboardNavigation";

import useGetGalleryListManager from "../controller/getGalleryListController";

import useDeleteGalleryManager, {
  DeleteGalleryManager,
} from "../controller/deleteGalleryController";

import GalleryCard from "../components/GalleryCard";
import Loader from "generalComponents/Loader";
import AdminHeaderTop from "generalComponents/AdminHeaderTop";
import AdminTopPage from "generalComponents/AdminTopPage";
import AddEditGallery from "../components/AddEditGallery";
import useGetCategoryListManager from "modules/admin/categories/controllers/getCategoryListController";
import TabsManagement from "generalComponents/TabsManagement";
import TablesComponent from "generalComponents/TablesComponent";
import useGetGalleryCategoriesManager from "../controller/getGalleryCategoriesController";
import { formatDate } from "utils/formatDate";
import AddEditGalleryCategory from "../components/AddEditGalleryCategory";
import AdminWrapper from "modules/admin/AdminWrapper";
import DataTable from "generalComponents/DataTable";
import { modifyData, validTableKeys } from "../utils";

const GalleryListPage = () => {
  const [selectedGallery, setSelectedGallery] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [tableValues, setTableValues] = useState({
    pageIndex: 0,
    perPage: 20,
  });
  function setter(name, value) {
    setTableValues((val) => ({
      ...val,
      [name]: value,
    }));
  }

  const { isLoading, data } = useGetGalleryListManager();
  const { data: categories, isLoading: loadingCategories } =
    useGetGalleryCategoriesManager({ page: "1", enabled: true });

  const tableHeads = ["Name", "Creation Date", "Action"];
  const getFormattedValue = (el, index) => {
    return [el?.name, formatDate(el?.createdAt)];
  };

  const { deleteGallery } = DeleteGalleryManager();

  const statuses = ["Galleries", "Categories"];

  function onRowClick(rowData) {
    setSelectedCategory(rowData);
    document.getElementById("add_galleryCategory").showModal();
  }
  return (
    <AdminWrapper title={"Gallery"}>
      <AdminTopPage
        subtitle={"30 albums in total"}
        title={"Gallery"}
        buttonText={currentIndex === 0 ? "+Add Gallery" : "+Create Category"}
        onclickButton={() => {
          if (currentIndex === 0) {
            setSelectedGallery(null);
            document.getElementById("add_gallery").showModal();
          } else {
            setSelectedCategory(null);
            document.getElementById("add_galleryCategory").showModal();
          }
        }}
      />
      <div className="w-full my-3">
        <TabsManagement
          options={statuses}
          currentView={currentIndex}
          setCurrentView={setCurrentIndex}
        />
      </div>
      {currentIndex === 0 && (
        <div className="p-4 md:p-5 flex flex-col lg:flex-row items-center lg:items-start ">
          <div class="grid grid-cols-1 gap-6  sm:grid-cols-2 lg:h-[70vh] overflow-y-auto w-full scrollbar-hide  lg:grid-cols-3 2xl:grid-cols-4 lg:mr-10">
            {isLoading ? (
              <Loader />
            ) : (
              data?.data.map((gallery, index) => (
                <GalleryCard
                  gallery={gallery}
                  key={index}
                  deleteCaller={deleteGallery}
                  onClick={() => {
                    setSelectedGallery(gallery);
                    document.getElementById("add_gallery").showModal();
                  }}
                />
              ))
            )}
          </div>
        </div>
      )}
      {currentIndex === 1 && (
        <div className="md:h-[67vh] relative md:overflow-y-auto scrollbar-hide">
          {loadingCategories ? (
            <Loader isSmall />
          ) : (
            <DataTable
              tableData={modifyData(categories?.data?.categories)}
              totalItemCount={data?.data?.length}
              validTableKeys={validTableKeys}
              onRowClick={onRowClick}
              noOfItemsPerPage={tableValues?.perPage}
              onCheckClick={() => null}
              onItemsPerPageChange={(newPage) => setter("perPage", newPage)}
              pageIndex={tableValues?.pageIndex}
              onPageIndexChange={(idx) => setter("pageIndex", idx)}
              showFooter
            />
          )}
          {/* <TablesComponent
            data={categories?.data?.categories}
            headers={tableHeads}
            isLoading={loadingCategories}
            getFormattedValue={getFormattedValue}
            buttonFunction={(val) => {
              console.log(val);
              setSelectedCategory(val);
              document.getElementById("add_galleryCategory").showModal();
            }}
          /> */}
        </div>
      )}
      <AddEditGallery
        details={selectedGallery}
        categories={categories?.data?.categories}
      />
      <AddEditGalleryCategory details={selectedCategory} />
    </AdminWrapper>
  );
};

export default GalleryListPage;
