// import BaseDashboardNavigation from "generalComponents/BaseDashboardNavigation";
import React from "react";
import AdminWrapper from "./AdminWrapper";

const AdminDashboard = () => {
  return (
    <AdminWrapper title={"Admin Dashboard"}>
      {" "}
      <div>AdminDashboard will be updated soon</div>
    </AdminWrapper>
  );
};

export default AdminDashboard;
