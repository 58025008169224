import { zenithLogo } from "assets/icons";
import { accessBankLogo, gtcoLogo } from "assets/images";
import { RoundedButton } from "generalComponents/Button";
import ModalManagement from "generalComponents/CustomModal";
import { useState } from "react";
import { firstFruitLinks } from "../giveLinks";

function returnBankLogo(bank) {
  if (bank?.toLowerCase()?.includes("zenith")) return zenithLogo;
  if (bank?.toLowerCase()?.includes("access")) return accessBankLogo;
  if (bank?.toLowerCase()?.includes("gt")) return gtcoLogo;
  return "";
}

const NigeriaIcon = () => (
  <svg
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.03027 16.5C1.03027 23.2547 5.36152 28.9781 11.3428 31.0922V1.90784C5.36152 4.0219 1.03027 9.74534 1.03027 16.5Z"
      fill="#009A49"
    />
    <path
      d="M31.9678 16.5C31.9678 9.74534 27.6881 4.0219 21.6553 1.90784V31.0922C27.6881 28.9781 31.9678 23.2547 31.9678 16.5Z"
      fill="#009A49"
    />
    <path
      d="M11.3447 31.0922C12.9432 31.6594 14.6963 31.9688 16.501 31.9688C18.3057 31.9688 20.0588 31.6594 21.6572 31.0922V1.90781C20.0588 1.34063 18.3057 1.03125 16.501 1.03125C14.6963 1.03125 12.9432 1.34063 11.3447 1.90781V31.0922Z"
      fill="#F9F9F9"
    />
  </svg>
);

const UsIcon = () => (
  <svg
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_814_18043)">
      <path
        d="M16.5 33C25.6127 33 33 25.6127 33 16.5C33 7.3873 25.6127 0 16.5 0C7.3873 0 0 7.3873 0 16.5C0 25.6127 7.3873 33 16.5 33Z"
        fill="#F0F0F0"
      />
      <path
        d="M15.7842 16.4999H33.0015C33.0015 15.0107 32.803 13.568 32.4331 12.1956H15.7842V16.4999Z"
        fill="#D80027"
      />
      <path
        d="M15.7842 7.89129H30.5798C29.5697 6.24309 28.2783 4.78626 26.7725 3.58691H15.7842V7.89129Z"
        fill="#D80027"
      />
      <path
        d="M16.5005 33C20.3837 33 23.9529 31.6578 26.7715 29.413H6.22949C9.04803 31.6578 12.6172 33 16.5005 33Z"
        fill="#D80027"
      />
      <path
        d="M2.42171 25.1087H30.5782C31.3891 23.7855 32.0179 22.3392 32.4315 20.8043H0.568359C0.981955 22.3392 1.61082 23.7855 2.42171 25.1087Z"
        fill="#D80027"
      />
      <path
        d="M7.64311 2.57671H9.14674L7.7481 3.59281L8.28236 5.23695L6.88379 4.22084L5.48522 5.23695L5.9467 3.81659C4.71526 4.84236 3.63593 6.04416 2.74648 7.38323H3.22826L2.33797 8.03002C2.19927 8.26141 2.06624 8.49647 1.93875 8.73501L2.36388 10.0435L1.57072 9.4672C1.37356 9.88492 1.19322 10.312 1.03112 10.7481L1.4995 12.1898H3.22826L1.82963 13.2059L2.36388 14.85L0.965314 13.8339L0.127553 14.4426C0.0436992 15.1166 0 15.8032 0 16.5H16.5C16.5 7.38736 16.5 6.31305 16.5 0C13.2405 0 10.202 0.945527 7.64311 2.57671ZM8.28236 14.85L6.88379 13.8339L5.48522 14.85L6.01947 13.2059L4.62084 12.1898H6.3496L6.88379 10.5456L7.41797 12.1898H9.14674L7.7481 13.2059L8.28236 14.85ZM7.7481 8.39934L8.28236 10.0435L6.88379 9.02737L5.48522 10.0435L6.01947 8.39934L4.62084 7.38323H6.3496L6.88379 5.7391L7.41797 7.38323H9.14674L7.7481 8.39934ZM14.2008 14.85L12.8023 13.8339L11.4037 14.85L11.9379 13.2059L10.5393 12.1898H12.2681L12.8023 10.5456L13.3364 12.1898H15.0652L13.6666 13.2059L14.2008 14.85ZM13.6666 8.39934L14.2008 10.0435L12.8023 9.02737L11.4037 10.0435L11.9379 8.39934L10.5393 7.38323H12.2681L12.8023 5.7391L13.3364 7.38323H15.0652L13.6666 8.39934ZM13.6666 3.59281L14.2008 5.23695L12.8023 4.22084L11.4037 5.23695L11.9379 3.59281L10.5393 2.57671H12.2681L12.8023 0.932572L13.3364 2.57671H15.0652L13.6666 3.59281Z"
        fill="#0052B4"
      />
    </g>
    <defs>
      <clipPath id="clip0_814_18043">
        <rect width="33" height="33" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const UkIcon = () => (
  <svg
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_814_18051)">
      <path
        d="M11.0029 32.065V24.475L5.33789 28.655C6.93289 30.14 8.85789 31.295 11.0029 32.065Z"
        fill="#252F6C"
      />
      <path
        d="M22.001 32.065C24.146 31.295 26.071 30.14 27.666 28.655L22.001 24.42V32.065Z"
        fill="#252F6C"
      />
      <path
        d="M0.9375 22C1.1025 22.55 1.3225 23.045 1.5975 23.595L3.7425 22H0.9375Z"
        fill="#252F6C"
      />
      <path
        d="M29.2627 22L31.4077 23.595C31.6277 23.1 31.8477 22.55 32.0677 22H29.2627Z"
        fill="#252F6C"
      />
      <path
        d="M11.8251 19.8H0.330078C0.495078 20.57 0.715078 21.285 0.935078 22H3.74008L1.59508 23.595C2.03508 24.53 2.53008 25.355 3.13508 26.18L8.80008 22H11.0001V23.1L4.56508 27.83L5.33508 28.6L11.0001 24.475V32.065C11.7151 32.34 12.4301 32.505 13.2001 32.67V19.8H11.8251Z"
        fill="white"
      />
      <path
        d="M32.6708 19.8H19.8008V32.67C20.5708 32.505 21.2858 32.285 22.0008 32.065V24.475L27.6658 28.6C28.4358 27.885 29.0958 27.115 29.7558 26.29L23.8708 22H27.6108L30.9658 24.475C31.1308 24.2 31.2958 23.87 31.4058 23.595L29.2608 22H32.0658C32.2858 21.285 32.5058 20.57 32.6708 19.8Z"
        fill="white"
      />
      <path
        d="M3.1377 26.1799C3.5777 26.7849 4.0177 27.3349 4.5127 27.8849L11.0027 23.1549V22.0549H8.8027L3.1377 26.1799Z"
        fill="#E51D35"
      />
      <path
        d="M23.9268 21.9999L29.8118 26.2899C30.0318 26.0149 30.1968 25.7399 30.4168 25.4649C30.4718 25.4099 30.4718 25.3549 30.5268 25.3549C30.6918 25.0799 30.9118 24.7499 31.0768 24.4749L27.6118 21.9999H23.9268Z"
        fill="#E51D35"
      />
      <path
        d="M22.001 0.934937V8.52494L27.666 4.34494C26.071 2.85994 24.146 1.70494 22.001 0.934937Z"
        fill="#252F6C"
      />
      <path
        d="M11.0029 0.934937C8.85789 1.70494 6.93289 2.85994 5.33789 4.34494L11.0029 8.57994V0.934937Z"
        fill="#252F6C"
      />
      <path
        d="M32.0677 10.9999C31.9027 10.4499 31.6827 9.95491 31.4077 9.40491L29.2627 10.9999H32.0677Z"
        fill="#252F6C"
      />
      <path
        d="M3.7425 10.9999L1.5975 9.40491C1.3775 9.95491 1.1575 10.4499 0.9375 10.9999H3.7425Z"
        fill="#252F6C"
      />
      <path
        d="M21.1758 13.2H32.6158C32.4508 12.43 32.2308 11.715 32.0108 11H29.2058L31.3508 9.40496C30.9108 8.46996 30.4158 7.64496 29.8108 6.81996L24.2008 11H22.0008V9.89996L28.4358 5.16996L27.6658 4.39996L22.0008 8.52496V0.934956C21.2858 0.659956 20.5708 0.494956 19.8008 0.329956V13.2H21.1758Z"
        fill="white"
      />
      <path
        d="M0.330078 13.2H13.2001V0.329956C12.4301 0.494956 11.7151 0.714956 11.0001 0.934956V8.52496L5.33508 4.39996C4.56508 5.11496 3.90508 5.88496 3.24508 6.70996L9.13008 11H5.39008L2.03508 8.52496C1.87008 8.79996 1.70508 9.12996 1.59508 9.40496L3.74008 11H0.935078C0.715078 11.715 0.495078 12.43 0.330078 13.2Z"
        fill="white"
      />
      <path
        d="M29.866 6.81999C29.426 6.21499 28.986 5.66499 28.491 5.11499L22.001 9.84499V10.945H24.201L29.866 6.81999Z"
        fill="#E51D35"
      />
      <path
        d="M9.07449 11L3.24449 6.70996C3.02449 6.98496 2.85949 7.25996 2.63949 7.53496C2.58449 7.58996 2.58449 7.64496 2.52949 7.64496C2.36449 7.91996 2.14449 8.24996 1.97949 8.52496L5.33449 11H9.07449Z"
        fill="#E51D35"
      />
      <path
        d="M32.67 13.2H19.8V0.33C18.755 0.11 17.655 0 16.5 0C15.345 0 14.245 0.11 13.2 0.33V13.2H0.33C0.11 14.245 0 15.345 0 16.5C0 17.655 0.11 18.755 0.33 19.8H13.2V32.67C14.245 32.89 15.345 33 16.5 33C17.655 33 18.755 32.89 19.8 32.67V19.8H32.67C32.89 18.755 33 17.655 33 16.5C33 15.345 32.89 14.245 32.67 13.2Z"
        fill="#E51D35"
      />
    </g>
    <defs>
      <clipPath id="clip0_814_18051">
        <rect width="33" height="33" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const ChevronRight = ({ className }) => (
  <svg
    width="9"
    height="16"
    viewBox="0 0 9 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M1 1L6.81206 6.81206C7.46814 7.46814 7.46814 8.53186 6.81206 9.18794L1 15"
      stroke="#8311B3"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

const CurrencyOption = ({ icon, currency, state, onClick }) => (
  <div
    onClick={onClick}
    data-state={state}
    role="button"
    className="group p-2.5 flex items-center justify-between w-[270px] rounded-[10px] data-[state=active]:bg-[#FFA72633] "
  >
    <p className="flex items-center gap-5 text-purpleOverlay font-medium">
      {icon}
      <span>{currency} Payment</span>
    </p>

    <ChevronRight className="group-hover:animate-bounce-forward" />
  </div>
);

const FirstFruitModal = ({ id, onClose, data, links }) => {
  const [activeTab, setActiveTab] = useState("1");
  const [copyText, setCopyText] = useState("Copy Account Number");
  const onCopy = (e, data) => {
    e.preventDefault();
    e.stopPropagation();
    navigator.clipboard.writeText(data);
    setCopyText("Copied!");

    setTimeout(() => {
      setCopyText("Copy Account Number");
    }, 5000);
  };

  const onChange = (e) => {
    const value = e.target?.value;
    if (value === "USD") {
      window.open(firstFruitLinks?.USD?.link, "_blank");
      window[id]?.close();
    } else if (value === "GBP") {
      window.open(firstFruitLinks?.GBP?.link, "_blank");
      window[id]?.close();
    }
  };
  return (
    <ModalManagement id={id} className={"max-w-fit"} onClose={onClose}>
      <div className=" lg:flex lg:divide-x">
        {/* {links && ( */}
        <select
          name=""
          onChange={onChange}
          id=""
          className="bg-white shadow-md rounded-xl px-4 py-2 text-purpleOverlay border border-purpleOverlay  outline-none font-bold text-lg lg:hidden -translate-y-10"
        >
          <option value="NGN">NGN</option>
          <option value="USD">USD</option>
          <option value="GBP">GBP</option>
        </select>
        {/* )} */}
        {/* {links && ( */}
        <div className="p-3 hidden lg:block">
          <CurrencyOption
            icon={<NigeriaIcon />}
            currency={"Naira"}
            state={activeTab === "1" ? "active" : "inactive"}
            onClick={() => setActiveTab("1")}
          />
          <CurrencyOption
            icon={<UsIcon />}
            currency={"Dollar"}
            state={activeTab === "2" ? "active" : "inactive"}
            onClick={() => {
              // setActiveTab("2")
              window.open(firstFruitLinks?.USD?.link, "_blank");
            }}
          />
          <CurrencyOption
            icon={<UkIcon />}
            currency={"Pounds"}
            state={activeTab === "3" ? "active" : "inactive"}
            onClick={() => {
              // setActiveTab("3")
              window.open(firstFruitLinks?.GBP?.link, "_blank");
            }}
          />
        </div>
        {/* )} */}
        <div className="flex flex-col items-center py-5 px-5 lg:px-10 [&>.hide]:text-sm">
          <h3 className="text-purpleOverlay font-semibold text-lg mb-2 lg:mb-8">
            First Fruit (Naira)
          </h3>
          <p className="hide text-center mb-2">
            Kindly send your givings to the Bank Details below
          </p>
          <p className="hide text-[#828282] text-center">
            For God loves a cheerful giver
          </p>
          <p className="text-purpleOverlay font-semibold text-2xl text-center uppercase my-5">
            COZA HQ
          </p>

          <div className="bg-[#FFF7FE] pt-7 pb-12 rounded-[20px] flex flex-col items-center px-5 min-w-[300px]">
            <p className="text-center text-purpleOverlay  font-medium">
              {firstFruitLinks?.NGN?.title}
            </p>
            <p className="mt-6 text-purpleOverlay font-bold text-[30px]">
              {firstFruitLinks?.NGN?.link}
            </p>

            <div className="flex items-center justify-center gap-4 mt-9">
              <img
                src={returnBankLogo(firstFruitLinks?.NGN?.vendor)}
                alt=""
                className="w-9"
              />
              <p className="text-lg font-semibold text-purpleOverlay">
                {firstFruitLinks?.NGN?.vendor}
              </p>
            </div>
          </div>
          <RoundedButton
            onClick={(e) => onCopy(e, firstFruitLinks?.NGN?.link)}
            className="mt-6 w-full py-5 text-white bg-purpleOverlay font-medium rounded-[40px]"
          >
            {copyText}
          </RoundedButton>
        </div>
      </div>
    </ModalManagement>
  );
};

export default FirstFruitModal;
