// import { Fragment } from "react";
import LocalStorageUtils from "./LocalStorageUtils";

export const TableFooterDiv = (props) => {
  const {
    noOfPages,
    pageIndex,
    onBtnClick,
    itemsPerPageChange,
    itemsPerPage,
    showMobileView,
  } = props;
  let lastPageIndex = noOfPages - 1;

  return (
    <div className="flex items-end lg:items-center justify-center flex-col lg:flex-row gap-3 mt-5 space-x-4">
      {!showMobileView && (
        <div className="space-x-4">
          <span className="text-gray-700">Show</span>
          <select
            onChange={(e) => {
              const val = parseInt(e.target.value);
              LocalStorageUtils.setPerPage(val);
              itemsPerPageChange(val);
            }}
            value={itemsPerPage}
            className="p-2 border border-gray-300 rounded-md bg-white"
          >
            <option value={8}>8</option>
            <option value={10}>10</option>
            <option value={15}>15</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
          <span className="text-gray-700 mr-9">Results</span>
        </div>
      )}

      {/* Prev Button */}
      <div className="space-x-4">
        <button
          disabled={pageIndex === 0}
          onClick={() => onBtnClick(pageIndex - 1)}
          className={`p-2 border border-gray-300 rounded-md ${
            pageIndex === 0
              ? "text-gray-400 cursor-not-allowed"
              : "text-gray-700 hover:bg-gray-100"
          }`}
        >
          Prev
        </button>

        {/* Current Page Index */}
        <button
          onClick={() => null}
          className="p-2 border border-gray-300 rounded-md text-gray-700"
        >
          {pageIndex + 1}
        </button>

        {/* Next Button */}
        <button
          onClick={() => {
            onBtnClick(pageIndex + 1);
          }}
          disabled={pageIndex === lastPageIndex}
          className={`p-2 border border-gray-300 rounded-md ${
            pageIndex === lastPageIndex
              ? "text-gray-400 cursor-not-allowed"
              : "text-gray-700 hover:bg-gray-100"
          }`}
        >
          Next
        </button>
      </div>
    </div>
  );
};
