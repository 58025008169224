import AxiosWithToken from "constants/api_management/MyHttpHelperWithToken";
import showToast from "constants/showToast";
import { useMutation, useQueryClient } from "react-query";

export const useDeleteComment = () => {
  const queryClient = useQueryClient();

  const deleteRequest = async (id) => {
    try {
      const response = await AxiosWithToken.delete(`/testimony/comment/${id}`);
      return response.data;
    } catch (error) {
      throw new Error(error?.response?.data?.message);
    }
  };

  const mutation = useMutation({
    mutationKey: ["praise_reports_comments"],
    mutationFn: deleteRequest,
    onSuccess: (data) => {
      showToast("Comment deleted successfully", "success");
      queryClient.invalidateQueries(["praise_reports_comments"]);
      // onSuccess();
    },
    onError: (error) => {
      showToast(error, "error");
    },
  });

  const deleteCaller = async (id) => {
    try {
      const data = await mutation.mutateAsync(id);
      return data;
    } catch (error) {
      throw error;
    }
  };

  return {
    deleteCaller,
    ...mutation,
  };
};
