import React, { useEffect, useState } from "react";
import { homeHeroBg, whiteLogo } from "../../../assets/images";
// import Header from "../../../generalComponents/Header";
// import { RoundedButton } from "../../../generalComponents/Button";
// import { bufferIcon } from "../../../assets/icons";
import { homeHeroVideo } from "assets/videos";
import { twMerge } from "tailwind-merge";

const HomeHero = () => {
  const [videoHero, setVideoHero] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setVideoHero(false);
    }, 26000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className=" w-full bg-cover bg-no-repeat h-[100svh] relative flex flex-col">
      <img
        src={homeHeroBg}
        alt=""
        className={twMerge(
          "absolute inset-0 h-full w-full object-cover blend-overlay"
          // !videoHero && "animate-fade-in"
        )}
      />
      <video
        src={homeHeroVideo}
        autoPlay
        loop={false}
        muted
        className={twMerge(
          "w-full h-full absolute inset-0 bg-neutral-300/80 object-cover pointer-events-none",
          !videoHero && "animate-fade-out hidden"
        )}
      ></video>
      <div
        className={twMerge(
          " absolute w-full h-full top-0 left-0 duration-300",
          videoHero
            ? "bg-transparent"
            : "bg-gradient-to-t from-purpleOverlay to-transparent"
        )}
      ></div>
      {/* <Header /> */}

      <div
        className={twMerge(
          "relative z-10 flex flex-col gap-5 items-center my-auto opacity-0",
          !videoHero && "animate-fade-in"
        )}
      >
        <img src={whiteLogo} alt="" className="w-[126px] h-[126px] " />
        <h1 className="font-bold text-3xl sm:text-5xl lg:text-7xl uppercase text-center select-none">
          Welcome to <br /> The Commonwealth of <br />
          Zion Assembly
        </h1>
        {/* <div className="flex justify-center gap-2.5">
          <RoundedButton url="/login">Get Involved</RoundedButton>
          <RoundedButton
            url="/live-stream"
            className="bg-orangeBg flex items-center gap-1"
          >
            <img src={bufferIcon} alt="" /> <span>Watch LIVE</span>
          </RoundedButton>
        </div> */}
      </div>

      <FloatingText />
    </div>
  );
};

export default HomeHero;

const FloatingText = () => (
  <div className="absolute z-10 bottom-[50%] -left-48 flex items-center gap-4 -rotate-90 opacity-30 lg:opacity-80">
    <div className="flex gap-2">
      {Array.from({ length: 3 }, (_, i) => (
        <div key={i} className="bg-white w-[5px] h-[5px] rounded-full"></div>
      ))}
    </div>
    <hr className="w-24 h-[1px] bg-white" />
    <p className="uppercase text-sm tracking-[0.7em]">We Celebrate You</p>
  </div>
);
