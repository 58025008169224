// import ContainerWithTextStroke from "generalComponents/ContainerWithTextStroke";
import TextStroke from "generalComponents/TextStroke";
import React from "react";
import { twMerge } from "tailwind-merge";

const Tenets = () => {
  return (
    <div>
      <TextStroke className="overflow-hidden max-w-[100svh]">
        THE TENETS OF COZA
      </TextStroke>
      <div className={"px-5 max-w-section mx-auto -mt-32"}>
        <h2 className="text-5xl md:text-6xl lg:text-7xl text-purpleBg text-center mt-20 mb-10">
          The Tenets of COZA
        </h2>
        <div className="flex flex-col items-center ">
          <div className="bg-gray-300 w-3 h-3 rounded-full"></div>
          {tenetsData.map(({ title, text }, index) => (
            <SingleTenet
              key={index}
              title={title}
              text={text}
              index={index}
              reverse={(index + 1) % 2 === 0}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Tenets;

const SingleTenet = ({ reverse, title, text, index }) => {
  return (
    <div
      className={twMerge("grid grid-cols-2", reverse && "translate-x-[2px]")}
    >
      {!reverse && <div></div>}
      <div
        className={twMerge(
          "border-dashed",
          index === 0 ? "pt-10 pb-5" : "py-5",
          reverse
            ? "border-r-2 border-r-gray-300"
            : "grid-start-2 border-l-2 border-l-gray-300"
        )}
      >
        <h3
          className={twMerge(
            "min-h-10 flex items-center text-white w-fit",
            "text-base font-medium py-2.5 mb-3 bg-purpleBg",
            "sm:text-lg md:text-xl",
            reverse
              ? "text-right pl-7 pr-2.5 border-l-[10px] border-l-orangeBg justify-end ml-auto"
              : "text-left pr-7 pl-2.5 border-r-[10px] border-r-orangeBg"
          )}
        >
          {title}
        </h3>
        <p className="text-gray-500 text-sm px-2.5 max-w-[380px]">{text}</p>
      </div>

      {reverse && <div></div>}
    </div>
  );
};

const tenetsData = [
  {
    title: "Righteousness",
    text: "We believe that Righteousness  is a gift and can NEVER be earned through works!",
  },
  {
    title: "The Word of God",
    text: "We believe that the Word of God is the final authority in every situation!",
  },
  {
    title: "Resurrection & Ascension",
    text: "We believe in the death, burial, resurrection and ascension of our Lord Jesus Christ.",
  },
  {
    title: "New Birth",
    text: "We believe in the new birth called the born again experience!",
  },
  {
    title: "Baptism of Holy Spirit",
    text: "We believe in the baptism of the Holy Spirit evidenced by the speaking of other tongues.",
  },
  {
    title: "Water Baptism",
    text: "We believe in the baptism of water by immersion.",
  },
  {
    title: "The Trinity",
    text: "We believe in the Trinity: Father, Son and Spirit",
  },
  {
    title: "Jesus",
    text: "We believe that Jesus is the Son of God and 2nd member of the Godhead!",
  },
  {
    title: "Authority",
    text: "We believe in submission to physical and spiritual authority.",
  },
  {
    title: "Heaven and Hell",
    text: "We believe in Heaven and Hell.",
  },
];
