import React from "react";
import CustomButton from "./Button";
import { deleteIcon, uploaded } from "assets/icons";
import { MdDelete } from "react-icons/md";

const BigUploadFileComponent = ({
  inputRef,
  progress,
  isLoading,
  image,
  accept = "",
  onChange,
  buttonText,
  className,
  buttonClick,
}) => {
  return (
    <div className="mb-6 flex items-center justify-start gap-10">
      <div className="flex items-center justify-start gap-2">
        {
          <div
            onClick={() => inputRef.current.click()}
            className="h-[129px] w-[129px] rounded-[20px] bg-[#FBF2FF] relative border border-dashed border-blackColor flex items-center justify-center"
          >
            {!image ? (
              <svg
                width="31"
                height="31"
                viewBox="0 0 31 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.4252 20.5862L16.2069 18.3864C15.1874 17.3754 14.6776 16.8698 14.0907 16.6802C13.5744 16.5133 13.0187 16.5133 12.5023 16.6802C11.9154 16.8698 11.4056 17.3754 10.3861 18.3864L5.22083 23.5887M18.4252 20.5862L18.8661 20.1488C19.9072 19.1165 20.4276 18.6004 21.0248 18.4121C21.55 18.2463 22.1144 18.2528 22.6357 18.4305C23.2283 18.6327 23.7368 19.1606 24.7538 20.2165L25.8333 21.3126M18.4252 20.5862L23.5342 25.7853M5.22083 23.5887C5.26049 23.925 5.3292 24.1892 5.44824 24.4228C5.6959 24.9089 6.09111 25.3041 6.57719 25.5518C7.12979 25.8333 7.85319 25.8333 9.3 25.8333H21.7C22.5025 25.8333 23.0825 25.8333 23.5342 25.7853M5.22083 23.5887C5.16667 23.1294 5.16667 22.5352 5.16667 21.7V9.3C5.16667 7.8532 5.16667 7.12979 5.44824 6.57719C5.6959 6.0911 6.09111 5.6959 6.57719 5.44824C7.12979 5.16667 7.85319 5.16667 9.3 5.16667H14.2083M23.5342 25.7853C23.8967 25.7467 24.1768 25.6772 24.4228 25.5518C24.9089 25.3041 25.3041 24.9089 25.5518 24.4228C25.8333 23.8703 25.8333 23.1468 25.8333 21.7V16.7917M23.25 11.625V7.75M23.25 7.75V3.875M23.25 7.75H27.125M23.25 7.75H19.375"
                  stroke="#8311B3"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            ) : (
              <img
                src={image}
                className="w-full h-full rounded-[20px] object-cover"
              />
            )}
          </div>
        }
      </div>
      {image ? (
        <MdDelete
          onClick={buttonClick}
          size={30}
          className="cursor-pointer text-red-150"
        />
      ) : (
        // <img src={deleteIcon.src}  role="button" />
        <CustomButton
          buttonText={buttonText}
          isLoading={isLoading}
          radius={"rounded-[40px]"}
          loader={`Loading ${progress}%`}
          onClick={() => inputRef.current.click()}
        />
      )}
      <input
        type="file"
        ref={inputRef}
        className="hidden"
        onChange={onChange}
        accept={accept}
      />
    </div>
  );
};

export default BigUploadFileComponent;
