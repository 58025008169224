import React from "react";
import { twMerge } from "tailwind-merge";

const RadioGroupInput = ({
  name,
  value,
  onChange,
  label,
  labelClass,
  placeholder,
  className,
  options,
  radioLabelClass,
}) => {
  return (
    <fieldset className="flex flex-col mb-4">
      <legend
        className={twMerge("text-sm font-medium mb-2", labelClass)}
        htmlFor={name}
      >
        {label}
      </legend>
      <div className="flex items-center gap-10 flex-wrap">
        {options.map((item, index) => (
          <div key={index} className="flex items-center gap-3">
            <input
              type="radio"
              name={name}
              checked={value === item.value}
              value={item.value}
              onChange={onChange}
              className="custom-radio-input"
            />
            <label htmlFor="" className={radioLabelClass}>
              {item.label}
            </label>
          </div>
        ))}
      </div>
    </fieldset>
  );
};

export default RadioGroupInput;
