const ArrowRight = ({ className }) => (
  <svg
    width="39"
    height="12"
    viewBox="0 0 39 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75L1 5.25ZM38.5303 6.53033C38.8232 6.23744 38.8232 5.76257 38.5303 5.46967L33.7574 0.696702C33.4645 0.403809 32.9896 0.403809 32.6967 0.696702C32.4038 0.989595 32.4038 1.46447 32.6967 1.75736L36.9393 6L32.6967 10.2426C32.4038 10.5355 32.4038 11.0104 32.6967 11.3033C32.9896 11.5962 33.4645 11.5962 33.7574 11.3033L38.5303 6.53033ZM1 6.75L38 6.75L38 5.25L1 5.25L1 6.75Z"
      fill="white"
    />
  </svg>
);

export default ArrowRight;
