import useQueryParams from "constants/useQueryParams";
import React from "react";
import { TABS } from "../view";
import { twMerge } from "tailwind-merge";

const Nav = () => {
  const { params, setParam } = useQueryParams();
  return (
    <div className="flex lg:flex-col gap-3 w-full lg:w-[25%] px-5 lg:px-0">
      <button
        onClick={() => setParam("tab", TABS.EDIT)}
        className={twMerge(
          "py-2.5 px-6 text-sm text-textGrey bg-textLightGrey rounded-md font-semibold",
          params?.tab === TABS.EDIT ? "bg-orangeBg text-white" : ""
        )}
      >
        Edit Profile
      </button>
      <button
        onClick={() => setParam("tab", TABS.PASSWORD)}
        className={twMerge(
          "py-2.5 px-6 text-sm text-textGrey bg-textLightGrey rounded-md font-semibold",
          params?.tab === TABS.PASSWORD ? "bg-orangeBg text-white" : ""
        )}
      >
        Change Password
      </button>
    </div>
  );
};

export default Nav;
