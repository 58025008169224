import React, { useEffect, useState } from "react";
import BaseDashboardNavigation from "../../../../generalComponents/BaseDashboardNavigation";
import { Pagination } from "@mui/material";
import useAllUsersManager from "../controllers/getAllUsersController";
import Loader from "../../../../generalComponents/Loader";
// import useGetAnalytics from "../../profiles/controllers/getAnalytics";
import ErrorManager from "../../../../generalComponents/ErrorManager";
import FullAllUsersTable from "../../../../generalComponents/FullAllUsersTable";
import PaginationRounded from "../../../../generalComponents/Pagination";
import useGetUserTotalsManager from "../../sermons/controllers/getUserTotalsController";
import CustomButton from "../../../../generalComponents/Button";
import useDownloadAllUserDataManager from "../controllers/downloadUserDataController";
import { toast } from "react-toastify";
import SearchComponent from "generalComponents/SearchComponent";
import InputWithFullBoarder from "generalComponents/InputWithFullBoarder";
import TablesComponent from "generalComponents/TablesComponent";
import GlobalVariables from "constants/GlobalVariables";
import { formatDate } from "utils/formatDate";
import useDebounce from "utils/UseDebounce";
import { useNavigate } from "react-router-dom";
import AvatarWithTitle from "generalComponents/AvatarWithTitle";
import AdminWrapper from "modules/admin/AdminWrapper";
import DataTable from "generalComponents/DataTable";
import { modifyData, validTableKeys } from "../utils";

const UserManagementPage = () => {
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(`&first_name=${searchValue}`, 1000);
  const [currentPage, setCurrentPage] = useState(1);
  const [runDownload, setRunDownload] = useState(false);
  const navigate = useNavigate();
  const [tableValues, setTableValues] = useState({
    pageIndex: 0,
    perPage: 20,
  });
  function setter(name, value) {
    setTableValues((val) => ({
      ...val,
      [name]: value,
    }));
  }

  const {
    data: userData,
    refetch: refetchData,
    isLoading: downloadingData,
    isSuccess: downloaded,
    Error: downloadError,
  } = useDownloadAllUserDataManager({
    enabled: runDownload,
  });

  const tableHeads = [
    "Name",
    "Email",
    "Phone number",
    "Church",
    "Join Date",
    "Action",
  ];
  const getFormattedValue = (el, index) => {
    return [
      <AvatarWithTitle
        image={el.profile_picture}
        title={`${el?.first_name} ${el?.last_name}`}
      />,
      el?.email,
      el?.phone,
      el?.campus?.name,
      formatDate(el?.createdAt),
    ];
  };

  const { data, isLoading } = useAllUsersManager({
    page: tableValues?.pageIndex + 1,
    pageSize: tableValues?.perPage,
    searchQuery: searchValue ? debouncedSearchValue : "",
    enabled: true,
  });

  function onRowClick(rowData) {
    navigate(`/admin/user-details/${rowData?.id}`);
  }

  return (
    <AdminWrapper
      title={"Users"}
      value={searchValue}
      onChange={(e) => {
        setSearchValue(e.target.value);
      }}
    >
      <div className="flex flex-col h-[90vh]">
        <div className="flex justify-between flex-col gap-5 md:flex-row w-full mb-10">
          <div className=" flex flex-col items-start">
            <p className="text-[24px] font-semibold">Users</p>
            <p className="text-14px">{data?.pagination?.total} Total Data</p>
          </div>
          <div className="mb-3">
            <CustomButton
              onClick={async () => {
                setRunDownload(true);
                await refetchData();
                if (downloaded) {
                  window.open(userData?.data?.url, "_blank");
                  setRunDownload(false);
                  // downloadFile(userData?.data?.url);
                } else {
                  toast.error(downloadError.message);
                }
              }}
              isLoading={downloadingData}
              buttonText={`Download User Data`}
              radius={`[5px]`}
              className={`max-w-max`}
            />
          </div>
        </div>
        <div className="flex items-center justify-between flex-col gap-5 md:flex-row">
          <div className="max-w-[291px]">
            <SearchComponent
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
          <InputWithFullBoarder type={"date"} />
        </div>

        {/* user tables */}
        <div className="md:h-[67vh] relative md:overflow-y-auto scrollbar-hide">
          {isLoading ? (
            <Loader isSmall />
          ) : (
            <DataTable
              tableData={modifyData(data?.users)}
              totalItemCount={data?.pagination?.total}
              validTableKeys={validTableKeys}
              onRowClick={onRowClick}
              noOfItemsPerPage={tableValues?.perPage}
              onCheckClick={() => null}
              onItemsPerPageChange={(newPage) => setter("perPage", newPage)}
              pageIndex={tableValues?.pageIndex}
              onPageIndexChange={(idx) => setter("pageIndex", idx)}
              showFooter
            />
          )}
          {/* <TablesComponent
            data={data?.users}
            headers={tableHeads}
            isLoading={isLoading}
            getFormattedValue={getFormattedValue}
            buttonFunction={(val) => {
              console.log(val);
              navigate(`/admin/user-details/${val?.id}`);
            }}
          /> */}
        </div>

        {/* {data?.users?.length && (
          <div className="mb-10 flex items-center justify-center">
            <PaginationRounded
              count={data?.pagination?.pageTotal}
              defaultPage={data?.pagination?.currentPage}
              onChange={(page) => {
                setCurrentPage(page);
              }}
            />
          </div>
        )} */}
      </div>
    </AdminWrapper>
  );
};

export default UserManagementPage;
