import { useEffect, useState } from "react";

const useWindowVisibility = () => {
  const [visible, setVisible] = useState(true);

  const handleVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      setVisible(true);
      //   console.log("Window is focused");
    } else {
      setVisible(false);
      //   console.log("Window is not focused");
    }
  };

  const handleFocus = () => {
    setVisible(true);
    // console.log("Window is focused");
  };

  const handleBlur = () => {
    setVisible(false);
    // console.log("Window is not focused");
  };

  useEffect(() => {
    let visibilityChange;
    if (typeof document.hidden !== "undefined") {
      visibilityChange = "visibilitychange";
    } else if (typeof document.mozHidden !== "undefined") {
      visibilityChange = "mozvisibilitychange";
    } else if (typeof document.msHidden !== "undefined") {
      visibilityChange = "msvisibilitychange";
    } else if (typeof document.webkitHidden !== "undefined") {
      visibilityChange = "webkitvisibilitychange";
    }

    // Listen to visibilitychange and focus/blur
    if (typeof window !== "undefined" && window.addEventListener) {
      document.addEventListener(
        visibilityChange,
        handleVisibilityChange,
        false
      );
      window.addEventListener("focus", handleFocus, false);
      window.addEventListener("blur", handleBlur, false);
    }

    return () => {
      // Be sure to unsubscribe if a new handler is set
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("focus", handleFocus);
      window.removeEventListener("blur", handleBlur);
    };
  }, []);

  return visible;
};

export default useWindowVisibility;
