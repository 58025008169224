import React, { useState } from "react";

import { useNavigate, useParams } from "react-router-dom";

import useGetUserFormsManager from "../../familyProcesses/controllers/getUserFormsController";
import TablesComponent from "generalComponents/TablesComponent";
import StatusButton from "generalComponents/StatusButton";
import { formatDate } from "utils/formatDate";
import { camelCaseTransform } from "utils/camelCaseTransform";
import PaginationRounded from "generalComponents/Pagination";

const UserSubmittedForms = ({ userId }) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);

  const { isLoading, data } = useGetUserFormsManager({
    userId: userId,
  });
  const { formType, formSlug } = useParams();
  const tableHeads = ["Form", "Status", "Date", "Action"];
  const getFormattedValue = (el, index) => {
    return [
      el?.type,
      <StatusButton status={el?.approved ? "approved" : "unknown"} />,
      formatDate(el?.createdAt),
    ];
  };

  return (
    <div className="md:h-[70vh] relative md:overflow-y-auto scrollbar-hide">
      <TablesComponent
        data={data?.data?.forms}
        headers={tableHeads}
        getFormattedValue={getFormattedValue}
        isLoading={isLoading}
        buttonFunction={(val) => {
          navigate(
            `/admin/form-details/${formType ?? camelCaseTransform(val.type)}/${
              formSlug ?? val.type
            }/${val.form}`
          );
        }}
      />
      {data?.data?.forms && (
        <div className="mb-10 flex items-center justify-center">
          <PaginationRounded
            count={data?.pagination?.pageTotal}
            defaultPage={data?.pagination?.currentPage}
            onChange={(page) => {
              setCurrentPage(page);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default UserSubmittedForms;
