import React, { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

const FormatButton = ({ children, command, title, focus }) => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    const checkState = () => {
      try {
        const active = document.queryCommandState(command);
        setActive(active);
      } catch (e) {
        console.error("Error checking command state:", e);
      }
    };

    // Check initial state
    checkState();

    // Add event listeners for selection changes
    document.addEventListener("selectionchange", checkState);
    return () => document.removeEventListener("selectionchange", checkState);
  }, [command]);

  const handleFormat = () => {
    document.execCommand(command, false, null);
    focus();
  };
  return (
    <button
      variant="ghost"
      size="icon"
      onClick={handleFormat}
      title={title}
      className={twMerge(
        "grid place-content-center rounded-md p-2 duration-200",
        active ? "text-white bg-purpleBg" : "bg-transparent text-purpleBg"
      )}
    >
      {children}
    </button>
  );
};

export default FormatButton;
