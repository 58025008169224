import HeaderFooter from "generalComponents/HeaderFooter";
import TabComponent from "generalComponents/TabComponent";
import TextGradient from "generalComponents/TextGradient";
import CreatePraiseReport from "../components/CreatePraiseReport";
import GeneralPraiseReports from "../components/GeneralPraiseReports";
import UserPraiseReport from "../components/UserPraiseReport";
import CreateButton from "../components/CreateButton";
import { useCallback, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import useWindowVisibility from "constants/useWindowVisibility";

const tabs = ["Everyone", "My Reports"];

const PraiseReports = () => {
  const [pageBottom, setPageBottom] = useState(false);
  const observer = useRef();
  const visible = useWindowVisibility();
  const tabContent = [
    <GeneralPraiseReports visible={visible} />,
    <UserPraiseReport visible={visible} />,
  ];

  const lastElementRef = useCallback((node) => {
    // if (isLoading) return;

    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setPageBottom(true);
      } else {
        setPageBottom(false);
      }
    });

    if (node) observer.current.observe(node);
  }, []);

  return (
    <HeaderFooter>
      <TextGradient>Praise Reports</TextGradient>

      <div className="pt-[300px] pb-20 max-w-section mx-auto lg:px-5 relative">
        <h1 className="font-semibold text-[52px] lg:text-[60px] lg:leading-[70px] text-purpleOverlay mb-3 px-5">
          Praise Reports
        </h1>

        <TabComponent
          tabContainer={
            "!max-w-[697px] mx-auto md:border-x md:border-x-gray-300 "
          }
          tabButtonContainer={"flex items-center justify-between px-16"}
          tabButtons={tabs}
          tabContent={tabContent}
        />

        <button
          onClick={() => window["create_praise_report"]?.showModal()}
          className={twMerge(
            "right-4 bottom-4  z-20",
            pageBottom ? "absolute" : "fixed"
          )}
        >
          <CreateButton />
        </button>
        <div ref={lastElementRef}></div>
      </div>

      <CreatePraiseReport id={"create_praise_report"} />
    </HeaderFooter>
  );
};

export default PraiseReports;
