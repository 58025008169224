import HeaderFooter from "generalComponents/HeaderFooter";
import TabComponent from "generalComponents/TabComponent";
import React, { useEffect, useState } from "react";
import FpItem, { fpList } from "../components/FpItem";
import TextGradient from "generalComponents/TextGradient";
import { useGetFamilyProcessSubmissions } from "../controllers/get_controller";
import { formatDistance } from "date-fns";
import useQueryParams from "constants/useQueryParams";
import Loader from "generalComponents/Loader";
import { formTypes } from "../utils";
import ModalManagement from "generalComponents/CustomModal";
import EmptyState from "generalComponents/EmptyState";
import EditIcon from "assets/icons/EditIcon";

function capitalizeAndReplaceHyphen(str) {
  if (!str) return "";
  if (str === "marital-class") return "Pre-Marital Class";
  if (str === "whistle-blower") return "Whistle Blowing";
  return str
    .split("-") // Split the string by hyphen
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
    .join(" "); // Join the words with a space
}

const tabs = ["New Submissions", "My Submissions"];

const FamilyProcess = () => {
  const { params, setParam, setParams, deleteParam } = useQueryParams({
    tab: "new",
  });
  const [tabIdx, setTabIdx] = useState(0);

  const { data, isLoading } = useGetFamilyProcessSubmissions();

  const tabUpdate = (tab) => {
    setParam("tab", tab?.split(" ")?.[0]?.toLowerCase());
  };

  const tabContent = [
    <div className="flex flex-col gap-2.5">
      {fpList.map((item, index) => (
        <FpItem
          key={index}
          icon={item?.icon}
          name={item?.name}
          onClick={() => {
            deleteParam("form");
            setParam("type", item?.slug);
          }}
        />
      ))}
    </div>,
    isLoading ? (
      <Loader />
    ) : data?.data?.forms?.length > 0 ? (
      <div className="flex flex-col gap-2.5">
        {data?.data?.forms?.map((item, index) => (
          <FpItem
            key={index}
            createdAt={formatDistance(new Date(item?.createdAt), new Date(), {
              addSuffix: true,
            })}
            name={capitalizeAndReplaceHyphen(item?.type)}
            onClick={() => {
              setParams({
                form: item?.form,
                type: item?.type,
              });
            }}
          />
        ))}
      </div>
    ) : (
      <EmptyState
        icon={EditIcon}
        heading={"Hmm..."}
        message={
          "It looks like you haven't submitted any family process forms. To see family process forms, please head over to the New Submissions tab"
        }
        scriptureText={
          "But as for me and my household, we will serve the Lord."
        }
        scripture={"Joshua 24:15 (NIV)"}
      />
    ),
  ];

  useEffect(() => {
    if (!params?.tab) {
      setParam("tab", "new");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (params?.tab === "new") {
      setTabIdx(0);
    } else if (params?.tab === "my") {
      setTabIdx(1);
    }
  }, [params]);

  useEffect(() => {
    if (params?.type) {
      window["fp-modal"]?.showModal();
    } else {
      window["fp-modal"]?.close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.type]);
  return (
    <HeaderFooter>
      <TextGradient>Family Process</TextGradient>
      <div className="pt-[300px] pb-20 max-w-section mx-auto px-5">
        <h1 className="font-semibold text-[52px] lg:text-[60px] lg:leading-[70px] text-purpleOverlay mb-3">
          Family Process
        </h1>

        <TabComponent
          tabButtons={tabs}
          tabContent={tabContent}
          tabIdx={tabIdx}
          tabUpdate={tabUpdate}
        />
      </div>

      {/* {params?.type && ( */}
      <ModalManagement
        id={"fp-modal"}
        title={capitalizeAndReplaceHyphen(params?.type)}
        modalClass={"max-w-[640px]"}
        className={"max-w-none w-[100%]"}
        titleClass={params?.form ? "mb-0" : ""}
        onClose={(e) => {
          e?.preventDefault();
          // window["fp-modal"]?.close();
          deleteParam("type");
          deleteParam("form");
        }}
        hasClose
      >
        {params?.type ? formTypes[params?.type]?.component : null}
      </ModalManagement>
      {/* )} */}
    </HeaderFooter>
  );
};

export default FamilyProcess;
