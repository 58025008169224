import useUpdateManager from "constants/controller_templates/put_controller_template";

export const EditCategoryManager = (id) => {
  const isMultiKey = false;
  const { updateCaller, isLoading, isSuccess, error, data } = useUpdateManager(
    `/category/${id}`,
    ["categoryList"],
    isMultiKey
  );
  const editCategory = async (details) => {
    try {
      await updateCaller(details);
    } catch (error) {
      // Handle error if necessary
      console.error("error:", error);
    }
  };
  return {
    editCategory,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
