import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { ProgressBar } from "./ProgressBar";
import { v4 as uuid } from "uuid";
import { FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";

export const MAX_UPLOAD_SIZE = 1024 * 1024 * 2; // 2MB

/**
 * 
 * @param {import("react-dropzone").Accept} param.accept
 * @returns
 */

const Dropzone = ({ onFilesAdded, onFilesRemoved, accept, progress = {}, maxSize = MAX_UPLOAD_SIZE, maxFiles = 40 }) => {
    const [fileHistory, setFileHistory] = useState([]);

    const onDropRejected = useCallback(
        (fileRejections) => {
            try {
                const errorMessage = capitalizeFirstLetter((fileRejections[0].errors[0]?.code).replaceAll('-', ' '))
                toast.error(errorMessage)
            } catch (err) {
                // Handle error here
            }
        },
        []
    );

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles) => handleFiles(acceptedFiles),
        multiple: true,  // Allow multiple file uploads
        accept,
        maxSize,
        maxFiles,
        onDropRejected
    });

    const handleFiles = useCallback((acceptedFiles) => {
        const newFiles = ((acceptedFiles.map(newFile => { return { file: newFile, key: uuid() } })))
        onFilesAdded(newFiles);
        setFileHistory(prev => [...prev, ...newFiles])
    }, [onFilesAdded])

    const handleRemoveFile = useCallback((fileKey) => {
        const remainingFiles = fileHistory?.filter((file) => file.key !== fileKey)

        setFileHistory(remainingFiles);
        onFilesAdded(remainingFiles);
    }, [fileHistory, onFilesAdded])

    const sizeLimit = `${maxSize ? maxSize / (1024 * 1024) : 0}MB`;
    const fileCount = fileHistory?.length

    return (
        <div className="flex flex-col items-center space-y-4">
            <div className="w-full max-w-4xl space-y-4">
                <div
                    {...getRootProps()}
                    className="flex flex-col items-center space-y-1 justify-center border-2 border-dashed border-gray-400 p-8 w-full h-48 rounded-lg bg-gray-100 hover:bg-gray-200 transition-all cursor-pointer"
                >
                    <input {...getInputProps()} className="hidden" />
                    <p className="text-xl font-semibold text-gray-600">Drag & Drop Files Here</p>
                    <p className="text-sm text-gray-600">or click to select files</p>
                    <p className="text-gray-500 text-xs">
                        Each file must be {sizeLimit} or smaller and no more than {maxFiles} in number
                    </p>
                </div>
                {fileCount > 0 && <div className="w-full">
                    <div className="overflow-y-scroll h-60 pr-4">
                        <ul className={`grid ${fileCount > 4 ? "grid-cols-2 md:grid-cols-3 lg:grid-cols-4" : "grid-cols-1"} gap-4 mt-4 w-full`}>
                            {fileHistory?.map((data, index) => {
                                const file = data.file;
                                const fileKey = data.key;

                                return (
                                    <div key={file.name}>
                                        <li className="flex justify-between items-center space-x-4 w-full">
                                            <div className="grid grid-cols-3 items-center justify-between space-x-4 w-full">
                                                <div className="col-span-3">
                                                    <div className="flex items-center justify-between w-full">
                                                        <p className="text-sm text-gray-600 truncate">{file.name}</p>
                                                        <button
                                                            className="text-sm text-red-500"
                                                            onClick={() => handleRemoveFile(fileKey)}
                                                            disabled={progress[index] !== undefined}
                                                        >
                                                            <FaTrash />
                                                        </button>
                                                    </div>
                                                    <ProgressBar progress={progress[fileKey] ?? 0} />
                                                </div>
                                                {/* TODO: Images suspended for performance improvement */}
                                                {/* {file.type.startsWith("image/") && (
                                                    <img
                                                        src={URL.createObjectURL(file)}
                                                        alt={file.name}
                                                        className="w-12 h-12 object-cover rounded-md"
                                                    />
                                                )} */}

                                            </div>
                                        </li>
                                    </div>
                                )
                            }
                            )}
                        </ul>
                    </div>
                </div>}
            </div>
        </div>
    );
};

export default Dropzone;
