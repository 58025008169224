import { cozaGive } from "assets/images";
import React from "react";

const Banner = () => {
  return (
    <div className="max-w-full h-full flex items-end justify-between relative pl-8 pb-12 pt-3 rounded-[30px] overflow-hidden shadow-md">
      <img
        src={cozaGive}
        alt=""
        className="object-cover absolute top-0 left-0 -z-20 w-full h-full"
      />
      <div className="absolute -z-10 top-0 left-0 w-full h-full bg-gradient-to-b from-[#7A10A6]/0 to-purpleOverlay"></div>

      <div>
        <p className="text-orangeBg text-2xl">2 Corinthians 9:7 (AMPC)</p>
        <p className="text-base">
          Let each one [give] as he has made up his own mind and purposed in his
          heart, not reluctantly or sorrowfully or under compulsion, for God
          loves (He takes pleasure in, prizes above other things, and is
          unwilling to abandon or to do without) a cheerful (joyous, "prompt to
          do it") giver [whose heart is in his giving].
        </p>
      </div>
      <p className="text-[100px] leading-[100px] lg:text-[156px] lg:leading-[156px] rotate-180 font-bold vertical-text text-right">
        COZA Give
      </p>
    </div>
  );
};

export default Banner;
