import { formatDistance } from "date-fns";
import React, { useCallback, useRef } from "react";
import { useUserStore } from "store/userStore";
import useGetPraiseReports from "../controllers/get_praise_reports_contoller";
import PraiseReportItem from "./PraiseReportItem";
import Loader from "generalComponents/Loader";
import EmptyState from "generalComponents/EmptyState";
import EditIcon from "assets/icons/EditIcon";
const praiseReportMapper = (data) => {
  if (!data || data?.length < 1) return [];
  const newData = data.map((item) => ({
    praiseReportData: item,
    avatar: item?.user?.profile_picture,
    fullname: item?.name,
    title: item?.title,
    likes: item?.like_count,
    image: item?.imageUrl === "" ? item?.user?.profile_picture : item?.imageUrl,
    // image: item?.category?.cover_image?.url,
    time: formatDistance(new Date(item?.createdAt), new Date(), {
      addSuffix: true,
    }),
    // time: "3hrs ago",
    description: item?.description,
  }));

  return newData;
};

const UserPraiseReport = ({ visible }) => {
  const userStore = useUserStore((state) => state);
  const observer = useRef();
  const { data, isLoading, fetchNextPage, hasNextPage, isFetching } =
    useGetPraiseReports({
      pageSize: 20,
      user: userStore.user?.id,
    });
  const lastElementRef = useCallback(
    (node) => {
      if (isLoading) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage && !isFetching) {
          fetchNextPage();
        }
      });

      if (node) observer.current.observe(node);
    },
    [fetchNextPage, hasNextPage, isFetching, isLoading]
  );

  if (isLoading) return <Loader ball />;

  return (
    <div className="h-full overflow-scroll">
      {data?.pages[0]?.data?.testimonies?.length ? (
        data?.pages?.map((page, idx) =>
          praiseReportMapper(page?.data?.testimonies).map((item, index) => (
            <PraiseReportItem
              key={index}
              ref={lastElementRef}
              visible={visible}
              {...item}
            />
          ))
        )
      ) : (
        <EmptyState
          icon={EditIcon}
          heading={"Hmm..."}
          messageClass={"text-sm"}
          message={
            "It looks like you haven't shared any praise reports yet. To share your praise report, click on the floating button."
          }
          scriptureText={
            "Let the redeemed of the Lord tell their story—those he redeemed from the hand of the foe."
          }
          scripture={"Psalm 107:2 (NIV)"}
        />
      )}

      {isFetching ? <Loader isSmall /> : null}
    </div>
  );
};

export default UserPraiseReport;
