// import {
//   churchesAbuja,
//   churchesBirmingham,
//   churchesDubai,
//   churchesIlorin,
//   churchesLagos,
//   churchesManchester,
//   churchesPh,
// } from "assets/images";
import Accordion from "./Accordion";
import React from "react";
import { ChurchLabel, SingleChurch } from "./ChurchContent";
import { useGetCampuses } from "../controllers/get_controller";
import Loader from "generalComponents/Loader";
import { churchesContent } from "assets/images";

const ChurchList = () => {
  const { data, isLoading } = useGetCampuses();

  const cities = [...new Set(data?.data?.map((item) => item?.location.city))];

  if (isLoading) return <Loader isSmall />;
  return (
    <div className="overflow-auto py-20">
      <Accordion
        list={cities?.map((item, index) => ({
          label: (
            <ChurchLabel
              label={item}
              image={
                data?.data?.filter(
                  (church) => church?.location.city === item
                )[0]?.images[0] ?? churchesContent
              }
            />
          ),
          content: data?.data
            ?.filter((church) => church?.location.city === item)
            ?.map((campus, index) => (
              <SingleChurch
                key={index}
                title={campus?.name}
                text={campus?.location?.address}
                url={`https://www.google.com/maps?q=${campus?.location?.lat},${campus?.location?.long}`}
                image={campus?.images[0]}
              />
            )),
        }))}
        allowMultiple
      />
    </div>
  );
};

export default ChurchList;

// const faqList = [
//   {
//     label: <ChurchLabel label="Abuja" image={churchesAbuja} />,
//     content: churchLocationList.ABUJA?.map((item, index) => (
//       <SingleChurch key={index} {...item} />
//     )),
//     isSelected: true,
//   },
//   {
//     label: <ChurchLabel label="Lagos" image={churchesLagos} />,
//     content: churchLocationList.LAGOS?.map((item, index) => (
//       <SingleChurch key={index} {...item} />
//     )),
//   },
//   {
//     label: <ChurchLabel label="Ilorin" image={churchesIlorin} />,
//     content: churchLocationList.ILORIN?.map((item, index) => (
//       <SingleChurch key={index} {...item} />
//     )),
//   },
//   {
//     label: <ChurchLabel label="Port Harcourt" image={churchesPh} />,
//     content: churchLocationList.PH?.map((item, index) => (
//       <SingleChurch key={index} {...item} />
//     )),
//   },
//   {
//     label: <ChurchLabel label="Manchester" image={churchesManchester} />,
//     content: churchLocationList.MANCHESTER?.map((item, index) => (
//       <SingleChurch key={index} {...item} />
//     )),
//   },
//   {
//     label: <ChurchLabel label="Birmingham" image={churchesBirmingham} />,
//     content: churchLocationList.BIRMINGHAM?.map((item, index) => (
//       <SingleChurch key={index} {...item} />
//     )),
//   },
//   {
//     label: <ChurchLabel label="Dubai" image={churchesDubai} />,
//     content: churchLocationList.DUBAI?.map((item, index) => (
//       <SingleChurch key={index} {...item} />
//     )),
//   },
// ];
