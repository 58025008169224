import {
  cozaKidsAnimation,
  cozaKidsDevotional,
  cozaKidsGames,
} from "assets/images";
import React from "react";
import SectionCard from "../components/SectionCard";
import HeaderFooter from "generalComponents/HeaderFooter";
import TextGradient from "generalComponents/TextGradient";

const sections = [
  {
    url: "/coza-kids/devotional",
    name: "Devotional",
    imageUrl: cozaKidsDevotional,
    yellow: true,
  },
  {
    url: "/coza-kids/animation",
    name: "Animation",
    imageUrl: cozaKidsAnimation,
    blue: true,
  },
  {
    url: "/coza-kids/",
    name: "Games",
    imageUrl: cozaKidsGames,
    pink: true,
  },
];

const CozaKids = () => {
  return (
    <HeaderFooter>
      <TextGradient>COZA Kids</TextGradient>
      <div className="max-w-section mx-auto pt-[300px] pb-20 px-5">
        <h1 className="text-purpleOverlay font-semibold text-[52px] lg:text-[60px] mb-14">
          COZA Kids
        </h1>

        <div className="grid lg:grid-cols-3 gap-10">
          {sections.map((item, index) => (
            <SectionCard key={index} {...item} />
          ))}
        </div>
      </div>
    </HeaderFooter>
  );
};

export default CozaKids;
