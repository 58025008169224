import { useUserStore } from "store/userStore";

const useLoggedInUser = () => {
  const userStore = useUserStore((state) => state);
  const loggedInUser = localStorage.getItem("token") !== null;
  function logout() {
    userStore.reset();
    localStorage.removeItem("token");
    localStorage.clear();
    window.location.href = "/login";
  }

  return { loggedInUser, logout };
};

export default useLoggedInUser;
