import { RoundedButton } from "generalComponents/Button";
import ModalManagement from "generalComponents/CustomModal";
import PlayVideo from "generalComponents/PlayVideo";
import SermonDisc from "generalComponents/SermonDisc";
import usePutCart from "../controllers/put_controller";
import { Spinner } from "generalComponents/Loader";

// const appBaseUrl = process.env.REACT_APP_APP_BASE_URL;

const StoreItemDetails = ({ id, title, message, type, onClose }) => {
  const { putCaller, isLoading } = usePutCart({
    onSuccess: () => window[id]?.close(),
  });
  // console.log(cart.cart);

  async function addItemToCart() {
    await putCaller({
      item: message?.id,
      quantity: 1,
    });
  }

  return (
    <ModalManagement id={id} title={title} onClose={onClose}>
      <div className="px-8">
        <div className="flex justify-center items-center h-fit mb-8 relative">
          {type === "audio" ? (
            <SermonDisc
              image={message?.cover_image?.url}
              className={"mb-2 border-purpleBg size-[200px]"}
              small={true}
            />
          ) : type === "video" ? (
            <img
              src={message?.cover_image?.url}
              alt=""
              className="size-full h-[258px] rounded-[12px] object-contain"
            />
          ) : (
            <img
              src={message?.cover_image?.url}
              alt=""
              className="w-auto h-[246px] rounded-[12px] object-cover"
            />
          )}
          {type === "video" ? (
            <button
              // onClick={goToVideoSermonDetails}
              className="size-[65px] bg-purpleOverlay/50 absolute inset-0 m-auto rounded-full flex justify-center items-center"
            >
              <svg
                width="19"
                height="21"
                viewBox="0 0 19 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.598389 3.71242C0.598389 1.29048 3.23006 -0.2147 5.31762 1.01328L16.8575 7.8015C18.916 9.01231 18.916 11.989 16.8575 13.1998L5.31762 19.988C3.23006 21.216 0.598389 19.7108 0.598389 17.2889V3.71242Z"
                  fill="white"
                />
              </svg>
            </button>
          ) : null}
        </div>
        <p className="text-xl font-semibold text-purpleOverlay">
          {message.title}
        </p>
        <p className="text-orangeBg">{message.facilitator}</p>

        <p className="text-3xl font-semibold text-purpleBg text-right w-full mt-3">
          <span className="text-orangeBg">₦</span>
          {message.price ? message.price?.toLocaleString() : 0}
        </p>
        <div className="mt-10">
          <h4 className="font-medium text-lg text-purpleOverlay">About</h4>
          <p className="text-sm text-textGrey whitespace-pre-line">
            {message?.description}
          </p>
        </div>
        <RoundedButton
          onClick={addItemToCart}
          className="py-4 mt-20 w-full text-base flex justify-center items-center gap-2.5 rounded-[40px] font-semibold"
        >
          {isLoading ? <Spinner /> : "Add to cart"}
        </RoundedButton>
      </div>

      {type === "video" ? (
        <PlayVideo id={`detail-${message?.id}`} src={message?.content} />
      ) : null}
    </ModalManagement>
  );
};

export default StoreItemDetails;
