import ModalManagement from "generalComponents/ModalManagement";
import useFileUpload from "modules/fileupload/fileUploadController";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Switch from "react-switch";
import InputWithFullBoarder from "generalComponents/InputWithFullBoarder";
import CustomDropdown from "generalComponents/CustomDropdown";
import UploadFileComponent from "generalComponents/UploadFileComponent";
import CustomButton from "generalComponents/Button";
import { format } from "date-fns";
import { convertBytesToMB } from "utils/fileSize";
import useDebounce from "utils/UseDebounce";

import BigUploadFileComponent from "generalComponents/BigUploadFileComponent";
import {
  CreateChildrenContentManager,
  useCreateChildrenContentManager,
} from "../controllers/createChildrenContentController";
import { EditChildrenContentManager } from "../controllers/editChildrenContentController";
import SelectInput from "generalComponents/SelectInput";

const AddEditKids = ({ details, categories }) => {
  const { createContent, isLoading } = CreateChildrenContentManager();
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(`&title=${searchValue}`, 1000);
  const { updateContent, isLoading: editing } = EditChildrenContentManager(
    details?.id
  );

  const initialFormData = {
    title: "",
    facilitator: "",
    author: "",
    category: "",
    content_type: "video",
    content: "",
    description: "",
    status: "publish",
    subscription_type: "free",
    cover_image: {
      url: "",
      thumbnail: "",
    },
    price: 0,
    featured: false,
    date: "",
  };

  const contentType = ["audio", "video"];

  const [formData, setFormData] = useState(initialFormData);
  useEffect(() => {
    if (details) {
      setFormData({ ...formData, ...details });
    }
  }, [details]);
  const mediaRef = useRef();
  const [mediaFile, setMediaFile] = useState(null);
  const [uploadingMedia, setUploadingMedia] = useState(false);
  const coverImageRef = useRef();
  const [coverFile, setCoverFile] = useState(null);
  const [uploadingCover, setUploadingCover] = useState(false);
  const {
    progress,
    handleFileUpload,
    isLoading: fileLoading,
  } = useFileUpload();

  const categoryList = useMemo(() => {
    const list =
      categories?.map((el) => ({
        label: el?.name,
        value: el?.id,
      })) || [];
    return list;
  }, [categories]);

  return (
    <ModalManagement id={"add_kidsContent"} title={"Kids"}>
      <div className="rounded-[20px] w-full md:w-[500px] mt-5">
        <BigUploadFileComponent
          buttonText={"Upload Cover"}
          inputRef={coverImageRef}
          isLoading={uploadingCover}
          image={
            coverFile
              ? URL.createObjectURL(coverFile)
              : details && formData?.cover_image?.thumbnail
          }
          progress={progress}
          accept={"image/*"}
          buttonClick={() => setCoverFile(null)}
          onChange={(e) => {
            const file = e.target.files[0];
            if (file) {
              setCoverFile(file);
            } else {
              setCoverFile(null);
            }
          }}
        />

        <InputWithFullBoarder
          label={"Title"}
          placeholder={"Enter title"}
          value={formData.title}
          onChange={(e) => setFormData({ ...formData, title: e.target.value })}
        />
        <InputWithFullBoarder
          label={"Facilitator"}
          placeholder={"Enter facilitator"}
          value={formData.facilitator}
          onChange={(e) =>
            setFormData({ ...formData, facilitator: e.target.value })
          }
        />

        <SelectInput
          label={"Category"}
          name="category"
          value={formData.category}
          onChange={(e) =>
            setFormData((val) => ({
              ...val,
              category: e.target?.value,
            }))
          }
          placeholder={"Select gender"}
          options={categoryList}
          className={"bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-xs"}
          otherProps={{
            required: true,
          }}
        />

        <InputWithFullBoarder
          label={"Content Date"}
          className={"mb-0"}
          placeholder={"Enter Date"}
          type={"date"}
          value={formData.date}
          onChange={(e) => setFormData({ ...formData, date: e.target.value })}
        />

        <InputWithFullBoarder
          label={"Description"}
          isTextArea={true}
          className={"mb-4"}
          value={formData.description}
          onChange={(e) =>
            setFormData({ ...formData, description: e.target.value })
          }
        />
        <div className="flex flex-col mb-6">
          <label className="text-sm font-medium mb-2" htmlFor={"content_type"}>
            {"Media Content Type"}
          </label>
          <div className="flex items-center gap-5">
            {contentType.map((option) => (
              <label key={option} className="flex items-center capitalize">
                <input
                  type="radio"
                  name="content_type"
                  value={option}
                  checked={formData.content_type === option}
                  onChange={(e) =>
                    setFormData({ ...formData, content_type: option })
                  }
                  className="mr-2"
                />
                {option}
              </label>
            ))}
          </div>
        </div>
        <div className="flex justify-between items-center gap-0 md:gap-2 mb-5">
          <label htmlFor="forFree" className="text-12px font-medium mt-1">
            Make Paid?
          </label>
          <Switch
            id="status_update"
            checked={formData.subscription_type === "paid"}
            onChange={() => {
              setFormData({
                ...formData,
                subscription_type:
                  formData?.subscription_type === "free" ? "paid" : "free",
              });
            }}
            onColor="#FE8235"
            offColor="#828282"
            checkedIcon={false}
            uncheckedIcon={false}
            checkedHandleIcon={<div className="dot dot-on bg-brandOrange" />}
            uncheckedHandleIcon={<div className="dot dot-off bg-grey3" />}
            height={19} // Adjust the height of the switch
            width={30} // Adjust the width of the switch
            handleDiameter={15} // Adjust the size of the handle
          />
        </div>
        {formData?.subscription_type === "paid" && (
          <InputWithFullBoarder
            label={"Set Price"}
            className={"mb-0"}
            placeholder={"Enter amount"}
            type={"number"}
            value={formData.price}
            onChange={(e) =>
              setFormData({ ...formData, price: Number(e.target.value) })
            }
          />
        )}

        <UploadFileComponent
          description={`Upload Media`}
          inputRef={mediaRef}
          isLoading={uploadingMedia}
          format={formData.content_type}
          maxSize={mediaFile ? convertBytesToMB(mediaFile.size) : `20`}
          fileName={mediaFile ? mediaFile.name : null}
          progress={progress}
          accept={"video/*,audio/*"}
          buttonClick={() => setMediaFile(null)}
          onChange={async (e) => {
            const file = e.target.files[0];
            setMediaFile(file);
          }}
        />

        <CustomButton
          buttonText={details ? "Update Kids" : "Upload Kids"}
          radius={"rounded-[40px]"}
          className={"w-full"}
          isLoading={fileLoading || editing || isLoading}
          loader={
            uploadingCover
              ? `Cover Image ${progress}%`
              : uploadingMedia
              ? `Kids ${progress}%`
              : "Submitting..."
          }
          onClick={async () => {
            const updatedFormData = { ...formData };
            if (mediaFile) {
              setUploadingMedia(true);
              const mediaUrl = await handleFileUpload(mediaFile);
              updatedFormData.content = mediaUrl;
              setUploadingMedia(false);
            }
            if (coverFile) {
              setUploadingCover(true);
              const coverUrl = await handleFileUpload(coverFile);
              updatedFormData.cover_image = {
                url: coverUrl,
                thumbnail: coverUrl,
              };
              setUploadingCover(false);
            }

            console.log(updatedFormData);
            if (details) {
              await updateContent(updatedFormData);
            } else {
              await createContent(updatedFormData);
            }
            document.getElementById("add_kidsContent").close();
            setMediaFile(null);
            setFormData(initialFormData);
          }}
        />
      </div>
    </ModalManagement>
  );
};

export default AddEditKids;
