// import { homeAboutUs, homeLatestSermon } from "assets/images";
import AudioItem from "generalComponents/AudioItem";
import CategoriesNav from "generalComponents/CategoriesNav";
// import CategoryRow from "generalComponents/CategoryRow";
import HeaderFooter from "generalComponents/HeaderFooter";
import TabComponent from "generalComponents/TabComponent";
import VideoItem from "generalComponents/VideoItem";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  useGetChildrenCategories,
  useGetChildrenResources,
} from "../controllers/get_controller";
import useQueryParams from "constants/useQueryParams";
import EmptyState from "generalComponents/EmptyState";
import CustomPagination from "generalComponents/CustomPagination";
import TextGradient from "generalComponents/TextGradient";
import SermonDetails from "generalComponents/SermonDetails";

const tabs = ["Audio", "Video"];

const Devotional = () => {
  const { params, setParam } = useQueryParams({
    page: 1,
    pageSize: 28,
  });
  const [tabIdx, setTabIdx] = useState(0);
  const tabUpdate = (type) => {
    setParam("content_type", type?.toLowerCase());
  };
  const [selectedVideo, setSelectedVideo] = useState();
  const [selectedAudio, setSelectedAudio] = useState();

  const onSelectVideo = (data) => {
    setSelectedVideo(data);
  };
  const onCloseVideoDetails = () => {
    setSelectedVideo(undefined);
  };
  const onSelectAudio = (data) => {
    setSelectedAudio(data);
  };
  const onCloseAudioDetails = () => {
    setSelectedAudio(undefined);
  };

  const { data, isLoading } = useGetChildrenResources({
    ...params,
    group: "children",
  });
  const { data: categories } = useGetChildrenCategories({
    group: "children",
  });

  const tabContent = [
    <div className="flex flex-wrap gap-5 mx-auto">
      {data?.data?.items?.length > 0 ? (
        data?.data?.items?.map((message, mIndex) => (
          <AudioItem
            key={mIndex}
            message={message}
            onShowDetails={onSelectAudio}
          />
        ))
      ) : (
        <EmptyState
          heading="Ohh no!"
          message="It seems this category has no audio devotionals at the moment. Tune into other categories and discover the powerful content awaiting your kids!"
          scriptureText={
            "For the word of God is alive and active. Sharper than any double-edged sword, it penetrates even to dividing soul and spirit, joints and marrow; it judges the thoughts and attitudes of the heart."
          }
          scripture={"Hebrews 4:12 (NIV)"}
        />
      )}
    </div>,
    <div className="flex flex-wrap gap-5 mx-auto">
      {data?.data?.items?.length > 0 ? (
        data?.data?.items?.map((message, mIndex) => (
          <VideoItem
            key={mIndex}
            message={message}
            onShowDetails={onSelectVideo}
          />
        ))
      ) : (
        <EmptyState
          heading="Whoops!"
          message="Looks like this category has no video devotionals at the moment. But don't worry, there's a treasure trove of inspiring content waiting for you in other categories. Go ahead, explore and be uplifted!"
          scriptureText={
            "Like newborn babies, crave pure spiritual milk, so that by it you may grow up in your salvation."
          }
          scripture={"1 Peter 2:2 (NIV)"}
        />
      )}
    </div>,
  ];

  useEffect(() => {
    if (!params?.content_type) {
      setParam("content_type", "audio");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (params?.content_type === "audio") {
      setTabIdx(0);
    } else if (params?.content_type === "video") {
      setTabIdx(1);
    }
  }, [params]);

  useEffect(() => {
    if (selectedVideo !== undefined) {
      window["video-details"]?.showModal();
    } else {
      window["video-details"]?.close();
    }
  }, [selectedVideo]);
  useEffect(() => {
    if (selectedAudio !== undefined) {
      window["audio-details"]?.showModal();
    } else {
      window["audio-details"]?.close();
    }
  }, [selectedAudio]);
  return (
    <HeaderFooter>
      <TextGradient>Devotional</TextGradient>
      <div className="pt-[184px] pb-20 max-w-section mx-auto px-5">
        <Link
          to="/coza-kids"
          className="text-sm text-[#667185] flex items-center gap-3 mb-2"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.5"
              y="0.5"
              width="23"
              height="23"
              rx="3.5"
              fill="white"
            />
            <rect
              x="0.5"
              y="0.5"
              width="23"
              height="23"
              rx="3.5"
              stroke="#E4E7EC"
            />
            <path
              d="M7.14645 12.3536C6.95118 12.1583 6.95118 11.8417 7.14645 11.6464L9.14645 9.64645C9.34171 9.45118 9.65829 9.45118 9.85355 9.64645C10.0488 9.84171 10.0488 10.1583 9.85355 10.3536L8.70711 11.5L16.5 11.5C16.7761 11.5 17 11.7239 17 12C17 12.2761 16.7761 12.5 16.5 12.5L8.70711 12.5L9.85355 13.6464C10.0488 13.8417 10.0488 14.1583 9.85355 14.3536C9.65829 14.5488 9.34171 14.5488 9.14645 14.3536L7.14645 12.3536Z"
              fill="black"
            />
          </svg>

          <span>Go back</span>
        </Link>
        <div className="flex justify-between items-center mb-3 max-w-section mx-auto">
          <h1 className="font-semibold text-[52px] lg:text-[60px] lg:leading-[70px] text-purpleOverlay">
            Devotional
          </h1>

          <div className="flex justify-end items-center gap-2 [&>*]:cursor-pointer">
            {/* <CircleArrowLeft onClick={tileScrollLeft} />
            <CircleArrowRight onClick={tileScrollRight} /> */}
          </div>
        </div>

        <CategoriesNav categoryList={categories?.data?.categories ?? []} />

        <TabComponent
          tabButtons={tabs}
          tabContent={tabContent}
          isLoading={isLoading}
          tabIdx={tabIdx}
          tabUpdate={tabUpdate}
        />

        {data?.data?.pagination?.total > 0 && (
          <div className="flex justify-end mt-10 min-w-full">
            <CustomPagination
              count={parseInt(data?.data?.pagination?.total)}
              page={parseInt(params?.page)}
              pageSize={parseInt(params?.pageSize) ?? 20}
              setPage={(newPage) => setParam("page", newPage)}
              isLoading={isLoading}
            />
          </div>
        )}
      </div>

      {selectedVideo && (
        <SermonDetails
          onClose={onCloseVideoDetails}
          message={selectedVideo}
          id={"video-details"}
          type="video"
          title="Video Details"
        />
      )}

      {selectedAudio && (
        <SermonDetails
          onClose={onCloseAudioDetails}
          message={selectedAudio}
          id={"audio-details"}
          type="audio"
          title="Audio Details"
        />
      )}
    </HeaderFooter>
  );
};

export default Devotional;
