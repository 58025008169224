import Axios from "constants/api_management/MyHttpHelper";
// import AxiosWithToken from "constants/api_management/MyHttpHelperWithToken";
import { useQuery } from "react-query";

export const useGetCampuses = (params = {}) => {
  return useQuery(
    ["campuses", params],
    async () => {
      try {
        const [response] = [await Axios.get(`/campuses`, { params })];
        //console.log(`this is the current subscription status ${response}`);
        return response.data;
      } catch (error) {
        //console.log(error.response.data);
        throw new Error(`Sorry: ${error.response.data.message}`);
      }
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};
