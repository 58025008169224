import AxiosWithToken from "constants/api_management/MyHttpHelperWithToken";
import showToast from "constants/showToast";
import { useMutation, useQueryClient } from "react-query";

const useDeleteCart = ({ onSuccess = () => null } = {}) => {
  const queryClient = useQueryClient();

  const putRequest = async (id) => {
    try {
      const response = await AxiosWithToken.delete(`/cart/${id}`);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  };

  const mutation = useMutation({
    mutationKey: ["cart"],
    mutationFn: putRequest,
    onSuccess: (data) => {
      showToast(data.message, "success");
      queryClient.invalidateQueries(["cart"]);
      onSuccess();
    },
    onError: (error) => {
      showToast(error?.message, "error");
    },
  });

  const deleteCaller = async (id) => {
    try {
      await mutation.mutateAsync(id);
    } catch (error) {
      console.log({ error });
    }
  };

  return {
    deleteCaller,
    ...mutation,
  };
};

export default useDeleteCart;
