import { RoundedButton } from "generalComponents/Button";
import DatePickerInput from "generalComponents/DatePickerInput";
import InputWithFullBoarder from "generalComponents/InputWithFullBoarder";
import RadioGroupInput from "generalComponents/RadioGroupInput";
import SelectInput from "generalComponents/SelectInput";
import { useEffect, useState } from "react";
import usePostFamilyProcess from "../controllers/post_controller";
import guarantors from "./guarantors.json";
import { useUserStore } from "store/userStore";
import FileInput from "generalComponents/FileInput";
import useFileUpload from "modules/fileupload/fileUploadController";
import Loader, { Spinner } from "generalComponents/Loader";
import useQueryParams from "constants/useQueryParams";
import { useGetSingleFamilyProcessSubmission } from "../controllers/get_controller";
import { StatusBadge } from "generalComponents/Badges";
import { capitalizeFirstLetter } from "constants/textFormatters";
import useReadOnlyInputs from "../useReadOnlyInputs";

const guarantorList = guarantors?.map((item) => ({
  label: item,
  value: item,
}));

const partnershipOptions = [
  {
    label: "Partner",
    value: "active",
  },
  {
    label: "Not a Partner",
    value: "inactive",
  },
];
const trueFalseOptions = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

const WeddingThanksgivingModal = ({ id }) => {
  const { params } = useQueryParams();
  const { data: singleForm, isLoading: singleFormLoading } =
    useGetSingleFamilyProcessSubmission({
      formId: params?.form,
      type: "relationship-registration",
      enabled: !!params?.form,
    });
  const user = useUserStore((state) => state.user);
  const initialValues = {
    name:
      user?.first_name && user?.last_name
        ? `${user?.first_name} ${user?.last_name}`
        : "",
    phone: user?.phone ?? "",
    address: user?.location?.address ?? "",
    married_since: "",
    marriage_date: "",
    marriage_location: "",
    member_duration: "",
    guarantor: "",
    partnership_status: "partner",
    partnership_id: "",
    allow_social_share: "",
    is_relationship_registered: true,
    premarital_class_completed: true,
  };
  const [formData, setFormData] = useState(initialValues);
  const [files, setFiles] = useState({
    husband_signature: undefined,
    wife_signature: undefined,
    passport: undefined,
  });
  const [filesSrc, setFilesSrc] = useState({
    husband_signature: "",
    wife_signature: "",
    passport: "",
  });

  const handleFileChange = (e, name) => {
    if (e.target.files[0]) {
      setFiles((val) => ({
        ...val,
        [name]: e.target.files[0],
      }));
      setFilesSrc((val) => ({
        ...val,
        [name]: URL.createObjectURL(e.target.files[0]),
      }));
    }
  };

  const handleChange = (event, name) => {
    const value = event.target?.value;
    setFormData((data) => ({
      ...data,
      [name]: value,
    }));
  };
  const handleMarriageDate = (date) => {
    setFormData((data) => ({
      ...data,
      marriage_date: date,
    }));
  };

  const {
    // error: fileUploadError,
    handleFileUpload: uploadFile,
    isLoading: isUploadingFlie,
    // data: uploadUrl,
  } = useFileUpload();

  const { postCaller, isLoading } = usePostFamilyProcess({
    endpoint: "wedding-thanksgiving",
    mutationKey: ["family_process"],
    onSuccess: () => window[id]?.close(),
  });

  function handleFileUpload() {
    return new Promise(async (resolve, reject) => {
      try {
        const uploadPromises = Object.values(files)?.map(async (file) => {
          const fileUrl = await uploadFile(file);
          return fileUrl;
        });

        const uploadedFiles = await Promise.all(uploadPromises);
        resolve(uploadedFiles);
        return uploadedFiles;
      } catch (error) {
        reject(error);
      }
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    // console.log({ formData });
    try {
      const [husband_signature, wife_signature, passport] =
        await handleFileUpload();

      await postCaller({
        ...formData,
        member_duration: Number(formData.member_duration),
        // courtship_duration: Number(formData.courtship_duration),
        allow_social_share:
          formData?.allow_social_share === "true" ? true : false,
        husband_signature,
        wife_signature,
        passport,
      });

      setFormData(initialValues);
      setFiles({});
      setFilesSrc({});
    } catch (error) {
      console.log({ error });
    }
  }

  useEffect(() => {
    if (singleForm) {
      setFormData({
        name: singleForm?.data?.name,
        phone: singleForm?.data?.phone,
        address: singleForm?.data?.address,
        married_since: singleForm?.data?.married_since,
        marriage_date: singleForm?.data?.marriage_date,
        marriage_location: singleForm?.data?.marriage_location,
        member_duration: singleForm?.data?.member_duration,
        guarantor: singleForm?.data?.guarantor,
        partnership_status: singleForm?.data?.partnership_status,
        partnership_id: singleForm?.data?.partnership_id,
        allow_social_share: singleForm?.data?.allow_social_share,
        is_relationship_registered:
          singleForm?.data?.is_relationship_registered,
        premarital_class_completed:
          singleForm?.data?.premarital_class_completed,
      });
      setFilesSrc({
        husband_signature: singleForm?.data?.husband_signature,
        wife_signature: singleForm?.data?.wife_signature,
        passport: singleForm?.data?.passport,
      });
    }
  }, [singleForm]);

  useReadOnlyInputs(params?.form !== undefined);

  if (singleFormLoading) return <Loader />;
  return (
    <form onSubmit={handleSubmit}>
      {params?.form && (
        <div className="mb-5">
          <StatusBadge
            label={capitalizeFirstLetter(singleForm?.data?.status)}
            status={singleForm?.data?.status}
          />
        </div>
      )}
      <InputWithFullBoarder
        label={"Name"}
        type="text"
        id="name"
        value={formData.name}
        onChange={(e) => handleChange(e, "name")}
        placeholder={"Enter full name"}
        className={"bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-xs"}
        otherProps={{
          required: true,
        }}
      />
      <InputWithFullBoarder
        label={"Phone Number"}
        type="tel"
        id="phone"
        value={formData.phone}
        onChange={(e) => handleChange(e, "phone")}
        placeholder={"Enter phone number"}
        className={"bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-xs"}
        otherProps={{
          required: true,
        }}
      />

      <InputWithFullBoarder
        label={"Address"}
        type="text"
        id="address"
        value={formData.address}
        onChange={(e) => handleChange(e, "address")}
        placeholder={"Enter your address"}
        className={"bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-xs"}
        otherProps={{
          required: true,
        }}
      />

      <InputWithFullBoarder
        label={"How long have you been in COZA?"}
        type="number"
        id="member_duration"
        value={formData.member_duration}
        onChange={(e) => handleChange(e, "member_duration")}
        placeholder={"Enter number of years"}
        className={"bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-xs"}
        otherProps={{
          required: true,
        }}
      />
      {/* <InputWithFullBoarder
          label={"How long have you been in COZA?"}
          type="number"
          id="member_duration"
          value={formData.member_duration}
          onChange={(e) => handleChange(e, "member_duration")}
          placeholder={"Enter number of years"}
          className={"bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-xs"}
          otherProps={{
            required: true,
          }}
        /> */}

      <DatePickerInput
        label="Marriage Date"
        name="marriage_date"
        value={formData.marriage_date}
        onChange={handleMarriageDate}
        placeholder={"Select marriage date"}
        disabledPeriod={(date) =>
          new Date(date).getTime() > new Date().getTime()
        }
      />

      <InputWithFullBoarder
        label={"Marriage Location"}
        type="text"
        id="marriage_location"
        value={formData.marriage_location}
        onChange={(e) => handleChange(e, "marriage_location")}
        placeholder={"Enter marriage location"}
        className={"bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-xs"}
        otherProps={{
          required: true,
        }}
      />

      <SelectInput
        label={"Guarantor"}
        type="text"
        id="guarantor"
        value={formData.guarantor}
        onChange={(e) => handleChange(e, "guarantor")}
        placeholder={"Select guarantor"}
        className={"bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-xs"}
        otherProps={{
          required: true,
        }}
        options={guarantorList}
      />

      <SelectInput
        label={"Allow to share on social media"}
        name="allow_social_share"
        value={formData.allow_social_share}
        onChange={(e) => handleChange(e, "allow_social_share")}
        placeholder={"Select option"}
        options={trueFalseOptions}
        className={"bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-xs"}
        otherProps={{
          required: true,
        }}
      />

      <SelectInput
        label={"Did you take the premarital class?"}
        name="premarital_class_completed"
        value={formData.premarital_class_completed}
        onChange={(e) => handleChange(e, "premarital_class_completed")}
        placeholder={"Select option"}
        options={trueFalseOptions}
        className={"bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-xs"}
        otherProps={{
          required: true,
        }}
      />

      <SelectInput
        label={"Did you register your relationship?"}
        name="is_relationship_registered"
        value={formData.is_relationship_registered}
        onChange={(e) => handleChange(e, "is_relationship_registered")}
        placeholder={"Select option"}
        options={trueFalseOptions}
        className={"bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-xs"}
        otherProps={{
          required: true,
        }}
      />

      <RadioGroupInput
        label={"Partnership Status"}
        name="partnership_status"
        value={formData.partnership_status}
        onChange={(e) => handleChange(e, "partnership_status")}
        options={partnershipOptions}
      />

      <InputWithFullBoarder
        label={"Partnership ID"}
        type="text"
        id="partnership_id"
        value={formData.partnership_id}
        onChange={(e) => handleChange(e, "partnership_id")}
        placeholder={"Enter partnership ID"}
        className={"bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-xs"}
        otherProps={{
          required: formData?.partnership_status === "active",
        }}
      />

      <div className="mb-4">
        <label className={`text-sm font-medium mb-2`}>Signature</label>
        <p className="text-textGrey font-medium text-xs">
          Add one photo for each party.
        </p>
        <div className="flex gap-6 items-start mt-3">
          <FileInput
            label={"Husband"}
            onChange={(e) => handleFileChange(e, "husband_signature")}
            required
            src={filesSrc?.husband_signature}
            value={files?.husband_signature}
          />
          <FileInput
            label={"Wife"}
            onChange={(e) => handleFileChange(e, "wife_signature")}
            required
            src={filesSrc?.wife_signature}
            value={files?.wife_signature}
          />
        </div>
      </div>

      <div className="mb-4">
        <label className={`text-sm font-medium mb-2`}>Passport</label>
        {/* <p className="text-textGrey font-medium text-xs">
            Add one photo each for both parties.
          </p> */}
        <div className="flex gap-6 items-start mt-3">
          <FileInput
            // label={"Father"}
            onChange={(e) => handleFileChange(e, "passport")}
            required
            src={filesSrc?.passport}
            value={files?.passport}
          />
        </div>
      </div>

      {!params?.form && (
        <RoundedButton
          className={
            "rounded-[40px] text-base font-medium w-full py-5 mt-10 grid place-content-center"
          }
        >
          {isLoading || isUploadingFlie ? (
            <Spinner />
          ) : params?.form ? (
            "Update"
          ) : (
            "Submit"
          )}
        </RoundedButton>
      )}
    </form>
  );
};

export default WeddingThanksgivingModal;
