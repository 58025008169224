import HeaderFooter from "generalComponents/HeaderFooter";
import useMobile from "modules/admin/AdminWrapper/hooks/useMobile";
import React from "react";
import ReactPlayer from "react-player";
import { Link, useLocation } from "react-router-dom";

const CozaTv = () => {
  const location = useLocation();
  const isMobile = useMobile();
  const historyExists =
    location.key !== "default" && location.state !== "login";
  return (
    <HeaderFooter solid>
      <div className="relative pt-[98px]">
        <div className="fixed left-0 top-0 w-full h-[50%] bg-gradient-to-br from-purpleBg/30 to-white fade-b-20"></div>
        <div className="fixed left-0 top-[50%] w-full h-[50%] bg-gradient-to-br to-orangeBg/30 from-[#FFFFFF] fade-t-20"></div>
        <div className="relative z-[2] w-full">
          <div className="relative w-full">
            <Link
              to={historyExists ? -1 : "/"}
              className="text-xs text-[#2F0640] flex items-center gap-3 p-5 w-fit"
            >
              <svg
                width="9"
                height="16"
                viewBox="0 0 9 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="rotate-180"
              >
                <path
                  d="M1 1L6.81206 6.81206C7.46814 7.46814 7.46814 8.53186 6.81206 9.18794L1 15"
                  stroke="#2F0640"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
              <span>{"Go back"}</span>
            </Link>

            <p className="text-lg font-medium text-purpleOverlay absolute inset-0 m-auto w-fit h-fit">
              COZA TV
            </p>
          </div>

          <div className="max-h-fit w-full pb-10">
            <ReactPlayer
              url="https://webstreaming-2.viewmedia.tv/web_019/Stream/playlist.m3u8"
              fallback={
                <div
                  className={
                    isMobile
                      ? "w-full h-full min-h-[480px] bg-black/80 animate-pulse"
                      : "w-[70%] h-[70%] min-h-[480px] bg-black/80 animate-pulse"
                  }
                ></div>
              }
              playing
              controls
              width={isMobile ? "100%" : "70%"}
              height={isMobile ? "100%" : "70%"}
              style={{
                marginInline: "auto",
                position: "sticky",
                top: isMobile ? "88px" : "95px",
              }}
            />

            <div className="px-5 py-10 mx-auto w-[100%] lg:w-[70%]">
              <p className="text-xl font-semibold text-purpleOverlay">
                COZA TV Broadcast
              </p>
              <p className="text-sm text-purpleOverlay mt-1">
                COZA TV is the dynamic broadcasting arm of the Commonwealth of
                Zion Assembly (COZA), committed to spreading the gospel and
                enriching lives with transformative Christian content. Launched
                to extend the reach of COZA's vibrant ministry, COZA TV serves
                as a powerful medium to share the message of faith, hope, love,
                spirituality and excellence across the globe.
              </p>
              {/* <p className="text-orangeBg">{data?.data?.facilitator}</p> */}

              <div>
                <p className="font-semibold text-purpleOverlay text-lg mt-5">
                  Mission and Vision
                </p>
                <p className="text-sm font-medium text-purpleOverlay whitespace-pre-line">
                  Mission:{" "}
                  <span className="font-light">
                    To broadcast the gospel of Jesus Christ to the ends of the
                    earth, providing spiritual nourishment and empowerment to
                    viewers globally.
                  </span>
                </p>
                <p className="text-sm font-medium text-purpleOverlay whitespace-pre-line">
                  Vision:{" "}
                  <span className="font-light">
                    To create a global platform where lives are transformed,
                    destinies are recharted, and God's Kingdom is advanced
                    through the power of media.
                  </span>
                </p>
              </div>

              <div>
                <p className="font-semibold text-purpleOverlay text-lg mt-5">
                  Key Features and Programming
                </p>

                <ol className="text-sm font-medium text-purpleOverlay whitespace-pre-line list-decimal list-inside">
                  <li className="text-sm font-medium text-purpleOverlay whitespace-pre-line">
                    Inspirational Sermons:{" "}
                    <span className="font-light">
                      COZA TV broadcasts impactful sermons from Pastors Biodun
                      and Modele Fatoyinbo and other anointed ministers,
                      offering deep insights and practical teachings that
                      inspire viewers to live victorious lives.
                    </span>
                  </li>

                  <li className="text-sm font-medium text-purpleOverlay whitespace-pre-line">
                    Worship and Praise:{" "}
                    <span className="font-light">
                      Featuring soul-stirring worship and praise sessions led by
                      COZA's world-class music team - The Avalanche and other
                      ministers, viewers can experience powerful moments of
                      praise and worship that uplift the spirit and draw people
                      closer to God.
                    </span>
                  </li>

                  <li className="text-sm font-medium text-purpleOverlay whitespace-pre-line">
                    Faith-Based Talk Shows:{" "}
                    <span className="font-light">
                      Engaging talk shows on COZA TV explore a variety of topics
                      relevant to daily Christian living, providing viewers with
                      wisdom and guidance on how to navigate life's challenges
                      with faith.
                    </span>
                  </li>

                  <li className="text-sm font-medium text-purpleOverlay whitespace-pre-line">
                    Live Services and Events:{" "}
                    <span className="font-light">
                      COZA TV offers live broadcasts of all COZA’s services,
                      conferences, and special events, allowing viewers to join
                      in real-time and be part of the COZA community from
                      anywhere in the world.
                    </span>
                  </li>

                  <li className="text-sm font-medium text-purpleOverlay whitespace-pre-line">
                    Praise Reports:{" "}
                    <span className="font-light">
                      Sharing powerful testimonies and stories of
                      transformation, COZA TV highlights the miraculous works of
                      God in the lives of believers, encouraging viewers to
                      believe in the power of faith and prayer.
                    </span>
                  </li>

                  <li className="text-sm font-medium text-purpleOverlay whitespace-pre-line">
                    Youth and Children's Programmes:{" "}
                    <span className="font-light">
                      COZA TV provides engaging Bible-based content specifically
                      designed for young people, helping to build a strong
                      foundation of faith in the next generation.
                    </span>
                  </li>

                  <li className="text-sm font-medium text-purpleOverlay whitespace-pre-line">
                    Community Outreach:{" "}
                    <span className="font-light">
                      Featuring reports and documentaries on COZA's outreach
                      initiatives, COZA TV showcases the church's efforts to
                      make a positive impact in communities, demonstrating the
                      love of Christ in action.
                    </span>
                  </li>
                </ol>

                <div>
                  <p className="font-semibold text-purpleOverlay text-lg mt-5">
                    Access and Availability
                  </p>
                  <p className="text-sm text-purpleOverlay whitespace-pre-line">
                    COZA TV is accessible through various platforms to ensure
                    that viewers can tune in from anywhere at any time:
                  </p>
                  <ol className="text-sm font-medium text-purpleOverlay whitespace-pre-line list-decimal list-inside">
                    <li className="text-sm font-medium text-purpleOverlay whitespace-pre-line">
                      Satellite and Cable Networks:{" "}
                      <span className="font-light">
                        It is available on IntelSat 20, making it easy for
                        viewers to access content in their homes.
                      </span>
                    </li>
                    <li className="text-sm font-medium text-purpleOverlay whitespace-pre-line">
                      Online Streaming:{" "}
                      <span className="font-light">
                        COZA TV offers live streaming on the COZA Global mobile
                        app (android and iOS) and User Web, providing convenient
                        access for viewers on the go globally.
                      </span>
                    </li>
                  </ol>
                </div>

                <div>
                  <p className="font-semibold text-purpleOverlay text-lg mt-5">
                    Impact and Reach
                  </p>
                  <p className="text-sm text-purpleOverlay whitespace-pre-line">
                    Since its inception, COZA TV has had a profound impact on
                    countless lives, bringing the message of hope and salvation
                    to millions of viewers worldwide. Through its diverse and
                    engaging programming, COZA TV has become a trusted source of
                    spiritual enrichment and a beacon of light in the media
                    landscape.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </HeaderFooter>
  );
};

export default CozaTv;
