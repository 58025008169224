import React from "react";

const AdminHeaderTop = ({ title, subtitle }) => {
  return (
    <div className=" flex flex-col items-start">
      <p className="text-[24px] font-semibold">{title}</p>
      <p className="text-14px">{subtitle}</p>
    </div>
  );
};

export default AdminHeaderTop;
