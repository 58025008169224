import AxiosWithToken from "constants/api_management/MyHttpHelperWithToken";
import { useQuery } from "react-query";

const useGetLibrary = (params = {}) => {
  return useQuery(
    ["library", params],
    async () => {
      try {
        const [response] = [
          await AxiosWithToken.get(`/library/list`, { params }),
        ];
        //console.log(`this is the current subscription status ${response}`);
        return response.data;
      } catch (error) {
        //console.log(error.response.data);
        throw new Error(`Sorry: ${error.response.data.message}`);
      }
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const useGetPurchasedItems = (params = {}) => {
  return useQuery(
    ["purchases", params],
    async () => {
      try {
        const [response] = [
          await AxiosWithToken.get(`/store/purchases`, { params }),
        ];
        //console.log(`this is the current subscription status ${response}`);
        return response.data;
      } catch (error) {
        //console.log(error.response.data);
        throw new Error(`Sorry: ${error.response.data.message}`);
      }
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};

export default useGetLibrary;
