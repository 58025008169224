import React from "react";
import { GoDownload } from "react-icons/go";

const ButtonWithIcon = ({
  buttonText,
  textColor,
  className,
  onClick,
  buttonColor,
  radius,
  isLoading,
  type,
  loader,
  icon: IconComponent,
  ...props
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={`${
        buttonColor ?? "bg-brandPurple"
      } flex items-center py-[8px] md:py-[8px] px-[16px] md:px-[25px] font-regular hover:shadow-xl hover:scale-y-105 duration-300 h-[36px] ${
        !textColor ? "text-whiteColor" : textColor
      } ${radius ?? "rounded-md"} ${className}`}
      {...props}
    >
      {!IconComponent ? (
        <GoDownload className="mr-2" />
      ) : (
        <IconComponent className="mr-2" />
      )}{" "}
      {/* Render the icon if provided */}
      {isLoading ? loader ?? "loading..." : buttonText}
    </button>
  );
};

export default ButtonWithIcon;
