import React from "react";
import { useLocation } from "react-router-dom";

const IconsWithText = ({ icon, text, iconSize, editText, className, path }) => {
  const location = useLocation();
  return (
    <div
      className={`max-w-[90%] mx-auto  hover:w-full  hover:rounded-[4px] hover:bg-purpleBg duration-300  ${
        location.pathname.includes(path)
          ? ` rounded-[4px] bg-purpleBg w-full `
          : ``
      } flex px-8 py-4 gap-3 justify-start text-[${iconSize}] ${className} items-center text-whiteColor hover:text-whiteColor group`}
    >
      {icon}

      <h4 className={` ${editText ?? "text-14px  group"} `}>{text}</h4>
    </div>
  );
};

export default IconsWithText;
