import { adminMenu } from "constants/menu";
import UserMenu from "generalComponents/UserMenu";
import NotificationPopover from "modules/notification/NotificationPopover";
import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";

const Header = ({ value, onChange, hideSearch }) => {
  const location = useLocation();
  const selectedMenu = useMemo(() => {
    const selected = adminMenu?.filter(
      (item) => item.path === location?.pathname
    )[0];
    return selected;
  }, [location]);
  return (
    <div className="flex items-center justify-between flex-1">
      <div className="flex items-center gap-2">
        {selectedMenu?.darkIcon}
        <p className="font-medium text-purpleOverlay">{selectedMenu?.title}</p>
      </div>
      <div className="flex items-center gap-1 sm:gap-4 text-purpleOverlay">
        {!hideSearch && (
          <div className="relative hidden lg:block">
            <input
              className="text-[14px] font-normal text-purpleOverlay bg-white px-5 pl-8 py-2 rounded-md flex-1 outline-none border border-gray-300 focus:border-purpleBg w-full min-w-[420px] placeholder:text-[#667185]"
              placeholder="Search here..."
              type="search"
              value={value}
              onChange={onChange}
            />
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="absolute inset-0 left-3 my-auto"
            >
              <path
                d="M15 15L10.3333 10.3333M11.8889 6.44444C11.8889 9.45133 9.45133 11.8889 6.44444 11.8889C3.43756 11.8889 1 9.45133 1 6.44444C1 3.43756 3.43756 1 6.44444 1C9.45133 1 11.8889 3.43756 11.8889 6.44444Z"
                stroke="#667185"
                strokeWidth="1.67"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        )}
        <NotificationPopover dark />
        <UserMenu />
      </div>
    </div>
  );
};

export default Header;
