import { RoundedButton } from "generalComponents/Button";
import InputWithFullBoarder from "generalComponents/InputWithFullBoarder";
import RadioGroupInput from "generalComponents/RadioGroupInput";
import { useEffect, useState } from "react";
import guarantors from "./guarantors.json";
import useFileUpload from "modules/fileupload/fileUploadController";
import usePostFamilyProcess from "../controllers/post_controller";
import SelectInput from "generalComponents/SelectInput";
import FileInput from "generalComponents/FileInput";
import Loader, { Spinner } from "generalComponents/Loader";
import useQueryParams from "constants/useQueryParams";
import { useGetSingleFamilyProcessSubmission } from "../controllers/get_controller";
import { StatusBadge } from "generalComponents/Badges";
import { capitalizeFirstLetter } from "constants/textFormatters";
// import EditIcon from "assets/icons/EditIcon";
import useReadOnlyInputs from "../useReadOnlyInputs";

const guarantorList = guarantors?.map((item) => ({
  label: item,
  value: item,
}));

const partnershipOptions = [
  {
    label: "Partner",
    value: "active",
  },
  {
    label: "Not a Partner",
    value: "inactive",
  },
];

const HouseDedicationModal = ({ id }) => {
  const { params } = useQueryParams();
  const { data: singleForm, isLoading: singleFormLoading } =
    useGetSingleFamilyProcessSubmission({
      formId: params?.form,
      type: "house-dedication",
      enabled: !!params?.form,
    });
  // console.log({ singleForm });
  const initialValues = {
    house_owner_name: "",
    house_type: "",
    house_address: "",
    occupation: "",
    phone: "",
    email: "",
    contact_address: "",
    guarantor: "",
    partnership_status: "active",
    partnership_id: "",
  };
  const [formData, setFormData] = useState(initialValues);
  const [files, setFiles] = useState({
    signature: undefined,
  });
  const [filesSrc, setFilesSrc] = useState({
    signature: undefined,
  });

  const handleFileChange = (e, name) => {
    if (e.target.files[0]) {
      setFiles((val) => ({
        ...val,
        [name]: e.target.files[0],
      }));
      setFilesSrc((val) => ({
        ...val,
        [name]: URL.createObjectURL(e.target.files[0]),
      }));
    }
  };

  const handleChange = (event, name) => {
    const value = event.target?.value;
    setFormData((data) => ({
      ...data,
      [name]: value,
    }));
  };

  const {
    // error: fileUploadError,
    handleFileUpload: uploadFile,
    isLoading: isUploadingFlie,
    // data: uploadUrl,
  } = useFileUpload();

  const { postCaller, isLoading } = usePostFamilyProcess({
    endpoint: "house-dedication",
    mutationKey: ["family_process"],
    onSuccess: () => window[id]?.close(),
  });

  function handleFileUpload() {
    return new Promise(async (resolve, reject) => {
      try {
        const uploadPromises = Object.values(files)?.map(async (file) => {
          const fileUrl = await uploadFile(file);
          return fileUrl;
        });

        const uploadedFiles = await Promise.all(uploadPromises);
        resolve(uploadedFiles);
        return uploadedFiles;
      } catch (error) {
        reject(error);
      }
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    // console.log({ formData });

    try {
      const [signature] = await handleFileUpload();

      await postCaller({
        ...formData,
        signature,
      });

      setFormData(initialValues);
      setFiles({});
      setFilesSrc({});
    } catch (error) {
      console.log({ error });
    }
  }

  useEffect(() => {
    if (singleForm) {
      setFormData({
        house_owner_name: singleForm?.data?.house_owner_name,
        house_type: singleForm?.data?.house_type,
        house_address: singleForm?.data?.house_address,
        occupation: singleForm?.data?.occupation,
        phone: singleForm?.data?.phone,
        email: singleForm?.data?.email,
        contact_address: singleForm?.data?.contact_address,
        guarantor: singleForm?.data?.guarantor,
        partnership_status: singleForm?.data?.partnership_status,
        partnership_id: singleForm?.data?.partnership_id,
      });
      setFilesSrc({
        signature: singleForm?.data?.signature,
      });
    }
  }, [singleForm]);

  useReadOnlyInputs(params?.form !== undefined);

  if (singleFormLoading) return <Loader />;
  return (
    <form onSubmit={handleSubmit} className="w-full">
      {params?.form && (
        <div className="mb-5 mt-1 flex items-center justify-between">
          <StatusBadge
            label={capitalizeFirstLetter(singleForm?.data?.status)}
            status={singleForm?.data?.status}
          />

          {/* {!edit && (
            <RoundedButton
              onClick={() => setEdit(true)}
              className="py-1 px-3 flex items-center gap-1 shadow-lg"
            >
              <EditIcon className={"stroke-white size-4"} /> Edit
            </RoundedButton>
          )} */}
        </div>
      )}
      <InputWithFullBoarder
        label={"House Owner's Name"}
        type="text"
        id="house_owner_name"
        value={formData.house_owner_name}
        onChange={(e) => handleChange(e, "house_owner_name")}
        placeholder={"Enter house owner's name"}
        className={"bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-xs"}
        otherProps={{
          required: true,
        }}
      />

      <InputWithFullBoarder
        label={"House Type"}
        type="text"
        id="house_type"
        value={formData.house_type}
        onChange={(e) => handleChange(e, "house_type")}
        placeholder={"Enter house type"}
        className={"bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-xs"}
        otherProps={{
          required: true,
        }}
      />

      <InputWithFullBoarder
        label={"House Address"}
        type="text"
        id="house_address"
        value={formData.house_address}
        onChange={(e) => handleChange(e, "house_address")}
        placeholder={"Enter house address"}
        className={"bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-xs"}
        otherProps={{
          required: true,
        }}
      />

      <InputWithFullBoarder
        label={"Owner's Occupation"}
        type="text"
        id="occupation"
        value={formData.occupation}
        onChange={(e) => handleChange(e, "occupation")}
        placeholder={"Enter owner's occupation"}
        className={"bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-xs"}
        otherProps={{
          required: true,
        }}
      />

      <InputWithFullBoarder
        label={"Phone Number"}
        type="tel"
        id="phone"
        value={formData.phone}
        onChange={(e) => handleChange(e, "phone")}
        placeholder={"Enter phone number"}
        className={"bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-xs"}
        otherProps={{
          required: true,
        }}
      />

      <InputWithFullBoarder
        label={"Email Address"}
        type="email"
        id="email"
        value={formData.email}
        onChange={(e) => handleChange(e, "email")}
        placeholder={"Enter your email address"}
        className={"bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-xs"}
        otherProps={{
          required: true,
        }}
      />

      <InputWithFullBoarder
        label={"Address"}
        type="text"
        id="contact_address"
        value={formData.contact_address}
        onChange={(e) => handleChange(e, "contact_address")}
        placeholder={"Enter your address"}
        className={"bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-xs"}
        otherProps={{
          required: true,
        }}
      />

      <SelectInput
        label={"Guarantor"}
        type="text"
        id="guarantor"
        value={formData.guarantor}
        onChange={(e) => handleChange(e, "guarantor")}
        placeholder={"Select guarantor"}
        className={"bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-xs"}
        otherProps={{
          required: true,
        }}
        options={guarantorList}
      />

      <RadioGroupInput
        label={"Partnership Status"}
        name="partnership_status"
        value={formData.partnership_status}
        onChange={(e) => handleChange(e, "partnership_status")}
        options={partnershipOptions}
      />

      <InputWithFullBoarder
        label={"Partnership ID"}
        type="text"
        id="partnership_id"
        value={formData.partnership_id}
        onChange={(e) => handleChange(e, "partnership_id")}
        placeholder={"Enter partnership ID"}
        className={"bg-[#F2F2F2] placeholder:text-[#8E8E8E] text-xs"}
        otherProps={{
          required: formData?.partnership_status === "active",
        }}
      />

      <div className="mb-4">
        <label className={`text-sm font-medium mb-2`}>Signature</label>
        {/* <p className="text-textGrey font-medium text-xs">
            Add one photo each for both parties.
          </p> */}
        <div className="flex gap-6 items-start mt-3">
          <FileInput
            // label={"Father"}
            onChange={(e) => handleFileChange(e, "signature")}
            required
            src={filesSrc?.signature}
            value={files?.signature}
          />
        </div>
      </div>

      {!params?.form && (
        <RoundedButton
          className={
            "rounded-[40px] text-base font-medium w-full py-5 mt-10 grid place-content-center"
          }
        >
          {isLoading || isUploadingFlie ? (
            <Spinner />
          ) : params?.form ? (
            "Update"
          ) : (
            "Submit"
          )}
        </RoundedButton>
      )}
    </form>
  );
};

export default HouseDedicationModal;
