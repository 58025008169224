// import { galleryItem } from "assets/images";
import { format } from "date-fns";
import React from "react";
import { Link } from "react-router-dom";

const GalleryItem = ({ item }) => {
  return (
    <Link to={`/gallery/${item?.id}`}>
      <div className="rounded-[15px] min-w-[300px] h-[222px] px-2.5 py-4 relative overflow-hidden flex items-end">
        <img
          src={item?.cover_image?.url}
          alt=""
          className="object-cover w-full h-full absolute top-0 left-0"
        />
        <div className="w-full h-full top-0 left-0 absolute bg-gradient-to-b from-purpleBg/0 to-purpleOverlay"></div>
        <div className="bg-[#1D1D1D80] px-2.5 py-[5px] rounded-[50px] flex items-center justify-center gap-[5px] relative z-10 w-fit">
          <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.282 10.4967C19.282 11.2661 18.6313 11.89 17.8285 11.89C17.0258 11.89 16.375 11.2661 16.375 10.4967C16.375 9.72728 17.0258 9.10352 17.8285 9.10352C18.6313 9.10352 19.282 9.72728 19.282 10.4967Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M18.7885 5.76332C17.6846 5.62106 16.2741 5.62107 14.4933 5.62109H10.508C8.72722 5.62107 7.31672 5.62106 6.21284 5.76332C5.07678 5.90973 4.15724 6.2182 3.43209 6.91328C2.70693 7.60837 2.38511 8.48975 2.23237 9.5787C2.08395 10.6368 2.08397 11.9888 2.08398 13.6957V13.8006C2.08397 15.5074 2.08395 16.8594 2.23237 17.9175C2.38511 19.0065 2.70693 19.8879 3.43209 20.583C4.15724 21.2781 5.07677 21.5865 6.21284 21.733C7.31673 21.8751 8.72722 21.8751 10.508 21.8751H14.4933C16.2741 21.8751 17.6846 21.8751 18.7885 21.733C19.9245 21.5865 20.8441 21.2781 21.5692 20.583C22.2944 19.8879 22.6162 19.0065 22.7689 17.9175C22.9173 16.8594 22.9173 15.5074 22.9173 13.8006V13.6958C22.9173 11.9888 22.9173 10.6368 22.7689 9.5787C22.6162 8.48975 22.2944 7.60837 21.5692 6.91328C20.8441 6.2182 19.9245 5.90973 18.7885 5.76332ZM6.40652 7.1441C5.43162 7.26974 4.86994 7.50534 4.45986 7.89843C4.04978 8.2915 3.80397 8.82989 3.6729 9.76434C3.56777 10.5139 3.54423 11.4507 3.53898 12.676L4.02944 12.2646C5.20039 11.2825 6.96519 11.3389 8.06539 12.3935L12.2221 16.3777C12.6383 16.7767 13.2935 16.8311 13.775 16.5067L14.064 16.3121C15.4499 15.3785 17.325 15.4866 18.5842 16.5729L21.0922 18.7364C21.1947 18.4614 21.2721 18.1332 21.3284 17.7319C21.4623 16.7774 21.4639 15.5192 21.4639 13.7482C21.4639 11.977 21.4623 10.7188 21.3284 9.76434C21.1973 8.82989 20.9515 8.2915 20.5415 7.89843C20.1314 7.50534 19.5697 7.26974 18.5948 7.1441C17.599 7.01577 16.2864 7.01429 14.4387 7.01429H10.5626C8.71497 7.01429 7.40231 7.01577 6.40652 7.1441Z"
              fill="white"
            />
            <path
              d="M17.7973 2.71948C16.9017 2.60446 15.7613 2.60447 14.3399 2.60449H11.1214C9.69992 2.60447 8.55962 2.60446 7.66399 2.71948C6.73651 2.83859 5.96322 3.09236 5.3499 3.67819C4.99618 4.01606 4.75796 4.40484 4.5957 4.84333C5.12091 4.60374 5.71471 4.46256 6.38139 4.3762C7.51095 4.22988 8.95426 4.22989 10.7764 4.2299H14.8545C16.6767 4.22989 18.1199 4.22988 19.2495 4.3762C19.8318 4.45163 20.3584 4.56888 20.8324 4.75697C20.6713 4.35363 20.4418 3.99371 20.1113 3.67819C19.498 3.09236 18.7248 2.83859 17.7973 2.71948Z"
              fill="white"
            />
          </svg>
          <span>{item?.image_count}</span>
        </div>
      </div>

      <p className="text-purpleOverlay font-semibold">{item?.title}</p>
      <p className="text-gray-400 text-xs">{format(item?.createdAt, "PPP")}</p>
    </Link>
  );
};

export default GalleryItem;
