import { useQuery } from "react-query";
import AxiosWithToken from "../../../../constants/api_management/MyHttpHelperWithToken";

const useGetAllPermissions = ({ enabled = true }) => {
  return useQuery(
    ["all_permissions"],
    async () => {
      try {
        const [response] = [await AxiosWithToken.get(`/permissions`)];
        console.log(`i am checking this ${response.data.response}`);
        return response.data;
      } catch (error) {
        console.log(error.response.data);
        throw new Error(`Sorry: ${error.response.data.message}`);
      }
    },
    { enabled: enabled }
  );
};

export default useGetAllPermissions;
