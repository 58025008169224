import ModalManagement from "generalComponents/ModalManagement";
import React from "react";

import DisplayFile from "generalComponents/DisplayFile";
import CustomButton from "generalComponents/Button";
import { EditSermonManager } from "../controllers/editSermonController";
import AudioItem from "generalComponents/AudioItem";
import SermonDisc from "generalComponents/SermonDisc";

const ViewSermon = ({ details }) => {
  const { editSermon, isLoading: editing } = EditSermonManager(details?.id);
  return (
    <ModalManagement id={"view_sermon"} title={"Sermon Details"}>
      <div className="w-full md:w-[500px] flex flex-col gap-5 mt-5">
        {details?.content_type === "audio" && (
          <div className="w-full flex items-center justify-center relative max-w-max mx-auto">
            <SermonDisc
              image={details?.cover_image?.url}
              className={"mb-2 border-purpleBg"}
              small={false}
            />
            <svg
              width="65"
              height="65"
              viewBox="0 0 65 65"
              fill="none"
              className="absolute inset-x-22 inset-y-21  z-10 mb-1.5 "
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="32.5"
                cy="32.5"
                r="32.5"
                fill="url(#paint0_linear_507_11351)"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M25 26.1362C25 23.7143 27.6317 22.2091 29.7192 23.4371L41.2592 30.2253C43.3176 31.4361 43.3176 34.4128 41.2592 35.6236L29.7192 42.4118C27.6317 43.6398 25 42.1346 25 39.7127V26.1362Z"
                fill="white"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_507_11351"
                  x1="32.5"
                  y1="0"
                  x2="32.5"
                  y2="65"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#7A10A6" />
                  <stop offset="1" stop-color="#2F0640" />
                </linearGradient>
              </defs>
            </svg>
          </div>
        )}
        <DisplayFile fileUrl={details?.content ? details?.content : ""} />
        <div className="w-full flex flex-col">
          <p className="text-12px font-light text-textGrey">
            {" "}
            Category:{" "}
            <span className="text-brandPurple font-medium">
              {details?.category?.name}
            </span>
          </p>
          <p className="text-20px text-purpleOverlay font-semibold">
            {details?.title}
          </p>
          <p className="text-[15px] text-brandOrange font-light">
            {details?.facilitator}
          </p>
        </div>
        <div className="flex flex-col w-full">
          <p className="text-[15px] text-purpleOverlay">Description</p>
          <p className="text-12px text-textGrey">{details?.description}</p>
        </div>

        <div className="flex flex-col w-full">
          <CustomButton
            buttonText={details?.featured ? "Make Featured" : "Remove Featured"}
            isLoading={editing}
            onClick={async () => {
              const info = {
                featured: !details?.featured,
              };
              await editSermon(info);
              document.getElementById("view_sermon").close();
            }}
            buttonColor={details?.approved ? "bg-brandRed" : "bg-success"}
            radius={"rounded-[40px]"}
          />
          <div className="flex items-center gap-5 w-full my-2">
            <CustomButton
              buttonText={"Edit"}
              buttonColor={"bg-purpleOverlay"}
              className={"w-full"}
              radius={"rounded-[40px]"}
              onClick={() => {
                document.getElementById("view_sermon").close();
                document.getElementById("add_sermon").showModal();
              }}
            />
            <CustomButton
              buttonText={"Delete"}
              buttonColor={"bg-brandRed"}
              onClick={() => {
                document.getElementById("admin_delete").showModal();
              }}
              className={"w-full"}
              radius={"rounded-[40px]"}
            />
          </div>
        </div>
      </div>
    </ModalManagement>
  );
};

export default ViewSermon;
