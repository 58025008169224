import usePostManager from "constants/controller_templates/post_controller_template";

export const CreateCampusManager = () => {
  const { postCaller, isLoading, isSuccess, error, data } = usePostManager(
    `/campuses/create`,
    ["campuses"]
  );
  const createCampus = async (details) => {
    try {
      await postCaller(details);
    } catch (error) {
      // Handle error if necessary
      console.error("post error:", error);
    }
  };
  return {
    createCampus,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
