import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../../../generalComponents/Loader";
import ErrorManager from "../../../../generalComponents/ErrorManager";
import useGetGalleryImagesManager from "../controller/getGalleryImagesController";
import BaseDashboardNavigation from "../../../../generalComponents/BaseDashboardNavigation";
import { AiOutlineArrowLeft } from "react-icons/ai";
import useGetGallerySingleManager from "../controller/getGallerySingleController";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import CustomButton from "../../../../generalComponents/Button";
import { AddImagesManager } from "../controller/addImagesController";
import useFileUpload from "../../../fileupload/fileUploadController";
import { AiFillDelete } from "react-icons/ai";
import { DeleteGalleryImageManager } from "../controller/deleteGalleryImageController";
import GoBackButton from "generalComponents/GoBackButton";
import UploadFileComponent from "generalComponents/UploadFileComponent";
import AdminWrapper from "modules/admin/AdminWrapper";

const GalleryImagePage = () => {
  const [images, setImages] = useState([]);
  const imageRef = useRef();
  const { galleryId } = useParams();
  const { data, isLoading } = useGetGalleryImagesManager(galleryId);
  const {
    progress,
    handleFileUpload,
    isLoading: fileLoading,
  } = useFileUpload();
  const { data: galleryDetails, isLoading: loadingGallery } =
    useGetGallerySingleManager(galleryId);

  const { addImages, isLoading: addingImages } = AddImagesManager(galleryId);

  useEffect(() => {
    const uploadFiles = async () => {
      if (images.length > 0) {
        const fileUrls = [];

        for (let i = 0; i < images.length; i++) {
          const url = await handleFileUpload(images[i]);
          fileUrls.push({
            url: url,
            thumbnail: url,
          });
        }
        addImages({ images: fileUrls });
      }
    };

    uploadFiles();
  }, [images]);

  const { isLoading: deletingImages, deleteImage } =
    DeleteGalleryImageManager(galleryId);

  if (isLoading || loadingGallery) {
    return <Loader />;
  }

  return (
    <AdminWrapper title={"Gallery Details"}>
      <div className="w-full flex items-center mb-10">
        <GoBackButton />
      </div>
      <div className="w-full pr-10 flex items-center justify-between">
        <h2 class="text-[20px] font-bold text-black w-full mb-6">
          {addingImages
            ? `Uploading ${progress}%`
            : fileLoading
            ? "Submitting..."
            : galleryDetails.data.title}
        </h2>
        <div className="w-full pr-10">
          <UploadFileComponent
            description={`Upload Images`}
            inputRef={imageRef}
            isLoading={fileLoading}
            format={"Images"}
            multiple={true}
            files={images}
            fileName={images ? images.name : null}
            progress={progress}
            accept={"image/*"}
            buttonClick={() => setImages([])}
            onChange={async (e) => {
              if (e.target.files.length > 0) {
                setImages(e.target.files);
              }
            }}
          />
        </div>
      </div>
      <section class="w-full pt-5 pr-10">
        <ResponsiveMasonry
          columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3, 1240: 4 }}
        >
          <Masonry gutter="10px">
            {data.data.map((image, index) => (
              <div key={index} className="relative group rounded-[20px]">
                <div
                  onClick={() => {
                    deleteImage(galleryId);
                  }}
                  className="absolute opacity-0 top-4 right-4 group-hover:opacity-100 text-red-600 bg-brandOrange p-3 rounded-full cursor-pointer transition-opacity duration-200"
                >
                  <AiFillDelete />
                </div>
                <img
                  className="h-auto w-full rounded-[20px]"
                  src={image.image.thumbnail}
                  alt=""
                />
                {/* <p className="mt-5 text-base font-bold text-gray-600">{`Image ${index + 1}`}</p> */}
              </div>
            ))}
          </Masonry>
        </ResponsiveMasonry>
      </section>
    </AdminWrapper>
  );
};

export default GalleryImagePage;
