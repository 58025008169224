import React from "react";
import { useAudioContext } from "../view";
import AudioDiskWrapper from "generalComponents/AudioDiskWrapper";
import SermonDisc from "generalComponents/SermonDisc";
import AudioProgress from "./AudioProgress";
import AudioControls from "./AudioControls";

const AudioModal = ({ id }) => {
  const { currentAudio, setCurrentAudio, setMinimize } = useAudioContext();

  function onClose() {
    window[id]?.close();
    setCurrentAudio(undefined);
  }
  function minimize() {
    setMinimize(true);
    window[id]?.close();
  }
  return (
    <dialog id={id} className="modal modal-middle mx-auto outline-none">
      <div className="modal-backdrop bg-black/30 backdrop-blur-md"></div>
      <div className="relative modal-box p-5 bg-white shadow-xl text-black pb-20 no-scrollbar">
        <div className="fixed left-0 top-0 w-full h-[50%] bg-gradient-to-br from-purpleBg/30 to-white fade-b-20"></div>
        <div className="fixed left-0 top-[50%] w-full h-[50%] bg-gradient-to-br to-orangeBg/30 from-[#FFFFFF] fade-y-20"></div>

        <div className="relative z-10">
          <div className="grid grid-cols-3 mb-16">
            <span></span>
            <p className="text-lg font-medium text-purpleOverlay text-center">
              Sermon
            </p>
            <div className="flex justify-end items-center">
              <div className="flex justify-end divide-[#BDBDBD] divide-x gap-3">
                <button onClick={minimize}>
                  <span className="sr-only">Minimize</span>
                  <svg
                    width="17"
                    height="2"
                    viewBox="0 0 17 2"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L16 1"
                      stroke="#8311B3"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                    />
                  </svg>
                </button>
                <button onClick={onClose}>
                  <span className="sr-only">Close</span>
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.79688 6.79297L20.3866 20.3826"
                      stroke="#8311B3"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                    />
                    <path
                      d="M6.79688 20.3867L20.3866 6.79704"
                      stroke="#8311B3"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <AudioDiskWrapper large className={"mx-auto mb-9"}>
            <SermonDisc
              image={currentAudio?.cover_image?.url}
              className={"border-purpleBg"}
            />
          </AudioDiskWrapper>
          <div className="flex items-center gap-5 justify-between mb-8">
            <div className="">
              <p className="text-xl text-purpleOverlay font-semibold">
                {currentAudio?.title}
              </p>
              <p className="text-xs text-orangeBg">
                {currentAudio?.facilitator}
              </p>
            </div>
            <div className="flex items-center">
              <a href={currentAudio?.content} download={currentAudio?.title}>
                <svg
                  width="50"
                  height="50"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.5"
                    y="0.5"
                    width="49"
                    height="49"
                    rx="24.5"
                    fill="#FFF3E5"
                  />
                  <rect
                    x="0.5"
                    y="0.5"
                    width="49"
                    height="49"
                    rx="24.5"
                    stroke="#FF8A00"
                  />
                  <path
                    d="M24.7733 16.3574V28.0486M24.7733 28.0486L29.7838 23.0381M24.7733 28.0486L19.7628 23.0381"
                    stroke="#FF8A00"
                    strokeWidth="1.58426"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M31.4541 33.0586H24.7734H18.0928"
                    stroke="#FF8A00"
                    strokeWidth="1.58426"
                    strokeLinecap="round"
                  />
                </svg>
              </a>
            </div>
          </div>
          <div className="flex flex-col items-center gap-10">
            <AudioProgress />
            <AudioControls large />
          </div>

          <div className="mt-5">
            <p className="font-semibold text-purpleOverlay text-lg mt-5">
              Description
            </p>
            <p className="text-sm text-purpleOverlay whitespace-pre-line">
              {currentAudio?.description}
            </p>
          </div>
        </div>
      </div>
    </dialog>
  );
};

export default AudioModal;
