import { useMemo } from "react";

export function useIsTouchDevice() {
  const isTouchDevice = useMemo(() => {
    return () => {
      return (
        "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        Number(navigator?.msMaxTouchPoints) > 0
      );
    };
  }, []);

  return isTouchDevice();
}
