import { useQuery } from "react-query";
import AxiosWithToken from "../../../../constants/api_management/MyHttpHelperWithToken";

const useDownloadAllUserDataManager = ({ enabled = true }) => {
  return useQuery(
    ["userData", enabled],
    async () => {
      try {
        const [response] = [await AxiosWithToken.get(`/user/export`)];

        return response.data;
      } catch (error) {
        throw new Error(`Sorry: ${error.response.data.message}`);
      }
    },
    { enabled: enabled, refetchOnWindowFocus: false }
  );
};

export default useDownloadAllUserDataManager;
