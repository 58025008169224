import React, { useEffect, useState } from "react";
// import { authentication, topPattern } from "../../../assets/images";
import InputWithFullBoarder from "../../../generalComponents/InputWithFullBoarder";
import { Link } from "react-router-dom";
import AuthenticationBase from "../components/AuthenticationBase";
import useLoginManager from "../controllers/login.controller";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { toast } from "react-toastify";
import Loader from "generalComponents/Loader";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [viewPassword, setViewPassword] = useState(false);

  const [windowLoading, setLoading] = useState(true);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const { postCaller, data, isSuccess, isLoading, error } =
    useLoginManager(email);

  useEffect(() => {
    if (isSuccess) {
      toast.success(data.message);
    }
    if (error) {
      toast.error(error.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, error, data]);

  const handleSubmission = async (e) => {
    e.preventDefault();
    const details = {
      id: email,
      password: password,
    };

    await postCaller(details);

    setEmail("");
    setPassword("");
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  if (windowLoading) return <Loader ball />;
  return (
    <AuthenticationBase
      className={"animate-fade-in"}
      title={"Welcome back!"}
      subtitle={
        <>
          Don't have an account?{" "}
          <Link to={"/create-account"} className="text-purpleBg">
            Sign Up
          </Link>
        </>
      }
      inputFields={
        <div>
          <InputWithFullBoarder
            label={"Email address"}
            type="email"
            id="email"
            value={email}
            onChange={handleEmailChange}
          />
          <InputWithFullBoarder
            label={"Password"}
            type={viewPassword ? "text" : "password"}
            hasSuffix={true}
            icon={
              viewPassword ? (
                <AiOutlineEyeInvisible
                  size={22}
                  onClick={() => setViewPassword(!viewPassword)}
                />
              ) : (
                <AiOutlineEye
                  size={22}
                  onClick={() => setViewPassword(!viewPassword)}
                />
              )
            }
            id="password"
            value={password}
            onChange={handlePasswordChange}
          />
          {/* <div className="w-full flex items-end justify-end">
            <button
              onClick={() => navigate("/forgot-password")}
              className="text-[14px] md:text-[20px] w-full font-semibold text-blackColor text-right mx-auto hover:scale-125 hover:text-blackColor duration-300"
            >
              Forgot Password?
            </button>
          </div> */}
        </div>
      }
      buttonText={"Login"}
      isLoading={isLoading}
      onClick={handleSubmission}
      afterElements={
        <div className="flex flex-col items-center ">
          <p className="text-sm py-5 text-gray-500 text-center">
            Forgot password?{" "}
            <Link to={"/forgot-password"}>
              <span className="duration-300 hover:underline  text-orangeBg">
                {"Recover"}
              </span>
            </Link>
          </p>
        </div>
      }
    />
  );
};

export default LoginPage;
