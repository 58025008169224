import AxiosWithToken from "constants/api_management/MyHttpHelperWithToken";
import { useInfiniteQuery, useQuery } from "react-query";

const useGetPraiseReports = (params = {}) => {
  return useInfiniteQuery(
    ["praise_reports", params],
    async ({ pageParam: page = 1 }) => {
      try {
        const [response] = [
          await AxiosWithToken.get(`/testimony/public`, {
            params: { page, ...params },
          }),
        ];
        //console.log(`this is the current subscription status ${response}`);
        return response.data;
      } catch (error) {
        //console.log(error.response.data);
        throw new Error(`Sorry: ${error.response.data.message}`);
      }
    },
    {
      getNextPageParam: (lastPage, pages) => {
        return lastPage?.data?.pagination?.nextPage;
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const useGetPraiseReportById = (id, param = "") => {
  const queryParam = param === "" ? "" : `/${param}`;
  return useQuery(
    ["praise_reports_by_id", id],
    async () => {
      try {
        const response = await AxiosWithToken.get(
          `/testimony/${id}${queryParam}`
        );
        //console.log(`this is the current subscription status ${response}`);
        return response.data;
      } catch (error) {
        //console.log(error.response.data);
        throw new Error(`Sorry: ${error.response.data.message}`);
      }
    },
    {
      select: (data) => data.data,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const useGetPraiseReportComments = (id) => {
  return useQuery(
    ["praise_reports_comments", id],
    async () => {
      try {
        const response = await AxiosWithToken.get(`/testimony/${id}/comments`);
        //console.log(`this is the current subscription status ${response}`);
        return response.data;
      } catch (error) {
        //console.log(error.response.data);
        throw new Error(`Sorry: ${error.response.data.message}`);
      }
    },
    {
      select: (data) => data.data,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};

export default useGetPraiseReports;
